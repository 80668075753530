import type { Dispatch, SetStateAction } from 'react'
import type {
  CloseCampaignState,
  AddTuitionToCampaignState,
} from '@monorepo/enrollment/pages/Campaigns/CampaignDetails/types'
import { mdiCalendar, mdiCancel } from '@mdi/js'
import { Tooltip } from '@gravity/tooltip'
import { IconButton } from '@gravity/icon-button'
import { Table } from '@gravity/table'
import Icon from '@mdi/react'

type Props = {
  enrollmentID: string
  setAddTuitionDialogState: Dispatch<SetStateAction<AddTuitionToCampaignState>>
  setCloseCampaignDialogState: Dispatch<SetStateAction<CloseCampaignState>>
  showAddCampaignTuitionOption: boolean
  showRemoveStudentFromCampaignOption: boolean
  studentName?: string
}
export const StudentsTableActions = ({
  enrollmentID,
  studentName,
  showRemoveStudentFromCampaignOption,
  setCloseCampaignDialogState,
  showAddCampaignTuitionOption,
  setAddTuitionDialogState,
}: Props) => {
  return (
    <Table.ActionCell
      actions={
        <>
          {showAddCampaignTuitionOption && (
            <Tooltip text="Adicionar parcelas" position="left">
              <IconButton
                variant="ghost"
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation()
                  setAddTuitionDialogState({ enrollmentID, studentName, openDialog: true })
                }}
              >
                <Icon path={mdiCalendar} size={16} />
              </IconButton>
            </Tooltip>
          )}
          {showRemoveStudentFromCampaignOption && (
            <Tooltip text="Cancelar proposta" position="left">
              <IconButton
                variant="ghost"
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation()
                  setCloseCampaignDialogState({ enrollmentID, openDialog: true })
                }}
              >
                <Icon path={mdiCancel} size={16} />
              </IconButton>
            </Tooltip>
          )}
        </>
      }
    />
  )
}
