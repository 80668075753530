import { REALMS } from '@/shared/constants'
import {
  LiquidationPaymentMethodAdminLabel,
  LiquidationPaymentMethodAdminLabelSourceAccoutIsaac,
  LiquidationPaymentMethodLabel,
  LiquidationSource,
} from '@/shared/interfaces'
import { useState } from 'react'
import BoxFooter from '../../BoxFooter/BoxFooter'
import type { PaymentMethodFieldProps } from '../types'
import * as S from '../styles'
import { Select } from '@gravity/select'

const PaymentMethodField = ({
  realm,
  onSave,
  onCancel,
  value = '',
  source,
}: PaymentMethodFieldProps) => {
  const [selectedValue, setSelectedValue] = useState(value)

  const handleValue = (value: any) => {
    setSelectedValue(value)
  }

  const getAvailablePaymentMethods = () => {
    if (realm === REALMS.SCHOOL_REALM) return LiquidationPaymentMethodLabel
    if (source === LiquidationSource.ISAAC_ACCOUNT)
      return LiquidationPaymentMethodAdminLabelSourceAccoutIsaac
    return LiquidationPaymentMethodAdminLabel
  }

  const liquidationPaymentMethodOptions = getAvailablePaymentMethods()

  const isValid = !!selectedValue

  return (
    <S.StyledBox>
      <S.StyledContent>
        <Select
          size={3}
          variant="surface"
          placeholder="Forma de pagamento"
          aria-label="Forma de pagamento"
          options={Object.keys(liquidationPaymentMethodOptions).map(payment_method => ({
            label: liquidationPaymentMethodOptions[payment_method],
            value: payment_method,
          }))}
          value={selectedValue}
          onValueChange={handleValue}
        />
      </S.StyledContent>
      <BoxFooter isValid={isValid} onCancel={onCancel} onSave={() => onSave(selectedValue)} />
    </S.StyledBox>
  )
}

export default PaymentMethodField
