import { FC } from 'react'
import { Typography } from '@olaisaac/design-system'

import { formatCentsToReal } from 'src/shared/utils'

import InstallmentCard from '../InstallmentCard'

import { v4 as uuidv4 } from 'uuid'

import * as S from './styles'
import { InstallmentDetailProps } from './types'
import StepFooter from '../StepFooter'

const InstallmentsDetail: FC<InstallmentDetailProps> = ({
  installmentsByStudents,
  stepName,
  totalAmount,
  onFinish,
  goBack,
  isLoading,
  hideDiscount = false,
  helpButtonEntry,
}) => {
  return (
    <div style={{ marginTop: '16px' }}>
      <S.Wrapper>
        {installmentsByStudents.map((student, index) => (
          <div key={student.studentId}>
            {index / 2 !== 0 && <S.CardSeparator />}

            <Typography withoutMargin variation="subtitleDesktopLarge" style={{ marginBottom: 24 }}>
              {student.studentName}
            </Typography>

            {student.products.map(product => (
              <InstallmentCard
                key={product.productId + uuidv4()}
                productName={product.productName}
                installmentsLabel={product.consolidatedMonthNames}
                total={formatCentsToReal(product.finalAmount)}
                installmentsPrice={formatCentsToReal(product.baseAmount)}
                finesAndInterest={formatCentsToReal(product.finesAndInterest)}
                discount={formatCentsToReal(-product.discounts)}
                isOverdue={!!student.isOverdue}
                hideDiscount={hideDiscount}
              />
            ))}
          </div>
        ))}

        <StepFooter
          goBack={goBack}
          isLoading={isLoading}
          onFinish={onFinish}
          totalAmount={totalAmount}
          stepName={stepName}
          showHelpButton
          helpButtonEntry={helpButtonEntry}
        />
      </S.Wrapper>
    </div>
  )
}

export default InstallmentsDetail
