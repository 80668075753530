import { normalizeText } from 'src/shared/utils/normalizeText'

import { FIXED_STUDENTS_FILTER_OPTIONS } from '../../constants'

import { OptionType } from '@/shared/components/CollapsibleFilterGroup/types'
import { FixedStudentsFilterType } from '../../types'

const filterOptionsByText = (list: OptionType[], text: string) => {
  return list.filter(({ label }) => {
    const normalizedLabel = normalizeText(label)
    const normalizedText = normalizeText(text)

    return normalizedLabel.includes(normalizedText)
  })
}

/**
 * Function to filter options for fixed filters by a provided term
 *
 * @param searchText Term to search
 *
 * @returns Object containing options that match for each filter category
 */
export const filterFixedFilterOptionsByText = (searchText: string) => {
  return FIXED_STUDENTS_FILTER_OPTIONS.reduce((acc, item) => {
    return {
      ...acc,
      [item.filterName]: filterOptionsByText(item.options, searchText),
    }
  }, {} as Record<FixedStudentsFilterType['filterName'], OptionType[]>)
}
