import { useHistory } from 'react-router-dom'
import { DashboardCard } from '../DashboardCard'
import { DatePicker } from '@monorepo/report/components/DatePicker'
import dayjs from 'dayjs'
import { TileDisabledFunctionArgs } from '@olaisaac/design-system'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'

export type IsaacPayPayoutReportData = {
  ended_at: Date
  id: string
  isOpen: boolean
  payoutValue: number
  started_at: Date
}

type IsaacPayReportDashboardCardProps = {
  currentPayout?: IsaacPayPayoutReportData
  hasError: boolean
  isaacPayPayouts: IsaacPayPayoutReportData[]
  isLoading: boolean
  onChange: (isaacPayPayoutReportId: string) => void
  onRetry: () => void
}

/**
 * Specialized dashboard card for isaac pay payout report
 *
 * @param props
 * @param props.currenPayout Current selected isaac pay payout report
 * @param props.isaacPayPayouts List of available isaac pay payout reports
 * @param props.isLoading Indicates if the loading state should be displayed
 * @param props.hasError Indicates if the error state should be displayed
 * @param props.onChange On change callback function
 * @param props.onRetry On retry callback function
 */
export const IsaacPayReportDashboardCard = ({
  currentPayout,
  isaacPayPayouts,
  isLoading,
  hasError,
  onChange,
  onRetry,
}: IsaacPayReportDashboardCardProps) => {
  const { push } = useHistory()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const findIsaacPayPayoutByDate = (date: Date) =>
    isaacPayPayouts.find(
      item => dayjs(item.ended_at).format('YYYY-MM-DD') === dayjs(date).format('YYYY-MM-DD')
    )

  const handleChangeSelectedPayout = (date: Date) => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.REPORT_DASHBOARD,
        name: EventDispatcherEvents.ISAAC_PAY_PAYOUT_DATE_CHANGE_ON_REPORT_DASHBOARD,
        action: 'click',
        customProperties: {
          $page_name: 'Dashboard de repasse isaac pay',
        },
      })

    const payout = findIsaacPayPayoutByDate(date)

    if (!payout) return

    onChange(payout.id)
  }

  const handleNavigateToIsaacPayPayoutReport = () => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.REPORT_DASHBOARD,
        name: EventDispatcherEvents.ISAAC_PAY_NAVIGATION_TO_PAYOUT_REPORT,
        action: 'click',
        customProperties: { $page_name: 'Dashboard de repasses Isaac Pay' },
      })
    push(`repasses/isaac-pay${currentPayout && `?id=${currentPayout.id}`}`)
  }

  const checkIfDateShouldBeDisabled = ({ view, date }: TileDisabledFunctionArgs) => {
    if (view !== 'month') return false

    const payout = findIsaacPayPayoutByDate(date)

    return !payout
  }
  const isaacPayMinDate = isaacPayPayouts.at(0)?.ended_at
  const isaacPayMaxDate = isaacPayPayouts.at(-1)?.ended_at

  const datePicker = currentPayout ? (
    <DatePicker
      valueTemplate="DD [de] MMMM [de] YYYY"
      variation="ghost"
      maxDetail="month"
      minDetail="decade"
      minDate={isaacPayMinDate}
      maxDate={isaacPayMaxDate}
      onChange={handleChangeSelectedPayout}
      tileDisabled={checkIfDateShouldBeDisabled}
      value={dayjs(currentPayout?.ended_at).toDate()}
    />
  ) : (
    <></>
  )

  return (
    <DashboardCard
      title="Repasse diário"
      isPayoutOpen={currentPayout?.isOpen ?? true}
      payoutAmountFormat="microcents"
      payoutValue={currentPayout?.payoutValue ?? 0}
      isLoading={isLoading}
      hasError={hasError}
      onRequestDetails={handleNavigateToIsaacPayPayoutReport}
      onRetry={onRetry}
      payoutSubtitleLabel="Valor total"
      datePicker={datePicker}
    />
  )
}
