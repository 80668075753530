import type { RouteDefinition } from '@/shared/core/router'
import { UploadPage } from './pages/UploadPage'
import { useJWT, UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { ValidatePage } from './pages/ValidatePage'
import { BatchPage } from './pages/BatchPage'
import { PreviewPage } from './pages/PreviewPage'

const canAccess = (): boolean => {
  const { isBackofficeUser } = useJWT()
  const ffEnabled = useUnleashFlag(UnleashFlags.EFI_395_PI_ONBOARDING)

  return isBackofficeUser || ffEnabled
}

export const routes: RouteDefinition[] = [
  {
    key: 'ONBOARDING',
    path: '/migrar-dados',
    component: UploadPage,
    exact: true,
    canAccess,
  },
  {
    key: 'ONBOARDING_PREVIEW',
    path: '/migrar-dados/:batchID/pre-visualizar',
    component: PreviewPage,
    exact: true,
    canAccess,
  },
  {
    key: 'ONBOARDING_VALIDATE',
    path: '/migrar-dados/:batchID/validar',
    component: ValidatePage,
    exact: true,
    canAccess,
  },
  {
    key: 'BATCH',
    path: '/migrar-dados/:batchID/migrar',
    component: BatchPage,
    exact: true,
    canAccess,
  },
]
