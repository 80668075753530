import { useLayout } from '@/shared/hooks/useLayout'
import { StyledCard, TextGroup } from './styles'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useGetSchoolEligibility } from '../../hooks/queries/useGetSchoolEligibility'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { LoadingHome } from './LoadingHome'
import {
  useContextChosenOffer,
  useContextCreditOrderId,
  useContextCreditValue,
  useContextEligibility,
  useContextStepperMap,
} from '../../contexts/CreditDataContext'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { NoCreditAvailable } from '../NoCreditAvailable'
import { CreditEventComponentNames } from '../../constants/CreditEventComponentNames.enum'
import { MixpanelEventActions } from '../../constants/MixpanelEventActions.enum'
import useMixpanelEventSender from '../../utils/useMixpanelEventSender'
import { useGetOrderStatus } from '../../hooks/queries/useGetOrderStatus'
import { isObjectEmpty } from '../../utils/validateObject'
import { HomeTemplate } from '../../templates/HomeTemplate'
import { InfoCard } from '../../components/InfoCard'
import { Grid, GridItem } from '@gravity/grid'

import { IconButton } from '@gravity/icon-button'
import { useGetLock } from '../../hooks/queries/useGetLock'
import { GetOrderStatusResponse } from '@/shared/services/credit/types'
import { OrderLock } from './types'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks/useUnleashFlag'
import { Link } from '@gravity/link'
import { Text } from '@gravity/text'
import { Heading } from '@gravity/heading'
import { spacing } from '@gravity/tokens'
import {
  Check,
  CalendarEventOutline,
  MoneyDollarCircleOutline,
  ArrowRightUpLong,
} from '@gravity/icons'

export const CreditHome = () => {
  useLayout({ enableSideMenu: true, enableHeader: true, headerTitle: 'Crédito' })
  const { push } = useHistory()
  const { school, schoolSlug } = useSelectedSchool()
  const schoolId = school?.id ?? ''
  const isInMaintenance = useUnleashFlag(UnleashFlags.CRD_166_DISABLE_CREDIT_FLOWS)
  const { data: lock, isLoading: isLoadingLock, refetch: refetchLock } = useGetLock(schoolId, {
    enabled: !isInMaintenance,
  })
  const {
    data: orderStatus,
    isLoading: isLoadingStatus,
    refetch: refetchStatus,
  } = useGetOrderStatus<GetOrderStatusResponse>(schoolId, undefined, {
    enabled: !isInMaintenance,
  })

  const shouldCallEligibility =
    !isInMaintenance && !isLoadingStatus && isObjectEmpty(orderStatus?.data)

  const { data: eligibility, isLoading, refetch: refetchEligibility } = useGetSchoolEligibility(
    schoolId,
    {
      enabled: shouldCallEligibility,
    }
  )

  const eligible = eligibility?.eligibility?.eligible ?? { CP: false, CPCR: false }

  const [showSimulationPage, setShowSimulationPage] = useState(false)

  const { setEligibility } = useContextEligibility()
  const { setChosenOffer } = useContextChosenOffer()
  const { setCreditValue } = useContextCreditValue()
  const { setStepperMap } = useContextStepperMap()
  const { setCreditOrderId } = useContextCreditOrderId()

  const { sendCreditEventToMixpanel } = useMixpanelEventSender()

  useEffect(() => {
    sendCreditEventToMixpanel(EventDispatcherEvents.PAGE_VIEWED, MixpanelEventActions.PAGE_VIEW, {
      $page_name: CreditEventComponentNames.LOADING_HOME_PAGE,
    })

    if (!isInMaintenance) {
      refetchLock()
      refetchStatus()

      if (shouldCallEligibility) {
        refetchEligibility()
      }
    }
  }, [])

  useEffect(() => {
    if (!isLoading && eligibility) setEligibility(eligibility)

    if (eligible.CP || eligible.CPCR) {
      sendCreditEventToMixpanel(EventDispatcherEvents.PAGE_VIEWED, MixpanelEventActions.PAGE_VIEW, {
        $page_name: CreditEventComponentNames.HOME_PAGE,
      })
    } else {
      sendCreditEventToMixpanel(EventDispatcherEvents.PAGE_VIEWED, MixpanelEventActions.PAGE_VIEW, {
        $page_name: CreditEventComponentNames.NO_CREDIT_HOME,
      })
    }
  }, [eligibility])

  const orderLock: OrderLock =
    !isLoadingLock && lock
      ? {
          isLocked: lock.isLocked ?? false,
          creditType: lock.order?.data?.chosen_offer?.type ?? '',
        }
      : {
          isLocked: false,
          creditType: '',
        }

  const shouldRenderLoadingHome = shouldCallEligibility && isLoading
  const shouldRenderLoadingStatus = !isInMaintenance && !shouldCallEligibility && isLoadingStatus
  const shouldRenderIneligibleState = shouldCallEligibility && !eligible.CP && !eligible.CPCR
  const shouldRenderStatusPage = !isLoadingStatus && !isObjectEmpty(orderStatus?.data)

  if (shouldRenderLoadingHome || shouldRenderLoadingStatus) {
    return <LoadingHome />
  }

  if (shouldRenderIneligibleState) {
    return <NoCreditAvailable />
  }

  if (showSimulationPage) {
    push(`/${schoolSlug}/credito/simulacao`)
  }

  if (shouldRenderStatusPage) {
    const { chosenOffer, creditValue, stepperMapper, orderId } = orderStatus?.data ?? {}

    if (chosenOffer) {
      setChosenOffer(chosenOffer)
    }

    if (creditValue) {
      setCreditValue(creditValue)
    }

    if (stepperMapper) {
      setStepperMap(stepperMapper)
    }

    if (orderId) {
      setCreditOrderId(orderId)
    }

    push(`/${schoolSlug}/credito/estado`)
  }

  const handleSimulateClick = () => {
    sendCreditEventToMixpanel(EventDispatcherEvents.BUTTON_CLICKED, MixpanelEventActions.CLICK, {
      $button_name: 'Simule agora',
    })
    setShowSimulationPage(true)
  }

  const cards = [
    <InfoCard
      key={1}
      icon={<Check />}
      title="Sem burocracia"
      description="Solicitamos apenas dois documentos para contratação de créditos pré-aprovados. Escolas parceiras têm acesso a capital sem burocracia."
    />,
    <InfoCard
      key={2}
      icon={<MoneyDollarCircleOutline />}
      title="Desconto direto no repasse"
      description="Sabemos que lidar com muitos boletos no dia a dia pode ser complicado. Por isso, no isaac, a parcela do seu crédito é descontada diretamente do repasse."
    />,
    <InfoCard
      key={3}
      icon={<CalendarEventOutline />}
      title="Pula parcela em janeiro"
      description="O início do ano letivo pode trazer despesas desafiadoras. Por isso, no isaac, não fazemos cobrança de parcelas de crédito em janeiro. O faturamento da escola não é impactado logo no início do ano."
    />,
  ]
  return (
    <HomeTemplate
      orderLock={orderLock}
      title={'Crédito que impulsiona os melhores planos \n da sua escola'}
      cardDescription={
        'Simule quantas vezes quiser, sem compromisso, \n e contrate o crédito ideal para sua escola.'
      }
      isEligible
      cards={cards}
      mainCardDescription="Pensado e feito para escolas"
      onClickButton={() => handleSimulateClick()}
      bottomInfo={
        <>
          <Grid className="grid-component">
            <GridItem xl={8} lg={8} md={8} sm={5}>
              <StyledCard>
                <TextGroup style={{ gap: `${spacing[6]}` }}>
                  <Heading variant="heading-h2-medium">
                    Entenda as possibilidades de parcelamento
                  </Heading>
                  <Text variant="body-1-regular">
                    As parcelas de crédito são condicionadas à duração do contrato vigente da escola
                    com o isaac. Créditos que excedem a vigência atual só podem ser liberados
                    mediante renovação ou extensão do contrato.
                    <br />
                    <br />
                    Faça uma simulação e veja as possibilidades da sua oferta.
                  </Text>
                </TextGroup>

                <Text variant="body-2-regular">
                  Leia:&nbsp;
                  <Link
                    rel="noreferrer"
                    target="_blank"
                    href="https://centraldeajuda.olaisaac.io/credito-isaac/entenda-como-funciona-o-parcelamento-de-credito-no-isaac"
                  >
                    Entenda como funciona o parcelamento de crédito no isaac
                  </Link>
                </Text>
              </StyledCard>
            </GridItem>
            <GridItem xl={4} lg={4} md={4} sm={3}>
              <StyledCard>
                <TextGroup style={{ gap: `${spacing[4]}` }}>
                  <Heading variant="heading-h4-medium">Dúvidas frequentes</Heading>
                  <Text variant="body-1-regular">
                    Quais documentos são exigidos? Quando o dinheiro estará na minha conta? Consulte
                    aqui suas principais dúvidas sobre o crédito isaac.
                  </Text>
                </TextGroup>

                <IconButton
                  size={2}
                  onClick={() => {
                    sendCreditEventToMixpanel(
                      EventDispatcherEvents.BUTTON_CLICKED,
                      MixpanelEventActions.CLICK,
                      {
                        $page_name: CreditEventComponentNames.FAQ,
                      }
                    )
                    window.open('https://centraldeajuda.olaisaac.io/credito-isaac', '_blank')
                  }}
                >
                  <ArrowRightUpLong />
                </IconButton>
              </StyledCard>
            </GridItem>
          </Grid>
        </>
      }
      isInMaintenance={isInMaintenance}
    />
  )
}
