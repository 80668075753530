import { useHistory } from 'react-router-dom'

import Icon from '@mdi/react'
import { mdiArrowLeft, mdiPlus } from '@mdi/js'
import { Button } from '@gravity/button'

import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import * as Styled from './styles'
import { CardDetailsHeaderProps } from './types'
import { CURRENT_YEAR } from '@/shared/constants'
import { GUARDIAN_ID_QUERY_PARAM } from '@/escolas/components/contract/create/constants'
import useAddContractEnabled from '@monorepo/enrollment/hooks/useAddContractEnabled'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

const GuardianDetailsHeaderSkeleton = ({
  isAddContractButtonEnabled,
}: {
  isAddContractButtonEnabled: boolean
}) => {
  return (
    <Styled.Container>
      <Styled.BackButton variant="ghost" disabled>
        <Icon path={mdiArrowLeft} size="1.25rem" />
        Voltar
      </Styled.BackButton>

      <Styled.ButtonGroup>
        <Button variant="ghost" disabled>
          Editar cadastro
        </Button>
        {isAddContractButtonEnabled && (
          <Styled.AddContractButton disabled>
            <Icon path={mdiPlus} size="1.25rem" style={{ marginBottom: 2, marginTop: 2 }} />
            Adicionar contrato
          </Styled.AddContractButton>
        )}
      </Styled.ButtonGroup>
    </Styled.Container>
  )
}

export const GuardianDetailsHeader = ({
  schoolSlug,
  guardianId,
  isLoading,
  openEditRegistrationDrawer,
}: CardDetailsHeaderProps) => {
  const history = useHistory()
  const isAddContractButtonEnabled = useAddContractEnabled()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const sendEvent = (scope: EventDispatcherEventScopes, name: EventDispatcherEvents) => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope,
        name,
        action: 'click',
      })
  }

  if (isLoading) {
    return <GuardianDetailsHeaderSkeleton isAddContractButtonEnabled={isAddContractButtonEnabled} />
  }

  const onClickAddContractBtn = () => {
    sendEvent(EventDispatcherEventScopes.GUARDIANS, EventDispatcherEvents.CREATE_CONTRACT_GUARDIAN)

    const path = `/${schoolSlug}/contratos/${CURRENT_YEAR}`
    const params = new URLSearchParams()
    params.append(GUARDIAN_ID_QUERY_PARAM, guardianId)

    history.push({
      pathname: `${path}/novo`,
      search: params.toString(),
    })
  }

  return (
    <Styled.Container>
      <Styled.BackButton variant="ghost" onClick={() => history.goBack()} data-testid="backButton">
        <Icon path={mdiArrowLeft} size="1.25rem" />
        Voltar
      </Styled.BackButton>
      <Styled.ButtonGroup>
        <Button
          variant="ghost"
          onClick={() => {
            sendEvent(
              EventDispatcherEventScopes.GUARDIANS,
              EventDispatcherEvents.PERSONAL_DATA_CHANGE
            )
            openEditRegistrationDrawer()
          }}
          data-testid="guardianRegistrationViewButton"
        >
          Editar cadastro
        </Button>

        {isAddContractButtonEnabled && (
          <Styled.AddContractButton
            onClick={() => onClickAddContractBtn()}
            data-testid="guardianAddContractButton"
          >
            <Icon path={mdiPlus} size="1.25rem" style={{ marginBottom: 2, marginTop: 2 }} />
            Adicionar contrato
          </Styled.AddContractButton>
        )}
      </Styled.ButtonGroup>
    </Styled.Container>
  )
}
