import { useEffect, useMemo, useState } from 'react'
import { Box } from '@material-ui/core'
import { Chip } from '@olaisaac/design-system'

import { useTuitionPayoutReportFilter } from '../../hooks/useTuitionPayoutReportFilter'
import { useEnrollmentPayoutReportFilter } from '../../hooks/useEnrollmentPayoutReportFilter'

import type { PayoutReportType } from '../../models/PayoutReportType'
import type { Filter as TuitionPayoutReportFilter } from '@monorepo/report/hooks/useTuitionPayoutReportFilter/types'
import type { Filter as EnrollmentPayoutReportFilter } from '@monorepo/report/hooks/useEnrollmentPayoutReportFilter/types'

type FilterTag = {
  label: string
  type: string
  value: string
}

type PayoutReportActiveFiltersProps = {
  filterOptions: Record<string, Omit<FilterTag, 'type'>[]>
  reportType: PayoutReportType
}

export const PayoutReportActiveFilters = ({
  reportType,
  filterOptions,
}: PayoutReportActiveFiltersProps) => {
  const {
    filter: tuitionFilter,
    updateFilter: updateTuitionFilter,
  } = useTuitionPayoutReportFilter()

  const {
    filter: enrollmentFilter,
    updateFilter: updateEnrollmentFilter,
  } = useEnrollmentPayoutReportFilter()

  const [activeFilterTags, setActiveFiltersTags] = useState<FilterTag[]>([])
  const [containerWidth, setContainerWidth] = useState(0)

  useEffect(() => {
    const availableOptions = Object.entries(filterOptions).reduce((acc, [, value]) => {
      return acc + value.length
    }, 0)

    if (availableOptions === 0) return

    const tags = Object.keys(filterOptions).reduce<FilterTag[]>((acc, key) => {
      const options = filterOptions[key]

      let activeOptions: FilterTag[] = []

      if (reportType !== 'enrollment') {
        activeOptions = tuitionFilter[key as keyof TuitionPayoutReportFilter].map(
          (item: string) => {
            return {
              type: key,
              label: options.find(option => option.value === item)?.label ?? '',
              value: item,
            }
          }
        )
      } else {
        activeOptions = enrollmentFilter[key as keyof EnrollmentPayoutReportFilter].map(
          (item: string) => {
            return {
              type: key,
              label: options.find(option => option.value === item)?.label ?? '',
              value: item,
            }
          }
        )
      }

      return [...acc, ...activeOptions]
    }, [])

    setActiveFiltersTags(tags)
  }, [tuitionFilter, enrollmentFilter, filterOptions])

  useEffect(() => {
    const updateDrawerWidth = () => {
      const width = document.querySelector<HTMLDivElement>('#tags-container')?.offsetWidth

      if (width) setContainerWidth(width)
    }

    updateDrawerWidth()

    window.addEventListener('resize', updateDrawerWidth)

    return () => {
      window.removeEventListener('resize', updateDrawerWidth)
    }
  }, [])

  const handleRemoveTuitionOption = (type: keyof TuitionPayoutReportFilter, value: string) => {
    updateTuitionFilter({
      ...tuitionFilter,
      [type]: tuitionFilter[type].filter(item => item !== value),
    })
  }

  const handleRemoveEnrollmentOption = (
    type: keyof EnrollmentPayoutReportFilter,
    value: string
  ) => {
    updateEnrollmentFilter({
      ...enrollmentFilter,
      [type]: enrollmentFilter[type].filter(item => item !== value),
    })
  }

  const displayableTagsCount = useMemo(() => {
    const CHAR_WIDTH = 6.3
    const DEFAULT_MARGIN = 72
    const CHIP_SPACE = 32

    const activeFilterTagsWidthSize = activeFilterTags.map(item => ({
      ...item,
      size: item.label.length * CHAR_WIDTH + CHIP_SPACE,
    }))

    let sizeAccumulator = DEFAULT_MARGIN

    return activeFilterTagsWidthSize.filter(item => {
      sizeAccumulator += item.size

      if (containerWidth > sizeAccumulator) return true

      return false
    }).length
  }, [activeFilterTags, containerWidth])

  return (
    <Box
      id="tags-container"
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      flexWrap="wrap"
      mt="1rem"
    >
      {activeFilterTags.slice(0, displayableTagsCount).map(({ label, value, type }) => (
        <Box ml="0.5rem" key={value}>
          <Chip
            variation="blue"
            label={label}
            onDelete={() => {
              if (reportType !== 'enrollment') {
                handleRemoveTuitionOption(type as keyof TuitionPayoutReportFilter, value)
                return
              }

              handleRemoveEnrollmentOption(type as keyof EnrollmentPayoutReportFilter, value)
            }}
          />
        </Box>
      ))}

      {activeFilterTags.length > displayableTagsCount && (
        <Box ml="0.5rem">
          <Chip variation="blue" label={`+${activeFilterTags.length - displayableTagsCount}`} />
        </Box>
      )}
    </Box>
  )
}
