import { useEffect, useState } from 'react'
import { Accordion } from '@gravity/accordion'
import { Heading } from '@gravity/heading'
import { Badge, BadgeProps } from '@gravity/badge'
import { Skeleton } from '@gravity/skeleton'

import Icon from '@mdi/react'
import { mdiAlertCircle, mdiMinusCircle } from '@mdi/js'

import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'

import { PaymentDisputeSummaryState } from '@/shared/models/enums/PaymentDisputeSummaryState.enum'
import { PaymentDisputeSummary } from '@/shared/models/PaymentDisputeSummary'
import { PaymentDisputeAccordionContent } from '../PaymentDisputeAccordionContent'

import {
  AccordionContainer,
  AccordionTriggerItem,
  AccordionTriggerItems,
  AccordionTriggerWrapper,
} from './styles'
import { useGetPaymentDisputeItems } from '@/shared/hooks/queries/usePaymentDisputeQueries'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventPageName } from '@/shared/models/enums/EventPageName.enum'
import { EventIdentifierName } from '@/shared/models/enums/EventIdentifierName.enum'

export type PaymentDisputeAccordionProps = {
  data?: PaymentDisputeSummary[]
  loading?: boolean
}

type PaymentDisputeStatusProps = {
  color: BadgeProps['color']
  title: string
}

const paymentDisputeStatusMapping: Record<PaymentDisputeSummaryState, PaymentDisputeStatusProps> = {
  [PaymentDisputeSummaryState.STARTED]: {
    color: 'warning',
    title: 'Iniciada',
  },
  [PaymentDisputeSummaryState.NOT_STARTED]: {
    color: 'error',
    title: 'Não iniciada',
  },
  [PaymentDisputeSummaryState.COMPLETED]: {
    color: 'neutral',
    title: 'Concluída',
  },
}

export const PaymentDisputeAccordion = ({ loading, data }: PaymentDisputeAccordionProps) => {
  const [selectPaymentDisputeId, setSelectPaymentDisputeId] = useState('')

  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const { isLoading, data: items } = useGetPaymentDisputeItems(
    { id: selectPaymentDisputeId },
    { enabled: !!selectPaymentDisputeId }
  )

  const paymentDisputeItems = items?.data || []

  const handleClickPaymentDispute = (id: string) => {
    setSelectPaymentDisputeId(id)
  }

  useEffect(() => {
    if (isInitialized && selectPaymentDisputeId !== '') {
      const paymentDispute = data?.find(item => item.id === selectPaymentDisputeId)

      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.PAYMENT_DISPUTE,
        name: EventDispatcherEvents.BUTTON_CLICKED,
        pageName: EventPageName.PAYMENT_DISPUTE,
        identifierName: EventIdentifierName.SEE_PAYMENT_DISPUTE_DETAILS,
        customProperties: {
          $button_name: 'Detalhes da disputa',
          $type: paymentDispute?.type,
          $status: paymentDispute?.status,
          $payment_dispute_id: selectPaymentDisputeId,
        },
      })
    }
  }, [isInitialized, selectPaymentDisputeId])

  if (loading) {
    return (
      <Accordion.Root type="single">
        <AccordionContainer>
          {[1, 2, 3].map(item => (
            <Accordion.Item key={item} value={`item-${uuidv4()}`} disabled>
              <Accordion.Trigger>
                <AccordionTriggerWrapper>
                  <Skeleton width={200} height={24} />

                  <AccordionTriggerItems>
                    <AccordionTriggerItem>
                      <Skeleton radius="full" width={24} height={24} />
                      <Skeleton width={130} height={24} />
                    </AccordionTriggerItem>
                    <AccordionTriggerItem>
                      <Skeleton radius="full" width={24} height={24} />
                      <Skeleton width={130} height={24} />
                    </AccordionTriggerItem>
                  </AccordionTriggerItems>
                </AccordionTriggerWrapper>
              </Accordion.Trigger>
            </Accordion.Item>
          ))}
        </AccordionContainer>
      </Accordion.Root>
    )
  }

  const getDaysRemainingToResolve = (createdAt: string): number => {
    const endOfCreationDay = dayjs.utc(createdAt).endOf('day')
    const limitDay = endOfCreationDay.add(30, 'day')
    const today = dayjs().utc().endOf('day')
    return limitDay.diff(today, 'day')
  }

  const getPaymentDisputeDaysOpenText = (createdAt: string) => {
    const daysRemainingToResolve = getDaysRemainingToResolve(createdAt)

    if (daysRemainingToResolve > 1) {
      return `Você tem ${daysRemainingToResolve} dias para resolver esta contestação`
    }

    if (daysRemainingToResolve === 1) {
      return `Você tem ${daysRemainingToResolve} dia para resolver esta contestação`
    }

    if (daysRemainingToResolve === 0) {
      return `Hoje é seu último dia para resolver esta contestação`
    }

    if (daysRemainingToResolve < 0) {
      return `Contestação aberta em ${dayjs(createdAt).format('DD/MM/YYYY')}`
    }

    return `Você tem ${daysRemainingToResolve} dias para resolver esta contestação`
  }

  const getPaymentDisputeDaysFinishText = (createdAt: string) => {
    return `Contestação aberta em ${dayjs(createdAt).format('DD/MM/YYYY')}`
  }

  const getPaymentDisputeText = (createdAt: string, status: PaymentDisputeSummaryState) => {
    if (status === PaymentDisputeSummaryState.COMPLETED) {
      return getPaymentDisputeDaysFinishText(createdAt)
    }

    return getPaymentDisputeDaysOpenText(createdAt)
  }

  return (
    <Accordion.Root type="single">
      <AccordionContainer>
        {data?.map(payment_dispute => {
          const { id, created_at, guardian, status, total_items } = payment_dispute
          const { title, color } = paymentDisputeStatusMapping[status]
          const text = getPaymentDisputeText(created_at, status)

          return (
            <Accordion.Item key={id} value={`item-${id}`}>
              <Accordion.Trigger
                onClick={() => handleClickPaymentDispute(id)}
                labelComponent={
                  <Badge variant="soft" color={color}>
                    {title}
                  </Badge>
                }
                descriptionList={[
                  {
                    iconStart: <Icon path={mdiAlertCircle} color="gray" />,
                    text: `${total_items} ${total_items > 1 ? 'faturas' : 'fatura'}`,
                  },
                  {
                    iconStart: <Icon path={mdiMinusCircle} color="gray" />,
                    text,
                  },
                ]}
              >
                <Heading variant="heading-h4-medium">{guardian?.name}</Heading>
              </Accordion.Trigger>

              <PaymentDisputeAccordionContent
                loading={isLoading}
                paymentDispute={payment_dispute}
                paymentDisputeItems={paymentDisputeItems}
              />
            </Accordion.Item>
          )
        })}
      </AccordionContainer>
    </Accordion.Root>
  )
}
