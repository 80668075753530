import { mdiBlockHelper, mdiPencil, mdiUndo } from '@mdi/js'
import { Tooltip } from '@gravity/tooltip'
import { IconButton } from '@gravity/icon-button'
import { Table } from '@gravity/table'
import Icon from '@mdi/react'
import type { CustomizationStepActionTableProps } from '../CustomizationStepTable/types'
import { useAddBulkInstallmentStore } from '@monorepo/enrollment/store/AddBulkInstallmentStore'

export const CustomizationStepActionTable = ({
  enrollmentID,
  isStudentRemovedFromBulkAdd,
  setAddCustomTuitionDialogState,
  studentName,
}: CustomizationStepActionTableProps) => {
  const {
    setExcludedEnrollmentIds,
    removeEnrollmentFromExcludedEnrollmentIds,
    removeCustomInstallmentsFormData,
  } = useAddBulkInstallmentStore()
  return (
    <Table.ActionCell
      actions={
        <>
          {!isStudentRemovedFromBulkAdd && (
            <Tooltip text="Personalizar mensalidades" position="left">
              <IconButton
                variant="ghost"
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation()
                  setAddCustomTuitionDialogState({ enrollmentID, studentName, openDialog: true })
                }}
              >
                <Icon path={mdiPencil} size={16} />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip
            text={isStudentRemovedFromBulkAdd ? 'Restaurar aluno' : 'Remover aluno'}
            position="left"
          >
            <IconButton
              variant="ghost"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation()
                if (isStudentRemovedFromBulkAdd) {
                  removeEnrollmentFromExcludedEnrollmentIds(enrollmentID)
                  return
                }
                setExcludedEnrollmentIds(enrollmentID)
                removeCustomInstallmentsFormData(enrollmentID)
              }}
            >
              <Icon path={isStudentRemovedFromBulkAdd ? mdiUndo : mdiBlockHelper} size={16} />
            </IconButton>
          </Tooltip>
        </>
      }
    />
  )
}
