import { FormControl } from '@material-ui/core'
import BoxFooter from '../../BoxFooter/BoxFooter'
import type { DiscountFieldProps } from '../types'
import * as S from '../styles'
import { TextField } from '@olaisaac/design-system'
import { Checkbox } from '@gravity/checkbox'
import { Callout } from '@gravity/callout'
import { Controller } from 'react-hook-form'
import { formatCentsToReal, formatRealToCents } from '@/shared/utils'
import NumberFormat from 'react-number-format'
import { CircularLoading } from '@/shared/components/CircularLoading'

const DiscountsField = ({
  onSave,
  onCancel,
  discountsInfo,
  form,
  isLoading,
}: DiscountFieldProps) => {
  const { control, formState, getValues, setValue } = form
  const isAdditionalDiscountAmountInvalid = Boolean(
    formState.isDirty && formState.errors.additional_discount_amount
  )
  const formValues = getValues()
  const validateSave = () => {
    if (isLoading) return false
    if (
      discountsInfo?.isAdditionalDiscountEnabled &&
      (formValues.additional_discount_amount?.toString() === '0' ||
        !discountsInfo?.validateTotalDiscount)
    ) {
      return false
    }
    return true
  }

  const isValid = validateSave()

  return (
    <S.StyledBox>
      <S.StyledContent>
        {isLoading && (
          <S.StyledBoxLoading>
            <CircularLoading />
          </S.StyledBoxLoading>
        )}
        {!!discountsInfo?.warningMessage && (
          <div style={{ width: '100%' }}>
            <Callout text={discountsInfo?.warningMessage} />
          </div>
        )}
        {(!!discountsInfo?.fineAndInterest || !!discountsInfo?.fineAndInterestChange) && (
          <>
            <S.StyledTypograph>Multas</S.StyledTypograph>
            {discountsInfo?.fineAndInterest !== 0 && (
              <S.StyledBoxFields>
                <Controller
                  defaultValue
                  name="apply_previous_fines_and_interest"
                  control={control}
                  render={({ field: { onChange, value, ...rest } }) => (
                    <Checkbox
                      id="fine-and-interest-checkbox"
                      disabled={discountsInfo?.disableFinesAndInterest}
                      text="Multa e juros"
                      size={1}
                      onCheckedChange={onChange}
                      {...rest}
                      checked={value}
                    />
                  )}
                />
                <S.NoWrapTypography variant="body-1-regular" className="ml-2">
                  {formatCentsToReal(discountsInfo?.previousFinesAndInterest || 0)}
                </S.NoWrapTypography>
              </S.StyledBoxFields>
            )}

            {discountsInfo?.fineAndInterest !== 0 && discountsInfo?.fineAndInterestChange !== 0 && (
              <S.StyledBoxFields>
                <Controller
                  defaultValue
                  name="apply_fines_and_interest_change"
                  control={control}
                  render={({ field: { onChange, value, ...rest } }) => (
                    <Checkbox
                      id="fine-and-interest-change-checkbox"
                      disabled={discountsInfo?.disableFineAndInterestChange}
                      text="Multa e juros mudança de data"
                      size={1}
                      onCheckedChange={onChange}
                      {...rest}
                      checked={discountsInfo?.applyPreviousFinesAndInterest ? value : false}
                    />
                  )}
                />
                {discountsInfo?.applyPreviousFinesAndInterest ? (
                  <S.NoWrapTypography variant="body-1-regular" aria-disabled className="ml-2">
                    {formatCentsToReal(discountsInfo?.fineAndInterestChange || 0)}
                  </S.NoWrapTypography>
                ) : (
                  <S.NoWrapTypographyDisabled
                    variant="body-1-regular"
                    aria-disabled
                    className="ml-2"
                  >
                    {formatCentsToReal(discountsInfo?.fineAndInterestChange || 0)}
                  </S.NoWrapTypographyDisabled>
                )}
              </S.StyledBoxFields>
            )}
          </>
        )}
        {(!!discountsInfo?.lostEarlyPaymentDiscount ||
          !!discountsInfo?.lostDuePaymentDiscount ||
          !discountsInfo?.isRenegotiated) && (
          <>
            <S.StyledTypograph className="mb-1">Descontos</S.StyledTypograph>
            {discountsInfo?.lostEarlyPaymentDiscount !== 0 && (
              <S.StyledBoxFields>
                <Controller
                  defaultValue={false}
                  name="apply_lost_early_payment_discount"
                  control={control}
                  render={({ field: { onChange, value, ...rest } }) => (
                    <Checkbox
                      id="lost-early-payment-checkbox"
                      disabled={discountsInfo?.disableEarlyPayment}
                      onCheckedChange={onChange}
                      {...rest}
                      checked={value}
                      size={1}
                      text="Antecipação"
                    />
                  )}
                />
                <S.NoWrapTypography variant="body-1-regular" className="ml-2">
                  {formatCentsToReal(discountsInfo?.lostEarlyPaymentDiscount || 0)}
                </S.NoWrapTypography>
              </S.StyledBoxFields>
            )}
            {discountsInfo?.lostDuePaymentDiscount !== 0 && (
              <S.StyledBoxFields>
                <Controller
                  defaultValue={false}
                  name="apply_lost_due_payment_discount"
                  control={control}
                  render={({ field: { onChange, value, ...rest } }) => (
                    <Checkbox
                      id="lost-due-payment-checkbox"
                      disabled={discountsInfo?.disableDuePayment}
                      text="Pontualidade"
                      onCheckedChange={onChange}
                      {...rest}
                      size={1}
                      checked={value}
                    />
                  )}
                />
                <S.NoWrapTypography variant="body-1-regular" className="ml-2">
                  {formatCentsToReal(discountsInfo?.lostDuePaymentDiscount || 0)}
                </S.NoWrapTypography>
              </S.StyledBoxFields>
            )}
            {!discountsInfo?.isRenegotiated && (
              <>
                <Checkbox
                  id="other-discounts-checkbox"
                  text="Outros descontos"
                  disabled={discountsInfo?.disableMoreDiscounts}
                  size={1}
                  onCheckedChange={checked => {
                    discountsInfo?.setIsAdditionalDiscountEnabled?.(!!checked)
                    if (!checked) {
                      setValue('additional_discount_amount', 0)
                    }
                  }}
                  checked={discountsInfo?.isAdditionalDiscountEnabled}
                />

                {discountsInfo?.isAdditionalDiscountEnabled && (
                  <div style={{ paddingTop: '24px' }}>
                    <FormControl variant="outlined" fullWidth>
                      <Controller
                        control={control}
                        name="additional_discount_amount"
                        defaultValue={0}
                        render={({ field: { onChange, value } }) => (
                          <NumberFormat
                            disabled={discountsInfo?.disableMoreDiscounts}
                            id="additional-discount-input"
                            onBlur={event => {
                              onChange(formatRealToCents(event.target.value))
                              discountsInfo?.setIsAdditionalDiscountEnabled?.(true)
                            }}
                            customInput={TextField}
                            variant="outlined"
                            label="Desconto"
                            format={formatCentsToReal}
                            InputProps={{
                              inputProps: { min: 0 },
                            }}
                            value={value}
                            error={
                              isAdditionalDiscountAmountInvalid ||
                              !discountsInfo?.validateTotalDiscount
                            }
                            helperText={
                              isAdditionalDiscountAmountInvalid ||
                              !discountsInfo?.validateTotalDiscount
                                ? `O valor máximo de desconto é ${formatCentsToReal(
                                    discountsInfo?.finalBaseAmount
                                  )}`
                                : ''
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </S.StyledContent>
      <S.StyledBoxIndex>
        <BoxFooter isValid={isValid} onCancel={onCancel} onSave={() => onSave(form)} />
      </S.StyledBoxIndex>
    </S.StyledBox>
  )
}

export default DiscountsField
