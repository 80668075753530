import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { ContractDetailsDrawerState } from 'src/escolas/components/contract/ContractDetailsDrawer/constants'
import ContractDetailsDrawerDefaultContent from 'src/escolas/components/contract/ContractDetailsDrawer/ContractDetailsDrawerDefaultContent'
import ManualSignDrawer from 'src/escolas/components/contract/ManualSignDrawer'
import {
  Contract,
  ContractStatus,
  ReceivableStatuses,
  SignAndPaymentStatus,
  Receivable,
} from 'src/shared/interfaces'
import { Drawer } from '@olaisaac/design-system'
import ContractDetailsDrawerFooter from './ContractDetailsDrawerFooter'
import { IconButton } from '@gravity/icon-button'
import { Close, ArrowLeft } from '@gravity/icons'
import { Heading } from '@gravity/heading'
import * as Styled from './ContractDetailsDrawer.styles'

export type ContractDetailsDrawerProps = {
  addContract?: Dispatch<SetStateAction<boolean>>
  cancelContractQueryLoading?: boolean
  changeContractOwnership?: () => void
  contract: Contract
  drawerState: ContractDetailsDrawerState
  getContract: () => void
  hasInvoicesWithError: boolean
  isCheckoutSchool?: boolean
  isOpen: boolean
  isReenrollable?: boolean
  onClose: () => void
  openCancellationDrawer: () => void
  setDrawerState: Dispatch<SetStateAction<ContractDetailsDrawerState>>
  setIsConfirmationDialogVisible: (value: boolean) => void
  setShowInvoicesNotGeneratedDialog: (value: boolean) => void
}

export type ButtonHeaderContainerProps = {
  showGoBack: boolean
}

export const ContractDetailsDrawer = ({
  contract,
  getContract,
  isCheckoutSchool,
  isOpen,
  isReenrollable,
  onClose,
  drawerState,
  changeContractOwnership,
  setDrawerState,
  setIsConfirmationDialogVisible,
  addContract,
  openCancellationDrawer,
  hasInvoicesWithError,
  cancelContractQueryLoading,
  setShowInvoicesNotGeneratedDialog,
}: ContractDetailsDrawerProps) => {
  const [contractStatus, setContractStatus] = useState<string | null>(null)
  const [showAnnualPaidAmountsStatement, setShowAnnualPaidAmountsStatement] = useState(false)
  const goBackToDefaultContent = () => setDrawerState(ContractDetailsDrawerState.DEFAULT)
  const changeContractStatus = () => {
    const { status, sign_payment_status } = contract

    if (status === ContractStatus.PENDING) {
      return setContractStatus(ContractStatus.PENDING)
    }

    return status === ContractStatus.OPEN
      ? setContractStatus(
          SignAndPaymentStatus[sign_payment_status as keyof typeof SignAndPaymentStatus]
        )
      : setContractStatus(ContractStatus.CANCELED)
  }

  const isContractActive = contract?.status === 'OPEN'

  const isSignable = !!(
    (isContractActive && contractStatus === SignAndPaymentStatus.PAYMENT_AND_SIGN_PENDING) ||
    contractStatus === SignAndPaymentStatus.SIGN_PENDING
  )

  const hasPaidReceivables = (c: Contract = contract) => {
    let flatReceivables: Receivable[] = []

    c?.installments?.forEach(({ receivables }) => {
      if (receivables) {
        flatReceivables = [...flatReceivables, ...receivables]
      }
    })
    return flatReceivables.some(({ status }) => status === ReceivableStatuses.PAID)
  }

  useEffect(() => {
    if (!contract) return
    changeContractStatus()
    // feature toggle: the left side of the condition below must be removed when the test is over
    setShowAnnualPaidAmountsStatement(hasPaidReceivables(contract))
  }, [contract])

  const ContractDetailsDrawerMap = {
    [ContractDetailsDrawerState.DEFAULT]: (
      <ContractDetailsDrawerDefaultContent
        contract={contract}
        contractStatus={contractStatus ?? ''}
        setDrawerState={setDrawerState}
        isSignable={isSignable}
      />
    ),
    [ContractDetailsDrawerState.CONFIRM_MANUAL_SIGN]: (
      <ManualSignDrawer
        contractId={contract?.id}
        setDrawerState={setDrawerState}
        updateContractStatus={getContract}
        isSignable={isSignable}
      />
    ),
  }
  const HeaderTitleMap = {
    [ContractDetailsDrawerState.DEFAULT]: `Contrato ${contract?.reference_year}`,
    [ContractDetailsDrawerState.CONFIRM_MANUAL_SIGN]: 'Registrar assinatura',
  }
  const headerCheckoutTitle = `${contract?.product?.name} - ${contract?.reference_year}`

  const showGoBack = drawerState !== ContractDetailsDrawerState.DEFAULT

  return (
    <Drawer open={isOpen}>
      <Styled.HeaderContainer>
        <Styled.ButtonHeaderContainer showGoBack={showGoBack}>
          {showGoBack && (
            <IconButton onClick={goBackToDefaultContent} variant="ghost" size={1}>
              <ArrowLeft />
            </IconButton>
          )}
          <IconButton onClick={onClose} variant="ghost" size={1}>
            <Close />
          </IconButton>
        </Styled.ButtonHeaderContainer>

        <Heading variant="heading-h4-regular">
          {isCheckoutSchool
            ? headerCheckoutTitle
            : HeaderTitleMap[drawerState as keyof typeof HeaderTitleMap]}
        </Heading>
      </Styled.HeaderContainer>

      {ContractDetailsDrawerMap[drawerState as keyof typeof HeaderTitleMap]}
      <ContractDetailsDrawerFooter
        {...{
          contract,
          isCheckoutSchool,
          isReenrollable,
          setIsConfirmationDialogVisible,
          addContract,
          changeContractOwnership,
          openCancellationDrawer,
          cancelContractQueryLoading,
          showAnnualPaidAmountsStatement,
          hasInvoicesWithError,
          setShowInvoicesNotGeneratedDialog,
        }}
      />
    </Drawer>
  )
}
