import {
  ContainerCard,
  InternalContainerCard,
  StyledButton,
  ContainerButton,
  ResponsiveContainer,
  StyledCard,
  TextGroup,
} from './styles'
import { Tooltip } from '@gravity/tooltip'
import { HeaderHomeProps } from './types'
import { Grid, GridItem } from '@gravity/grid'
import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import { spacing } from '@gravity/tokens'

export const HeaderHome = ({
  description,
  isEligible,
  title,
  onClickButton,
  orderLock,
  isInMaintenance,
}: HeaderHomeProps) => {
  const hasOrderLock = orderLock?.isLocked

  const getSimulationButton = () => {
    const tooltipText = isInMaintenance
      ? `Estamos em manutenção, solicitação de crédito\n indisponível no momento. Voltamos em breve.`
      : hasOrderLock
      ? 'Existe um pedido de antecipação em andamento. Aguarde sua conclusão antes de simular uma oferta de crédito.'
      : ''

    return (
      <Tooltip
        position="bottom"
        text={tooltipText}
        style={hasOrderLock ? { maxWidth: '13.875rem' } : {}}
        hidden={!tooltipText}
      >
        <ContainerButton>
          <StyledButton size={2} disabled={isInMaintenance || hasOrderLock} onClick={onClickButton}>
            Simule agora
          </StyledButton>
        </ContainerButton>
      </Tooltip>
    )
  }

  return (
    <ContainerCard isEligible={isEligible}>
      <ResponsiveContainer>
        <Grid justify={isEligible ? 'start' : 'end'}>
          <GridItem md={isEligible ? 7 : 6}>
            <InternalContainerCard>
              <StyledCard isEligible={isEligible}>
                <TextGroup style={{ display: 'flex', flexDirection: 'column', gap: spacing[4] }}>
                  <Heading variant="heading-h1-medium">{title}</Heading>
                  <Text variant="body-1-regular">{description}</Text>
                </TextGroup>

                <GridItem>{isEligible && getSimulationButton()}</GridItem>
              </StyledCard>
            </InternalContainerCard>
          </GridItem>
        </Grid>
      </ResponsiveContainer>
    </ContainerCard>
  )
}
