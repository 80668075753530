import { StudentsEnrollmentsFilterType } from '@monorepo/enrollment/hooks/useStudentsEnrollmentsFilter/types'
import { useGetStatusSummary } from '@monorepo/enrollment/hooks/queries/enrollment'
import { useEnrollmentCycle } from '@monorepo/enrollment/hooks/queries/useEnrollmentCycle'
import { usePagination } from '@/shared/hooks/usePagination'

type UseEnrollmentDataProps = {
  name: string
  schoolId: string
  selectedYear: number
  studentsEnrollmentsFilter: StudentsEnrollmentsFilterType
}

export const useEnrollmentData = ({
  schoolId,
  selectedYear,
  name,
  studentsEnrollmentsFilter,
}: UseEnrollmentDataProps) => {
  const { pagination, updatePaginationValue } = usePagination(1, 25)

  const { data: enrollmentsData, isLoading: enrollmentDataLoading, isError } = useEnrollmentCycle({
    referenceYears: [selectedYear, selectedYear - 1].map(String),
    schoolId,
    name,
    enrollmentStatus: studentsEnrollmentsFilter.enrollmentStatus,
    enrollmentSubStatus: studentsEnrollmentsFilter.enrollmentSubStatus,
    pagination: {
      page: pagination.page,
      per_page: pagination.itemsPerPage,
    },
  })

  const {
    data: dataSummary,
    isLoading: isLoadingSummary,
    isError: isErrorSummary,
  } = useGetStatusSummary({
    schoolId,
    referenceYear: String(selectedYear),
  })

  return {
    enrollmentsData,
    dataSummary,
    pagination,
    updatePaginationValue,
    isLoading: enrollmentDataLoading,
    isLoadingSummary,
    isError,
    isErrorSummary,
  }
}
