import { useFlag, useFlagsStatus, useVariant } from '@unleash/proxy-client-react'

export enum UnleashFlags {
  B2BCOR_193_BLOCK_CONTRACT_REVOKE = 'B2BCOR-193-BLOCK_CONTRACT_REVOKE',

  COB_703_ENABLE_NEW_CHANGE_CONTRACT_OWNERSHIP_FLOW = 'COB-703_ENABLE_NEW_CHANGE_CONTRACT_OWNERSHIP_FLOW',

  /**
   * Determines if credit module is enabled
   */
  CRD_114_ENABLE_TERMS_AND_CONDITIONS_TO_AR = 'CRD_114_ENABLE_TERMS_AND_CONDITIONS_TO_AR',
  CRD_166_DISABLE_CREDIT_FLOWS = 'CRD_166_DISABLE_CREDIT_FLOWS',
  CRD_189_ENABLE_CREDIT_BANNER = 'CRD_189_ENABLE_CREDIT_BANNER',
  CRD_256_ENABLE_CAMPAIGN_CREDIT = 'CRD_256_ENABLE_CAMPAIGN_CREDIT',
  CRD_410_ENABLE_PAYOUT_DS_MIGRATION = 'CRD_410_ENABLE_PAYOUT_DS_MIGRATION',
  CRD_411_ENABLE_CREDIT_DS_MIGRATION = 'CRD_411_ENABLE_CREDIT_DS_MIGRATION',
  CRD_84_ENABLE_PAYOUT_ANTECIPATION = 'CRD_84_ENABLE_PAYOUT_ANTECIPATION',

  /**
   * Determines if the new flow to generate income tax installments is enabled
   */
  DIV_651_ENABLE_IR_STATEMENTS_FLOW = 'DIV-651_ENABLE_IR_STATEMENTS_FLOW',
  /**
   * FF from EFICIENCIA
   */
  EFI_168_CONTRACT_FORM_IMPROVEMENTS = 'EFI-168_CONTRACT_FORM_IMPROVEMENTS',
  EFI_171_STUDENT_CPF_AND_BIRTHDATE = 'EFI-171_STUDENT_CPF_AND_BIRTHDATE',
  EFI_215_CANCEL_CONTRACT_NEW_RULES = 'EFI-215_CANCEL_CONTRACT_NEW_RULES',
  EFI_239_VALIDATE_EMAIL_ON_CONTRACT_CREATE = 'EFI-239_VALIDATE_EMAIL_ON_CONTRACT_CREATE',
  EFI_248_STUDENT_EDITING = 'EFI-248-STUDENT-EDITING',
  EFI_279_STUDENT_SINGLENESS = 'EFI-279-STUDENT-SINGLENESS',

  EFI_395_PI_ONBOARDING = 'EFI-395_PI_ONBOARDING',
  ENABLE_CLASSAPP_CAMPAIGN_NOTIFICATION = 'MAT-94-ENABLE_CLASSAPP_CAMPAIGN_NOTIFICATION',
  ENABLE_DISCLAIMER = 'ENABLE-DISCLAIMER',
  ENABLE_MAINTENANCE_PAGE = 'ENABLE_MAINTENANCE_PAGE',
  ENABLE_NEW_RULES_FOR_SCHOOL_CONTRACT_EDITS = 'GC-1784-ENABLE_NEW_RULES_FOR_SCHOOL_CONTRACT_EDITS',
  FII_321_ENABLE_CANCEL_INSTALLMENTS = 'FII-321_ENABLE_CANCEL_INSTALLMENTS',
  FII_329_ENABLE_EDIT_INSTALLMENT_AMOUNT = 'FII-329_ENABLE_EDIT_INSTALLMENT_AMOUNT',
  FII_394_ENABLE_CANCEL_CONTRACT_VIA_INSTALLMENTS = 'FII-394_ENABLE_CANCEL_CONTRACT_VIA_INSTALLMENTS',
  FII_97_ENABLE_INACTIVE_CONTRACT_CANCELLING = 'FII-97_ENABLE_INACTIVE_CONTRACT_CANCELLING',
  /**
   * Determines if the new RF pages are enabled
   */
  GC_973_B2BCORE_122_EDIT_RF = 'GC_973_B2BCORE_122_EDIT_RF',
  INT_43_ENABLE_CANCEL_IRREGULARITY_BUTTON = 'INT_43_ENABLE_CANCEL_IRREGULARITY_BUTTON',
  IS_INTEGRATED_SCHOOL = 'is_integrated_school',
  /**
   * Determines if current school is included at ActiveSoft schools list
   */
  IS_NOT_ACTIVESOFT_SCHOOL = 'IS_NOT_ACTIVESOFT_SCHOOL',
  ISAAC_PAY_102_ENABLE_EXTERNAL_PAYOUT_TABLE = 'ISAAC_PAY_102_ENABLE_EXTERNAL_PAYOUT_TABLE',
  ISAAC_PAY_99_ENABLE_CALENDAR_TOGGLE = 'ISAAC_PAY_99_ENABLE_CALENDAR_TOGGLE',
  ISP_131_ENABLE_TEACHING_MATERIAL_REPORT_PAGE = 'ISP_131_ENABLE_TEACHING_MATERIAL_REPORT_PAGE',
  ISP_225_ENABLE_TEACHING_MATERIAL_LOGISTICS_PAGE = 'ISP_225_ENABLE_TEACHING_MATERIAL_LOGISTICS_PAGE',
  ISP_38_ENABLE_HYBRID_MODEL_CHARGING = 'ISP-38-ENABLE_HYBRID_MODEL_CHARGING',
  /**
   * FF from Matriculas
   */
  MAT_231_CAMPAIGN_AUTO_ACCEPT_PAYMENT_PLAN = 'MAT-231-CAMPAIGN_AUTO_ACCEPT_PAYMENT_PLAN',
  MAT_474_ENABLE_CAMPAIGN_ADD_INSTALLMENTS = 'MAT-474-ENABLE_CAMPAIGN_ADD_INSTALLMENTS',
  MAT_616_ENABLE_ISAAC_SCORE = 'MAT-616-ENABLE_ISAAC_SCORE',
  MAT_673_ENABLE_DIGITAL_SIGNATURE = 'MAT-673-ENABLE_DIGITAL_SIGNATURE',
  MAT_813_ENABLE_ENROLLMENT_MANAGEMENT_PANELS = 'MAT-813-ENABLE-ENROLLMENT-MANAGEMENT-PANELS',
  MAT_843_ENABLE_ENROLLMENT_CYCLE_FILTER = 'MAT-843-ENABLE-ENROLLMENT-CYCLE-FILTER',

  MAT_957_PRODUCT_WITHOUT_TUITION = 'MAT-957-PRODUCT_WITHOUT_TUITION',
  PAS_561_ENABLE_REONBOARDING = 'PAS-561_ENABLE_REONBOARDING',
  PAS_734_EDIT_ENROLLMENT_DUEDATE = 'PAS-734-EDIT-ENROLLMENT-DUEDATE',
  PAYMENTS_PAYM227_ENABLE_V0_CHECKOUT_FLOW = 'PAYMENTS_PAYM-227_ENABLE_V0_CHECKOUT_FLOW',
  PAYMENTS_PAYM377_ENABLE_CHECKOUT_FOR_BACKOFFICE = 'PAYMENTS_PAYM-377_ENABLE_CHECKOUT_FOR_BACKOFFICE',
  PAYMENTS_PAYM_641_ENABLE_ERROR_DIALOG_WHEN_POLLING_FAILS = 'PAYMENTS_PAYM-641_ENABLE_ERROR_DIALOG_WHEN_POLLING_FAILS',
  PAYMENTS_PAYM_641_ENABLE_TIMEOUT_BEFORE_POLLING = 'PAYMENTS_PAYM-641_ENABLE_TIMEOUT_BEFORE_POLLING',
  PAYMENTS_PAYM_678_EXPERIMENT_DISPLAY_ACTION_LINKS = 'PAYMENTS_PAYM-678_EXPERIMENT_DISPLAY_ACTION_LINKS',
  PAYMENTS_PAYM_915_ENABLE_AGREEMENT_USERGUIDING = 'PAYM-915_ENABLE_AGREEMENT_USERGUIDING',
  PAYMENTS_PAYM_953_FEATURE_FLAG_ENABLE_VALIDATE_PAID_DATE_MANUAL_LIQUIDATE = 'PAYM-953_FEATURE_FLAG_ENABLE_VALIDATE_PAID_DATE_MANUAL_LIQUIDATE',
  PAYMENTS_PEX_237_ENABLE_PORTFOLIO_REPURCHASE_OPTION_ON_MANUAL_LIQUIDATION = 'PEX-237_ENABLE_PORTFOLIO_REPURCHASE_OPTION_ON_MANUAL_LIQUIDATION',
  PAYMENTS_PEX_273_ENABLE_MANUAL_LIQUIDATION_PAGE = 'PEX-273_ENABLE_MANUAL_LIQUIDATION_PAGE',
  PAYMENTS_PEX_273_ENABLE_VALIDATE_PAID_DATE_MIN_7_DAYS_BEFORE = 'PEX-273_ENABLE_VALIDATE_PAID_DATE_MIN_7_DAYS_BEFORE',
  PAYMENTS_PEX_288_ENABLE_EDIT_MANUAL_LIQUIDATION_PAGE = 'PEX-288_ENABLE_EDIT_MANUAL_LIQUIDATION_PAGE',
  PE_233_ENABLE_BASIC_OPERATION_ACCESS_LEVEL = 'PE_233_ENABLE_BASIC_OPERATION_ACCESS_LEVEL',
  PE_41_ENABLE_PAYMENT_STATUS_REPORT_BY_BFF = 'PE_41_ENABLE_PAYMENT_STATUS_REPORT_BY_BFF',
  /**
   * Determines if the new redesign of the student page is enabled
   */
  PE_423_ENABLE_NEW_STUDENT_PAGE = 'PE_423_ENABLE_NEW_STUDENT_PAGE',
  PE_ENABLE_SCHOOL_GROUPS = 'PE_ENABLE_SCHOOL_GROUPS',
  PE_ENABLE_SCHOOL_GROUPS_MODULES = 'PE_173_ENABLE_SCHOOL_GROUPS_MODULES',
  PEX_447_CHANGE_FORM_TO_EXPORT_CARNE_PI = 'PEX-447_CHANGE_FORM_TO_EXPORT_CARNE_PI',
  REG_1926_ENABLE_DEMONSTRATIVE_IR_REPORT = 'REG_1926_ENABLE_DEMONSTRATIVE_IR_REPORT',
  REG_2042_ENABLE_RENEGOTIATE_AND_AGGLUTINATE_BUTTONS = 'REG_2042_ENABLE_RENEGOTIATE_AND_AGGLUTINATE_BUTTONS',
  /**
   * Determines if credit card fee is enabled
   */
  REG_2105_ENABLE_CREDIT_CARD_PAYMENT = 'REG_2105_ENABLE_CREDIT_CARD_PAYMENT',
  /**
   * Determines if unified invoice is enabled
   */
  REG_228_ENABLE_UNIFIED_INVOICE = 'REG_228_ENABLE_UNIFIED_INVOICE',
  REG_337_DISABLE_OTHER_PAYMENT_OPTIONS = 'REG_337_DISABLE_OTHER_PAYMENT_OPTIONS',
  /** Determines if credit card fee is enabled */
  REG_497_DISABLE_DATE_PICKER_ON_CREDIT_CARD_FLOW = 'REG_497_DISABLE_DATE_PICKER_ON_CREDIT_CARD_FLOW',
  /** Determines if the new installment page is enabled */
  SHOW_DOWNLOAD_BUTTON_FOR_ENROLLMENT_SUMMARY = 'SHOW_DOWNLOAD_BUTTON_FOR_ENROLLMENT_SUMMARY',
}

export const useUnleashFlag = (feature: UnleashFlags) => useFlag(feature)
export const useUnleashFlagsStatus = useFlagsStatus
export const useUnleashVariant = useVariant
