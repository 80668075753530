import { WeekPicker as IsaacWeekPicker } from '@olaisaac/design-system'

import type { DetailType } from '../..'

import { Popover } from '@monorepo/report/components/DatePicker/components/PickerPopOver/styles'

export type BaseItem = {
  ended_at: Date
  started_at: Date
}

type PickerPopOverProps<T> = {
  anchorEl: Element | ((element: Element) => Element)
  isOpen: boolean
  keyExtractor: (item: T) => string
  maxDate?: Date
  maxDetail: DetailType
  minDate?: Date
  minDetail?: DetailType
  onChange: (item: T) => void
  onClose: () => void
  options: T[]
  value: T
}

/**
 * Popover that wraps the date picker component
 *
 * @param props
 * @param props.anchorEl Anchor element ref
 * @param props.isOpen Defines if popover is open
 * @param props.maxDate Defines the max date
 * @param props.minDate Defines the min date
 * @param props.maxDetail Defines the maximum date detail
 * @param props.minDetail Defines the minimum date detail
 * @param props.value Input value
 * @param props.onChange On change callback function
 * @param props.onClose On close callback function
 * @param props.tileDisabled Function that allows disable specific dates
 */
export const PickerPopOver = <T extends Record<string, any> & BaseItem>({
  anchorEl,
  value,
  isOpen,
  onClose,
  onChange,
  ...rest
}: PickerPopOverProps<T>) => {
  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      elevation={0}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <IsaacWeekPicker<T> value={value} onChange={onChange} {...rest} />
    </Popover>
  )
}
