import { IrregularStudentStatusFilter } from './types'

export const DEFAULT_PAGE = 1
export const DEFAULT_PER_PAGE = 10

export const DEFAULT_IRREGULAR_STUDENT_STATUS_FILTERS = [
  IrregularStudentStatusFilter.NOT_STARTED,
  IrregularStudentStatusFilter.IN_PROGRESS,
]

export const IRREGULARITY_PAGE_NAME = 'Pendências de registro'
