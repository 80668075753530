import { useState } from 'react'
import dayjs from 'dayjs'

import { Button } from '@gravity/button'
import { GridItem } from '@gravity/grid'
import { DatepickerPrimitives as Datepicker, formatDateToYear } from '@gravity/datepicker'
import Icon from '@mdi/react'
import { mdiTrayArrowDown } from '@mdi/js'
import { CircularLoading } from '@/shared/components/CircularLoading'

import { ReportCard } from '../ReportCard'
import { CardFooter, StyledDatepickerTrigger } from './styles'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'

import { convertToUTCDate } from '@/shared/utils'
import { useDownloadIRReport } from '../../hooks/useDownloadIRReport'

export const DemonstrativeIRReportCard = () => {
  const { downloadIRReport, isLoading } = useDownloadIRReport()

  const enabledIrStatements = useUnleashFlag(UnleashFlags.DIV_651_ENABLE_IR_STATEMENTS_FLOW)

  const currentReferenceYear = dayjs().subtract(1, 'year')

  const [selectedDateForIR, setSelectedDateForIR] = useState<Date>(
    enabledIrStatements ? currentReferenceYear.toDate() : dayjs('2023').toDate()
  )

  const minDateUTCForIR = convertToUTCDate('2023-01-01').format('YYYY')
  const maxDateUTCForIR = enabledIrStatements
    ? currentReferenceYear.format('YYYY')
    : convertToUTCDate('2023-12-31').format('YYYY')

  const handleDownloadIR = async () => {
    await downloadIRReport(selectedDateForIR.getFullYear())
  }

  return (
    <GridItem xl={6} lg={6} md={6} sm={8} xs={4}>
      <ReportCard
        title="Demonstrativo de valores pagos"
        description={`Demonstrativo referente às despesas educacionais em ${formatDateToYear(
          selectedDateForIR
        )}. Na senha, utilize o CNPJ da escola (somente números).`}
      >
        <CardFooter>
          <Datepicker.Root>
            <StyledDatepickerTrigger size={3}>
              {formatDateToYear(selectedDateForIR)}
            </StyledDatepickerTrigger>
            <Datepicker.Calendar
              value={selectedDateForIR}
              minDate={dayjs(minDateUTCForIR).toDate()}
              maxDate={dayjs(maxDateUTCForIR).toDate()}
              maxDetail="decade"
              onChange={year => setSelectedDateForIR(year as Date)}
            />
          </Datepicker.Root>
          <Button
            variant="ghost"
            iconEnd={
              isLoading ? <CircularLoading iconSize={16} /> : <Icon path={mdiTrayArrowDown} />
            }
            onClick={handleDownloadIR}
            disabled={isLoading}
          >
            Baixar
          </Button>
        </CardFooter>
      </ReportCard>
    </GridItem>
  )
}
