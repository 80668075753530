import { useState } from 'react'

export const useSteps = () => {
  const [activeStep, setActiveStep] = useState(0)
  const steps = ['Selecionar contrato', 'Informar novo responsável', 'Revise as edições']

  return {
    steps,
    activeStep,
    setActiveStep,
  }
}
