import { Table } from '@gravity/table'
import { ComponentProps } from 'react'
import { Text } from '@gravity/text'
import { colors } from '@gravity/tokens'

export const TableEmptyState = ({
  columns,
}: {
  columns: Array<ComponentProps<typeof Table.HeaderCell>>
}) => (
  <Table.Row>
    <Table.CustomCell colSpan={columns.length}>
      <Text
        variant="body-1-regular"
        style={{ color: colors['colors-text-main-3'], margin: '0 auto' }}
      >
        Nenhum responsável encontrado
      </Text>
    </Table.CustomCell>
  </Table.Row>
)
