import { Tooltip } from '@material-ui/core'
import { Button } from '@olaisaac/design-system'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { PayoutReportPageFooter } from '@monorepo/report/components/PayoutReportPageFooter'

import { useApiClient, useSnackbar } from '@/shared/hooks'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useJWT } from '@/shared/hooks/useJWT'
import { usePagination } from '@/shared/hooks/usePagination'

import { downloadFile } from '@/shared/utils/downloadFile'
import { formatDate } from '@/shared/utils/dateFormatters'

import { PAYOUT_SPREADSHEET_FILE_VERSION } from '@/shared/constants/report'
import { REPORT_PAGE_NAMES } from '@monorepo/report/constants/REPORT_PAGE_NAMES'

import type { PayoutReportStatus } from '@monorepo/report/models/PayoutReportStatus'
import type { ConstEnumValue } from '@/shared/models/utils'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { reportService } from '@monorepo/report/services/report'

type TuitionPayoutReportFooterProps = {
  isLoading: boolean
  isNewVersionPayout: boolean
  payoutFileVersion?: ConstEnumValue<typeof PAYOUT_SPREADSHEET_FILE_VERSION>
  totalItems: number
  tuitionPayoutReportDate?: Date
  tuitionPayoutReportId: string
  tuitionPayoutReportStatus?: PayoutReportStatus
}

const DISABLED_BUTTON_TOOLTIP = 'Disponível apenas para repasses fechados.'
const DOWNLOAD_SPREADSHEET_TOOLTIP = 'Baixar o arquivo .xlsx do período'

/**
 * Specialized footer component for tuition report page
 *
 * @param props
 * @param props.tuitionPayoutReportId Payout ID
 * @param props.tuitionPayoutReportDate Payout due date
 * @param props.tuitionPayoutReportStatus Payout status
 * @param props.isNewVersionPayout Indicates if the payout is in the new version
 * @param props.payoutFileVersion Indicates the file version available for payout
 * @param props.totalItems Total items to be paginated
 * @param props.isLoading Indicates if the loading state should be displayed
 */
export const TuitionPayoutReportFooter = ({
  tuitionPayoutReportId,
  tuitionPayoutReportDate = new Date(),
  tuitionPayoutReportStatus = 'OPEN',
  isNewVersionPayout,
  payoutFileVersion = PAYOUT_SPREADSHEET_FILE_VERSION.V2,
  totalItems,
  isLoading,
}: TuitionPayoutReportFooterProps) => {
  const { isAdmin } = useJWT()
  const { apiClient } = useApiClient()
  const { school } = useSelectedSchool()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { pagination, updatePaginationValue } = usePagination(1, 25)
  const { setIsOpen } = useSnackbar()

  const sendPaginationEvent = (event: 'pageChange' | 'pageSizeChange') => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.PAYOUT_REPORT,
        name:
          event === 'pageChange'
            ? EventDispatcherEvents.PAGINATION_CHANGE_PAGE
            : EventDispatcherEvents.PAGINATION_CHANGE_PAGE_SIZE,
        action: 'click',
        customProperties: {
          $page_name: REPORT_PAGE_NAMES.TUITION_PAYOUT_REPORT,
        },
      })
  }

  const handleDownloadReport = async (
    payoutId: string,
    filename: string,
    fileVersion: ConstEnumValue<typeof PAYOUT_SPREADSHEET_FILE_VERSION>
  ) => {
    const extension = 'xlsx'

    const service = reportService(apiClient.getClients().privateApi)

    try {
      await downloadFile(filename, extension, () =>
        service.fetchPayoutReportFile({
          id: payoutId,
          extension,
          version: fileVersion,
        })
      )
    } catch {
      setIsOpen(true, {
        variation: 'error',
        description:
          'Erro ao baixar o relatório. Tente novamente mais tarde ou entre em contato com nosso suporte.',
      })
    } finally {
      isInitialized &&
        eventDispatcherClient.sendEvent({
          scope: EventDispatcherEventScopes.PAYOUT_REPORT,
          name: EventDispatcherEvents.TUITION_PAYOUT_DOWNLOAD_CSV,
          action: 'file_download',
          customProperties: {
            $file_version: fileVersion,
            $page_name: REPORT_PAGE_NAMES.TUITION_PAYOUT_REPORT,
          },
        })
    }
  }

  const getFileVersion = (
    isNewVersionPayout: boolean,
    payoutFileVersion: ConstEnumValue<typeof PAYOUT_SPREADSHEET_FILE_VERSION>,
    docType: 'current' | 'previous'
  ) => {
    if (!isNewVersionPayout) return PAYOUT_SPREADSHEET_FILE_VERSION.V1

    if (docType === 'current') {
      return payoutFileVersion
    }

    return payoutFileVersion === PAYOUT_SPREADSHEET_FILE_VERSION.V3
      ? PAYOUT_SPREADSHEET_FILE_VERSION.V2
      : PAYOUT_SPREADSHEET_FILE_VERSION.V1
  }

  const shouldDisableDownloadButton = ({
    isNewVersionPayout,
    isOpenPayout,
    isLoading,
    isEmptyPayout,
  }: Record<'isNewVersionPayout' | 'isOpenPayout' | 'isLoading' | 'isEmptyPayout', boolean>) => {
    if (!isNewVersionPayout) {
      return isOpenPayout || isLoading
    }

    return isOpenPayout || isEmptyPayout || isLoading
  }

  const formattedTuitionPayoutReportDate = formatDate(tuitionPayoutReportDate, 'DD-MM-YYYY')

  const filenames = {
    excel: `analitico-${formattedTuitionPayoutReportDate}-${school?.slug}.xlsx`,
  }

  const isEmptyPayout = totalItems === 0
  const isOpenPayout = tuitionPayoutReportStatus === 'OPEN'

  const isDownloadButtonsEnabled = shouldDisableDownloadButton({
    isNewVersionPayout,
    isOpenPayout,
    isLoading,
    isEmptyPayout,
  })

  return (
    <PayoutReportPageFooter
      page={pagination.page}
      itemsPerPage={pagination.itemsPerPage}
      totalItems={totalItems}
      onChangePage={value => {
        updatePaginationValue('page', value, true)
        sendPaginationEvent('pageChange')
      }}
      onChangeItemsPerPage={value => {
        updatePaginationValue('itemsPerPage', value, true)
        sendPaginationEvent('pageSizeChange')
      }}
    >
      {isNewVersionPayout && isAdmin && (
        <Tooltip title={isOpenPayout ? DISABLED_BUTTON_TOOLTIP : DOWNLOAD_SPREADSHEET_TOOLTIP}>
          <span>
            <Button
              variation="ghost"
              disabled={isDownloadButtonsEnabled}
              onClick={() =>
                handleDownloadReport(
                  tuitionPayoutReportId,
                  filenames.excel,
                  getFileVersion(true, payoutFileVersion, 'previous')
                )
              }
            >
              Baixar modelo anterior
            </Button>
          </span>
        </Tooltip>
      )}

      <Tooltip title={isOpenPayout ? DISABLED_BUTTON_TOOLTIP : DOWNLOAD_SPREADSHEET_TOOLTIP}>
        <span>
          <Button
            disabled={isDownloadButtonsEnabled}
            onClick={() =>
              handleDownloadReport(
                tuitionPayoutReportId,
                filenames.excel,
                getFileVersion(isNewVersionPayout, payoutFileVersion, 'current')
              )
            }
          >
            Baixar planilha
          </Button>
        </span>
      </Tooltip>
    </PayoutReportPageFooter>
  )
}
