import type { MouseEvent } from 'react'
import { LinkProps } from 'react-router-dom-v5-compat'
import { Text } from '@gravity/text'

import { MenuItem } from './styles'

export type SidebarMenuItemProps = LinkProps & {
  isActive: boolean
  label: string
  onClick: (e: MouseEvent<HTMLAnchorElement>) => void
  subLabel?: string
}

export const SidebarMenuItem = ({ label, subLabel, isActive, ...rest }: SidebarMenuItemProps) => {
  return (
    <MenuItem $isActive={isActive} {...rest}>
      <Text variant="body-2-medium">{label}</Text>
      {!!subLabel && <Text variant="caption-regular">{subLabel}</Text>}
    </MenuItem>
  )
}
