import { FC, useEffect, useState } from 'react'
import {
  ActionDrawerHeader,
  Button,
  ButtonDocker,
  DialogContent,
  Drawer,
} from '@olaisaac/design-system'
import { Text } from '@gravity/text'
import useHelperEditRegistration from './useHelperEditRegistration'
import ConfirmationEditDialog from './ConfirmationEditDialog'
import { Box, CircularProgress } from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom'
import { UnleashFlags, useUnleashFlag } from 'src/shared/hooks/useUnleashFlag'
import { EditGuardianFormSection } from '@/escolas/router/responsavel/[guardianId]/contratos/constants'
import ConfirmationDialog from '@/shared/components/ConfirmationDialog'
import { Student } from '../../types'
import { MomentsOfAlert } from './contants'
import { EditRegistrationDrawerProps } from './types'
import CollapseStudent from '@/escolas/components/contract/EditRegistrationDrawer/CollapseStudent'
import CollapseGuardian from '@/escolas/components/contract/EditRegistrationDrawer/CollapseGuardian'
import CollapseAddress from '@/escolas/components/contract/EditRegistrationDrawer/CollapseAddress'
import { EditNotification } from './EditNotification'

export const CollapsesMap = new Map<string, EditGuardianFormSection>([
  ['student', EditGuardianFormSection.STUDENT_SECTION],
  ['guardian', EditGuardianFormSection.GUARDIAN_SECTION],
  ['address', EditGuardianFormSection.ADDRESS_SECTION],
])

export const clearCollapses = (searchParams: URLSearchParams) => {
  CollapsesMap.forEach((_, key, map) => {
    searchParams.delete(map.get(key) as string)
  })
}

const EditRegistrationDrawer: FC<EditRegistrationDrawerProps> = ({
  isOpen,
  guardian,
  refreshData,
}) => {
  const isNewEditRfNameEnabled = useUnleashFlag(UnleashFlags.GC_973_B2BCORE_122_EDIT_RF)

  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [openAlertRfEdit, setOpenAlertRfEdit] = useState<MomentsOfAlert | null>(null)

  const history = useHistory()
  const location = useLocation()
  const { search } = location
  const searchParams = new URLSearchParams(search)

  const students: Student[] = guardian.students

  const studentsIds = students?.map(student => student.id)

  const handleOnClose = () => {
    clearCollapses(searchParams)
    searchParams.delete('abrirCadastro')
    searchParams.delete('ativarEdicao')
    history.push({ search: searchParams.toString() })
  }

  const {
    form,
    handleMenuItemClick,
    isZipValid,
    onZipFieldFilled,
    isFirstRender,
    submitHandler,
    isFeedbackDialogOpen,
    handleCloseFeedbackDialog,
    isStudentsFetched,
  } = useHelperEditRegistration(guardian, studentsIds, handleOnClose)

  useEffect(() => {
    if (isStudentsFetched) {
      handleMenuItemClick()
      setOpenAlertRfEdit(MomentsOfAlert.START_EDIT)
    }
  }, [isStudentsFetched])

  const handleOpenCollapse = (value: string) => {
    const selectedCollapse = CollapsesMap.get(value)
    if (searchParams.get(selectedCollapse as string)) {
      searchParams.delete(selectedCollapse as string)
    } else {
      clearCollapses(searchParams)
      searchParams.append(selectedCollapse as string, 'true')
    }
    history.push({ search: searchParams.toString() })
  }

  const handleStartRfEditAlert = () => {
    setOpenAlertRfEdit(null)
    if (!searchParams.get('ativarEdicao')) {
      clearCollapses(searchParams)
      searchParams.append(EditGuardianFormSection.GUARDIAN_SECTION, 'true')
      searchParams.append('ativarEdicao', 'true')
      history.push({ search: searchParams.toString() })
    }
  }

  const handleConfirmRfEditButton = () => {
    setOpenAlertRfEdit(MomentsOfAlert.END_EDIT)
  }

  const handleCancelRfEditButton = () => {
    searchParams.delete('ativarEdicao')
    handleOnClose()
    history.push({ search: searchParams.toString() })
  }

  const handleCloseConfirmationEditDialog = () => {
    setIsVisible(false)
    handleCloseFeedbackDialog()
  }

  const handleCloseEditViewMode = () => {
    searchParams.delete('ativarEdicao')
    setOpenAlertRfEdit(null)
    history.push({ search: searchParams.toString() })
  }

  const handleConfirmRfEditAlert = () => {
    setOpenAlertRfEdit(null)
    form.handleSubmit(submitHandler)()
  }

  const handleConfirmationDialog = () => {
    setIsVisible(true)
  }

  const {
    formState: { isValid, isDirty },
  } = form

  const isEdit = !isNewEditRfNameEnabled || Boolean(searchParams.get('ativarEdicao'))
  const isStartEdit = openAlertRfEdit === MomentsOfAlert.START_EDIT

  return (
    <Drawer open={isOpen}>
      {isStudentsFetched ? (
        <>
          <ConfirmationEditDialog
            form={form}
            submitHandler={submitHandler}
            isVisible={isVisible}
            handleClose={handleCloseConfirmationEditDialog}
            isFeedbackDialogOpen={isFeedbackDialogOpen}
            handleCloseFeedbackDialog={handleCloseFeedbackDialog}
            refreshData={refreshData}
          />
          <ActionDrawerHeader
            onClose={handleOnClose}
            title={isNewEditRfNameEnabled ? 'Dados cadastrais' : 'Editar Cadastro'}
          />
          <EditNotification isEdit={isEdit} isNewEditRfNameEnabled={isNewEditRfNameEnabled} />
          <DialogContent style={{ padding: '0' }}>
            <CollapseStudent
              edit={isEdit}
              handleOpenCollapse={handleOpenCollapse}
              openCollapse={
                searchParams.get(CollapsesMap.get('student') as string) ? 'student' : ''
              }
              form={form}
            />
            <CollapseGuardian
              edit={isEdit}
              handleOpenCollapse={handleOpenCollapse}
              openCollapse={
                searchParams.get(CollapsesMap.get('guardian') as string) ? 'guardian' : ''
              }
              guardian={guardian}
              form={form}
            />
            <CollapseAddress
              edit={isEdit}
              handleOpenCollapse={handleOpenCollapse}
              openCollapse={
                searchParams.get(CollapsesMap.get('address') as string) ? 'address' : ''
              }
              form={form}
              isZipValid={isZipValid}
              isFirstRender={isFirstRender}
              onZipFieldFilled={onZipFieldFilled}
            />
          </DialogContent>
          <ButtonDocker>
            {isNewEditRfNameEnabled ? (
              isEdit && (
                <>
                  <Button
                    variation="primary"
                    disabled={!(isValid && isDirty)}
                    fullWidth
                    onClick={handleConfirmRfEditButton}
                  >
                    Concluir edição
                  </Button>
                  <Button variation="ghost" fullWidth onClick={handleCancelRfEditButton}>
                    Cancelar
                  </Button>
                </>
              )
            ) : (
              <Button
                disabled={!isValid || !isDirty}
                variation="primary"
                fullWidth
                onClick={handleConfirmationDialog}
              >
                Salvar
              </Button>
            )}
          </ButtonDocker>
          <ConfirmationDialog
            isVisible={Boolean(openAlertRfEdit)}
            onClose={isStartEdit ? handleCloseEditViewMode : () => setOpenAlertRfEdit(null)}
            submitHandler={isStartEdit ? handleStartRfEditAlert : handleConfirmRfEditAlert}
            title={`Novas faturas podem ser geradas, você quer ${
              isStartEdit ? 'continuar' : 'concluir'
            } a edição?`}
            backButtonLabel={isStartEdit ? 'Apenas visualizar' : 'Voltar'}
            buttonLabel="Continuar"
          >
            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.8rem' }}>
              <Text variant="body-2-regular">
                Antes de você continuar, saiba que ao editar nome e/ou endereço, faturas anteriores
                serão reemitidas e enviadas novas faturas para o e-mail do responsável financeiro.
              </Text>
              <Text variant="body-2-regular">
                A alteração de e-mail e/ou telefone não irá atualizar faturas.{' '}
              </Text>
              <Text variant="body-2-regular">Você deseja continuar?</Text>
            </div>
          </ConfirmationDialog>
        </>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="80vh"
        >
          <CircularProgress style={{ marginTop: '24px' }} />
        </Box>
      )}
    </Drawer>
  )
}

export default EditRegistrationDrawer
