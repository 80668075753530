import { FC } from 'react'
import { Button } from '@gravity/button'

import * as S from './styles'
import type { FooterProps } from './types'

const Footer: FC<FooterProps> = ({
  enableSubmit = false,
  onClickGoBack,
  onClick,
  isLoading,
  type = 'submit',
  hideBackButton = false,
  label = 'Registrar recebimento',
}) => {
  return (
    <S.Wrapper>
      {!hideBackButton && (
        <Button variant="ghost" onClick={onClickGoBack}>
          Voltar
        </Button>
      )}
      <Button type={type} disabled={!enableSubmit} loading={isLoading} onClick={onClick}>
        {label}
      </Button>
    </S.Wrapper>
  )
}

export default Footer
