import { useParams } from 'react-router-dom'

import { useSetPageTitle } from '@/shared/hooks/useSetPageTitle'
import { useLayout } from '@/shared/hooks/useLayout'
import { Stepper, StepperItem } from '@/shared/components/Stepper'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { ErrorDialog } from '@/shared/components/ErrorDialog'

import { ContractSelectionSep } from './components/ContractSelectionStep'
import { PageFooter } from './components/PageFooter'
import { PageHeader } from './components/PageHeader'
import * as Styled from './styles'
import { useSteps } from './hooks/useSteps'
import { useContracts } from './hooks/useContracts'

export const ChangeContractOwnership = () => {
  useSetPageTitle('Troca de titularidade de contrato')
  useLayout({ enableSideMenu: false, enableHeader: false })

  const { activeStep, setActiveStep, steps } = useSteps()
  const { guardianId } = useParams<{ guardianId: uuid }>()
  const { school, hasErrorOnFetchSchool } = useSelectedSchool()
  const {
    eligibleContracts,
    handleSelectContract,
    isContractSelected,
    contractsQuery,
  } = useContracts(guardianId, school?.id ?? '')

  return (
    <>
      <ErrorDialog isError={contractsQuery.isError || hasErrorOnFetchSchool} />

      <PageHeader />
      <Styled.PageWrapper>
        <Styled.CenterGrid justify="start">
          <Styled.CustomGridItem lg="4 / 10" sm="2 / 8" xs={4}>
            <Stepper activeStep={activeStep}>
              {steps.map((step, i) => (
                <StepperItem key={i} title={step} onClickStep={() => setActiveStep(i)} />
              ))}
            </Stepper>

            {activeStep === 0 && (
              <ContractSelectionSep
                isLoading={contractsQuery.isLoading}
                isSelected={isContractSelected}
                eligibleContracts={eligibleContracts}
                onSelectContract={handleSelectContract}
              />
            )}
          </Styled.CustomGridItem>
        </Styled.CenterGrid>

        <Styled.FooterSpaceClearance>
          <div style={{ display: 'flex' }} />
          <PageFooter goToNextStepBtnDisabled={contractsQuery.isLoading} />
        </Styled.FooterSpaceClearance>
      </Styled.PageWrapper>
    </>
  )
}
