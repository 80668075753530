import { useEffect } from 'react'
import { useSetPageTitle } from '@/shared/hooks/useSetPageTitle'
import { useHistory, useParams } from 'react-router-dom'

import { useEnrollmentCampaignRedirect } from '@/shared/hooks/useEnrollmentCampaignRedirect'
import { Stepper } from '@monorepo/enrollment/components/Stepper'
import { useSchoolEnrollmentCycleContext } from '@monorepo/enrollment/contexts/SchoolEnrollmentContext'
import { useAddBulkInstallmentStore } from '@monorepo/enrollment/store/AddBulkInstallmentStore'

import { useIsPageEnabled } from './hooks/useIsPageEnabled'
import { useCampaignDetails } from './hooks/useCampaignDetails'
import { PaymentPlanStep } from './components/PaymentPlanStep'
import { useStepper } from './hooks/useStepper'
import * as Styled from './styles'
import type { URLParams } from './types'
import { CustomizationStep } from './components/CustomizationStep'

export const AddBulkInstallment = () => {
  useEnrollmentCampaignRedirect()
  useIsPageEnabled()
  useSetPageTitle('Adicionar mensalidades: - isaac')
  const history = useHistory()

  const addBulkInstallmentSteps = [
    {
      label: 'Plano de pagamento',
      component: PaymentPlanStep,
    },
    { label: 'Personalização', component: CustomizationStep },
  ]
  const { campaignId } = useParams<URLParams>()

  const { campaignDetails } = useCampaignDetails(campaignId)

  const { selectedEnrollmentCycle } = useSchoolEnrollmentCycleContext()

  const {
    activeStep,
    ActiveComponent,
    handleStepFinish,
    stepsNavHistory,
    handleStepClick,
    reset,
  } = useStepper(addBulkInstallmentSteps)

  const { resetStore } = useAddBulkInstallmentStore()

  useEffect(() => {
    return () => {
      reset()
      resetStore()
    }
  }, [])

  return (
    <Styled.Container>
      <Styled.StepsWrapper>
        <Stepper
          steps={addBulkInstallmentSteps}
          title="Adicionar mensalidades"
          activeStep={activeStep}
          handleStepClick={handleStepClick}
          subtitle={`Produto: ${campaignDetails.productName}`}
          stepsNavHistory={stepsNavHistory}
          // eslint-disable-next-line react/jsx-handler-names
          onClickBackBtn={history.goBack}
        />

        <Styled.ContentContainer>
          <ActiveComponent enrollmentCycle={selectedEnrollmentCycle} onFinish={handleStepFinish} />
        </Styled.ContentContainer>
      </Styled.StepsWrapper>
    </Styled.Container>
  )
}
