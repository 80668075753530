import {
  HeartOutline,
  CalendarEventOutline,
  ShakeHandsOutline,
  PriceTagOutline,
  PercentOutline,
  Check,
  ArrowRightUpLong,
  CalendarScheduleOutline,
} from '@gravity/icons'
import { InfoCard } from '../../components/InfoCard'
import { HomeTemplate } from '../../templates/HomeTemplate'
import { RowContainer, RowContainerSpaceBetween, StyledCard, TextGroup } from './styles'
import { IconButton } from '@gravity/icon-button'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { MixpanelEventActions } from '../../constants/MixpanelEventActions.enum'
import { CreditEventComponentNames } from '../../constants/CreditEventComponentNames.enum'
import useMixpanelEventSender from '../../utils/useMixpanelEventSender'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks/useUnleashFlag'
import { Heading } from '@gravity/heading'
import { Grid, GridItem } from '@gravity/grid'
import { Text } from '@gravity/text'
import { spacing } from '@gravity/tokens'

export const NoCreditAvailable = () => {
  const { sendCreditEventToMixpanel } = useMixpanelEventSender()
  const isInMaintenance = useUnleashFlag(UnleashFlags.CRD_166_DISABLE_CREDIT_FLOWS)

  const cards = [
    <InfoCard
      key={1}
      icon={<HeartOutline />}
      title="Tenha uma operação saudável com o isaac"
      description="Mantenha a taxa de recebimentos manuais em zero, instruindo o responsável financeiro a efetuar os pagamentos através do meu isaac."
    />,
    <InfoCard
      key={2}
      icon={<CalendarEventOutline />}
      title="Tenha as contas em dia"
      description="Um critério importante para ter mais crédito é pagar as contas sem atraso. Os CNPJs e CPFs dos sócios da escola não podem ter restrições."
    />,
    <InfoCard
      key={3}
      icon={<ShakeHandsOutline />}
      title="Busque renegociar dívidas antigas"
      description="Contratar crédito agora pode comprometer a saúde financeira da escola. Busque renegociar dívidas antigas para que o crédito caiba no orçamento."
    />,
  ]

  return (
    <HomeTemplate
      title={'Saiba como aumentar suas chances  \n de ter crédito no isaac'}
      cardDescription={
        'Sua escola não possui ofertas disponíveis no momento,  \n mas preparamos algumas dicas de como melhorar sua análise de crédito.'
      }
      isEligible={false}
      cards={cards}
      mainCardDescription="Como melhorar a análise de crédito"
      bottomInfo={
        <>
          <Grid className="grid-component">
            <GridItem md={8}>
              <StyledCard style={{ gap: '82px', paddingBottom: spacing[10] }}>
                <TextGroup style={{ gap: spacing[6] }}>
                  <Heading variant="heading-h2-medium">Conheça o crédito isaac</Heading>
                  <TextGroup style={{ gap: spacing[4] }}>
                    <RowContainer>
                      <CalendarScheduleOutline />
                      <Text variant="body-1-regular">
                        Número de parcelas máximo condicionado ao contrato vigente com o isaac
                      </Text>
                    </RowContainer>

                    <RowContainer>
                      <PriceTagOutline />
                      <Text variant="body-1-regular">
                        Campanhas com condições especiais ao longo do ano
                      </Text>
                    </RowContainer>

                    <RowContainer>
                      <PercentOutline />
                      <Text variant="body-1-regular">Taxas competitivas com o mercado</Text>
                    </RowContainer>

                    <RowContainer>
                      <Check />
                      <Text variant="body-1-regular">
                        Elegível a partir do segundo mês de operação
                      </Text>
                    </RowContainer>
                  </TextGroup>
                </TextGroup>
                <Text variant="body-2-regular">
                  A disponibilidade das ofertas estão sujeitas a análise de crédito.
                </Text>
              </StyledCard>
            </GridItem>
            <GridItem md={4}>
              <GridItem md={12}>
                <TextGroup style={{ gap: `${spacing[4]}` }}>
                  <StyledCard
                    style={{
                      gap: `${spacing[4]}`,
                      paddingTop: `61.5px`,
                      paddingBottom: `61.5px`,
                    }}
                  >
                    <Heading variant="heading-h4-medium">O isaac está aqui para te apoiar</Heading>
                    <Text variant="body-1-regular">
                      Você pode entrar em contato com seu consultor para entender todas as
                      possibilidades do crédito isaac.
                    </Text>
                  </StyledCard>

                  <StyledCard style={{ padding: `${spacing[8]}` }}>
                    <RowContainerSpaceBetween>
                      <Heading variant="heading-h4-medium">Dúvidas frequentes</Heading>
                      <IconButton
                        size={2}
                        onClick={() => {
                          sendCreditEventToMixpanel(
                            EventDispatcherEvents.BUTTON_CLICKED,
                            MixpanelEventActions.CLICK,
                            {
                              $page_name: CreditEventComponentNames.FAQ,
                            }
                          )
                          window.open(
                            'https://centraldeajuda.olaisaac.io/credito-isaac/elegibilidade-do-credito-isaac',
                            '_blank'
                          )
                        }}
                      >
                        <ArrowRightUpLong />
                      </IconButton>
                    </RowContainerSpaceBetween>
                  </StyledCard>
                </TextGroup>
              </GridItem>
            </GridItem>
          </Grid>
        </>
      }
      isInMaintenance={isInMaintenance}
    />
  )
}
