import { Typography } from '@olaisaac/design-system'
import styled from 'styled-components'
import theme from '@/shared/theme'

const { spacing } = theme.primitiveTokens
const { variation, color } = theme.humanizedTokens.components.typography

export const StyledBoxLoading = styled.div`
  padding: ${spacing[3]};
`

export const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Message = styled(Typography)`
  ${variation.subtitleDesktopMedium}
  ${color.secondary}
`
