import styled from 'styled-components'
import { spacing } from '@gravity/tokens'
import { ButtonHeaderContainerProps } from './ContractDetailsDrawer'

export const HeaderContainer = styled.div`
  padding: ${spacing[6]};
`

export const ButtonHeaderContainer = styled.div<ButtonHeaderContainerProps>`
  display: flex;
  flex: 1;
  justify-content: ${({ showGoBack }) => (showGoBack ? 'space-between' : 'flex-end')};
  align-items: center;
`
