import { createContext, ReactNode, useContext, useMemo, useState } from 'react'

import { FormProps } from '@monorepo/enrollment/pages/Campaigns/NewCampaign/components/PaymentConditionsStep/types'

type CampaignProviderProps = {
  children: ReactNode
}

export type StepNavHistoryType = {
  completed: boolean
  index: number
  visited: boolean
}

type ChosenProductType = {
  id: string
  name: string
}

type CampaignContextType = {
  activeStep: number
  chosenProducts: ChosenProductType[]
  conditions: FormProps | null
  customEmailMsg: string
  nonParticipatingStudentsIds: string[]
  setActiveStep: (index: number) => void
  setConditions: (conditions: FormProps) => void
  setCustomEmailMsg: (msg: string) => void
  stepsNavHistory: StepNavHistoryType[]
  totalParticipatingStudents: number
  updateChosenProducts: (products: ChosenProductType[]) => void
  updateNonParticipatingStudentsIds: (students: string[]) => void
  updateStepsNavHistory: (index: number) => void
  updateTotalParticipatingStudents: (total: number) => void
}

export const CampaignContext = createContext({} as CampaignContextType)
export const useCampaignContext = () => {
  const context = useContext(CampaignContext)
  if (context === undefined) {
    throw new Error('useCampaignContext must be used within a CampaignProvider')
  }
  return context
}

export const CampaignProvider = ({ children }: CampaignProviderProps) => {
  const stepsNavHistoryTemplate: StepNavHistoryType[] = [
    { index: 0, completed: false, visited: false },
    { index: 1, completed: false, visited: false },
    { index: 2, completed: false, visited: false },
    { index: 3, completed: false, visited: false },
    { index: 4, completed: false, visited: false },
  ]
  const [stepsNavHistory, setStepsNavHistory] = useState<StepNavHistoryType[]>(
    stepsNavHistoryTemplate
  )

  const [activeStep, setActiveStep] = useState<number>(0)

  const [conditions, setConditions] = useState<FormProps | null>(null)
  const [customEmailMsg, setCustomEmailMsg] = useState('')
  const [chosenProducts, setChosenProducts] = useState<ChosenProductType[]>([])
  const [nonParticipatingStudentsIds, setNonParticipatingStudentsIds] = useState<string[]>([])
  const [totalParticipatingStudents, setTotalParticipatingStudents] = useState<number>(0)

  const updateStepsNavHistory = (index: number) => {
    const updatedStepsNavHistory = stepsNavHistory.map(step => {
      if (step.index === index - 1) {
        return { ...step, completed: true, visited: true }
      }
      if (step.index === index) {
        return { ...step, visited: true }
      }
      return step
    })

    setStepsNavHistory(updatedStepsNavHistory)
  }

  const updateChosenProducts = (products: ChosenProductType[]) => {
    setChosenProducts(products)
  }

  const updateNonParticipatingStudentsIds = (students: string[]) => {
    setNonParticipatingStudentsIds(students)
  }

  const updateTotalParticipatingStudents = (total: number) => {
    setTotalParticipatingStudents(total)
  }

  const contextValue: CampaignContextType = useMemo(
    () => ({
      activeStep,
      updateStepsNavHistory,
      stepsNavHistory,
      setActiveStep,
      conditions,
      setConditions,
      customEmailMsg,
      setCustomEmailMsg,
      chosenProducts,
      updateChosenProducts,
      nonParticipatingStudentsIds,
      updateNonParticipatingStudentsIds,
      updateTotalParticipatingStudents,
      totalParticipatingStudents,
    }),
    [
      activeStep,
      conditions,
      customEmailMsg,
      stepsNavHistory,
      chosenProducts,
      nonParticipatingStudentsIds,
      totalParticipatingStudents,
    ]
  )

  return <CampaignContext.Provider value={contextValue}>{children}</CampaignContext.Provider>
}
