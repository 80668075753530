import styled from 'styled-components'
import { Text } from '@gravity/text'
import { spacing } from '@gravity/tokens'

export const Container = styled.div`
  margin-top: ${spacing[4]};
  margin-bottom: ${spacing[8]};
`

export const Discount = styled(Text)`
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  & span {
    font-size: ${spacing[4]};
  }
  font-weight: normal;
  color: ${({ theme }) => theme.gravity.colors['colors-text-main-3']};
`

export const GrayText = styled(Text)`
  color: ${({ theme }) => theme.gravity.colors['colors-text-main-3']};
`

export const BoldText = styled(Text)`
  font-weight: bold;
`

export const AmountDisplay = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const ContentContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${spacing[4]};
  margin-bottom: ${spacing[6]};
`

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ContractIDContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: ${spacing[4]};
  padding-top: ${spacing[6]};
`
