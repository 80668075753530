import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import Icon from '@mdi/react'
import { mdiPlus } from '@mdi/js'
import { Button } from '@gravity/button'
import { useForm } from 'react-hook-form'

import type { FormProps } from '@monorepo/enrollment/pages/Campaigns/CampaignDetails/components/InstallmentForm/types'
import type { StepPageProps } from '@monorepo/enrollment/components/Stepper/types'

import { InstallmentForm } from '../../../CampaignDetails/components/InstallmentForm'
import { useAddBulkInstallmentStore } from '@monorepo/enrollment/store/AddBulkInstallmentStore'

import * as Styled from './styles'

export const PaymentPlanStep = ({ onFinish, enrollmentCycle }: StepPageProps) => {
  const { setFormData, formData } = useAddBulkInstallmentStore()

  const form = useForm<FormProps>({
    mode: 'all',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    defaultValues: formData,
  })

  const handleSubmit = async (formData: FormProps) => {
    setFormData(formData)
    onFinish()
  }

  return (
    <Styled.Form onSubmit={form.handleSubmit(form => handleSubmit(form))}>
      <Heading variant="heading-h3-medium">Plano de pagamento</Heading>

      <Styled.Content>
        <Text variant="body-1-regular">
          Defina o número de parcelas, seus valores e a data de vencimento. Alunos que já tiveram
          mensalidades adicionadas individualmente não terão seus valores atualizados.
        </Text>

        <Styled.FormWrapper>
          <InstallmentForm
            form={form}
            referenceYear={enrollmentCycle?.reference_year}
            renderDiscountBtn={(disabled, onClick) => (
              <Styled.SubmitBtnRow>
                <Styled.AddDiscountBtn
                  fullWidth
                  disabled={disabled}
                  variant="outline"
                  iconEnd={<Icon path={mdiPlus} />}
                  onClick={onClick}
                  type="button"
                >
                  Adicionar desconto
                </Styled.AddDiscountBtn>
                <Button disabled={!form.formState.isValid} type="submit">
                  Continuar
                </Button>
              </Styled.SubmitBtnRow>
            )}
          />
        </Styled.FormWrapper>
      </Styled.Content>
    </Styled.Form>
  )
}
