import { Button } from '@gravity/button'
import Icon from '@mdi/react'
import { mdiCallMerge } from '@mdi/js'
import { useNavigation } from '@/escolas/hooks'
import { useSnackbar } from '@/shared/hooks'
import useCheckoutCart from '@/modules/guardians/hooks/useCheckoutCart'
import { useCanAgglutinate } from '../../hooks/useCanAgglutinate'
import { Installment } from '../../types'
import { useEffect, useState } from 'react'
import { MIN_RECEIVABLES_ALLOWED, ReceivableType } from '../../constants'
import { AgglutinationErrorMap } from './constants'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { NotificationProps } from '@olaisaac/design-system'

type AgglutinationProps = {
  setShowAgglutinationDrawer: (value: boolean) => void
}

export const Agglutination = ({ setShowAgglutinationDrawer }: AgglutinationProps) => {
  const [receivables, setReceivables] = useState<uuid[]>([])
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const { schoolId } = useNavigation()
  const { checkoutCart } = useCheckoutCart()

  const { data, isCheckCanAgglutinateFetched } = useCanAgglutinate({
    receivable_ids: receivables,
    school_id: schoolId ?? '',
  })

  const {
    setMessage: setSnackbarMessage,
    setIsOpen: setSnackbarIsOpen,
    setVariation: setSnackbarVariation,
  } = useSnackbar()

  const showSnackbar = (variation: NotificationProps['variation'], message: string) => {
    setSnackbarVariation(variation)
    setSnackbarMessage(message)
    setSnackbarIsOpen(true)
  }

  const handleAgglutinationCheck = () => {
    const receivables = checkoutCart?.map((installment: Installment) => installment.receivable_id)

    if (checkoutCart?.find((installment: Installment) => installment.is_from_credit_card_flow))
      return showSnackbar('error', AgglutinationErrorMap.CREDIT_CARD_RECEIVABLES)

    if (receivables?.length < MIN_RECEIVABLES_ALLOWED)
      return showSnackbar('error', AgglutinationErrorMap.LESS_THAN_TWO_RECEIVABLES)

    if (
      checkoutCart?.find(
        (installment: Installment) => installment.receivable_type === ReceivableType.ENROLLMENT
      )
    )
      return showSnackbar('error', AgglutinationErrorMap.ONLY_TUITION_RECEIVABLES_ALLOWED)
    isInitialized &&
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.BUTTON_CLICKED,
        scope: EventDispatcherEventScopes.INSTALLMENTS_LIST,
        entity: EventDispatcherEntities.AGGLUTINATION,
        action: 'click',
        customProperties: {
          $name: 'Aglutinar',
          $receivables_ids: receivables,
        },
      })
    setReceivables(receivables as uuid[])
  }

  useEffect(() => {
    if (isCheckCanAgglutinateFetched) {
      return data?.can_agglutinate
        ? setShowAgglutinationDrawer(true)
        : showSnackbar(
            'error',
            AgglutinationErrorMap[data?.reason as keyof typeof AgglutinationErrorMap]
          )
    }
  }, [isCheckCanAgglutinateFetched, receivables])

  return (
    <Button variant="ghost" aria-label="aglutinar" onClick={handleAgglutinationCheck}>
      <Icon path={mdiCallMerge} size="20px" style={{ marginRight: '12px' }} />
      Aglutinar
    </Button>
  )
}
