import { ContainerCards } from '../../components/ContainerCards'
import { ResponsiveContainer } from './styles'
import { HeaderHome } from '../../components/HeaderHome'
import { HomeTemplateProps } from './types'

export const HomeTemplate = ({
  title,
  cardDescription,
  mainCardDescription,
  isEligible,
  cards,
  bottomInfo,
  onClickButton,
  orderLock,
  isInMaintenance,
}: HomeTemplateProps) => {
  return (
    <>
      <HeaderHome
        description={cardDescription}
        isEligible={isEligible}
        title={title}
        onClickButton={onClickButton}
        orderLock={orderLock}
        isInMaintenance={isInMaintenance}
      />
      <ResponsiveContainer>
        <ContainerCards cards={cards} title={mainCardDescription} />
        {bottomInfo}
      </ResponsiveContainer>
    </>
  )
}
