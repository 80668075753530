import { useState } from 'react'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useApiClient, useSnackbar } from '@/shared/hooks'
import { accountReportsService } from '@monorepo/account-reports/services/account-reports'

import { downloadFile } from '@/shared/utils'

import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { IR_BASE_FOLDER_NAME, ReportTypes } from '../../models/enums/ReportTypes.enum'

export const useDownloadIRReport = () => {
  const { apiClient } = useApiClient()
  const service = accountReportsService(apiClient.getClients().privateApi)
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { school } = useSelectedSchool()
  const { setMessage, setIsOpen } = useSnackbar()
  const [isLoading, setIsLoading] = useState(false)

  const sendEvent = (isDownloadSuccessful: boolean) => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.DOWNLOAD_FILE,
        scope: EventDispatcherEventScopes.REPORTS,
        action: 'file_download',
        customProperties: {
          $is_download_successful: isDownloadSuccessful,
          $report: ReportTypes.IR,
          $Button_name: 'Baixar relatório do demonstrativo de valores pagos 2023',
        },
      })
  }

  const downloadIRReport = async (selectedYear: number) => {
    setIsLoading(true)

    let isDownloadSuccessful = true

    try {
      const fileName =
        selectedYear === 2023 ? school?.id : `${IR_BASE_FOLDER_NAME}/${selectedYear}/${school?.id}`

      const { url } = await service.getPresignedUrl({
        extension: 'zip',
        file_name: fileName,
        method: 'GET',
      })

      const zipFileDownload = async (fileName: string) => {
        const { data } = await service.downloadFile(fileName)
        return data
      }

      await downloadFile(school?.id ?? '', 'zip', () => zipFileDownload(url))
    } catch (error) {
      isDownloadSuccessful = false
      setMessage(
        'Erro ao baixar relatório. Por favor tente novamente mais tarde ou entre em contato com o nosso suporte.'
      )
      setIsOpen(true, { variation: 'error' })
    } finally {
      setIsLoading(false)
      sendEvent(isDownloadSuccessful)
    }
  }

  return {
    downloadIRReport,
    isLoading,
  }
}
