import { date2PTFormat, formatCentsToReal } from 'src/shared/utils'
import { ContractInstallments } from 'src/escolas/components/contract/ContractDetailsDrawer/ContractDetailsDrawerDefaultContent'
import { useContract } from 'src/escolas/hooks'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { isIsaacPaySchool } from '@/shared/utils/isIsaacPaySchool'
import { Separator } from '@gravity/separator'
import { Text } from '@gravity/text'
import * as Styled from './ContractDetailsSummary.styles'

export type ContractDetailsSummaryProps = {
  contractInstallments: ContractInstallments
}

export const ContractDetailsSummary = ({ contractInstallments }: ContractDetailsSummaryProps) => {
  const { annuity, enrollment, tuition, isaacPay } = contractInstallments
  const { contract } = useContract()

  const { school } = useSelectedSchool()
  const tuitionInstallments = isIsaacPaySchool(school) ? isaacPay : tuition

  return (
    <Styled.Container>
      {enrollment.installments_quantity > 0 && (
        <Styled.ContentContainer>
          <Text variant="subtitle-regular">Pré-matrícula</Text>
          <Styled.AmountDisplay>
            <Styled.GrayText variant="body-1-regular">Total</Styled.GrayText>
            <Styled.GrayText variant="body-1-regular" data-testid="enrollment-total-amount">
              {formatCentsToReal(enrollment.totalAmount)}
            </Styled.GrayText>
          </Styled.AmountDisplay>

          <Styled.AmountDisplay>
            <Styled.Discount variant="body-1-regular">Descontos</Styled.Discount>
            <Styled.Discount
              variant="body-1-regular"
              data-testid="enrollment-discounts-total-amount"
            >
              {`- ${formatCentsToReal(enrollment.discounts)}`}
            </Styled.Discount>
          </Styled.AmountDisplay>

          <Styled.AmountDisplay>
            <Text variant="body-1-regular">Parcelas</Text>
            <Text variant="body-1-regular" data-testid="enrollment-installments">{`${
              enrollment.installments_quantity
            }x de ${formatCentsToReal(enrollment.installment - enrollment.discounts)}`}</Text>
          </Styled.AmountDisplay>
        </Styled.ContentContainer>
      )}

      <Styled.ContentContainer>
        <Text variant="subtitle-regular">Mensalidades</Text>
        <Styled.AmountDisplay>
          <Styled.GrayText variant="body-1-regular">Total</Styled.GrayText>
          <Styled.GrayText variant="body-1-regular" data-testid="tuition-total-amount">
            {formatCentsToReal(tuitionInstallments.totalAmount)}
          </Styled.GrayText>
        </Styled.AmountDisplay>
        <Styled.AmountDisplay>
          <Styled.Discount variant="body-1-regular">Descontos</Styled.Discount>
          <Styled.Discount variant="body-1-regular" data-testid="tuition-discounts-total-amount">
            {`- ${formatCentsToReal(tuitionInstallments.discounts)}`}
          </Styled.Discount>
        </Styled.AmountDisplay>
        <Styled.AmountDisplay>
          <Text variant="body-1-regular">Parcelas</Text>
          <Text variant="body-1-regular" data-testid="tuition-installments">{`${
            tuitionInstallments.installments_quantity
          }x de ${
            tuitionInstallments.installments_quantity > 0
              ? formatCentsToReal(tuitionInstallments.installment - tuitionInstallments.discounts)
              : formatCentsToReal(0)
          }`}</Text>
        </Styled.AmountDisplay>
        <Separator color="neutral-2" orientation="horizontal" />

        <Styled.AmountDisplay>
          <Styled.GrayText variant="body-1-regular">Anuidade</Styled.GrayText>
          <Styled.GrayText variant="body-1-regular">
            {formatCentsToReal(annuity.totalAmount)}
          </Styled.GrayText>
        </Styled.AmountDisplay>
        <Styled.AmountDisplay>
          <Styled.GrayText variant="body-1-regular">Total de descontos</Styled.GrayText>
          <Styled.GrayText variant="body-1-regular">
            {formatCentsToReal(annuity.totalDiscounts)}
          </Styled.GrayText>
        </Styled.AmountDisplay>
      </Styled.ContentContainer>
      <Separator color="neutral-2" orientation="vertical" />

      <Styled.FooterContainer>
        <Styled.ContractIDContent>
          <Styled.BoldText variant="body-2-medium">ID do contrato</Styled.BoldText>
          <Text variant="body-2-regular">{contract?.id}</Text>
        </Styled.ContractIDContent>
        {contract?.created_at && (
          <Styled.BoldText variant="body-2-medium">{`Contrato criado em ${date2PTFormat(
            contract?.created_at
          )}`}</Styled.BoldText>
        )}
      </Styled.FooterContainer>
    </Styled.Container>
  )
}
