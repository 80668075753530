import { Button } from '@gravity/button'
import { mdiArrowLeft, mdiArrowRight } from '@mdi/js'
import { useHistory } from 'react-router-dom'
import Icon from '@mdi/react'

import * as Styled from './styles'
import type { PageFooterProps } from './types'

export const PageFooter = ({ goToNextStepBtnDisabled }: PageFooterProps) => {
  const history = useHistory()
  const handleCancelClick = history.goBack
  return (
    <Styled.Wrapper>
      <Button variant="outline" onClick={handleCancelClick}>
        Cancelar
      </Button>

      <Styled.ButtonsWrapper>
        <Button disabled variant="outline" iconStart={<Icon path={mdiArrowLeft} />}>
          Voltar
        </Button>
        <Button
          disabled={goToNextStepBtnDisabled}
          variant="solid"
          iconEnd={<Icon path={mdiArrowRight} />}
        >
          Próximo
        </Button>
      </Styled.ButtonsWrapper>
    </Styled.Wrapper>
  )
}
