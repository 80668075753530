import { useState } from 'react'
import BoxFooter from '../../BoxFooter/BoxFooter'
import type { AccountFieldProps } from '../types'
import * as S from '../styles'
import { LiquidationSource } from '@/shared/interfaces'
import { RadioGroup, RadioGroupItem } from '@gravity/radio'
import { Text } from '@gravity/text'
import { colors } from '@gravity/tokens'

const AccountField = ({ onSave, onCancel, value }: AccountFieldProps) => {
  const [selectedValue, setSelectedValue] = useState<string | undefined>(value)

  const handleValueChange = (valueRadio: string) => {
    setSelectedValue(valueRadio)
  }

  const isValid = !!selectedValue
  return (
    <S.StyledBox>
      <S.StyledContent>
        <RadioGroup aria-label="account" name="radio-buttons-account" value={selectedValue}>
          <S.WrapperRadio>
            <RadioGroupItem
              className="mt-2"
              value={LiquidationSource.ISAAC_ACCOUNT}
              onClick={() => handleValueChange(LiquidationSource.ISAAC_ACCOUNT)}
            />
            <S.StyledTextRadio onClick={() => handleValueChange(LiquidationSource.ISAAC_ACCOUNT)}>
              <Text variant="body-1-regular">Conta do isaac</Text>
              <Text variant="body-2-regular" style={{ color: colors['colors-text-main-3'] }}>
                O recebimento foi na maquininha isaac, e o valor está na conta do isaac.
              </Text>
            </S.StyledTextRadio>
          </S.WrapperRadio>
          <S.WrapperRadio>
            <RadioGroupItem
              className="mt-2"
              value={LiquidationSource.SCHOOL_ACCOUNT}
              onClick={() => handleValueChange(LiquidationSource.SCHOOL_ACCOUNT)}
            />
            <S.StyledTextRadio onClick={() => handleValueChange(LiquidationSource.SCHOOL_ACCOUNT)}>
              <Text variant="body-1-regular">Conta da Escola</Text>
              <Text variant="body-2-regular" style={{ color: colors['colors-text-main-3'] }}>
                O recebimento foi feito na maquininha escola, em dinheiro ou transferência e o valor
                está na conta da escola.
              </Text>
            </S.StyledTextRadio>
          </S.WrapperRadio>
        </RadioGroup>
      </S.StyledContent>
      <BoxFooter
        isValid={isValid}
        onCancel={onCancel}
        onSave={() => selectedValue && onSave(selectedValue)}
      />
    </S.StyledBox>
  )
}

export default AccountField
