import { useCallback } from 'react'
import { Table } from '@gravity/table'
import { mdiTrashCan, mdiUndo } from '@mdi/js'
import Icon from '@mdi/react'
import { IconButton } from '@gravity/icon-button'
import { Tooltip } from '@gravity/tooltip'

import { StatusBadge } from '@monorepo/enrollment/components/StatusBadge'
import { PaginationType } from '@/shared/hooks/usePagination/types'

import { TableRowProps, ColumnProps, RowActionTypes } from './types'

interface StudentsTableProps {
  disableActions: boolean
  disabledRows: string[]
  isLoading: boolean
  onActionRow: (rowKey: string, action: RowActionTypes) => void
  pagination: PaginationType
  rows: TableRowProps[]
  totalStudents: number
  updatePaginationValue: (key: keyof PaginationType, value: number, replaceRoute?: boolean) => void
}

interface ActionButtonProps {
  icon: string
  onClick: () => void
  text: string
}

const COLUMN_HEADERS = [
  { key: 'student_column', label: 'Aluno' },
  { key: 'main_guardian_column', label: 'Responsável' },
  { key: 'product_column', label: 'Produto de origem' },
  { key: 'student_status_column', label: 'Situação' },
]

const EmptyState = () => {
  return (
    <Table.Row>
      <Table.CustomCell>Nenhum aluno encontrado</Table.CustomCell>
    </Table.Row>
  )
}

const LoadingState = () => {
  return (
    <Table.Row>
      <Table.LoadingCell />
      <Table.LoadingCell />
      <Table.LoadingCell />
      <Table.LoadingCell />
    </Table.Row>
  )
}

const ActionButton = ({ onClick, icon, text }: ActionButtonProps) => (
  <Tooltip text={text} position="left">
    <IconButton onClick={onClick} variant="ghost">
      <Icon path={icon} />
    </IconButton>
  </Tooltip>
)

export const StudentsTable = ({
  rows,
  isLoading,
  disabledRows,
  disableActions,
  onActionRow,
  pagination,
  totalStudents,
  updatePaginationValue,
}: StudentsTableProps) => {
  const isRowDisabled = useCallback((rowKey: string) => disabledRows.includes(rowKey), [
    disabledRows,
  ])

  return (
    <Table.Root>
      <Table.Head>
        {COLUMN_HEADERS.map(({ key, label }: ColumnProps) => (
          <Table.HeaderCell key={key} name={key}>
            {label}
          </Table.HeaderCell>
        ))}
      </Table.Head>
      <Table.Body>
        {isLoading && <LoadingState />}
        {!isLoading && rows.length === 0 && <EmptyState />}
        {rows.map(row => {
          const { key, columns } = row
          const disabled = isRowDisabled(key)
          return (
            <Table.Row key={key}>
              <Table.TextCell disabled={disabled}>{columns[0]}</Table.TextCell>
              <Table.TextCell disabled={disabled}>{columns[1]}</Table.TextCell>
              <Table.TextCell disabled={disabled}>{columns[2]}</Table.TextCell>
              <Table.CustomCell disabled={disabled}>
                <StatusBadge status={columns[3]} disabled={disabled} />
              </Table.CustomCell>
              {!disableActions && (
                <Table.ActionCell
                  actions={
                    <ActionButton
                      onClick={() =>
                        onActionRow(key, disabled ? RowActionTypes.UNDO : RowActionTypes.REMOVE)
                      }
                      icon={disabled ? mdiUndo : mdiTrashCan}
                      text={disabled ? 'Desfazer' : 'Remover'}
                    />
                  }
                />
              )}
            </Table.Row>
          )
        })}
      </Table.Body>
      <Table.Pagination
        page={pagination.page}
        itemsPerPage={pagination.itemsPerPage}
        total={totalStudents}
        onChangePage={newPage => {
          updatePaginationValue('page', newPage, true)
          window.scrollTo(0, 0)
        }}
        onChangeItemsPerPage={newItensPerPage => {
          updatePaginationValue('itemsPerPage', newItensPerPage, true)
        }}
      />
    </Table.Root>
  )
}
