import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import MuiTypography from '@material-ui/core/Typography'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import {
  Checkbox,
  Divider,
  FormControlLabel,
  TooltipButton,
  Typography,
} from '@olaisaac/design-system'
import { isEmpty } from 'ramda'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { fieldsNamesMapping } from 'src/escolas/components/contract/create/constants'
import {
  ContractFormProps,
  EnrollmentFormFields,
  FormProps,
} from 'src/escolas/components/contract/create/types'
import {
  getDefaultEnrollmentFormFields,
  newEmptyGuardian,
} from 'src/escolas/components/contract/create/utils'
import { Enrollment } from 'src/shared/interfaces'
import styled from 'styled-components'
import ConfirmationDialog, {
  FailureFeedbackContent,
  failureFeedbackTitle,
} from '@/shared/components/ConfirmationDialog'
import { AddDiscountsFormType } from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentDrawer/AddDiscountContent'
import ContractSelectYear from './ContractSelectYear'
import ContractSummary from './ContractSummary'
import PaymentPlanForm from './PaymentPlanForm'
import PreContractDisclaimer from './PreContractDisclaimer'
import SelectProduct from './SelectProduct'
import { StudentForm } from './StudentForm'
import GuardianForm from './components/GuardianForm/GuardianForm'
import { getConditionalValueRender } from './conditions'
import useDueDayCalculator from './hooks/useDueDayCalculator'
import { GoBackButton } from '@/shared/components/GoBackButton'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EnrollmentEventDispatcherEvents } from '@/shared/models/enums/EnrollmentEventDispatcherEvents.enum'
import * as Sentry from '@sentry/react'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { useApiClient } from '@/shared/hooks/useApiClient'
import { userCreationService } from '@/modules/contract/services/user-creation'

export const StyledTypography = styled(Typography).attrs({ withoutMargin: true })`
  overflow-wrap: break-word;
`
export const FieldTitleTypography = styled(StyledTypography)`
  line-height: 24px;
  font-size: 18px;
  margin-bottom: 4px;
  color: #414141;
`

export const ContractForm = ({
  contractCreationProgress,
  onSubmit,
  errorFieldNames,
  isFeedbackDialogOpen,
  isInvoiceLimitError,
  isLoading,
  setReferenceYear,
  referenceYear,
  setIsFeedbackDialogOpen,
  hasGuardianDocumentError,
  clearGuardianDocumentError,
}: ContractFormProps) => {
  const [enrollmentFormFields, setEnrollmentFormFields] = useState<EnrollmentFormFields>(
    getDefaultEnrollmentFormFields(0)
  )
  const [isEnrollmentEnabled, setEnrollmentEnabled] = useState<boolean>(false)

  const { apiClient } = useApiClient()
  const { checkUserByEmail } = userCreationService(apiClient.getClients().bffApi)
  const shouldValidateEmailOnContractCreate = useUnleashFlag(
    UnleashFlags.EFI_239_VALIDATE_EMAIL_ON_CONTRACT_CREATE
  )

  const form = useForm<FormProps>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: false,
    defaultValues: {
      disable_send_signable_document: false,
      due_day: undefined,
      use_working_dates_tuition: 'true',
      duration_months: '',
      enrollment_due_day: undefined,
      use_working_dates_enrollment: 'true',
      enrollment_duration_months: '',
      enrollment_start_month: undefined,
      guardian: newEmptyGuardian(),
      product_id: '',
      send_first_enrollment_installment_message: true,
      start_month: undefined,
      student: { id: undefined, name: '', tax_id: '', no_tax_id: false },
      reference_year: referenceYear,
      custom_monthly_amount: undefined,
      custom_enrollment_monthly_amount: undefined,
      pre_contract_disclaimer_check: false,
      product: undefined,
    },
  })
  const { control, handleSubmit: formHandleSubmit, getValues, formState, watch, setError } = form

  watch()
  const formValues = getValues()

  const discountsForm = useForm<AddDiscountsFormType>({
    mode: 'onChange',
  })

  const {
    getValues: discountsFormGetValues,
    formState: discountsFormState,
    trigger: triggerDiscountsForm,
  } = discountsForm

  const discountsFormValues = discountsFormGetValues()

  const discounts = discountsFormValues?.discounts

  const EnrollmentDiscountsForm = useForm<EnrollmentFormFields>({
    mode: 'onChange',
  })

  const {
    getValues: enrollmentDiscountsFormGetValues,
    formState: enrollmentDiscountsFormState,
    trigger: triggerEnrollmentDiscountsForm,
  } = EnrollmentDiscountsForm

  const enrollmentDiscountsFormValues = enrollmentDiscountsFormGetValues()

  const enrollmentDiscounts = enrollmentDiscountsFormValues?.discounts ?? []

  const { errors } = formState
  const { errors: discountErros } = discountsFormState
  const { errors: enrollmentDiscountErros } = enrollmentDiscountsFormState
  const product = getValues('product')
  const durationMonthsString = getValues('duration_months')
  const durationMonths = durationMonthsString ? +durationMonthsString : 0
  const enrollmentDurationMonths = +getValues('enrollment_duration_months')
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const {
    enrollment_start_month,
    enrollment_due_day,
    use_working_dates_enrollment,
    start_month,
    use_working_dates_tuition,
    due_day,
  } = getValues()

  const firstDueDay = useDueDayCalculator(
    start_month,
    due_day || start_month?.date(),
    use_working_dates_tuition === 'true'
  )

  const enrollmentFirstDueDay = useDueDayCalculator(
    enrollment_start_month,
    enrollment_due_day || enrollment_start_month?.date(),
    use_working_dates_enrollment === 'true'
  )

  useEffect(() => {
    if (hasGuardianDocumentError) {
      setError('guardian.tax_id', {
        type: 'custom',
        message:
          'Não pode ser utilizado CPF de menor de idade ou que seja inválido na Receita Federal.',
      })

      clearGuardianDocumentError()
    }
  }, [hasGuardianDocumentError])

  const getFeedbackDialogParameters = (): {
    feedbackDialogContent: React.ReactNode
    feedbackDialogTitle: string
  } => {
    if (isInvoiceLimitError) {
      return {
        feedbackDialogTitle: 'Limite de criação de faturas atingido',
        feedbackDialogContent: <FailureFeedbackContent />,
      }
    }
    if (errorFieldNames?.length) {
      return {
        feedbackDialogTitle: 'Por favor, revise os seguintes campos:',
        feedbackDialogContent: (
          <ul>
            {errorFieldNames.map((name, idx) => (
              <li key={idx}>{fieldsNamesMapping[name as keyof typeof fieldsNamesMapping]}</li>
            ))}
          </ul>
        ),
      }
    }

    return {
      feedbackDialogTitle: failureFeedbackTitle,
      feedbackDialogContent: <FailureFeedbackContent />,
    }
  }

  const { feedbackDialogTitle, feedbackDialogContent } = getFeedbackDialogParameters()

  const handleCloseFeedbackDialog = () => {
    setIsFeedbackDialogOpen(false)
  }

  const getEnrollmentData = () => {
    const enrollmentData: Enrollment | Record<string, never> = isEnrollmentEnabled
      ? {
          enrollment_discounts: enrollmentDiscounts?.map(discount => ({
            amount: discount.amount,
            description: discount.description,
            days_before_due_date: +discount.days_before_due_date,
          })),
          enrollment_due_day:
            +getValues('enrollment_due_day') || getValues('enrollment_start_month')?.date(),
          use_working_dates_enrollment: getValues('use_working_dates_enrollment') === 'true',
          enrollment_duration_months: +getValues('enrollment_duration_months'),
          enrollment_start_month: getValues('enrollment_start_month')?.toISOString(),
          send_first_enrollment_installment: getValues('send_first_enrollment_installment_message'),
        }
      : {}

    return enrollmentData
  }

  const [isEditing, setIsEditing] = useState(false)

  const hasErrors = !isEmpty(errors) || !isEmpty(discountErros) || !isEmpty(enrollmentDiscountErros)

  const handleSubmit = async () => {
    triggerDiscountsForm()
    triggerEnrollmentDiscountsForm()
    window.scrollTo(0, 0)
    const { canSaveContract } = getConditionalValueRender({
      customMonthlyAmount: formValues.custom_monthly_amount,
      customEnrollmentMonthlyAmount: formValues.custom_enrollment_monthly_amount,
      discounts,
      enrollmentDiscounts,
    })

    if (shouldValidateEmailOnContractCreate) {
      try {
        const { guardian } = formValues

        const { is_user_creation_allowed } = await checkUserByEmail({
          email: guardian.email,
          username: guardian.tax_id,
        })

        if (!is_user_creation_allowed) {
          setError('guardian.email', {
            type: 'custom',
            message: 'Este e-mail já é usado por outro responsável. Informe um e-mail novo.',
          })

          return
        }
      } catch (error) {
        console.error(error)
      }
    }

    if (!canSaveContract || hasErrors) {
      console.error({ canSaveContract, hasErrors, errors, discountErros, enrollmentDiscountErros })
      Sentry.captureMessage('Contract form has errors')
      return
    }

    const enrollmentData = getEnrollmentData()

    return onSubmit(enrollmentData, formValues, discounts)
  }

  return (
    <form onSubmit={formHandleSubmit(handleSubmit)}>
      <Grid item xs={8}>
        <Box>
          <Box mt={3} mb={3}>
            <GoBackButton
              aditionalOnClick={() =>
                isInitialized &&
                eventDispatcherClient.sendEvent({
                  scope: EventDispatcherEventScopes.ADD_NEW_CONTRACT_PAGE,
                  name: EnrollmentEventDispatcherEvents.CLICKED,
                  action: 'click',
                  customProperties: { $Button_name: 'VOLTAR' },
                })
              }
            />
          </Box>
          <Box display="flex" alignItems="center" mb="40px">
            <Typography variation="headlineDesktopMedium" style={{ margin: 0 }}>
              Adicionar contrato
            </Typography>
            <Box pl={1} display="flex">
              <Tooltip title="Aqui você insere todas as informações necessárias para cadastrar um contrato na plataforma. Se o responsável pelo aluno já estiver na base de dados, os dados serão resgatados.">
                <TooltipButton>
                  <InfoOutlinedIcon color="action" />
                </TooltipButton>
              </Tooltip>
            </Box>
          </Box>
          <Box mb={6}>
            <ContractSelectYear
              form={form}
              referenceYear={referenceYear}
              setReferenceYear={setReferenceYear}
            />
          </Box>
          <Box mb={6}>
            <SelectProduct form={form} referenceYear={referenceYear} />
          </Box>
          <Box>
            <GuardianForm
              form={form}
              setIsFeedbackDialogOpen={setIsFeedbackDialogOpen}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </Box>
          <Box mt="48px" style={{ marginBottom: 48 }}>
            <StudentForm form={form} />
          </Box>
          <Divider style={{ marginBottom: 48 }} />
          <PaymentPlanForm
            form={form}
            product={product}
            discountsForm={discountsForm}
            enrollmentForm={EnrollmentDiscountsForm}
            enrollmentFormFields={enrollmentFormFields}
            setEnrollmentFormFields={setEnrollmentFormFields}
            isEnrollmentEnabled={isEnrollmentEnabled}
            setEnrollmentEnabled={setEnrollmentEnabled}
          />
          {product?.envelope_template_referral_id && (
            <>
              <MuiTypography>Contrato</MuiTypography>
              <Box mt={2}>
                <Controller
                  defaultValue={false}
                  name="disable_send_signable_document"
                  control={control}
                  render={({ field: { value, ...rest } }) => (
                    <>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checkedLink={undefined}
                            valueLink={undefined}
                            {...rest}
                            checked={value}
                          />
                        }
                        label="Não enviar contrato pelo isaac"
                      />
                      <Box pl={4}>
                        <MuiTypography variant="body2" color="textSecondary">
                          Ao marcar, escola confirma que o responsável recebeu e assinou o contrato.
                          O responsável não receberá o contrato por intermédio do isaac.
                        </MuiTypography>
                      </Box>
                    </>
                  )}
                />
              </Box>
            </>
          )}
          <PreContractDisclaimer form={form} referenceYear={referenceYear} />
        </Box>
        <ConfirmationDialog
          isVisible={isFeedbackDialogOpen}
          onClose={handleCloseFeedbackDialog}
          submitHandler={handleCloseFeedbackDialog}
          title={feedbackDialogTitle}
        >
          {feedbackDialogContent}
        </ConfirmationDialog>
      </Grid>
      <Grid item xs={4}>
        <ContractSummary
          contractCreationProgress={contractCreationProgress}
          installments={durationMonths}
          installmentAmount={formValues.custom_monthly_amount}
          discounts={discounts}
          isEnrollmentEnabled={isEnrollmentEnabled}
          enrollmentAmount={formValues.custom_enrollment_monthly_amount}
          enrollmentDiscount={enrollmentDiscounts}
          enrollmentInstallments={enrollmentDurationMonths}
          envelopeTemplateReferralId={product?.envelope_template_referral_id}
          isLoading={isLoading}
          formValues={formValues}
          enrollmentFirstDueDay={enrollmentFirstDueDay}
          firstDueDay={firstDueDay}
          setIsEditing={setIsEditing}
        />
      </Grid>
    </form>
  )
}
