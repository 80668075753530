import type { ChangeEvent } from 'react'
import Icon from '@mdi/react'
import { mdiPlus } from '@mdi/js'

import { InstallmentFormDiscountItem } from '@monorepo/enrollment/pages/Campaigns/CampaignDetails/components/InstallmentFormDiscountItem'
import { useManageDiscounts } from '@monorepo/enrollment/pages/Campaigns/CampaignDetails/components/InstallmentForm/hooks/useManageDiscounts'

import type { DiscountFieldsProps } from '../../types'
import * as Styled from './styles'

export const DiscountFields = ({ form, renderDiscountBtn }: DiscountFieldsProps) => {
  const totalAmountPaid = form.watch('custom_monthly_amount')

  const {
    addNewDiscountInput,
    handleRemoveDiscountClick,
    isAddDiscountBtnDisabled,
    discountFields,
    validateDiscountAmount,
    onPercentageValueChange,
  } = useManageDiscounts(form)

  return (
    <>
      {discountFields.length > 0 && <Styled.Separator />}

      <Styled.DiscountList role="list" aria-label="Lista de descontos">
        {discountFields.map((discount, index) => (
          <InstallmentFormDiscountItem
            form={form}
            key={discount.id}
            index={index}
            totalAmountPaid={totalAmountPaid}
            onClickRemove={handleRemoveDiscountClick(index)}
            validateDiscountAmount={validateDiscountAmount}
            onPercentageValueChange={(event: ChangeEvent<HTMLInputElement>) =>
              onPercentageValueChange(event.target.value, index)
            }
          />
        ))}
      </Styled.DiscountList>

      {renderDiscountBtn ? (
        renderDiscountBtn(isAddDiscountBtnDisabled, addNewDiscountInput)
      ) : (
        <Styled.AddDiscountBtn
          fullWidth
          disabled={isAddDiscountBtnDisabled}
          variant="outline"
          iconEnd={<Icon path={mdiPlus} />}
          onClick={addNewDiscountInput}
          type="button"
        >
          Adicionar desconto
        </Styled.AddDiscountBtn>
      )}
    </>
  )
}
