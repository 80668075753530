import { Skeleton } from '@gravity/skeleton'
import { CardStyled } from '../../styles'
import { RowContainer, TitleRowContainer } from './styles'

/**
 * Loading state for dashboard card component
 */
export const LoadingState = () => {
  return (
    <CardStyled data-testid="loading-state">
      <TitleRowContainer>
        <Skeleton radius={6} width={76} height={22} />
        <Skeleton radius={6} height={40} fullWidth />
      </TitleRowContainer>
      <RowContainer>
        <Skeleton radius={6} width={208} height={21} />
        <Skeleton radius={6} width={252} height={40} />
        <Skeleton radius={6} height={21} fullWidth />
      </RowContainer>

      <Skeleton radius={6} height={40} fullWidth />
    </CardStyled>
  )
}
