import { useEffect, useMemo, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import styled from 'styled-components'
import { ActionDrawerHeader, Button, ButtonDocker, Divider, Drawer } from '@olaisaac/design-system'

import { CollapsibleFilterGroup } from '@/shared/components/CollapsibleFilterGroup'
import { FilterButton } from '../../../ListGuardians/components/FilterButton/FilterButton'
import { FiltersInstallmentsFormType, GuardianDetailsInstallmentsFilterProps } from './types'
import { InstallmentsFiltersType } from '../../types'
import { updatedFiltersForm } from '../../utils/updatedFiltersForm'
import { getInstallmentsFiltersGroup } from '../../utils/filtersGroup'
import { transformFilters } from '../../utils/transformFilters'
import { ActiveFilters } from '../ActiveFilters/ActiveFilters'
import LoadingProgress from '@/shared/components/CircularProgress/CircularProgress'
import { countSelectedFilters } from '@/shared/utils/countSelectedFilters'
import { getAllFilters } from '@/modules/guardians/GuardianDetails/utils/getAllFilters'

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: end;
  padding: 20px 0 10px;
  gap: ${({ theme }) => theme.primitiveTokens.spacing[4]};
`

export const GuardianDetailsInstallmentsFilter = ({
  filters,
  filtersOptions,
  isFiltersOptionsFetched,
  updateFilters,
  agglutinationButton,
  updateEnrollmentStatusButton,
}: GuardianDetailsInstallmentsFilterProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const defaultValues: any = {
    installment_statuses: [],
    installment_types: [],
    reference_years: [],
    student_ids: [],
    product_ids: [],
    contract_status: undefined,
  }

  const form = useForm<FiltersInstallmentsFormType>({
    defaultValues,
  })

  const { setValue, handleSubmit, reset, formState } = form

  const handleApplyFilters: SubmitHandler<FiltersInstallmentsFormType> = data => {
    const filters = updatedFiltersForm(data)

    const updatedFilters = Object.keys(filters).length > 0 ? filters : defaultValues

    updateFilters(updatedFilters)
    setIsOpen(false)
  }

  const handleClearFilter = () => {
    reset(defaultValues)
  }

  const handleClearFiltersLink = () => {
    handleClearFilter()
    handleApplyFilters(defaultValues)
  }

  useEffect(() => {
    Object.keys(filters).forEach(key => {
      setValue(
        key as keyof InstallmentsFiltersType,
        (filters[key as keyof InstallmentsFiltersType] || []) as any,
        {
          shouldDirty: true,
        }
      )
    })
  }, [isOpen])

  const allFiltersOptions = useMemo(() => getAllFilters(filtersOptions), [filtersOptions])

  const filtersGroupArray = isFiltersOptionsFetched
    ? getInstallmentsFiltersGroup(allFiltersOptions, setValue)
    : []

  const activeFilters = isFiltersOptionsFetched
    ? transformFilters(filters, allFiltersOptions)
    : null

  const hasActiveFilters = activeFilters
    ? Object.values(activeFilters).some(filter => {
        if (Array.isArray(filter)) {
          return filter.length > 0
        }

        return filter !== undefined
      })
    : false

  return (
    <>
      <ButtonsContainer>
        {updateEnrollmentStatusButton}
        {agglutinationButton}
        <FilterButton
          selectedFiltersCount={filters && countSelectedFilters(filters)}
          onClick={() => setIsOpen(true)}
          data-testid="guardianInstallmentsFilterButton"
        />
      </ButtonsContainer>
      {hasActiveFilters && (
        <>
          <Divider />
          <ActiveFilters
            filters={filters}
            activeFilters={(activeFilters || {}) as FiltersInstallmentsFormType}
            updateFilters={updateFilters}
            clearFilters={handleClearFiltersLink}
          />
        </>
      )}
      <Drawer anchor="right" variant="persistent" open={isOpen}>
        <ActionDrawerHeader
          onClose={() => {
            setIsOpen(false)
          }}
          title="Filtrar"
          subtitle="Os filtros são atualizados diariamente"
          data-testid="filter-drawer"
        />
        {isFiltersOptionsFetched ? (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                padding: '1.5rem',
                overflow: 'auto',
              }}
            >
              <FormProvider {...form}>
                {filtersGroupArray.map(
                  ({ label, filterName, options, totalOptions, searchText, onClearFilter }) => {
                    if (
                      (allFiltersOptions?.student?.length === 0 && filterName === 'student_ids') ||
                      (allFiltersOptions?.products?.length === 0 && filterName === 'product_ids')
                    ) {
                      return null
                    }

                    return (
                      <div style={{ marginBottom: '2rem' }} key={filterName}>
                        <CollapsibleFilterGroup
                          label={label}
                          filterName={filterName}
                          options={options}
                          totalOptions={totalOptions}
                          searchText={searchText}
                          onClearFilter={onClearFilter}
                          singleSelection={filterName === 'contract_status'}
                        />
                      </div>
                    )
                  }
                )}
              </FormProvider>
            </div>

            <ButtonDocker>
              <Button fullWidth onClick={handleSubmit(handleApplyFilters)} type="submit">
                Filtrar
              </Button>

              <Button
                fullWidth
                variation="ghost"
                disabled={!formState.isDirty}
                onClick={handleClearFilter}
              >
                Limpar tudo
              </Button>
            </ButtonDocker>
          </>
        ) : (
          <LoadingProgress />
        )}
      </Drawer>
    </>
  )
}
