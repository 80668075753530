import { formatCentsToReal } from '@/shared/utils'
import { Card } from '@gravity/card'
import { Grid, GridItem } from '@gravity/grid'
import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import { mdiAccountSchoolOutline, mdiCurrencyUsd, mdiFolderOutline, mdiTagOutline } from '@mdi/js'
import Icon from '@mdi/react'
import { useEvents } from '@monorepo/onboarding/hooks/eventContext'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useBatchSummaryQuery } from '../../hooks/useBatchSummaryQuery'
import { GenericError } from '../components/GenericError'
import { LoadingPage } from '../LoadingPage'

const StyledGrid = styled(Grid)`
  margin: 0;
`

export const DashboardContent = () => {
  const { batchID } = useParams<{ batchID: string }>()
  const events = useEvents()

  const { isLoading, data, isError } = useBatchSummaryQuery(
    { batchID },
    {
      onError(error) {
        console.error(error)
      },
    }
  )
  useEffect(() => {
    events?.batchPageDashboardViewed()
  }, [])

  if (isError) return <GenericError />

  if (isLoading || data === undefined) {
    return <LoadingPage />
  }

  return (
    <StyledGrid>
      <GridItem lg={6}>
        <Card variant="ghost">
          <Icon path={mdiCurrencyUsd} size={1} />
          <Heading style={{ color: '#2F7C57' }} variant="heading-h1-medium">
            {formatCentsToReal(data.total_amount)}
          </Heading>
          <Text variant="body-1-regular">Valor total de receita a ser migrado</Text>
        </Card>
      </GridItem>
      <GridItem lg={6}>
        <Card variant="ghost">
          <Icon path={mdiTagOutline} size={1} />
          <Heading style={{ color: '#CE2C31' }} variant="heading-h1-medium">
            {formatCentsToReal(data.total_discount_amount)}
          </Heading>
          <Text variant="body-1-regular">Valor total de desconto a ser migrado</Text>
        </Card>
      </GridItem>
      <GridItem lg={6}>
        <Card variant="ghost">
          <Icon path={mdiAccountSchoolOutline} size={1} />
          <Heading variant="heading-h1-medium">
            {data.student_count}{' '}
            <Text variant="subtitle-medium">
              {`(${data.student_full_scholarship_count} bolsistas integrais)`}
            </Text>
          </Heading>
          <Text variant="body-1-regular">Alunos a serem migrados</Text>
        </Card>
      </GridItem>
      <GridItem lg={6}>
        <Card variant="ghost">
          <Icon path={mdiFolderOutline} size={1} />
          <Heading variant="heading-h1-medium">{data.contract_count}</Heading>
          <Text variant="body-1-regular">Contratos a serem migrados</Text>
        </Card>
      </GridItem>
    </StyledGrid>
  )
}
