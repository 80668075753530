import { ListItem } from '@material-ui/core'
import styled from 'styled-components'
import * as Icons from '@material-ui/icons'
import { Text } from '@gravity/text'

export const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  padding: ${({ theme }) => theme.spacing(3)}px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.primitiveTokens.colors.gray[10]};
`

export const WrapperContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const WrapperTitle = styled.div`
  display: flex;
  flex-direction: column;
  text-align: initial;
`

export const Title = styled(Text)`
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  font-size: 1.25rem;
`
export const SubTitle = styled(Text)``

export const ButtonLabel = styled(Text)`
  margin-right: ${({ theme }) => theme.spacing(0.5)}px;
`

export const StyledListItem = styled(ListItem)`
  color: ${({ theme }) => theme.primitiveTokens.colors.gray[50]};
`

export const CollapseContent = styled.div``

export const ExpandLess = styled(Icons.ExpandLess)``

export const ExpandMore = styled(Icons.ExpandMore)``
