import { Typography } from '@olaisaac/design-system'
import { formOfPaymentTextMap } from './helpers/constants'
import type { FormOfPaymentItemProps } from './types'
import * as S from './styles'
import { RadioGroupItem } from '@gravity/radio'

const FormOfPaymentItem = ({
  formOfPayment,
  isSelected,
  isPaymentFeeEnabled,
  onSelect,
}: FormOfPaymentItemProps) => {
  const { title, subtitle, subtitleWithFee } = formOfPaymentTextMap[formOfPayment]

  return (
    <S.Wrapper>
      <RadioGroupItem
        checked={isSelected}
        value={formOfPayment}
        onClick={() => onSelect(formOfPayment)}
      />
      <div onClick={() => onSelect(formOfPayment)}>
        <Typography withoutMargin variation="headlineDesktopXsmall" color="primary">
          {title}
        </Typography>
        <Typography withoutMargin variation="bodySmall" color="secondary" style={{ marginTop: 4 }}>
          <span
            dangerouslySetInnerHTML={{
              __html: isPaymentFeeEnabled && subtitleWithFee ? subtitleWithFee : subtitle,
            }}
          />
        </Typography>
      </div>
    </S.Wrapper>
  )
}

export default FormOfPaymentItem
