import { useMemo, useRef, useState } from 'react'
import { Box } from '@material-ui/core'

import { BaseItem, PickerPopOver } from './components/PickerPopOver'
import { GhostPicker } from '@monorepo/report/components/DatePicker/components/GhostPicker'
import { StandardPicker } from '@monorepo/report/components/DatePicker/components/StandardPicker'

type LabelSize = 'small' | 'large'

export type DetailType = 'year' | 'decade'

type WeekPickerProps<T> = {
  keyExtractor: (item: T) => string
  labelSize?: LabelSize
  maxDate?: Date
  maxDetail: DetailType
  minDate?: Date
  minDetail?: DetailType
  onChange: (item: T) => void
  options: T[]
  value: T
  variation?: 'standard' | 'ghost'
}

/**
 * TODO: Unity tests
 *
 * Custom component for week picking
 *
 * @param props
 * @param props.variation Picker trigger variation
 * @param props.labelSize Label size
 * @param props.maxDate Max date allowed
 * @param props.minDate Min date allowed
 * @param props.maxDetail Defines the maximum date detail
 * @param props.minDetail Defines the minimum date detail
 * @param props.keyExtractor Item to key extractor
 * @param props.value Item value
 * @param props.onChange On change callback function
 * @param props.options Item options
 */
export const WeekPicker = <T extends Record<string, any> & BaseItem>({
  value,
  onChange,
  labelSize,
  variation = 'standard',
  ...rest
}: WeekPickerProps<T>) => {
  const openPickerButtonRef = useRef<HTMLButtonElement | null>(null)

  const [isPickerVisible, setIsPickerVisible] = useState(false)

  const handleSelectItem = (item: T) => {
    onChange(item)
    setIsPickerVisible(false)
  }

  const formatWeekLabel = (dates: Date[]): string => {
    const formatter = (date: Date): string => {
      return new Intl.DateTimeFormat('pt-BR', { day: 'numeric', month: 'short' })
        .format(date)
        .replace('de ', '')
        .replace('.', '')
    }

    return `${formatter(dates[0])} - ${formatter(dates[1])}`
  }

  const week = useMemo(() => {
    if (!value) {
      return 'Selecione uma semana'
    }

    return formatWeekLabel([value.started_at, value.ended_at])
  }, [value])

  return (
    <Box display="flex" alignItems="center">
      {variation === 'standard' ? (
        <StandardPicker
          ref={openPickerButtonRef}
          label={week}
          labelSize={labelSize}
          onClick={() => setIsPickerVisible(true)}
        />
      ) : (
        <GhostPicker
          ref={openPickerButtonRef}
          label={week}
          onClick={() => setIsPickerVisible(true)}
        />
      )}

      <PickerPopOver<T>
        value={value}
        onChange={handleSelectItem}
        anchorEl={openPickerButtonRef.current}
        isOpen={isPickerVisible}
        onClose={() => setIsPickerVisible(false)}
        {...rest}
      />
    </Box>
  )
}
