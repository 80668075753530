import { Typography } from '@olaisaac/design-system'
import { RadioGroupItem } from '@gravity/radio'
import { PropsWithChildren } from 'react'
import SplitIcon from 'src/escolas/assets/split-icon.svg'
import { Card, CircleIcon, InfoItem, RadioItem, Row, StyledCreditCard, TextItem } from './styles'
import { OptionCardProps } from './types'

export const PAYMENT_CARD: Record<string, { icon: React.ReactNode; name: string }> = {
  CREDIT_CARD: {
    name: 'Cartão de crédito',
    icon: <StyledCreditCard />,
  },
  DEBIT_CARD: {
    name: 'Cartão de débito',
    icon: <StyledCreditCard />,
  },
  BANK_SLIP: {
    name: 'Boleto e Pix',
    icon: <img src={SplitIcon} />,
  },
}

const OptionCard = ({
  informationText,
  type,
  onSelect,
  isPaymentFeeEnabled,
  isSelected,
  children,
}: PropsWithChildren<OptionCardProps>) => {
  const { name, icon } = PAYMENT_CARD[type]

  return (
    <div style={{ display: 'flex', width: '100%', marginBottom: 0 }}>
      <Card>
        <Row>
          <RadioItem onClick={() => onSelect(type)}>
            <RadioGroupItem value={type} checked={isSelected} />
            <CircleIcon>{icon}</CircleIcon>
          </RadioItem>
          <TextItem onClick={() => onSelect(type)}>
            <span style={{ width: '70%' }}>
              <Typography withoutMargin variation="headlineDesktopXsmall" color="primary">
                {name}
              </Typography>
              {isPaymentFeeEnabled && (
                <Typography variation="bodySmall" color="secondary">
                  {informationText?.bottomLeft}
                </Typography>
              )}
            </span>
            <InfoItem>
              {isPaymentFeeEnabled ? (
                <>
                  <Typography variation="headlineDesktopXsmall" color="primary">
                    {informationText?.info}
                  </Typography>
                  <Typography variation="bodySmall" color="secondary">
                    {informationText?.bottomRight}
                  </Typography>
                </>
              ) : (
                <Typography variation="bodySmall" color="primary">
                  {informationText?.info}
                </Typography>
              )}
            </InfoItem>
          </TextItem>
        </Row>
        {isSelected && children}
      </Card>
    </div>
  )
}

export default OptionCard
