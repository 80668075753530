import { KeyboardArrowDown } from '@material-ui/icons'
import { Box, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { Pagination } from '@olaisaac/design-system'
import { Callout } from '@gravity/callout'
import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import LoadingProgress from '@/shared/components/CircularProgress/CircularProgress'
import { useContractsByStudents } from '@/shared/hooks/queries/useContractsByStudents'
import { usePagination } from '@/shared/hooks/usePagination'
import { ErrorDialog } from '@/shared/components/ErrorDialog'
import { SearchWithDebounce } from '../../components/SearchWithDebounce'
import { useRefreshSituation } from '../../hooks/useRefreshSituation'
import { ContractsTable } from './components/ContractsTable'
import { EnrollButton } from './components/EnrollButton'
import { GuardianInfo } from './components/GuardianInfo'
import { StatusBanner } from './components/StatusBanner'
import { StudentInfo } from './components/StudentInfo'
import { paginateInMemory } from './utils'
import { useSetPageTitle } from '@/shared/hooks/useSetPageTitle'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EnrollmentEventDispatcherEvents } from '@/shared/models/enums/EnrollmentEventDispatcherEvents.enum'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { BreadcrumbsProvider } from '@/shared/contexts/BreadcrumbsContext'
import { PageWrapper } from '@/shared/components/PageWrapper'

import { useGetStudentEnrollmentCycle } from '../../hooks/queries/useGetStudentEnrollmentCycle'
import { useSelectCycle } from './hooks/useSelectCycle'
import { useGetMatriculaContracts } from './hooks/useGetMatriculaContracts'
import * as Styled from './styles'
import { GoBackButton } from '@/shared/components/GoBackButton'
import { UnleashFlags, useJWT, useUnleashFlag } from '@/shared/hooks'
import { PageBreadcrumbs } from './components/PageBreadcrumbs'
import { EditStudent } from './components/EditStudent'
import { Button } from '@gravity/button'
import { AddTuitionToCampaignState } from '../Campaigns/CampaignDetails/types'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useAddTuitionToOneCampaignStudent } from '../../hooks/useAddTuitionToOneCampaignStudent'
import { AddTuitionDialog } from '../../components/AddTuitionDialog'
import { Separator } from '@gravity/separator'
import { Address, Guardian, ReenrollmentStatus, ReEnrollmentSubStatus } from '@/shared/interfaces'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

const NO_YEAR_SELECTED_OPTION = 'Todos'

const emptyGuardian: Guardian = {
  created_at: '',
  id: '',
  address: {
    additional_information: '',
    city: '',
    number: '',
    state_code: '',
    street: '',
    zip: '',
  } as Address,
  address_id: '',
  email: '',
  name: '',
  phone_number: '',
  tax_id: '',
}

const emptyEnabledEnrollmentCycle = {
  is_reenrollable: false,
  reference_year: 0,
  started_enrollment: false,
  student_status: ReenrollmentStatus.ENROLLED,
  student_status_details: [ReEnrollmentSubStatus.PRE_ENROLLMENT_PENDING],
}
export const StudentEnrollment = () => {
  const [productName, setProductName] = useState('')
  const [referenceYear, setReferenceYear] = useState(NO_YEAR_SELECTED_OPTION)
  const [openDialog, setOpenDialog] = useState<boolean>(false)

  const { school } = useSelectedSchool()
  const schoolId = school?.id ?? ''

  const { studentId } = useParams<{ studentId: string }>()
  const { pagination, updatePaginationValue } = usePagination()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const studentEditing = useUnleashFlag(UnleashFlags.EFI_248_STUDENT_EDITING)
  const isIntegratedSchool = useUnleashFlag(UnleashFlags.IS_INTEGRATED_SCHOOL)
  const { isAdmin } = useJWT()

  const studentEnrollmentCycleQuery = useGetStudentEnrollmentCycle({ schoolId, studentId })
  const studentData = studentEnrollmentCycleQuery.data?.data
  const enabledCycles = studentData?.enabled_enrollment_cycles ?? []
  const studentEnrollmentData = studentEnrollmentCycleQuery.data?.data
  const hasEnrollmentCycleEnabled =
    (studentEnrollmentData?.enabled_enrollment_cycles.length ?? 0) > 0

  const { selectedCycle, setSelectedCycleYear, shouldShowSelectCycleYearDropdown } = useSelectCycle(
    enabledCycles
  )

  const studentName = studentData?.student_name ?? ''
  const pageTitle = studentData ? `${studentName} - isaac` : 'Matrículas - isaac'
  useSetPageTitle(pageTitle)

  const matriculaContractsQuery = useGetMatriculaContracts(studentId, schoolId)
  const matriculaContracts = matriculaContractsQuery.data
  const allMatriculasContracts = matriculaContractsQuery.allContracts

  const [addTuitionDialogState, setAddTuitionDialogState] = useState<AddTuitionToCampaignState>({
    campaignID: '',
    enrollmentID: '',
    studentName: '',
    openDialog: false,
    referenceYear: 0,
  })

  const {
    isLoadingAddTuitionMutation,
    handleSubmitAddTuitionToProposal,
    form,
    handleCloseDialogForm,
  } = useAddTuitionToOneCampaignStudent({
    campaignId: addTuitionDialogState.campaignID ?? '',
    school,
    setAddTuitionDialogState,
  })

  const contractsByStudentsQuery = useContractsByStudents({
    schoolId,
    filter: {
      studentId: studentId,
    },
    pagination: {
      page: 1,
      per_page: 100,
    },
  })
  const contractsByStudents = contractsByStudentsQuery.data?.data ?? []

  const refreshSituation = useRefreshSituation(() => contractsByStudentsQuery.refetch())
  const handleRefresh = () =>
    refreshSituation(
      schoolId,
      studentData?.student_id ?? '',
      selectedCycle?.reference_year ?? 0,
      selectedCycle?.student_status ?? ReenrollmentStatus.ENROLLED,
      studentName ?? ''
    )

  const contractsByStudentsWithCampaignInfo = useMemo(() => {
    return contractsByStudents.map(contract => {
      const matchingMatricula = allMatriculasContracts.find(
        matricula => matricula.id === contract.id
      )

      if (matchingMatricula) {
        return {
          ...contract,
          can_add_tuition: matchingMatricula.can_add_tuition,
          campaign_id: matchingMatricula.campaign_id,
        }
      }

      return contract
    })
  }, [contractsByStudents, allMatriculasContracts])

  const contractsFiltered = [...contractsByStudentsWithCampaignInfo, ...matriculaContracts]
    .filter(
      i => i.product?.name.toLowerCase().includes(productName.toLowerCase()) || productName === ''
    )
    .filter(i => i.reference_year === referenceYear || referenceYear === NO_YEAR_SELECTED_OPTION)

  const availableYears = Array.from(new Set(contractsFiltered.map(c => c.reference_year))).sort()

  const isLoadingContractsData =
    contractsByStudentsQuery.isLoading || matriculaContractsQuery.isLoading

  const handleSendEvent = () => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.ENROLLMENT_STUDENT,
        name: EventDispatcherEvents.LINK_CLICKED,
        pageName: EventDispatcherEventScopes.ENROLLMENT_STUDENT,
        identifierName: 'saiba_mais_nova_pagina_de_aluno',
      })
    }
  }

  if (
    studentEnrollmentCycleQuery.isError ||
    contractsByStudentsQuery.isError ||
    matriculaContractsQuery.isError
  ) {
    return <ErrorDialog isError />
  }

  if (
    studentEnrollmentCycleQuery.isLoading ||
    contractsByStudentsQuery.isLoading ||
    matriculaContractsQuery.isLoading
  ) {
    return <LoadingProgress />
  }

  return (
    <BreadcrumbsProvider>
      <PageWrapper>
        <Styled.PageContainer>
          {studentEditing ? (
            <>
              <GoBackButton />
              <Styled.Header style={{ display: 'flex', marginBottom: 64 }}>
                <Callout
                  className="new-student-page"
                  text="Estamos preparando uma nova página do aluno."
                  linkLabel="Veja as mudanças"
                  linkTarget="_blank"
                  onLinkClick={handleSendEvent}
                  href="https://centraldeajuda.olaisaac.io/respons%C3%A1veis-financeiros/nova-pagina-do-aluno"
                />
                <Styled.InfoContainer>
                  <StudentInfo
                    birthDate={contractsByStudents[0]?.student?.birth_date ?? ''}
                    studentName={studentName ?? ''}
                    taxId={contractsByStudents[0].student?.tax_id ?? ''}
                  />
                  <div style={{ display: 'flex', gap: 8 }}>
                    {(!isIntegratedSchool || isAdmin) && (
                      <>
                        <Button
                          size={2}
                          variant="outline"
                          color="accent"
                          onClick={() => setOpenDialog(true)}
                        >
                          Editar cadastro
                        </Button>

                        <EditStudent
                          birthDateParam={contractsByStudents[0]?.student?.birth_date ?? ''}
                          studentName={studentName ?? ''}
                          taxID={contractsByStudents[0].student?.tax_id ?? ''}
                          studentID={studentId}
                          open={openDialog}
                          onClose={() => {
                            setOpenDialog(false)
                          }}
                        />
                      </>
                    )}
                    {selectedCycle && (
                      <EnrollButton
                        enrollmentYear={selectedCycle.reference_year}
                        isReEnrollable={selectedCycle.is_reenrollable}
                        enrollmentStatus={selectedCycle.student_status}
                        startedEnrollment={selectedCycle.started_enrollment}
                        studentId={studentId}
                        studentName={studentName ?? ''}
                      />
                    )}
                  </div>
                </Styled.InfoContainer>
                <div style={{ marginBottom: 32, marginTop: 32 }}>
                  <Separator color="neutral-2" />
                </div>
                <GuardianInfo
                  mainGuardian={studentData?.main_guardian ?? emptyGuardian}
                  otherGuardians={studentData?.other_guardians ?? [emptyGuardian]}
                  studentName={studentName ?? ''}
                />
              </Styled.Header>
            </>
          ) : (
            // to do: remove after rollout
            <>
              <Styled.PageContainer>
                <PageBreadcrumbs studentName={studentName} />
                <Callout
                  className="new-student-page"
                  text="Estamos preparando uma nova página do aluno."
                  linkLabel="Veja as mudanças"
                  linkTarget="_blank"
                  onLinkClick={handleSendEvent}
                  href="https://centraldeajuda.olaisaac.io/respons%C3%A1veis-financeiros/nova-pagina-do-aluno"
                />
                <Styled.InfoContainerBeforeEFI248>
                  <StudentInfo studentName={studentName} />
                  <GuardianInfo
                    mainGuardian={studentData?.main_guardian ?? emptyGuardian}
                    otherGuardians={studentData?.other_guardians ?? [emptyGuardian]}
                    studentName={studentName}
                  />

                  {selectedCycle && (
                    <EnrollButton
                      enrollmentYear={selectedCycle.reference_year}
                      isReEnrollable={selectedCycle.is_reenrollable}
                      enrollmentStatus={selectedCycle.student_status}
                      startedEnrollment={selectedCycle.started_enrollment}
                      studentId={studentId}
                      studentName={studentName}
                    />
                  )}
                </Styled.InfoContainerBeforeEFI248>
              </Styled.PageContainer>
            </>
          )}

          <StatusBanner
            mainGuardianId={studentData?.main_guardian?.id ?? ''}
            studentId={studentId}
            data={selectedCycle ?? emptyEnabledEnrollmentCycle}
            hasEnrollmentCycleEnabled={hasEnrollmentCycleEnabled}
            onClickRefresh={handleRefresh}
            renderCycleSelector={
              shouldShowSelectCycleYearDropdown && (
                <Styled.SelectYear
                  IconComponent={props => <KeyboardArrowDown {...props} />}
                  value={selectedCycle?.reference_year ?? 0}
                  onChange={evt => setSelectedCycleYear(Number(evt.target.value))}
                >
                  {enabledCycles.map(cycle => (
                    <MenuItem key={cycle.reference_year} value={cycle.reference_year}>
                      {`Ano letivo ${cycle.reference_year}`}
                    </MenuItem>
                  ))}
                </Styled.SelectYear>
              )
            }
          />
          <Styled.TableFiltersContainer>
            <Styled.SearchContainer>
              <SearchWithDebounce
                value={productName}
                setValue={setProductName}
                placeholder="Pesquisar por produto"
              />
            </Styled.SearchContainer>
            <FormControl style={{ width: 265 }} variant="outlined">
              <InputLabel id="selectYearLabel">Período letivo</InputLabel>
              <Select
                labelId="selectYearLabel"
                id="selectYearLabel"
                value={referenceYear}
                onChange={e => {
                  const value = e.target.value as string
                  return setReferenceYear(value.toString())
                }}
                label={`Turmas/Cursos ${referenceYear}`}
              >
                {[...availableYears, NO_YEAR_SELECTED_OPTION].map(year => (
                  <MenuItem key={year} value={year}>
                    {year || 'Todos'}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Styled.TableFiltersContainer>
          <ContractsTable
            data={paginateInMemory(contractsFiltered, pagination)}
            isLoading={isLoadingContractsData}
            enrollmentStatus={selectedCycle?.student_status ?? ReenrollmentStatus.ENROLLED}
            setAddTuitionDialogState={setAddTuitionDialogState}
          />
          <Box my={2} px={2}>
            <Pagination
              currentPage={pagination.page}
              itensPerPage={pagination.itemsPerPage}
              itensPerPageOptions={[10, 15, 25, 50]}
              totalItens={isLoadingContractsData ? 0 : contractsFiltered.length}
              onPageChange={newPage => {
                updatePaginationValue('page', newPage)
                isInitialized &&
                  eventDispatcherClient.sendEvent({
                    scope: EventDispatcherEventScopes.ENROLLMENT_STUDENT,
                    name: EnrollmentEventDispatcherEvents.CLICKED,
                    action: 'click',
                    customProperties: {
                      $Button_name: 'NEXT_PAGE',
                      $table_name: 'LISTA_DE_PRODUTOS',
                    },
                  })
              }}
              onItensPerChangeChange={newItensPerPage => {
                updatePaginationValue('itemsPerPage', newItensPerPage)
              }}
            />
          </Box>
        </Styled.PageContainer>
        {addTuitionDialogState.enrollmentID && (
          <AddTuitionDialog
            open={addTuitionDialogState.openDialog}
            enrollmentID={addTuitionDialogState.enrollmentID}
            onConfirm={handleSubmitAddTuitionToProposal}
            onClose={handleCloseDialogForm}
            form={form}
            isLoading={isLoadingAddTuitionMutation}
            referenceYear={addTuitionDialogState.referenceYear ?? 0}
            studentName={studentName}
          />
        )}
      </PageWrapper>
    </BreadcrumbsProvider>
  )
}
