import { BtnSimulation, Caption, FormStyled } from './styles'
import { formatCurrencyValue } from '@/shared/utils'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useHistory } from 'react-router-dom'
import { numberFormat, TextField } from '@gravity/text-field'
import { ChangeEvent, FocusEventHandler, useEffect, useState } from 'react'
import { useCreateAnticipationSimulation } from '../../../hooks/queries/useCreateAnticipationSimulation'
import {
  AnticipationSimulationResponse,
  CreateAnticipationSimulationRequest,
} from '@/shared/services/credit/types'
import { LoadingSimulation, LoadingSimulationForm } from './LoadingSimulation'
import {
  numberWithoutCents,
  numberWithoutCentsAndRoundUp,
} from '@monorepo/credit/utils/formatNumber'
import ReasonDropdown from '@monorepo/credit/components/ReasonDropdown'
import DefaultARTemplate from '@monorepo/credit/templates/DefaultARTemplate'
import {
  useContextEligibility,
  useContextRequestSimulation,
  useContextResponseSimulation,
  useContextSimulation,
} from '@monorepo/credit/contexts/PayoutAnticipationContext'
import { useGetPayoutAnticipationReasons } from '@monorepo/credit/hooks/queries/useGetPayoutAnticipationReasons'
import useMixpanelEventSender from '@monorepo/credit/utils/useMixpanelEventSender'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { MixpanelEventActions } from '@monorepo/credit/constants/MixpanelEventActions.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { CreditEventComponentNames } from '@monorepo/credit/constants/CreditEventComponentNames.enum'
import { dateToFormattedString } from '@monorepo/credit/utils/formatDate'
import { useAnticipationEligibility } from '@monorepo/credit/hooks/queries/useAnticipationEligibility'

export const PayoutAnticipationSimulation = () => {
  const { push } = useHistory()
  const { setEligibility } = useContextEligibility()
  const { school, schoolSlug } = useSelectedSchool()
  const schoolId = school?.id ?? ''

  const { data: dataEligibility, isLoading: isLoadingEligibility } = useAnticipationEligibility(
    schoolId
  )
  useEffect(() => {
    if (!isLoadingEligibility) {
      if (dataEligibility) {
        setEligibility(dataEligibility)
      }
    }
  }, [dataEligibility])

  const { note, setNote, reason, setReason, requestValue, setRequestValue } = useContextSimulation()
  const { mutateAsync: mutateSimulation } = useCreateAnticipationSimulation()
  const { setRequestSimulation } = useContextRequestSimulation()
  const { setResponseSimulation } = useContextResponseSimulation()
  const { data: reasons, isLoading: IsLoadingReasons } = useGetPayoutAnticipationReasons()
  const { sendCreditEventToMixpanel } = useMixpanelEventSender()

  const DEFAULT_INPUT_VALUE = '0,00'

  const [loadingSummary, setLoadingSummary] = useState<boolean>(false)

  useEffect(() => {
    sendCreditEventToMixpanel(
      EventDispatcherEvents.PAGE_VIEWED,
      MixpanelEventActions.PAGE_VIEW,
      {
        $page_name: CreditEventComponentNames.SIMULATION_PAGE,
      },
      EventDispatcherEventScopes.PAYOUT_ANTICIPATION
    )
  }, [])

  const handleChangeSimulationValue = (value: string) => {
    setRequestValue(value)
  }

  const handleOnBlurSimulationValue: FocusEventHandler<HTMLInputElement> = event => {
    const splitValue = event.target.value.split(',')[0]
    event.target.value = splitValue ? `${splitValue},00` : DEFAULT_INPUT_VALUE
    setRequestValue(event.target.value)
  }

  const handleSimulateButtonClick = () => {
    setLoadingSummary(true)

    const requestValueNumber = numberWithoutCentsAndRoundUp(requestValue)

    const request: CreateAnticipationSimulationRequest = {
      note: note,
      reason: reason.label,
      requestedValue: requestValueNumber,
      schoolId: schoolId,
    }

    sendCreditEventToMixpanel(
      EventDispatcherEvents.BUTTON_CLICKED,
      MixpanelEventActions.CLICK,
      {
        $button_name: CreditEventComponentNames.SIMULATE_NOW,
        $selected: reason.label,
        $value: requestValue,
        $maximum: isValueGreaterThanPa ? 'more' : isEqualPa ? 'equal' : 'less',
      },
      EventDispatcherEventScopes.PAYOUT_ANTICIPATION
    )

    setRequestSimulation(request)

    mutateSimulation(request).then((response: AnticipationSimulationResponse) => {
      setResponseSimulation(response.data)
      setLoadingSummary(false)
      push(`/${schoolSlug}/antecipacao/repasse/resumo`)
    })
  }

  if (isLoadingEligibility || IsLoadingReasons) {
    return <LoadingSimulationForm />
  }

  const PA = dataEligibility?.eligibility?.pa?.AR ?? 0
  const gracePeriod = dataEligibility?.gracePeriod.AR?.[0] ?? null

  const paValueWithCurrencyFormat = formatCurrencyValue(PA)
  const isReasonOrNoteNotSet = reason === null || (reason?.requiredNote && note.trim().length === 0)
  const isValueGreaterThanPa = PA < numberWithoutCents(requestValue)
  const requestValueNumber = numberWithoutCentsAndRoundUp(requestValue)
  const isEqualPa = requestValueNumber / 100 === PA
  const isButtonDisabled =
    isReasonOrNoteNotSet || isValueGreaterThanPa || requestValue === DEFAULT_INPUT_VALUE

  const valueErrorMessage = isValueGreaterThanPa
    ? `Valor máximo para antecipar: ${paValueWithCurrencyFormat}`
    : undefined

  if (loadingSummary) {
    return <LoadingSimulation />
  }

  return (
    <DefaultARTemplate
      title="Antecipação de repasse"
      text="Tenha flexibilidade financeira para lidar com imprevistos ou despesas inesperadas."
      handleBackPreviousRoute={() => {
        history.back()
      }}
      child={
        <>
          <FormStyled>
            <ReasonDropdown
              title="Como a antecipação vai apoiar sua escola?"
              reasons={reasons}
              reason={reason}
              setReason={setReason}
              note={note}
              setNote={setNote}
            />

            <div>
              <TextField
                name="payoutAnticipationValue"
                defaultValue={requestValue}
                size={3}
                placeholder="0,00"
                inputPrefix="R$"
                label="Qual valor você deseja antecipar?"
                error={!!valueErrorMessage}
                errorMessage={valueErrorMessage}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  handleChangeSimulationValue(event.target.value)
                  numberFormat(event)
                }}
                onBlur={handleOnBlurSimulationValue}
              />

              {!valueErrorMessage && (
                // TODO: remove isPayoutDiscountDateEnabled after rollout
                <Caption>
                  {`Disponível para antecipar${
                    PA && gracePeriod ? ` de ${dateToFormattedString(gracePeriod, true)}` : ''
                  }: ${paValueWithCurrencyFormat}`}
                </Caption>
              )}
            </div>

            <BtnSimulation
              title="Simular"
              size={2}
              disabled={isButtonDisabled}
              onClick={handleSimulateButtonClick}
            >
              Simular
            </BtnSimulation>
          </FormStyled>
        </>
      }
    />
  )
}
