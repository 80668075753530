import { useGetMatriculasApiContracts } from '@monorepo/enrollment/hooks/queries/enrollment'
import { MatriculasApiContractStatus } from '@/shared/interfaces'

export const useGetMatriculaContracts = (studentId: string, schoolId: string) => {
  const contractsQuery = useGetMatriculasApiContracts(studentId, schoolId)
  const contracts = contractsQuery.data ?? []

  const visibleContracts = contracts.filter(contract => {
    return (
      contract.status === MatriculasApiContractStatus.PENDING_GUARDIAN_APPROVAL ||
      contract.status === MatriculasApiContractStatus.PENDING
    )
  })

  return {
    ...contractsQuery,
    data: visibleContracts,
    allContracts: contracts,
  }
}
