import { CardStyled } from '../../styles'
import { Button } from '@gravity/button'
import { ErrorStateWrapper, HeadingStyled, Text } from './styles'
import Icon from '@mdi/react'
import { mdiRefresh } from '@mdi/js'
type ErrorStateProps = {
  onRetry: () => void
  title: string
}

/**
 * Error state for dashboard card component
 *
 * @param props
 * @param props.title Title
 * @param props.onRetry Callback function for retry button
 */
export const ErrorState = ({ title, onRetry }: ErrorStateProps) => {
  return (
    <CardStyled data-testid="error-state">
      <ErrorStateWrapper>
        <HeadingStyled variant="heading-h4-medium" data-testid="title-element">
          {title}
        </HeadingStyled>

        <Text variant="body-2-regular">
          Ocorreu um erro inesperado ao carregar as informações do seu repasse. {'\n'}Tente
          novamente mais tarde.
        </Text>

        <Button size={2} iconStart={<Icon path={mdiRefresh} size={1} />} onClick={onRetry}>
          Recarregar
        </Button>
      </ErrorStateWrapper>
    </CardStyled>
  )
}
