import * as Styled from './styles'
import Icon from '@mdi/react'
import { mdiMagnify } from '@mdi/js'
import { Table } from '@gravity/table'
import { Tooltip } from '@gravity/tooltip'

import { ConfirmationDialog } from './components/ConfirmationDialog'
import { ColumnHeader } from './types'
import { PaginationType } from '@/shared/hooks/usePagination/types'
import { CircularLoadingPlaceholder } from '@/shared/components/CircularLoadingPlaceholder'
import { EmptyDeliveries } from './components/EmptyDeliveries'
import {
  CreateProductDeliveryRequest,
  ProductDelivery,
} from '@monorepo/account-reports/services/product_deliveries/types'
import { RenderDeliveryItem } from './components/RenderDeliveryItem'

type ConfirmationDialogState = {
  contract_id: string
  max_deliveries: number
  stage: number
  visible: boolean
}

type Props = {
  columnHeaders: ColumnHeader[]
  deliveryColumnHeaders: ColumnHeader[]
  error: boolean
  handleConfirmationDialogClick: (input: CreateProductDeliveryRequest) => void
  loading: boolean
  nameFilter: string
  onChangeNameFilter: (value: string) => void
  pagination: PaginationType
  productDeliveries: ProductDelivery[]
  setShowConfirmationDialog: (value: ConfirmationDialogState) => void
  showConfirmationDialog: ConfirmationDialogState
  total: number
  updatePaginationValue: (key: keyof PaginationType, value: number, replaceRoute?: boolean) => void
}

export function View({
  deliveryColumnHeaders,
  columnHeaders,
  error,
  onChangeNameFilter,
  showConfirmationDialog,
  setShowConfirmationDialog,
  nameFilter,
  handleConfirmationDialogClick,
  productDeliveries,
  pagination,
  updatePaginationValue,
  total,
  loading,
}: Props) {
  if (error) {
    return (
      <Styled.Wrapper>
        <Styled.Text variant="body-1-medium">
          Ocorreu um erro ao buscar as entregas de material didático.
        </Styled.Text>
        <Styled.Text>
          Por favor atualize a página e tente novamente. Se o problema persistir, contate o suporte.
        </Styled.Text>
      </Styled.Wrapper>
    )
  }

  return (
    <Styled.Wrapper>
      <Styled.NameFilterContainer>
        <Styled.NameFilter
          placeholder="Pesquisar aluno ou responsável..."
          name="filter"
          value={nameFilter}
          onChange={e => onChangeNameFilter(e.target.value)}
          size={3}
          iconStart={<Icon path={mdiMagnify} size={2} />}
          disabled={loading}
        />
      </Styled.NameFilterContainer>
      <ConfirmationDialog
        visible={showConfirmationDialog.visible}
        closeDialog={() =>
          setShowConfirmationDialog({
            visible: false,
            stage: 0,
            contract_id: '',
            max_deliveries: 0,
          })
        }
        handleClick={handleConfirmationDialogClick}
        stage={showConfirmationDialog.stage}
        contract_id={showConfirmationDialog.contract_id}
        max_deliveries={showConfirmationDialog.max_deliveries}
      />
      {loading ? (
        <CircularLoadingPlaceholder />
      ) : productDeliveries.length > 0 ? (
        <Table.Root>
          <Table.Head>
            {columnHeaders.map(({ label, name, sortable }) => (
              <Table.HeaderCell key={name} name={name} sortable={sortable}>
                {label}
              </Table.HeaderCell>
            ))}
          </Table.Head>

          <Table.Body>
            {productDeliveries.map(delivery => (
              <Table.Row key={`${delivery.student_id}-${delivery.contract_id}`}>
                <Table.TextCell>{delivery.student_name}</Table.TextCell>

                <Tooltip text={delivery.product_name}>
                  <Table.TextCell>{delivery.product_name}</Table.TextCell>
                </Tooltip>

                <Table.TextCell subtext={`CPF: ${delivery.guardian_tax_id}`}>
                  {delivery.guardian_name}
                </Table.TextCell>

                {deliveryColumnHeaders.map((_, i) => {
                  const deliveryItem = delivery.deliveries.find(x => x.delivery_stage === i + 1)

                  return deliveryItem ? (
                    <RenderDeliveryItem
                      key={`${delivery.student_id}-${delivery.contract_id}-stage-${i + 1}`}
                      deliveryItem={deliveryItem}
                    />
                  ) : (
                    <Table.TextCell
                      key={`${delivery.student_id}-${delivery.contract_id}-stage-${i + 1}`}
                      enableCheckbox
                      data-testid={`${delivery.student_id}-${delivery.contract_id}-stage-${i + 1}`}
                      onChange={() =>
                        setShowConfirmationDialog({
                          visible: true,
                          stage: i + 1,
                          contract_id: delivery.contract_id,
                          max_deliveries: delivery.max_deliveries,
                        })
                      }
                    >
                      {' '}
                    </Table.TextCell>
                  )
                })}
              </Table.Row>
            ))}
          </Table.Body>
          {!!pagination && (
            <Table.Pagination
              total={total}
              itemsPerPage={pagination.itemsPerPage}
              onChangePage={value => {
                updatePaginationValue('page', value, true)
              }}
              onChangeItemsPerPage={value => {
                updatePaginationValue('itemsPerPage', value, true)
              }}
              page={pagination.page}
            />
          )}
        </Table.Root>
      ) : (
        <EmptyDeliveries />
      )}
    </Styled.Wrapper>
  )
}
