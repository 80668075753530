import { useHistory, useParams } from 'react-router-dom'

import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { useApi } from '@/utils/hooks/useApi'
import { useJWT } from '@/shared/hooks/useJWT'
import { Contract, ErrorDialog } from '@/modules/guardians/GuardianDetails/types'
import {
  Contract as ContractData,
  ContractStatus,
  DebtStatus,
  PreContractStatuses,
  ReceivableStatuses,
} from '@/shared/interfaces'

import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { GuardiansInstallmentsQueryParamsNameEnum } from '@/modules/guardians/GuardianDetailsInstallmentsTab/constants'
import { downloadFile } from '@/shared/utils'
import { useIsaacPayOutsourcedPaymentProvider } from '@/shared/hooks/useIsaacPayOutsourcedPaymentProvider'
import {
  getAgglutinatedInstallment,
  getDueTodayInstallment,
  getOpenInstallments,
  getOverdueInstallment,
  getRenegotiatedInstallment,
} from '@/escolas/components/contract/utils'
import { ENROLLMENT_PAGE_BASE_PATH } from '@monorepo/enrollment/constants/enrollment'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'
import { HotjarEvents, useHotjar } from '@/shared/hooks/useHotjar'
import { processInstallments } from '@/escolas/components/contract/ContractDetails'

import { canCancelContract } from '../helpers/contractHelper'

type UseGuardianDetailsContractsTableActionsProps = {
  contract: Contract
  contractData?: ContractData
  setErrorDialog: (errorDialog: ErrorDialog) => void
}

export const useGuardianDetailsContractsTableActions = ({
  contract,
  contractData,
  setErrorDialog,
}: UseGuardianDetailsContractsTableActionsProps) => {
  const history = useHistory()

  const { isAdmin, getUserRolesBySchool, isBackofficeUser } = useJWT()
  const { api } = useApi()
  const { guardianId } = useParams<{ guardianId: string }>()

  const { schoolSlug, school } = useSelectedSchool()
  const isOutsourcedProvider = useIsaacPayOutsourcedPaymentProvider(school)

  const { sendHotjarEvent } = useHotjar()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const isPreContract = contract?.pre_contract_status !== PreContractStatuses.EFFECTED_PCS

  const isDebtPending = contract?.debt_status === DebtStatus.PENDING

  const isEnableDownloadCarneOption = !isDebtPending && !isOutsourcedProvider

  const isRevokedCancelContract = useUnleashFlag(UnleashFlags.B2BCOR_193_BLOCK_CONTRACT_REVOKE)
  const isIntegratedSchool = useUnleashFlag(UnleashFlags.IS_INTEGRATED_SCHOOL)

  const processedInstallments = contractData ? processInstallments(contractData) : []

  const openInstallments = getOpenInstallments(processedInstallments)

  const hasRenegotiatedInstallment = Boolean(getRenegotiatedInstallment(processedInstallments))
  const hasAgglutinatedInstallment = Boolean(getAgglutinatedInstallment(processedInstallments))
  const hasOverdueInstallment = Boolean(getOverdueInstallment(processedInstallments))
  const hasDueTodayInstallment = Boolean(getDueTodayInstallment(processedInstallments))

  const hasDebt = hasRenegotiatedInstallment || hasAgglutinatedInstallment || hasOverdueInstallment
  const hasOpenInstallments = openInstallments?.length > 0

  const hasPaidReceivables = contractData?.installments?.some(({ receivables }) =>
    receivables?.some(({ status }) => status === ReceivableStatuses.PAID)
  )

  const considerUserRole = useUnleashFlag(UnleashFlags.PE_233_ENABLE_BASIC_OPERATION_ACCESS_LEVEL)
  const hasCancelContractRole = getUserRolesBySchool(school?.id ?? '').has('cancelar_contrato')

  const hasEditContractPermission =
    isBackofficeUser || getUserRolesBySchool(school?.id ?? '').has('editar_contrato')

  const isEnableEditContractOption =
    !isDebtPending && (!considerUserRole || hasEditContractPermission)

  /**
   * Indica se o a ação de cancelamento de contrato deve estar disponível para
   * usuários que não são backoffice. Caso a feature-flag (PE_233_ENABLE_BASIC_OPERATION_ACCESS_LEVEL)
   * esteja ativa, deve validar a presença da role 'cancelar_contrato'
   */
  const isCancelContractEnabledForNonBackofficeUsers = considerUserRole
    ? hasCancelContractRole && !isRevokedCancelContract
    : !isAdmin && !isRevokedCancelContract

  const isCanceledContract = contract?.status === ContractStatus.CANCELED
  const isCanceledPreContract = isPreContract && isCanceledContract

  const isEnableCancelContractOption = canCancelContract(
    contract,
    hasOpenInstallments,
    isCancelContractEnabledForNonBackofficeUsers,
    isAdmin
  )

  const isEnableGeneratePaymentStatementOption = hasPaidReceivables || isCanceledPreContract

  const isEnableChangeContractOwnershipOption =
    hasOpenInstallments &&
    !hasDueTodayInstallment &&
    !hasDebt &&
    !isPreContract &&
    !isIntegratedSchool

  const sendEvent = (
    name: EventDispatcherEvents,
    scope: EventDispatcherEventScopes,
    entity?: EventDispatcherEntities,
    options?: Record<string, unknown>
  ) => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope,
        name,
        entity,
        action: 'click',
        customProperties: {
          $component: 'row',
          $contract_id: contract?.id,
          ...options,
        },
      })
  }

  const handleClickGoToContractEdit = () => {
    sendEvent(
      EventDispatcherEvents.BUTTON_CLICKED,
      EventDispatcherEventScopes.CONTRACT_DRAWER,
      undefined,
      {
        $button_name: 'Editar contrato',
        $contract_type: isPreContract ? 'PRE_CONTRACT' : 'EFFECTED_CONTRACT',
      }
    )

    const path = `/contratos/${contract?.id}/editar`

    history.push(`/${schoolSlug}${path}`)
  }

  const handleClickGoToInvoicePage = () => {
    sendEvent(
      EventDispatcherEvents.GUARDIAN_CONTRACT_INSTALLMENTS_LIST,
      EventDispatcherEventScopes.GUARDIANS,
      undefined,
      {
        $button_name: 'Ver faturas',
      }
    )

    const params = new URLSearchParams()
    params.append(GuardiansInstallmentsQueryParamsNameEnum.YEARS, contract.reference_year)
    params.append(GuardiansInstallmentsQueryParamsNameEnum.PRODUCT_IDS, contract?.product.id)
    params.append(GuardiansInstallmentsQueryParamsNameEnum.STUDENT_IDS, contract?.student.id)
    params.append(GuardiansInstallmentsQueryParamsNameEnum.CONTRACT_STATUS, contract?.status)
    params.append(GuardiansInstallmentsQueryParamsNameEnum.CONTRACT_ID, contract.id)

    history.push(`/${schoolSlug}/responsaveis/${guardianId}/faturas?${params.toString()}`)
  }

  const handleClickGoToDownloadCarne = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    const carneFileDownload = async () => {
      await api.contracts.downloadCarne(contract.id).then(async data => {
        if (data.status > 200) {
          const error = await data.data.text()
          setErrorDialog({ visible: true, message: error })
          return
        }
        downloadFile(`carne-${contract.id}`, 'pdf', async () => await data.data)
      })
    }

    try {
      await carneFileDownload()
    } catch (error) {
      setErrorDialog({
        visible: true,
        message: 'Ocorreu um erro ao tentar baixar o carnê. Tente novamente.',
      })
    }
  }

  const handleClickGoToEnrollmentPage = () => {
    sendEvent(
      EventDispatcherEvents.VIEW_STUDENT_PAGE,
      EventDispatcherEventScopes.GUARDIANS,
      undefined,
      {
        $button_name: 'Ver situação de matrícula',
      }
    )

    history.push(`/${schoolSlug}/${ENROLLMENT_PAGE_BASE_PATH}/${contract.student.id}`)
  }

  const sendGenerateStatementEvents = () => {
    sendHotjarEvent(HotjarEvents.GENERATE_STATEMENT_CHECKOUT)
    sendEvent(
      EventDispatcherEvents.BUTTON_CLICKED,
      EventDispatcherEventScopes.CONTRACT_PAGE,
      EventDispatcherEntities.PRINT_DEMONSTRATIVE_PAID_VALUES,
      {
        $button_name: 'Gerar demonstrativo de pagamento',
      }
    )
  }

  const sendChangeContractOwnershipEvents = () => {
    sendEvent(
      EventDispatcherEvents.BUTTON_CLICKED,
      EventDispatcherEventScopes.CONTRACT_PAGE,
      undefined,
      {
        $button_name: 'Alterar titularidade do contrato',
      }
    )
  }

  const sendCancelContractEvent = () => {
    sendEvent(
      EventDispatcherEvents.BUTTON_CLICKED,
      EventDispatcherEventScopes.CONTRACT_PAGE,
      undefined,
      {
        $button_name: 'Cancelar contrato',
        $contract_type: isPreContract ? 'PRE_CONTRACT' : 'EFFECTED_CONTRACT',
      }
    )
  }

  return {
    showEditContractOption: isEnableEditContractOption,
    showDownloadCarneOption: isEnableDownloadCarneOption,
    showChangeContractOwnershipOption: isEnableChangeContractOwnershipOption,
    showCancelContractOption: isEnableCancelContractOption,
    showGeneratePaymentStatement: isEnableGeneratePaymentStatementOption,
    handleClickGoToContractEdit,
    handleClickGoToInvoicePage,
    handleClickGoToDownloadCarne,
    handleClickGoToEnrollmentPage,
    sendGenerateStatementEvents,
    sendChangeContractOwnershipEvents,
    sendCancelContractEvent,
  }
}
