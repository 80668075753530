import { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'

import { ENROLLMENT_CYCLE_QUERY_KEY } from '@monorepo/enrollment/constants/enrollment'
import { useSchoolEnrollmentCycleContext } from '@monorepo/enrollment/contexts/SchoolEnrollmentContext'
import { useSnackbar } from '@/shared/hooks'

import { useRefreshStudentsSituation } from '../../../hooks/useRefreshStudentsSituation'

export const useRefreshStudentSituation = (schoolId: string, studentIds: string[]) => {
  const { setSnackBar } = useSnackbar()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { refreshStudentsSituation } = useRefreshStudentsSituation({
    schoolId: schoolId,
    studentIds: studentIds,
  })

  const queryClient = useQueryClient()

  const { schoolEnrollmentCycles, isFetchingCycles } = useSchoolEnrollmentCycleContext()
  const activeCycles = schoolEnrollmentCycles
    .filter(cycle => cycle.enabled)
    .map(cycle => cycle.reference_year)

  const invalidateQueries = () => {
    queryClient.invalidateQueries(['guardian-details-installments'])
    queryClient.invalidateQueries([ENROLLMENT_CYCLE_QUERY_KEY])
    queryClient.invalidateQueries(['guardian-details-filters'])
    queryClient.invalidateQueries(['guardian-details'])
    queryClient.invalidateQueries(['guardian-details-contracts'])
    queryClient.invalidateQueries(['guardians-debt-status'])
  }

  const handleRefreshStudentsSituation = async () => {
    try {
      setIsLoading(true)

      const requests = activeCycles.map(cycle => refreshStudentsSituation(cycle))
      const requestPromises = await Promise.allSettled(requests)

      const allFailed = requestPromises.every(request => request.status === 'rejected')
      if (allFailed) throw new Error('All requests to update student situation failed.')

      invalidateQueries()
    } catch (error) {
      setSnackBar('Não foi possível atualizar a situação de parcelas aguardando', 'error')
    } finally {
      setIsLoading(false)
    }
  }

  return {
    handleRefreshStudentsSituation,
    isLoading: isLoading || isFetchingCycles,
  }
}
