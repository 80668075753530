import React, { useEffect, useState } from 'react'
import { Dialog } from '@gravity/dialog'
import { Heading } from '@gravity/heading'
import { Button } from '@gravity/button'
import { CloseRounded } from '@material-ui/icons'

import { CheckoutStatus, CheckoutProvider } from '@/shared/interfaces'
import paymentFail from 'src/escolas/assets/paymentFail.svg'
import paymentSuccess from 'src/escolas/assets/paymentSuccess.svg'
import pos from 'src/escolas/assets/pos.svg'
import posFail from 'src/escolas/assets/POSFail.svg'

import { ConfirmationDialogProps } from '@/shared/components/ConfirmationDialog'
import * as Styled from './styles'
import { NotificationCheckoutDialog } from './NotificationCheckoutDialog'
import { Text } from '@gravity/text'
import { CircularLoading } from '@/shared/components/CircularLoading'

export const StatusFeedbackCheckout = {
  on_hold: {
    content: 'Clique na tela da maquininha e siga as instruções indicadas',
    subtitle: '',
    image: pos,
    labelButton: 'Cancelar pagamento',
    showClose: true,
  },
  processing: {
    content: 'Transação selecionada e em andamento',
    subtitle: '',
    image: '',
    labelButton: 'Cancelar pagamento',
    showClose: true,
  },
  finished: {
    content: 'Pagamento realizado com sucesso',
    subtitle: '',
    image: paymentSuccess,
    labelButton: 'Finalizar',
    showClose: true,
  },
  aborted: {
    content: 'Pagamento cancelado',
    subtitle:
      'Agora, aperte o botão vermelho na maquininha para cancelar a transação por lá também.',
    image: paymentFail,
    labelButton: 'Fechar',
    showClose: true,
  },
  FAILURE_TO_CONNECT: {
    content: 'Falha ao conectar com a maquininha',
    subtitle: '',
    image: posFail,
    labelButton: 'Tentar novamente',
    showClose: true,
  },
} as const

export const TextAndImageContentCheckout = ({
  imageSrc,
  message,
  optionMessages,
  status,
  subtitle,
}: {
  imageSrc: string
  message: string
  optionMessages?: Array<React.ReactNode>
  status: CheckoutStatus
  subtitle?: string
}) => (
  <>
    <Styled.IconDiv>
      {status === 'processing' ? (
        <CircularLoading iconSize={120} />
      ) : (
        <Styled.Image
          withButton={StatusFeedbackCheckout[status].showClose}
          src={imageSrc}
          height="120px"
        />
      )}
    </Styled.IconDiv>
    <Styled.HeadingDiv>
      <Heading style={{ padding: '16px 0' }} variant="heading-h3-medium">
        {message}
      </Heading>
    </Styled.HeadingDiv>
    {subtitle && (
      <Styled.SubTitleDiv>
        <Styled.SubtitleText>{subtitle}</Styled.SubtitleText>
      </Styled.SubTitleDiv>
    )}
    <Styled.MessagesDiv>
      {optionMessages
        ? optionMessages.map((message, index) => (
            <Text variant="body-1-regular" key={`option-message-${index}`}>
              {message}
            </Text>
          ))
        : null}
    </Styled.MessagesDiv>
  </>
)

export const FeedbackCheckout = ({
  listMessage,
  status,
  provider,
}: {
  listMessage: Array<React.ReactNode>
  provider?: CheckoutProvider
  status: CheckoutStatus
}) => {
  const valueStatus = StatusFeedbackCheckout[status]
  const subtitle =
    provider === CheckoutProvider.STONE_PAGARME
      ? StatusFeedbackCheckout[status].subtitle
      : undefined
  return (
    <TextAndImageContentCheckout
      message={valueStatus.content}
      imageSrc={valueStatus.image}
      optionMessages={listMessage}
      status={status}
      subtitle={subtitle}
    />
  )
}

type CheckoutFeedbackDialogProps = ConfirmationDialogProps & {
  listMessage: Array<React.ReactNode>
  onCancelCheckout?: () => void
  posProvider?: CheckoutProvider
  status: CheckoutStatus
}

type ConfirmationDialogCheckoutProps = ConfirmationDialogProps & {
  centered?: boolean
  onCancelCheckout?: () => void
  posProvider?: CheckoutProvider
  showCloseButton?: boolean
  status: CheckoutStatus
}

const ConfirmationDialogCheckout = ({
  buttonLabel = 'Ok, entendi!',
  children,
  isVisible,
  onClose,
  submitHandler,
  isConfirmDisabled,
  showCloseButton,
  shortIds,
  posProvider,
  status,
  onCancelCheckout,
}: ConfirmationDialogCheckoutProps) => {
  const isPagarme = posProvider === CheckoutProvider.STONE_PAGARME
  const isCancelNotPagarme = buttonLabel !== 'Cancelar pagamento' && !isPagarme

  const [isCanceling, setIsCanceling] = useState(false)

  useEffect(() => {
    if (status === CheckoutStatus.ABORTED) {
      setIsCanceling(false)
    }
  }, [status])

  return (
    <Dialog
      open={isVisible}
      backdrop
      title=""
      size={3}
      onOpenChange={isOpen => {
        if (isOpen) return

        if (isPagarme && status !== CheckoutStatus.ABORTED) {
          setIsCanceling(true)
          onCancelCheckout && onCancelCheckout()
        } else {
          submitHandler()
        }
      }}
      cancelButton={
        <>
          {isPagarme && status !== CheckoutStatus.ABORTED && (
            <Button
              type="button"
              variant="ghost"
              onClick={() => {
                setIsCanceling(true)
                onCancelCheckout && onCancelCheckout()
              }}
              loading={isCanceling}
            >
              Cancelar transação
            </Button>
          )}
          {isCancelNotPagarme || status === CheckoutStatus.ABORTED ? (
            <Button
              type="button"
              variant="ghost"
              onClick={submitHandler}
              disabled={isConfirmDisabled?.()}
            >
              {buttonLabel}
            </Button>
          ) : null}
        </>
      }
      content={
        <>
          {onClose && showCloseButton && !isPagarme && (
            <Styled.BoxCloseButton display="flex" justifyContent="flex-end">
              <Button
                data-testid="close-btn"
                type="button"
                variant="ghost"
                onClick={onClose}
                aria-label="Fechar"
              >
                <CloseRounded />
              </Button>
            </Styled.BoxCloseButton>
          )}
          <Styled.ConfirmationDiv>
            {children}
            <NotificationCheckoutDialog shortIds={shortIds} />
          </Styled.ConfirmationDiv>
        </>
      }
    />
  )
}

export const CheckoutFeedbackDialog = ({
  status,
  listMessage,
  posProvider,
  ...rest
}: CheckoutFeedbackDialogProps) => {
  const statusCheckout = StatusFeedbackCheckout[status]

  return (
    <ConfirmationDialogCheckout
      buttonLabel={statusCheckout.labelButton}
      showCloseButton={statusCheckout.showClose}
      posProvider={posProvider}
      status={status}
      {...rest}
    >
      {rest.children ?? (
        <FeedbackCheckout listMessage={listMessage} status={status} provider={posProvider} />
      )}
    </ConfirmationDialogCheckout>
  )
}

export default CheckoutFeedbackDialog
