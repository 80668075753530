import { useEffect, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { debounce } from 'throttle-debounce'
import { Box } from '@material-ui/core'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import {
  ActionDrawerHeader,
  Button,
  ButtonDocker,
  Drawer,
  Search,
  Typography,
} from '@olaisaac/design-system'

import { CollapsibleFilterGroup } from '@/shared/components/CollapsibleFilterGroup'

import { useEnrollmentPayoutReportFilter } from '@monorepo/report/hooks/useEnrollmentPayoutReportFilter'

import { filterOptionsByText } from './utils/filterOptionsByText'

import { FILTERS } from './constants/FILTERS'
import { REPORT_PAGE_NAMES } from '@monorepo/report/constants/REPORT_PAGE_NAMES'

import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import type {
  Filter,
  FilterObjectKeys,
} from '@monorepo/report/hooks/useEnrollmentPayoutReportFilter/types'
import type { EnrollmentPayoutReportFilterDrawerProps, FilterOptions } from './types'

/**
 * Filter drawer for enrollment payout report page
 *
 * @param props
 * @param props.filterOptions Items to be displayed as filters options
 * @param props.isOpen Indicates if drawer should be opened
 * @param props.onClose Callback function called on request close
 */
export const EnrollmentPayoutReportFilterDrawer = ({
  filterOptions,
  isOpen,
  onClose,
}: EnrollmentPayoutReportFilterDrawerProps) => {
  const form = useForm<Filter>()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { filter, filterCount, updateFilter, clearFilter } = useEnrollmentPayoutReportFilter()

  const [searchOptionsField, setSearchOptionsField] = useState('')
  const [isLoadingFilters, setIsLoadingFilters] = useState(true)
  const [isFilterApplied, setIsFilterApplied] = useState(true)
  const [filteredFilterOptions, setFilteredFilterOptions] = useState<FilterOptions>({
    amountSigns: [],
    events: [],
    products: [],
    referenceYears: [],
  })

  useEffect(() => {
    Object.entries(filter).forEach(([key, value]) => {
      form.setValue(key as FilterObjectKeys, value)
      setIsFilterApplied(true)
    })
  }, [filter])

  useEffect(() => {
    const subscription = form.watch(() => setIsFilterApplied(false))

    return () => subscription.unsubscribe()
  }, [form.watch])

  useEffect(() => {
    setIsLoadingFilters(true)

    const filteredOptions = filterOptionsByText(searchOptionsField, {
      ...filterOptions,
    })

    setFilteredFilterOptions(filteredOptions)
    setIsLoadingFilters(false)
  }, [searchOptionsField, filterOptions])

  const sendEventDispatcherEvent = (action: 'apply' | 'reset', filter?: Filter) => {
    let filteredCategories: string[] = []

    if (filter) {
      filteredCategories = Object.entries(FILTERS).reduce<string[]>((acc, [key, value]) => {
        if (filter[key as keyof Filter].length > 0) {
          return [...acc, value]
        }

        return acc
      }, [])
    }

    const eventName =
      action === 'apply' ? EventDispatcherEvents.APPLY_FILTER : EventDispatcherEvents.RESET_FILTER

    isInitialized &&
      eventDispatcherClient.sendEvent({
        name: eventName,
        scope: EventDispatcherEventScopes.ENROLLMENT_REPORT,
        action: 'click',
        customProperties: {
          $page_name: REPORT_PAGE_NAMES.ENROLLMENT_PAYOUT_REPORT,
          ...(action === 'apply' && { $filtered_categories: filteredCategories }),
        },
      })
  }

  const handleApplyFilters: SubmitHandler<Filter> = data => {
    updateFilter(data)
    setIsFilterApplied(true)
    sendEventDispatcherEvent('apply', data)
    onClose()
  }

  const handleClearFilters = () => {
    form.reset({
      amountSigns: [],
      events: [],
      products: [],
      referenceYears: [],
    })
    clearFilter()
    setIsFilterApplied(true)
    sendEventDispatcherEvent('reset')
    onClose()
  }

  return (
    <FormProvider {...form}>
      <Drawer open={isOpen}>
        <ActionDrawerHeader
          title="Filtrar"
          subtitle="Apenas filtros disponíveis para esse repasse são visíveis."
          data-testid="filter-drawer"
          onClose={onClose}
        />

        <Search
          placeholder="Buscar filtros"
          onChange={debounce(500, (value: string) => setSearchOptionsField(value))}
          onClear={() => setSearchOptionsField('')}
        />

        {isLoadingFilters && (
          <Box
            display="flex"
            flex="1"
            justifyContent="center"
            alignItems="center"
            padding="1.5rem 1.5rem"
          >
            <Typography variation="bodySmall" color="secondary" withoutMargin>
              Carregando filtros...
            </Typography>
          </Box>
        )}

        {!isLoadingFilters && (
          <Box
            display="flex"
            flex="1"
            flexDirection="column"
            padding="1.5rem 1.5rem"
            overflow="auto"
          >
            {Object.entries(FILTERS).map(([key, label]) => {
              if (filteredFilterOptions[key as FilterObjectKeys].length === 0) {
                return null
              }

              return (
                <Box mb="3rem" key={key}>
                  <CollapsibleFilterGroup
                    label={label}
                    filterName={key}
                    options={filteredFilterOptions[key as FilterObjectKeys]}
                    totalOptions={filteredFilterOptions[key as FilterObjectKeys].length}
                    searchText={searchOptionsField}
                    onClearFilter={filterName => form.setValue(filterName as FilterObjectKeys, [])}
                  />
                </Box>
              )
            })}
          </Box>
        )}

        <ButtonDocker>
          <Button
            fullWidth
            disabled={isFilterApplied}
            onClick={form.handleSubmit(handleApplyFilters)}
          >
            Filtrar
          </Button>

          <Button
            fullWidth
            variation="ghost"
            disabled={filterCount === 0}
            onClick={handleClearFilters}
          >
            Limpar tudo
          </Button>
        </ButtonDocker>
      </Drawer>
    </FormProvider>
  )
}
