import { PaymentDisputeSummaryState } from '@/shared/models/enums/PaymentDisputeSummaryState.enum'

export const DEFAULT_PAGE = 1
export const DEFAULT_PER_PAGE = 10

export const DEFAULT_SORT_ORDER = 'ASC'
export const DEFAULT_SORT_BY = 'created_at'

export const DEFAULT_PER_PAGE_OPTIONS = [10, 20, 30, 40, 50]

export const DEFAULT_PAYMENT_DISPUTE_STATUS_FILTERS = [
  PaymentDisputeSummaryState.NOT_STARTED,
  PaymentDisputeSummaryState.STARTED,
]
