import { GridItem, Grid } from '@gravity/grid'
import styled from 'styled-components'

export const FooterSpaceClearance = styled.div`
  padding-bottom: 136px;
`

export const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.gravity.spacing['14']};
`

export const CenterGrid = styled(Grid)`
  margin: 0 auto;
`
export const CustomGridItem = styled(GridItem)`
  display: flex;
  flex-direction: column;
`

export const StepWrapper = styled.div`
  margin-top: ${({ theme }) => theme.gravity.spacing['10']};
`
