import dayjs from 'dayjs'
import { Skeleton } from '@gravity/skeleton'

import { ContractsToChange } from '@/modules/contract/services/change-ownership/types'
import { months } from '@/shared/utils'

import { useChangeOwnershipPreview } from '../../hooks/usePreviewChangeOwnership'
import { ContractCard } from '../ContractCard'
import type { SelectedContractCardsListProps } from './types'
import * as Styled from './styles'

export const SelectedContractCardsList = ({
  selectedContractIds,
}: SelectedContractCardsListProps) => {
  const changeOwnershipPreviewQuery = useChangeOwnershipPreview(
    {
      contract_ids: selectedContractIds,
    },
    { enabled: selectedContractIds.length > 0 }
  )
  const contractsToChange = changeOwnershipPreviewQuery.data?.contracts_to_change ?? []

  const getContractCardDesc = (contract: ContractsToChange) => {
    if (contract.charges.length === 0) return ''

    const dueDate = dayjs(contract.charges[0].DueDate)
    const dueMonthName = months[+dueDate.month]
    return `A troca será efetiva a partir da parcela de ${dueMonthName} de ${dueDate.format(
      'YYYY'
    )}}.`
  }

  return (
    <Styled.Wrapper justify="start">
      {changeOwnershipPreviewQuery.isLoading &&
        selectedContractIds.map(id => (
          <Styled.Item key={id} xl={6} lg={6} md={6} sm={4} xs={4}>
            <Skeleton fullWidth height={237} />
          </Styled.Item>
        ))}

      {changeOwnershipPreviewQuery.isSuccess &&
        contractsToChange.map(c => (
          <Styled.Item key={c.contract_id} xl={6} lg={6} md={6} sm={4} xs={4}>
            <ContractCard
              key={c.contract_id}
              title={c.charges.length > 0 ? c.charges[0].ProductName : ''}
              description={getContractCardDesc(c)}
            />
          </Styled.Item>
        ))}
    </Styled.Wrapper>
  )
}
