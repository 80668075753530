import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import { CalendarEvent } from '@gravity/icons'

import * as Styled from './styles'
import { ContractCardProps } from './types'

export const ContractCard = ({ title, description }: ContractCardProps) => {
  return (
    <Styled.Wrapper>
      <CalendarEvent aria-hidden="true" role="img" />
      <Heading variant="heading-h4-medium">{title}</Heading>
      <Text>{description}</Text>
    </Styled.Wrapper>
  )
}
