import styled from 'styled-components'
import { Typography } from '@olaisaac/design-system'

import { formatMicroCentsToReal } from '@/shared/utils'

const GreenTypography = styled(Typography).attrs({
  withoutMargin: true,
})`
  color: ${props => props.theme.primitiveTokens.colors.green[90]};
`

const RedTypography = styled(Typography).attrs({
  withoutMargin: true,
})`
  color: ${props => props.theme.primitiveTokens.colors.coral[90]};
`

export const MoneyTypography = ({ amount, ...props }) => {
  let MyTypography = styled(Typography).attrs({ withoutMargin: true })``

  if (amount > 0) {
    MyTypography = GreenTypography
  } else if (amount < 0) {
    MyTypography = RedTypography
  }

  return (
    <MyTypography {...props} style={{ whiteSpace: 'nowrap', ...props.style }}>
      {formatMicroCentsToReal(amount)}
    </MyTypography>
  )
}
