import { useState } from 'react'
import { ToggleGroupItemStyled, ToggleGroupSyled } from './styles'
import { ISAAC_PAY_SCHOOL_CHARGE_CONFIG_ITEMS } from '@monorepo/report/constants/schoolChargeConfigItems'

type IsaacPayToggleGroupProps = {
  defaultReportingPeriod: string
  onChangeReportingPeriod: (reportingPeriod: string) => void
  value: string
}

/**
 * Component for calendar toggle
 *
 * @param props
 * @param props.reportingPeriod School Payout reporting period
 * @param props.onChangeReportingPeriod Callback function called on change payout
 */
export const IsaacPayToggleGroup = ({
  defaultReportingPeriod,
  onChangeReportingPeriod,
  value,
}: IsaacPayToggleGroupProps) => {
  const [currentItem, setItem] = useState(value ?? defaultReportingPeriod)

  const handleChange = item => {
    if (item) {
      setItem(item)
      onChangeReportingPeriod && onChangeReportingPeriod(item)
    }
  }

  return (
    <ToggleGroupSyled
      type="single"
      value={currentItem}
      onValueChange={handleChange}
      defaultValue="center"
      aria-label="Text alignment"
    >
      <ToggleGroupItemStyled
        className="ToggleGroupItem"
        value={defaultReportingPeriod}
        aria-label="Left aligned"
      >
        <p
          style={{
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '24px',
            letterSpacing: '0.15000000596046448px',
            textAlign: 'center',
          }}
        >
          {ISAAC_PAY_SCHOOL_CHARGE_CONFIG_ITEMS[defaultReportingPeriod]}
        </p>
      </ToggleGroupItemStyled>
      <ToggleGroupItemStyled
        className="ToggleGroupItem"
        value={ISAAC_PAY_SCHOOL_CHARGE_CONFIG_ITEMS.monthly.key}
        aria-label="Right aligned"
      >
        <p
          style={{
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '24px',
            letterSpacing: '0.15000000596046448px',
            textAlign: 'center',
          }}
        >
          {ISAAC_PAY_SCHOOL_CHARGE_CONFIG_ITEMS.monthly.label}
        </p>
      </ToggleGroupItemStyled>
    </ToggleGroupSyled>
  )
}
