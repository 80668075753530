import { Card } from '@gravity/card'
import { colors, spacing, borderRadius } from '@gravity/tokens'
import styled from 'styled-components'

const BaseRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  background-color: ${colors['colors-background-neutral-2']};
  border-radius: ${borderRadius[6]};
  padding: ${spacing[14]};
  border-style: none;
`

export const RowContainer = styled(BaseRowContainer)`
  gap: ${spacing[3]};
`

export const RowContainerSpaceBetween = styled(BaseRowContainer)`
  justify-content: space-between;
  align-items: center;
`

export const TextGroup = styled.div`
  display: flex;
  flex-direction: column;
`
