import type { FetchPayoutDetailsResponseDTO } from '@/shared/services/report/types'
import type { FilterOptions } from '../../components/TuitionPayoutReportFilterDrawer/types'
import type { PayoutTransactionEvents } from '@monorepo/report/models/PayoutTransactionEvents'

/**
 * Utility function to parse filter options for tuition payout report page
 *
 * @param data Filter options
 *
 * @returns Parsed filter options
 */
export const buildTuitionPayoutReportFilterOptions = (
  data?: FetchPayoutDetailsResponseDTO<'tuition'>['data']['filters_included']
): FilterOptions => {
  if (data) {
    return {
      events: data.events as Array<{ label: string; value: PayoutTransactionEvents }>,
      amountSigns: data.amount_signs as Array<{ label: string; value: 'POSITIVE' | 'NEGATIVE' }>,
      products: data.products,
    }
  }

  return {
    events: [],
    amountSigns: [],
    products: [],
  }
}
