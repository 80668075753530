import { useState } from 'react'

import { useContractsByGuardian } from '@/shared/hooks/queries/contracts/useContractsByGuardian'
import { SimplifiedContract } from '@/shared/interfaces'

export const useContracts = (guardianId: uuid, schoolId: uuid) => {
  const [selectedContractIds, setSelectedContractIds] = useState(new Set<uuid>())

  const contractsQuery = useContractsByGuardian({
    guardianId,
    schoolId,
  })

  const eligibleContracts = contractsQuery.data?.filter(c => c.actions.can_change_ownership) ?? []

  const selectContract = (id: uuid) => setSelectedContractIds(prev => new Set(prev).add(id))

  const unselectContract = (id: uuid) =>
    setSelectedContractIds(prev => {
      const next = new Set(prev)
      next.delete(id)
      return next
    })

  const handleSelectContract = (contract: SimplifiedContract) => {
    const isSelected = selectedContractIds.has(contract.id)
    if (isSelected) {
      unselectContract(contract.id)
      return
    }

    selectContract(contract.id)
  }

  const isContractSelected = (id: uuid) => selectedContractIds.has(id)

  return {
    contractsQuery,
    eligibleContracts,
    handleSelectContract,
    isContractSelected,
  }
}
