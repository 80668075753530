import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { ENROLLMENT_PAGE_BASE_PATH } from '@monorepo/enrollment/constants/enrollment'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { ReenrollmentStatus } from '@/shared/interfaces'

import { Badge } from '@gravity/badge'
import { Skeleton } from '@gravity/skeleton'

import { mdiAccountSchool, mdiOpenInNew } from '@mdi/js'
import Icon from '@mdi/react'

import { StudentCardProps } from './types'
import * as Styled from './styles'

export const StudentCardSkeleton = () => {
  return (
    <Styled.CardContainer>
      <Styled.CardAvatar>
        <Styled.IconButton size={1} color="accent" variant="ghost" cursor="default">
          <Icon path={mdiAccountSchool} size={1} />
        </Styled.IconButton>
      </Styled.CardAvatar>
      <Styled.CardContent>
        <Skeleton radius={2} width={248} />
        <Skeleton radius={2} width={248} />
      </Styled.CardContent>
    </Styled.CardContainer>
  )
}

export const StudentCard = ({ student }: StudentCardProps) => {
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { schoolSlug } = useSelectedSchool()
  const history = useHistory()

  const [cardOnHover, setCardOnHover] = useState(false)

  const sendEvent = () => {
    eventDispatcherClient.sendEvent({
      scope: EventDispatcherEventScopes.GUARDIANS,
      name: EventDispatcherEvents.CARD_VIEW_STUDENT_PAGE,
      action: 'click',
    })
  }

  const redirectToStudentPage = () => {
    isInitialized && sendEvent()
    history.push(`/${schoolSlug}/${ENROLLMENT_PAGE_BASE_PATH}/${student.student_id}`)
  }

  const getStudentStatusDict = {
    [ReenrollmentStatus.NOT_REENROLLABLE]: { color: 'error' as const, text: 'Não rematriculável' },
    [ReenrollmentStatus.REENROLLMENT_AVAILABLE]: {
      color: 'accent' as const,
      text: 'Rematrícula disponível',
    },
    [ReenrollmentStatus.ENROLLMENT_IN_PROGRESS]: {
      color: 'warning' as const,
      text: 'Matrícula em andamento',
    },
    [ReenrollmentStatus.ENROLLMENT_AT_RISK]: {
      color: 'error' as const,
      text: 'Matrícula em risco',
    },
    [ReenrollmentStatus.ENROLLED]: { color: 'success' as const, text: 'Matriculado' },
    [ReenrollmentStatus.GATE_ERROR]: { color: 'warning' as const, text: 'Indisponível no momento' },
    [ReenrollmentStatus.UNAVAILABLE_AT_MOMENT]: {
      color: 'warning' as const,
      text: 'Indisponível no momento',
    },
  }

  return (
    <Styled.CardContainer
      onClick={redirectToStudentPage}
      onMouseEnter={() => setCardOnHover(true)}
      onMouseLeave={() => setCardOnHover(false)}
    >
      <Styled.CardAvatar>
        <Styled.IconButton size={1} color="accent" variant="ghost" cursor="default">
          <Icon path={mdiAccountSchool} size={1} />
        </Styled.IconButton>
      </Styled.CardAvatar>
      <Styled.CardContent>
        <Styled.Text variant="subtitle-medium">{student?.student_name}</Styled.Text>
        <Badge color={getStudentStatusDict[student?.student_status]?.color} variant="soft" size={1}>
          {getStudentStatusDict[student?.student_status].text}
        </Badge>
      </Styled.CardContent>
      <Styled.IconButton size={1} color="accent" variant="ghost" hidden={!cardOnHover}>
        <Icon path={mdiOpenInNew} size={1} />
      </Styled.IconButton>
    </Styled.CardContainer>
  )
}
