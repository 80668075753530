import styled from 'styled-components'
import NoCreditHome from '@monorepo/credit/assets/no-credit-home.jpeg'
import CreditHome from '@monorepo/credit/assets/credit-home.jpeg'
import { spacing } from '@gravity/tokens'
import { Button } from '@gravity/button'
import { Card } from '@gravity/card'

export const ContainerCard = styled.div<{ isEligible: boolean }>`
  display: flex;
  justify-content: center;
  flex-direction: column;

  background-image: url(${props => (props.isEligible ? CreditHome : NoCreditHome)});
  height: 485px;
  width: 100%;
  background-size: 100%;
  background-position: ${props => (props.isEligible ? 'top -100px right' : 'top -140px right')};

  @media screen and (min-width: 1441px) {
    background-position: ${props => (props.isEligible ? 'top -150px right' : 'top -250px right')};
  }
`

export const InternalContainerCard = styled.div`
  max-width: 596px;
`

export const StyledCard = styled(Card)<{ isEligible: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${spacing[14]};
  padding-right: ${props => (props.isEligible ? '87px' : spacing[14])};
  padding-left: ${props => (props.isEligible ? '87px' : spacing[14])};
  gap: ${spacing[8]};
  border-radius: ${spacing[6]};
  border: none;
`

export const ContainerButton = styled.span`
  display: inline-block;
`

export const StyledButton = styled(Button)`
  width: 100%;
`

export const ResponsiveContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const TextGroup = styled.div`
  display: flex;
  flex-direction: column;
`
