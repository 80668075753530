import { forwardRef, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '@gravity/button'
import { Table } from '@gravity/table'
import { useToast } from '@gravity/toast'
import { mdiPlus } from '@mdi/js'
import Icon from '@mdi/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { ContractCancellationReason, ReenrollmentStatus } from '@/shared/interfaces'
import { StudentContract } from '@monorepo/students/services/students/types'
import {
  filterContractsForm,
  filterContractsSchema,
} from '@monorepo/students/models/filterContractsSchema'
import type { SortOrder } from '@/shared/hooks/useOrdering/types'

import { useContractRedirect } from './hooks/useContractRedirect'
import { useOrdering } from '@/shared/hooks/useOrdering'
import { useQueryParamFilters } from '@monorepo/students/hooks/useQueryParamFilters'
import { useFilterContracts } from '@monorepo/students/hooks/useFilterContracts'
import { useSortContracts } from '@monorepo/students/hooks/useSortContracts'

import { queryClient } from '@/shared/contexts/ReactQuery'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks/useUnleashFlag'

import { useMutationCancelProposal } from '@monorepo/students/hooks/mutations/contracts'
import { useAcceptPaymentPlan } from '@monorepo/students/hooks/queries/contracts'

import { FiltersDialog } from '@/shared/components/FiltersDialog'
import { ContractsSectionPlaceholder } from './components/ContractsSectionPlaceholder'
import { SectionContainer } from '../SectionContainer'
import { SectionErrorState } from '../SectionErrorState'
import { ContractActions } from './components/ContractActions'
import { CancelProposalDialog } from './components/CancelProposalDialog'
import { AddTuitionDialog } from './components/AddTuitionDialog'
import { useAddTuitionToOneCampaignStudent } from './hooks/useAddTuitionToOneCampaignStudent'
import { AcceptPaymentPlanDialog } from './components/AcceptPaymentPlanDialog'
import { FilterChip } from './components/FilterChip'
import { EmptyTable } from './components/EmptyTable'

import { getDebtStatus } from './utils/getDebtStatus'

import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventPageName } from '@monorepo/students/models/EventPageName.enum'
import { EventIdentifierName } from '@monorepo/students/models/EventIdentifierName.enum'

import { COLUMN_HEADERS, CONTRACTS_STATUS } from './constants'

import type { AddTuitionToCampaignState, Columns } from './types'

import { Container } from './styles'

type ContractsSectionProps = {
  data?: {
    contracts: StudentContract[]
    reference_years: string[]
  }
  hasError: boolean
  id: string
  isLoading: boolean
  onRetry: () => void
  selectedCycleReferenceYear: number
  studentId: string
  studentName: string
  studentStatus: ReenrollmentStatus
}

export const ContractsSection = forwardRef<HTMLElement, ContractsSectionProps>(
  (
    {
      id,
      studentId,
      studentName,
      studentStatus,
      selectedCycleReferenceYear,
      data,
      isLoading,
      hasError,
      onRetry,
    },
    ref
  ) => {
    const [enrollmentId, setEnrollmentId] = useState<string | undefined>(undefined)
    const { toast } = useToast()
    const { school } = useSelectedSchool()
    const { ordering, updateOrdering } = useOrdering<Columns>({
      sortBy: 'reference_year',
      sortOrder: 'DESC',
    })
    const { isInitialized, eventDispatcherClient } = useEventDispatcher()

    const { sortedContracts } = useSortContracts(data?.contracts ?? [], ordering)

    const isIntegratedSchool = useUnleashFlag(UnleashFlags.IS_INTEGRATED_SCHOOL)

    const { filtersParams, updateFiltersParams, removeFiltersParams } = useQueryParamFilters()

    const referenceYearsOptions = data?.reference_years ?? []

    const emptyForm: filterContractsForm = {
      reference_years: referenceYearsOptions.map(year => ({
        label: year,
        value: year,
        selected: false,
      })),
    }

    const filtersForm = useForm<filterContractsForm>({
      mode: 'all',
      defaultValues: emptyForm,
      resolver: zodResolver(filterContractsSchema),
    })

    const {
      handleCloseFilterDialog,
      handleOpenFilterDialog,
      handleRemoveFilter,
      isFilterDialogOpen,
      onSubmitFilters,
      setValue: filtersFormSetValue,
    } = useFilterContracts({
      updateFilterParam: updateFiltersParams,
      removeFilterParam: removeFiltersParams,
      referenceYearsOptions,
      form: filtersForm,
      emptyForm,
    })

    const selectedFilters = filtersParams?.reference_years ?? []

    const filteredAndOrderedContracts = sortedContracts.filter(contract => {
      return selectedFilters.length === 0
        ? true
        : selectedFilters.some(year => year === contract.reference_year)
    })

    const [addTuitionDialogState, setAddTuitionDialogState] = useState<AddTuitionToCampaignState>({
      campaignId: '',
      enrollmentId: '',
      studentName: '',
      openDialog: false,
      referenceYear: 0,
    })

    const {
      isLoadingAddTuitionMutation,
      handleSubmitAddTuitionToProposal,
      form,
      handleCloseDialogForm,
    } = useAddTuitionToOneCampaignStudent({
      campaignId: addTuitionDialogState.campaignId ?? '',
      school,
      setAddTuitionDialogState,
    })

    const {
      mutate,
      isLoading: isLoadingAcceptPaymentPlan,
      isError: isErrorAcceptPaymentPlan,
      isSuccess: isSuccessAcceptPaymentPlan,
      data: acceptPaymentPlanData,
      reset: acceptPaymentPlanReset,
    } = useAcceptPaymentPlan()

    const {
      mutateAsync: mutateOnCancelProposal,
      isLoading: isLoadingUpdateAccess,
    } = useMutationCancelProposal({
      onSuccess() {
        setEnrollmentId(undefined)
        toast({
          title: 'Proposta cancelada com sucesso',
          type: 'success',
        })

        queryClient.invalidateQueries(['students', studentId, 'contracts'])
      },
      onError: () => {
        toast({
          title: 'Erro ao cancelar proposta, tente novamente mais tarde',
          type: 'error',
        })
      },
    })

    const {
      handleAddNewContract,
      handleRedirectToEditContract,
      handleRedirectToInvoice,
    } = useContractRedirect()

    const handleAddTuition = ({
      campaignId,
      referenceYear,
      enrollmentId,
    }: {
      campaignId: string
      enrollmentId: string
      referenceYear: string
    }) => {
      setAddTuitionDialogState({
        studentName: '',
        enrollmentId,
        campaignId,
        referenceYear: Number(referenceYear),
        openDialog: true,
      })
    }

    const handleRemoveStudentFromCampaign = ({ enrollmentId }: { enrollmentId: string }) => {
      if (school?.id) {
        mutateOnCancelProposal({
          enrollmentId: enrollmentId,
          schoolId: school.id,
          params: {
            cancellation_description: 'PROPOSAL_CANCELED',
            cancellation_reason: ContractCancellationReason.OTHER,
          },
        })
      }
    }

    const handleCloseAcceptPaymentPlanDialog = () => {
      acceptPaymentPlanReset()

      queryClient.invalidateQueries(['students', studentId, 'contracts'])
    }

    const sendRowClickEvent = () => {
      if (isInitialized) {
        eventDispatcherClient.sendEvent({
          scope: EventDispatcherEventScopes.STUDENT_PAGE,
          name: EventDispatcherEvents.TABLE_ROW_CLICK,
          pageName: EventPageName.STUDENT_PAGE,
          identifierName: EventIdentifierName.CONTRACTS_TABLE_ROW,
        })
      }
    }

    const filtersGroup = [
      {
        id: 0,
        title: 'Ano de referência',
        component: <FilterChip control={filtersForm.control} filterType="reference_years" />,
      },
    ]

    useEffect(() => {
      filtersFormSetValue(
        'reference_years',
        referenceYearsOptions.map(year => ({
          label: year,
          value: year,
          selected: filtersParams?.reference_years?.includes(year) ?? false,
        }))
      )
    }, [referenceYearsOptions, filtersParams, filtersFormSetValue])

    if (hasError && !!onRetry) {
      return <SectionErrorState onRetry={onRetry} />
    }

    const reenrollmentOrAddContractLabel = {
      REENROLLMENT_AVAILABLE: 'Rematricular',
      DEFAULT: 'Adicionar contrato',
    }

    const getStudentStatusLabel = (_status: ReenrollmentStatus) => {
      return studentStatus === 'REENROLLMENT_AVAILABLE'
        ? reenrollmentOrAddContractLabel.REENROLLMENT_AVAILABLE
        : reenrollmentOrAddContractLabel.DEFAULT
    }

    return (
      <SectionContainer
        id={id}
        ref={ref}
        title="Contratos"
        action={
          !isIntegratedSchool && (
            <Button
              onClick={() =>
                handleAddNewContract(studentId, studentName, selectedCycleReferenceYear)
              }
              variant="outline"
              iconStart={<Icon path={mdiPlus} />}
            >
              {getStudentStatusLabel(studentStatus)}
            </Button>
          )
        }
      >
        <Container>
          <Table.Root>
            <Table.Filtering
              filters={
                filtersParams?.reference_years?.map(year => ({
                  text: year,
                  value: year,
                })) ?? []
              }
              onClickFilterButton={() => handleOpenFilterDialog()}
              onRemoveFilter={filter => handleRemoveFilter(filter)}
            />
            <Table.Head>
              {COLUMN_HEADERS.map(({ label, name, sortable }) => (
                <Table.HeaderCell
                  key={name}
                  name={name}
                  sortable={!isLoading && !!data?.contracts && sortable}
                  onSort={(sortBy, sortOrder) => {
                    updateOrdering({
                      sortBy: sortBy as Columns,
                      sortOrder: sortOrder as SortOrder,
                    })
                  }}
                  sortOrder={
                    ordering.sortBy === name && ordering.sortOrder
                      ? (ordering.sortOrder.toLowerCase() as Lowercase<SortOrder>)
                      : undefined
                  }
                >
                  {label}
                </Table.HeaderCell>
              ))}
            </Table.Head>

            <Table.Body>
              {isLoading && <ContractsSectionPlaceholder />}

              {!isLoading && filteredAndOrderedContracts.length === 0 && <EmptyTable />}

              {!isLoading && filteredAndOrderedContracts.length > 0 && (
                <>
                  {filteredAndOrderedContracts.map(contract => (
                    <Table.Row
                      onClick={event => {
                        event.preventDefault()

                        sendRowClickEvent()

                        handleRedirectToInvoice({
                          guardianId: contract.guardian?.id ?? '',
                          referenceYear: contract.reference_year,
                          productId: contract.product?.id ?? '',
                          studentId,
                        })
                      }}
                      key={contract.id}
                      data-testid="table-row"
                    >
                      <Table.TextCell>{contract.product?.name ?? '-'}</Table.TextCell>
                      <Table.TextCell>{contract.reference_year}</Table.TextCell>
                      <Table.TextCell>{contract.guardian?.name ?? ''}</Table.TextCell>
                      <Table.TextCell>{CONTRACTS_STATUS[contract.status] ?? '-'}</Table.TextCell>
                      {contract?.debt_status ? (
                        <Table.BadgeCell
                          badgeColor={getDebtStatus(contract.debt_status).color}
                          badgeVariant="soft"
                        >
                          {getDebtStatus(contract.debt_status).text}
                        </Table.BadgeCell>
                      ) : (
                        <Table.TextCell>-</Table.TextCell>
                      )}
                      <ContractActions
                        studentId={studentId}
                        contract={contract}
                        onRedirectToEditContract={handleRedirectToEditContract}
                        onRedirectToInvoice={handleRedirectToInvoice}
                        onOpenProposalDialog={() => setEnrollmentId(contract.id)}
                        onOpenAddTuitionDialog={handleAddTuition}
                        onAcceptPaymentPlan={contractId => mutate({ enrollmentId: contractId })}
                      />
                    </Table.Row>
                  ))}
                </>
              )}
            </Table.Body>
          </Table.Root>
          <CancelProposalDialog
            isLoading={isLoadingUpdateAccess}
            onClose={() => setEnrollmentId(undefined)}
            onConfirm={() => {
              if (enrollmentId) {
                handleRemoveStudentFromCampaign({
                  enrollmentId,
                })
              }
            }}
            open={!!enrollmentId}
          />
          <AddTuitionDialog
            open={addTuitionDialogState.openDialog && !!addTuitionDialogState.enrollmentId}
            enrollmentID={addTuitionDialogState.enrollmentId}
            onConfirm={handleSubmitAddTuitionToProposal}
            onClose={handleCloseDialogForm}
            form={form}
            isLoading={isLoadingAddTuitionMutation}
            referenceYear={addTuitionDialogState.referenceYear ?? 0}
            studentName={studentName}
          />
          <AcceptPaymentPlanDialog
            isLoading={isLoadingAcceptPaymentPlan}
            isSuccess={isSuccessAcceptPaymentPlan}
            isError={isErrorAcceptPaymentPlan}
            installment={
              !isErrorAcceptPaymentPlan && !!acceptPaymentPlanData
                ? acceptPaymentPlanData
                : undefined
            }
            onClose={handleCloseAcceptPaymentPlanDialog}
          />
        </Container>
        <FiltersDialog
          closeDialog={() => handleCloseFilterDialog()}
          filters={filtersGroup}
          form={filtersForm}
          onApplyFilters={onSubmitFilters}
          resetValues={{
            reference_years: referenceYearsOptions.map(year => ({
              label: year,
              value: year,
              selected: false,
            })),
          }}
          visible={isFilterDialogOpen}
        />
      </SectionContainer>
    )
  }
)
