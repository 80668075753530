import type { ReactNode } from 'react'

import { BigNumberPrimitives as BigNumber } from '@monorepo/enrollment/components/BigNumber'
import { Skeleton } from '@gravity/skeleton'
import * as Styled from './styles'

type Props = {
  description: string
  isLoading: boolean
  onClick?: () => void
  title: string | ReactNode
  value: string
}
export const View = ({ value, description, onClick, title, isLoading }: Props) => {
  if (isLoading)
    return (
      <Styled.BigNumberSkeleton>
        <Skeleton fullWidth height={24} radius={8} />
        <Skeleton fullWidth height={24} radius={8} />
      </Styled.BigNumberSkeleton>
    )
  return (
    <BigNumber.Root onClick={onClick}>
      <BigNumber.Title>{title}</BigNumber.Title>
      <BigNumber.Value>{value}</BigNumber.Value>
      <BigNumber.Description>{description}</BigNumber.Description>
    </BigNumber.Root>
  )
}
