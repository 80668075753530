import { useEffect } from 'react'
import { useQuery } from '@/shared/hooks/useQuery'
import { Box } from '@material-ui/core'
import { Pagination } from '@olaisaac/design-system'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { usePagination } from '@/shared/hooks/usePagination'

import {
  useGetPaymentDisputes,
  useGetPaymentDisputeStatusSummary,
} from '@/shared/hooks/queries/usePaymentDisputeQueries'

import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventPageName } from '@/shared/models/enums/EventPageName.enum'
import { EventIdentifierName } from '@/shared/models/enums/EventIdentifierName.enum'

import { PaymentDisputeHeader } from './components/PaymentDisputeHeader'
import { PaymentDisputeSummary } from './components/PaymentDisputeSummary'
import { PaymentDisputeAccordion } from './components/PaymentDisputeAccordion'
import { PaymentDisputeStatusFilter } from './components/PaymentDisputeStatusFilter'

import {
  DEFAULT_PAGE,
  DEFAULT_PAYMENT_DISPUTE_STATUS_FILTERS,
  DEFAULT_PER_PAGE,
  DEFAULT_PER_PAGE_OPTIONS,
  DEFAULT_SORT_BY,
  DEFAULT_SORT_ORDER,
} from './constants'

import { usePaymentDisputeFilter } from '../../hooks/usePaymentDisputeStatusFilter'

import { Container } from './styles'

export const PaymentDispute = () => {
  const { query } = useQuery()

  const { school } = useSelectedSchool()

  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const { pagination, updatePaginationValue } = usePagination(DEFAULT_PAGE, DEFAULT_PER_PAGE)

  const { paymentDisputeFilter, updateFilter } = usePaymentDisputeFilter(
    DEFAULT_PAYMENT_DISPUTE_STATUS_FILTERS
  )

  const {
    paymentDisputesResponse,
    isLoadingPaymentDisputes,
    refetchPaymentDisputes,
  } = useGetPaymentDisputes({
    schoolId: school?.id ?? '',
    status: paymentDisputeFilter.status,
    pagination: {
      page: pagination.page,
      per_page: pagination.itemsPerPage,
      sort_order: DEFAULT_SORT_ORDER,
      sort_by: DEFAULT_SORT_BY,
    },
  })

  const {
    paymentDisputeStatusSummary,
    isLoadingPaymentDisputeStatusSummary,
    refetchPaymentDisputeStatusSummary,
  } = useGetPaymentDisputeStatusSummary(school?.id ?? '')

  const totalizators = paymentDisputeStatusSummary?.data
  const paymentDisputes = paymentDisputesResponse?.data
  const totalItens = paymentDisputesResponse?.pagination?.total ?? 0

  useEffect(() => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.PAYMENT_DISPUTE,
        name: EventDispatcherEvents.PAGE_VIEWED,
        pageName: EventPageName.PAYMENT_DISPUTE,
        identifierName: EventIdentifierName.PAYMENT_DISPUTES_PAGE,
      })
    }
  }, [isInitialized])

  const shouldRefetch = Boolean(query.get('refetch'))

  useEffect(() => {
    if (shouldRefetch) {
      refetchPaymentDisputeStatusSummary()
      refetchPaymentDisputes()
    }
  }, [shouldRefetch])

  return (
    <Container>
      <PaymentDisputeHeader />
      <PaymentDisputeSummary
        loading={isLoadingPaymentDisputeStatusSummary}
        totalInReview={totalizators?.total_started}
        totalFinish={totalizators?.total_completed}
        totalPending={totalizators?.total_not_started}
      />
      <PaymentDisputeStatusFilter filter={paymentDisputeFilter} updateFilter={updateFilter} />
      <PaymentDisputeAccordion loading={isLoadingPaymentDisputes} data={paymentDisputes} />
      <Box my={2} px={2}>
        <Pagination
          currentPage={pagination.page}
          itensPerPage={pagination.itemsPerPage}
          itensPerPageOptions={DEFAULT_PER_PAGE_OPTIONS}
          totalItens={totalItens}
          onPageChange={newPage => {
            updatePaginationValue('page', newPage)
          }}
          onItensPerChangeChange={newItemsPerPage => {
            updatePaginationValue('itemsPerPage', newItemsPerPage)
          }}
        />
      </Box>
    </Container>
  )
}
