import React, { useEffect, useRef } from 'react'

import * as Styled from './styles'
import type { StepItemStatus, StepperProps } from './types'
import { StepItemProps } from './Components/StepItem/types'

/**
 * @deprecated
 * This component is temporary and will be replaced as soon as the Stepper component becomes available in the Design System
 */
export const Stepper = ({ activeStep, children }: StepperProps) => {
  const maxStepReached = useRef(activeStep)

  useEffect(() => {
    if (activeStep > maxStepReached.current) {
      maxStepReached.current = activeStep
    }
  }, [activeStep])

  const isStepDisabled = (originalProps: StepItemProps, index: number) => {
    if (originalProps.disabled) return originalProps.disabled
    return index > maxStepReached.current
  }

  const getStepStatus = (originalProps: StepItemProps, index: number): StepItemStatus => {
    if (originalProps.status) return originalProps.status
    if (index < maxStepReached.current) return 'completed'
    return 'incomplete'
  }

  return (
    <Styled.Root>
      {React.Children.map(children, (child, index) => {
        return React.cloneElement(
          child,
          {
            isActiveStep: index === activeStep,
            disabled: isStepDisabled(child.props, index),
            status: getStepStatus(child.props, index),
          },
          null
        )
      })}
    </Styled.Root>
  )
}
