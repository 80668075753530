import { Tooltip } from '@material-ui/core'
import { SaveAlt } from '@material-ui/icons'
import { Button } from '@olaisaac/design-system'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { PayoutReportPageFooter } from '@monorepo/report/components/PayoutReportPageFooter'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { usePagination } from '@/shared/hooks/usePagination'
import { useApiClient, useSnackbar } from '@/shared/hooks'

import { downloadFile } from '@/shared/utils/downloadFile'
import { formatDate } from '@/shared/utils/dateFormatters'

import { REPORT_PAGE_NAMES } from '@monorepo/report/constants/REPORT_PAGE_NAMES'

import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { reportService } from '@monorepo/report/services/report'

type EnrollmentPayoutReportFooterProps = {
  enrollmentPayoutReportEndDate: Date
  enrollmentPayoutReportId: string
  enrollmentPayoutReportStartDate: Date
  isLoading: boolean
  totalItems: number
}

/**
 * Specialized footer component for enrollment report page
 *
 * @param props
 * @param props.enrollmentPayoutReportId Payout ID
 * @param props.enrollmentPayoutReportStartDate Payout start date
 * @param props.enrollmentPayoutReportEndDate Payout end date
 * @param props.totalItems Total items to be paginated
 * @param props.isLoading Indicates if the loading state should be displayed
 */
export const EnrollmentPayoutReportFooter = ({
  enrollmentPayoutReportId,
  enrollmentPayoutReportStartDate,
  enrollmentPayoutReportEndDate,
  totalItems,
  isLoading,
}: EnrollmentPayoutReportFooterProps) => {
  const { apiClient } = useApiClient()
  const { school } = useSelectedSchool()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { pagination, updatePaginationValue } = usePagination(1, 25)
  const { setMessage, setVariation, setIsOpen } = useSnackbar()

  const sendPaginationEvent = (event: 'pageChange' | 'pageSizeChange') => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.ENROLLMENT_REPORT,
        name:
          event === 'pageChange'
            ? EventDispatcherEvents.PAGINATION_CHANGE_PAGE
            : EventDispatcherEvents.PAGINATION_CHANGE_PAGE_SIZE,
        action: 'click',
        customProperties: {
          $page_name: REPORT_PAGE_NAMES.ENROLLMENT_PAYOUT_REPORT,
        },
      })
  }

  const handleDownloadReport = async (payoutId: string, filename: string) => {
    const service = reportService(apiClient.getClients().privateApi)

    try {
      await downloadFile(filename, 'xlsx', () =>
        service.fetchUnguaranteedReportFile({
          schoolId: school?.id ?? '',
          id: payoutId,
          extension: 'xlsx',
        })
      )
    } catch {
      setMessage(
        'Erro ao baixar o relatório. Tente novamente mais tarde ou entre em contato com nosso suporte.'
      )
      setVariation('error')
      setIsOpen(true)
    } finally {
      isInitialized &&
        eventDispatcherClient.sendEvent({
          scope: EventDispatcherEventScopes.ENROLLMENT_REPORT,
          name: EventDispatcherEvents.ENROLLMENT_PAYOUT_DOWNLOAD_CSV,
          action: 'file_download',
          customProperties: {
            $page_name: REPORT_PAGE_NAMES.ENROLLMENT_PAYOUT_REPORT,
          },
        })
    }
  }

  const startDate = formatDate(enrollmentPayoutReportStartDate, 'DD/MM/YYYY')
  const endDate = formatDate(enrollmentPayoutReportEndDate, 'DD/MM/YYYY')

  const filename = `analítico-${startDate}-${endDate}-${school?.slug}.xlsx`

  const isEmptyPayout = totalItems === 0

  return (
    <PayoutReportPageFooter
      page={pagination.page}
      itemsPerPage={pagination.itemsPerPage}
      totalItems={totalItems}
      onChangePage={value => {
        updatePaginationValue('page', value, true)
        sendPaginationEvent('pageChange')
      }}
      onChangeItemsPerPage={value => {
        updatePaginationValue('itemsPerPage', value, true)
        sendPaginationEvent('pageSizeChange')
      }}
    >
      <Tooltip title="Baixar o arquivo .xlsx do período">
        <span>
          <Button
            disabled={isLoading || isEmptyPayout}
            startIcon={<SaveAlt />}
            onClick={() => handleDownloadReport(enrollmentPayoutReportId, filename)}
          >
            Baixar planilha
          </Button>
        </span>
      </Tooltip>
    </PayoutReportPageFooter>
  )
}
