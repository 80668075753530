import { useEffect, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { Fade } from '@material-ui/core'
import { Drawer, ActionDrawerHeader, ButtonDocker } from '@olaisaac/design-system'
import { Button } from '@gravity/button'

import { CollapsibleFilterGroup } from '@/shared/components/CollapsibleFilterGroup'
import { countSelectedFilters } from '@/escolas/pages/responsaveis/GuardianDetailsContractsTab/utils/countSelectedFilters'
import { FilterButton } from '../FilterButton/FilterButton'
import { ActiveFilters } from './components/ActiveFilters/ActiveFilters'

import { ListGuardiansFilterProps, ListGuardiansFilterType } from './types'
import { OPTIONS } from './constants'
import handleSendEvent from './events'
import { ActiveFiltersContainer, ButtonsContainer } from './styles'
import {
  haveActiveFilters,
  transformFilters,
} from '@/escolas/pages/responsaveis/ListGuardians/utils'

const ListGuardiansFilter = ({ filters, updateFilters }: ListGuardiansFilterProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const defaultValues = { debt_status: [] }
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const form = useForm<ListGuardiansFilterType>({
    defaultValues,
  })
  const { setValue, handleSubmit, reset, formState } = form

  const handleApplyFilters: SubmitHandler<ListGuardiansFilterType> = (
    data: ListGuardiansFilterType
  ) => {
    updateFilters(data)
    setIsOpen(false)
    handleSendEvent(data, isInitialized, eventDispatcherClient)
  }

  const handleClearFilter = () => {
    reset(defaultValues)
  }

  const handleClearFiltersLink = () => {
    handleClearFilter()
    handleApplyFilters(defaultValues)
  }

  useEffect(() => setValue('debt_status', filters.debt_status || [], { shouldDirty: true }), [
    isOpen,
  ])

  const allFiltersOptions = {
    debtStatus: OPTIONS,
  }

  const activeFilters = transformFilters(filters, allFiltersOptions)

  const haveAppliedFilters = haveActiveFilters(activeFilters)

  return (
    <>
      <Drawer anchor="right" variant="persistent" open={isOpen}>
        <ActionDrawerHeader
          onClose={() => {
            setIsOpen(false)
          }}
          title="Filtrar"
          data-testid="filter-drawer"
        />

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            overflow: 'auto',
            padding: '1.5rem',
          }}
        >
          <FormProvider {...form}>
            <CollapsibleFilterGroup
              label="Situação financeira"
              filterName="debt_status"
              options={OPTIONS}
              totalOptions={OPTIONS.length}
              searchText=""
              onClearFilter={() => setValue('debt_status', [])}
            />
          </FormProvider>
        </div>

        <ButtonDocker>
          <Button fullWidth onClick={handleSubmit(handleApplyFilters)} type="submit">
            Filtrar
          </Button>

          <Button
            fullWidth
            variant="ghost"
            disabled={!formState.isDirty}
            onClick={handleClearFilter}
          >
            Limpar tudo
          </Button>
        </ButtonDocker>
      </Drawer>
      <ButtonsContainer>
        <FilterButton
          selectedFiltersCount={filters && countSelectedFilters(filters)}
          onClick={() => setIsOpen(true)}
        />
      </ButtonsContainer>
      <Fade in={haveAppliedFilters}>
        <ActiveFiltersContainer haveAppliedFilters={haveAppliedFilters}>
          <ActiveFilters
            filters={filters}
            activeFilters={activeFilters}
            updateFilters={updateFilters}
            clearFilters={handleClearFiltersLink}
          />
        </ActiveFiltersContainer>
      </Fade>
    </>
  )
}

export default ListGuardiansFilter
