import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useSchoolEnrollmentCycleContext } from '@monorepo/enrollment/contexts/SchoolEnrollmentContext'
import {
  INITIAL_SUPPORTED_CYCLE_YEAR,
  LEGACY_CYCLE_YEARS,
} from '@monorepo/enrollment/constants/enrollment'

export const useSelectYear = () => {
  const { schoolSlug } = useSelectedSchool()
  const history = useHistory()
  const {
    schoolEnrollmentCycles,
    updateSelectedEnrollmentCycle,
    selectedEnrollmentCycle,
  } = useSchoolEnrollmentCycleContext()

  const selectedYear = selectedEnrollmentCycle?.reference_year ?? 0

  const years = useMemo(() => {
    const yearsSet = new Set(LEGACY_CYCLE_YEARS)

    if (schoolEnrollmentCycles) {
      schoolEnrollmentCycles.map(enrollmentCycle => yearsSet.add(enrollmentCycle.reference_year))
    }

    return Array.from(yearsSet).sort((a, b) => b - a)
  }, [schoolEnrollmentCycles])

  const handleChangeSelectedYear = (year: number) => {
    if (year < INITIAL_SUPPORTED_CYCLE_YEAR) {
      history.push(`/${schoolSlug}/contratos/${year}`)
    }

    const selectedEnrollment = schoolEnrollmentCycles.find(
      schoolEnrollmentCycle => schoolEnrollmentCycle.reference_year === year
    )
    updateSelectedEnrollmentCycle(selectedEnrollment)
  }

  return { handleChangeSelectedYear, selectedYear, years }
}
