import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { CreditComponentError } from './CreditComponentError'
import BookImage from '@monorepo/credit/assets/book.png'
import CoffeeImage from '../../assets/coffee.svg'

export const GenericCreditError = () => {
  return (
    <CreditComponentError
      src={BookImage}
      title="Algo deu errado"
      description="Por favor, aguarde um momento e tente novamente."
      titleButton="Tente novamente"
    />
  )
}

export const SimulationCreditError = () => {
  const { schoolSlug } = useSelectedSchool()
  const handleRetryButton = () => {
    location.href = `/${schoolSlug}/credito`
  }

  return (
    <CreditComponentError
      src={CoffeeImage}
      handleRetryButton={handleRetryButton}
      title="Não foi possível concluir a simulação"
      titleButton="Inicio"
      description={`Ocorreu um erro inesperado e estamos trabalhando para resolvê-lo.\n Por favor, tente novamente mais tarde.`}
    />
  )
}

export const NetworkError = () => {
  return (
    <CreditComponentError
      src={BookImage}
      title="Sem internet"
      description={`Por favor, verifique sua conexão com a internet ou aguarde um momento\n e tente novamente.`}
      titleButton="Tente novamente"
    />
  )
}
