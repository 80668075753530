import { ActionDrawerHeader, Drawer, Typography } from '@olaisaac/design-system'
import dayjs from 'dayjs'

import { formatCentsToReal, isDayBeforeToday } from 'src/shared/utils'
import { prettifyFullName } from 'src/shared/utils/namePrettifiers'
import { useAgreement } from 'src/escolas/contexts/agreementContext'
import type { AgreementDetailDrawerProps } from './types'
import { usePaymentCreditCard } from '@/escolas/hooks'
import {
  BottomContainer,
  ContentContainer,
  ItemSummary,
  SeparatorSummary,
  TotalValue,
} from '../InstallmentNegotiationDrawer/styles'
import { Separator } from '@gravity/separator'

const AgreementDetailDrawer = ({
  onClose,
  isOpen,
  creditCardFee,
  totalAmount,
}: AgreementDetailDrawerProps) => {
  const { agreementSimulations, positionAgreement } = useAgreement()
  const { isCreditCardFlow } = usePaymentCreditCard()

  const agreement = agreementSimulations?.[positionAgreement]
  const studentName = agreement?.receivables?.[0]?.student ?? ''
  const productName = agreement?.receivables?.[0]?.product ?? ''

  const discounts = agreement
    ? agreement.due_payment_discount_applied +
      agreement.early_payment_discount_applied +
      agreement.perpetual_discount_applied
    : 0

  const finesAndInterest = agreement ? agreement.fine_applied + agreement.interest_applied : 0

  const isOverdue = isDayBeforeToday(agreement?.receivables?.[0]?.due_date)

  return (
    <Drawer open={isOpen && !!agreement}>
      <ActionDrawerHeader title="Detalhes do pagamento" onClose={onClose} />
      <ContentContainer>
        <Typography
          withoutMargin
          variation="bodySmall"
          color="secondary"
          style={{ marginBottom: 10 }}
        >
          {prettifyFullName(studentName)}
        </Typography>
        <Typography style={{ marginBottom: 10 }} variation="subtitleDesktopLarge">
          {productName}
        </Typography>
        <SeparatorSummary>
          <Separator color="neutral-2" />
        </SeparatorSummary>
        {agreement?.receivables.map(receivable => (
          <ItemSummary key={receivable.id}>
            <Typography withoutMargin variation="bodyLarge">
              {dayjs(receivable?.competenceDate).utc().format('MMMM')}
            </Typography>
            <Typography withoutMargin variation="bodyLarge">
              {formatCentsToReal(receivable.original_amount)}
            </Typography>
          </ItemSummary>
        ))}

        <ItemSummary>
          <Typography withoutMargin variation="bodyLarge">
            Multa e Juros
          </Typography>

          <Typography
            withoutMargin
            variation="bodyLarge"
            data-testid="agreement-detail-drawer-fines-and-interest"
          >
            {formatCentsToReal(finesAndInterest)}
          </Typography>
        </ItemSummary>

        <ItemSummary>
          <Typography withoutMargin variation="bodyLarge">
            {isOverdue ? 'Desconto de negociação' : 'Descontos'}
          </Typography>

          <Typography
            withoutMargin
            variation="bodyLarge"
            data-testid="agreement-detail-drawer-discounts"
          >
            -{formatCentsToReal(discounts)}
          </Typography>
        </ItemSummary>

        {isCreditCardFlow && Boolean(creditCardFee) && (
          <ItemSummary>
            <Typography withoutMargin variation="bodyLarge">
              Taxa do cartão
            </Typography>

            <Typography
              withoutMargin
              variation="bodyLarge"
              data-testid="agreement-detail-drawer-credit-card-fee"
            >
              {formatCentsToReal(creditCardFee)}
            </Typography>
          </ItemSummary>
        )}
      </ContentContainer>

      <BottomContainer>
        <TotalValue>
          <Typography variation="bodyLarge" withoutMargin>
            Total
          </Typography>
          <Typography
            variation="bodyLarge"
            withoutMargin
            data-testid="agreement-detail-drawer-total"
          >
            {formatCentsToReal(totalAmount)}
          </Typography>
        </TotalValue>
      </BottomContainer>
    </Drawer>
  )
}

export default AgreementDetailDrawer
