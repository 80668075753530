import styled from 'styled-components'

export const Container = styled.header<{ scrolled: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;

  position: fixed;
  top: 0;
  z-index: 98;

  width: calc(100% - 96px);
  height: ${props => (props.scrolled ? '60px' : '64px')};

  box-sizing: border-box;

  padding: ${({ theme }) => theme.gravity.spacing[4]} 0px;

  background-color: ${({ theme }) => theme.gravity.colors['colors-background-neutral-1']};
  border-bottom: ${props =>
    props.scrolled ? `1px solid ${props.theme.gravity.colors['colors-border-neutral-3']}` : 'none'};

  .content {
    display: flex;
    gap: ${({ theme }) => theme.gravity.spacing[4]};

    box-sizing: border-box;
    max-width: 1136px;

    @media (min-width: 640px) {
      margin-left: 1rem;
      margin-right: 1rem;
      width: calc(100% - 2rem);
    }

    @media (min-width: 768px) {
      margin-left: 1rem;
      margin-right: 1rem;
      width: calc(100% - 2rem);
    }

    @media (min-width: 1024px) {
      margin-left: 1rem;
      margin-right: 1rem;
      width: calc(100% - 2rem);
    }

    @media (min-width: 1280px) {
      margin-left: 2.5rem;
      margin-right: 2.5rem;
      width: calc(100% - 5rem);
    }
  }
`

export const SubContainer = styled.div<{ scrolled: boolean }>`
  display: flex;
  gap: ${({ theme }) => theme.gravity.spacing[4]};
  align-items: center;

  opacity: ${props => (props.scrolled ? 1 : 0)};
  transform: ${props => (props.scrolled ? 'translateY(0)' : 'translateY(20px)')};
  transition: opacity 0.3s ease, transform 0.3s ease;
`
