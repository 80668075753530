import { useQuery } from '@/shared/hooks/useQuery'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { REPORT_PAGE_NAMES } from '@monorepo/report/constants/REPORT_PAGE_NAMES'
import { Add as IconAdd, Remove as IconRemove } from '@material-ui/icons'
import { IconButton } from '@gravity/icon-button'
import {
  ReceiptsCardContainer,
  ColumnReceipts,
  ReceiptsTitle,
  ReceiptsContainer,
  ReceiptsValue,
} from './styles'
import Icon from '@mdi/react'
import { mdiChevronRight } from '@mdi/js'

type PaymentReporterProps = {
  deductionsValue: string
  incomeValue: string
  onRequestOpenPayoutTransfersDrawer: () => void
}

const CARD_LABELS = {
  income: 'Ver todos os recebimentos',
  deductions: 'Ver todos os descontos',
}

export const PaymentReporter = ({
  incomeValue,
  deductionsValue,
  onRequestOpenPayoutTransfersDrawer,
}: PaymentReporterProps) => {
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { setOnQueryParam } = useQuery()

  const handleCardLinkClick = (section: 'income' | 'deductions') => {
    onRequestOpenPayoutTransfersDrawer()
    setOnQueryParam(section, 'transfer_section', 'replace')

    const buttonLabel = CARD_LABELS[section]

    isInitialized &&
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.BUTTON_CLICK,
        scope: EventDispatcherEventScopes.PAYOUT_REPORT,
        action: 'click',
        customProperties: {
          $page_name: REPORT_PAGE_NAMES.TUITION_PAYOUT_REPORT,
          $button_label: buttonLabel,
        },
      })
  }

  return (
    <ReceiptsContainer>
      <ReceiptsCardContainer
        style={{ marginBottom: '16px' }}
        onClick={() => handleCardLinkClick('income')}
      >
        <ColumnReceipts>
          <IconAdd />
          <ReceiptsTitle>Recebimentos</ReceiptsTitle>
          <ReceiptsValue>{incomeValue}</ReceiptsValue>
        </ColumnReceipts>
        <ColumnReceipts>
          <IconButton variant="ghost" size={2}>
            <Icon path={mdiChevronRight} size={1} />
          </IconButton>
        </ColumnReceipts>
      </ReceiptsCardContainer>
      <ReceiptsCardContainer onClick={() => handleCardLinkClick('deductions')}>
        <ColumnReceipts>
          <IconRemove />
          <ReceiptsTitle>Descontos</ReceiptsTitle>
          <ReceiptsValue>{deductionsValue}</ReceiptsValue>
        </ColumnReceipts>
        <ColumnReceipts>
          <IconButton variant="ghost" size={2}>
            <Icon path={mdiChevronRight} size={1} />
          </IconButton>
        </ColumnReceipts>
      </ReceiptsCardContainer>
    </ReceiptsContainer>
  )
}
