import { Grid } from '@material-ui/core'
import { useMemo } from 'react'
import Stepper from 'src/escolas/components/Stepper'
import { usePaymentCreditCard, useSelectPos } from 'src/escolas/hooks'
import { useAgreement } from 'src/escolas/contexts/agreementContext'
import { CheckoutStatus } from 'src/shared/interfaces'
import { FormOfPayment, SimulationPaymentMethod } from '@/modules/guardians/models/agreement'

import useFlowValidation from './hooks/useFlowValidation'
import InformationTitle from './components/InformationTitle'
import InstallmentNegotiationDrawer from './components/InstallmentNegotiationDrawer/InstallmentNegotiationDrawer'
import AgreementDetailDrawer from './components/AgreementDetailDrawer'
import useSteps, { StepsNegotiationFlow } from './hooks/useSteps'
import InformationTitleAgreement from './components/InformationTitleAgreement'
import * as S from './styles'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useNegotiationV2 } from './hooks/useNegotiationV2'

export const DefaultNegotiation = () => {
  const { isNegotiationDownPaymentFinished } = useNegotiationV2()

  const { currentStep, steps, stepComponents, handleStepperItemClick } = useSteps()
  const {
    hasPaidAgreement,
    selectedFormOfPayment,
    selectedSimulationPaymentMethod,
    agreementSimulations,
    agreementStatus,
    positionAgreement,
    totalAmount,
    totalCreditCardFee,
    totalAmountWithFee,
    receivables,
    checkoutData,
    openDrawer,
    setOpenDrawer,
  } = useAgreement()
  const { paymentFee } = usePaymentCreditCard()

  const { school } = useSelectedSchool()
  useSelectPos(school?.id)
  useFlowValidation()

  const studentNames = useMemo(() => {
    if (receivables) {
      const studentNamesSet = receivables.reduce((acc, curr) => {
        if (!acc.has(curr.student)) {
          acc.add(curr.student)
        }
        return acc
      }, new Set<string>())
      return Array.from(studentNamesSet)
    }
  }, [receivables])

  const studentNamesByAgreement = useMemo(() => {
    if (agreementSimulations) {
      const studentNamesSet = agreementSimulations.reduce((acc, curr) => {
        if (!acc.has(curr.receivables[0].student)) {
          acc.add(curr.receivables[0].student)
        }
        return acc
      }, new Set<string>())
      return Array.from(studentNamesSet)
    }
  }, [agreementSimulations])

  const combinedReceivablesByAgreement = useMemo(() => {
    const combinedReceivables = agreementSimulations.reduce((acc, curr) => {
      acc.push(...curr.receivables)
      return acc
    }, [])
    return combinedReceivables
  }, [agreementSimulations])

  function closeDrawer() {
    setOpenDrawer(false)
  }

  function shouldShowInformationTitle() {
    if (currentStep === 0) return false
    if (currentStep === 2) return false
    if (currentStep === 1) return false
    if (currentStep === 4 && checkoutData?.status === CheckoutStatus.FAILURE_TO_CONNECT)
      return false

    return true
  }

  const isPOSSelected = (formOfpayment?: FormOfPayment): boolean =>
    Boolean(
      formOfpayment &&
        (formOfpayment === FormOfPayment.POS || formOfpayment === FormOfPayment.POS_V2)
    )

  const getActiveStep = (currentStep: number) => {
    const activeStep = [
      StepsNegotiationFlow.OVERVIEW,
      StepsNegotiationFlow.FORM_OF_PAYMENT,
      StepsNegotiationFlow.AGREEMENT_LIST,
      StepsNegotiationFlow.PAYMENT_METHOD_AGREEMENT,
      StepsNegotiationFlow.CONCLUSION,
    ][currentStep]

    switch (activeStep) {
      case StepsNegotiationFlow.OVERVIEW:
        return 1
      case StepsNegotiationFlow.FORM_OF_PAYMENT:
      case StepsNegotiationFlow.AGREEMENT_LIST:
      case StepsNegotiationFlow.PAYMENT_METHOD_AGREEMENT:
        return 2
      case StepsNegotiationFlow.CONCLUSION:
        return 3
    }
  }

  const getCurrentValues = () => {
    const selectedAgreement = agreementSimulations[positionAgreement]
    if (isPOSSelected(selectedFormOfPayment)) {
      if (
        getActiveStep(currentStep) === 3 &&
        selectedSimulationPaymentMethod === SimulationPaymentMethod.CREDIT_CARD
      ) {
        return {
          currentTotalAmount: totalAmountWithFee,
          currentTotalCreditCardFee: paymentFee?.enabled ? totalCreditCardFee : 0,
        }
      }
      return {
        currentTotalAmount: totalAmount,
        currentTotalCreditCardFee: 0,
      }
    } else {
      if (
        getActiveStep(currentStep) === 3 &&
        selectedSimulationPaymentMethod === SimulationPaymentMethod.CREDIT_CARD
      ) {
        const creditCardFee = (paymentFee?.enabled ? selectedAgreement?.credit_card_fee : 0) ?? 0
        return {
          currentTotalAmount: creditCardFee + selectedAgreement?.total_amount,
          currentTotalCreditCardFee: creditCardFee,
        }
      }
      return {
        currentTotalAmount: selectedAgreement?.total_amount,
        currentTotalCreditCardFee: 0,
      }
    }
  }

  return (
    <S.ContainerWithActionDrawer item xs={12} $isActionDrawerOpen={openDrawer}>
      <Grid container justifyContent="center">
        <Grid item xs={4} sm={12}>
          <S.Content>
            <S.StepperWrapper>
              <Stepper
                disabled={hasPaidAgreement}
                steps={steps}
                activeStepIndex={getActiveStep(currentStep)}
                onItemClick={handleStepperItemClick}
              />
            </S.StepperWrapper>

            {shouldShowInformationTitle() &&
              (isPOSSelected(selectedFormOfPayment) ? (
                <InformationTitle
                  students={
                    selectedFormOfPayment && selectedFormOfPayment === FormOfPayment.POS
                      ? studentNames
                      : studentNamesByAgreement
                  }
                  receivables={
                    selectedFormOfPayment && selectedFormOfPayment === FormOfPayment.POS
                      ? receivables
                      : combinedReceivablesByAgreement
                  }
                  handleClick={() => setOpenDrawer(true)}
                  totalAmount={getCurrentValues().currentTotalAmount}
                />
              ) : (
                <InformationTitleAgreement
                  totalAmount={getCurrentValues().currentTotalAmount}
                  positionAgreement={positionAgreement}
                  agreementSimulations={agreementSimulations}
                  negotiationID={
                    isNegotiationDownPaymentFinished
                      ? agreementStatus[positionAgreement].negotiationId
                      : undefined
                  }
                  handleClick={() => setOpenDrawer(true)}
                />
              ))}

            {stepComponents[currentStep]}
          </S.Content>
        </Grid>

        {isPOSSelected(selectedFormOfPayment) ? (
          <InstallmentNegotiationDrawer
            isOpen={openDrawer}
            onClose={closeDrawer}
            combinedReceivables={receivables}
            creditCardFee={getCurrentValues().currentTotalCreditCardFee}
            totalAmount={getCurrentValues().currentTotalAmount}
          />
        ) : (
          <AgreementDetailDrawer
            isOpen={openDrawer}
            onClose={closeDrawer}
            creditCardFee={getCurrentValues().currentTotalCreditCardFee}
            totalAmount={getCurrentValues().currentTotalAmount}
          />
        )}
      </Grid>
    </S.ContainerWithActionDrawer>
  )
}

export default DefaultNegotiation
