import { useEffect, useState } from 'react'
import { Grid, GridItem } from '@gravity/grid'
import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import { Button } from '@gravity/button'
import { Callout } from '@gravity/callout'
import Icon from '@mdi/react'
import { mdiPlus } from '@mdi/js'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { AccessCreateDialog } from './components/AccessCreateDialog'
import { RemoveUserAccessDialog } from './components/RemoveAccessDialog'
import { AccessEditDialog } from './components/AccessEditDialog'
import { UsersTable } from '../../components/UsersTable'

import { useJWT } from '@/shared/hooks/useJWT'
import { useLayout } from '@/shared/hooks/useLayout'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useOrdering } from '@/shared/hooks/useOrdering'
import { useUserAccess } from '@monorepo/access-management/hooks/queries/user-access'

import { getRolesBasedOnEnabledFeatureFlag } from './utils/getRolesBasedOnEnabledFeatureFlag'

import type { User } from '../../models/User'
import type { SortableColumns } from '../../components/UsersTable/types'

import { Container, HeaderContainer } from './styles'

import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks/useUnleashFlag'
import { EventPageName } from '../../models/EventPageName.enum'
import { EventIdentifierName } from '../../models/EventIdentifierName.enum'
import { Roles } from '@/shared/contexts/Auth'

export const AccessManagement = () => {
  useLayout({
    enableSideMenu: true,
    enableHeader: true,
    headerTitle: 'Gestão de usuários',
  })

  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const { userId, getUserGroupNameBySchool, getUserRolesBySchool } = useJWT()
  const { school } = useSelectedSchool()
  const userRoles = getUserRolesBySchool(school?.id ?? '')
  const loggedUserGroupName = getUserGroupNameBySchool(school?.id as string)

  const { ordering, updateOrdering } = useOrdering<SortableColumns>({
    sortBy: 'group_name',
    sortOrder: 'ASC',
  })

  const { data, isFetching } = useUserAccess(
    {
      schoolId: school?.id ?? '',
      sortBy: ordering?.sortBy,
      sortOrder: ordering?.sortOrder,
    },
    {
      enabled: !!school?.id,
    }
  )

  const [selectedUser, setSelectedUser] = useState<User>()
  const [isCreateAccessDialogOpen, setIsCreateAccessDialogOpen] = useState(false)
  const [isEditAccessDialogOpen, setIsEditAccessDialogOpen] = useState(false)
  const [isRemoveAccessDialogOpen, setIsRemoveAccessDialogOpen] = useState(false)

  const actionDialogsDispatchers = {
    edit: setIsEditAccessDialogOpen,
    remove: setIsRemoveAccessDialogOpen,
  }

  const handleCreateAccessButtonClick = () => {
    setIsCreateAccessDialogOpen(true)

    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.ACCESS_MANAGEMENT,
        name: EventDispatcherEvents.BUTTON_CLICKED,
        pageName: EventPageName.USER_ACCESS,
        identifierName: EventIdentifierName.NEW_ACCESS,
        customProperties: {
          $button_name: 'novo_acesso',
        },
      })
    }
  }

  const handleCalloutLinkClick = () => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.ACCESS_MANAGEMENT,
        name: EventDispatcherEvents.LINK_CLICKED,
        pageName: EventPageName.USER_ACCESS,
        identifierName: EventIdentifierName.LINK_LEARN_MORE_ABOUT_ACCESS,
      })
    }
  }

  const handleOpenActionDialog = (dialog: 'edit' | 'remove', user: User) => {
    setSelectedUser(user)
    actionDialogsDispatchers[dialog](true)

    if (isInitialized) {
      const eventProperties = {
        edit: {
          $button_name: 'editar_acesso',
          identifierName: EventIdentifierName.EDIT_ACCESS,
        },
        remove: {
          $button_name: 'excluir_acesso',
          identifierName: EventIdentifierName.REMOVE_ACCESS,
        },
      }

      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.ACCESS_MANAGEMENT,
        name: EventDispatcherEvents.BUTTON_CLICKED,
        pageName: EventPageName.USER_ACCESS,
        identifierName: eventProperties[dialog].identifierName,
        customProperties: {
          $button_name: eventProperties[dialog].$button_name,
        },
      })
    }
  }

  const handleCloseDialog = (dialog: 'edit' | 'remove') => {
    actionDialogsDispatchers[dialog](false)
    setSelectedUser(undefined)
  }
  const enabledBasicOperation = useUnleashFlag(
    UnleashFlags.PE_233_ENABLE_BASIC_OPERATION_ACCESS_LEVEL
  )

  const userRolesBasedOnFeatureFlag = getRolesBasedOnEnabledFeatureFlag(
    userRoles,
    enabledBasicOperation
  )

  const assignRolesToCheck: Roles[] = [
    'atribuir_acesso_gestor',
    'atribuir_acesso_mantenedor',
    'atribuir_acesso_operador_basico',
    'atribuir_acesso_secretaria',
  ]

  const canCreateNewAccess = assignRolesToCheck.some(option =>
    userRolesBasedOnFeatureFlag.has(option)
  )

  useEffect(() => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.ACCESS_MANAGEMENT,
        name: EventDispatcherEvents.PAGE_VIEWED,
        pageName: EventPageName.USER_ACCESS,
        identifierName: EventIdentifierName.USER_ACCESS,
      })
    }
  }, [])

  return (
    <Container>
      <Grid className="grid-container">
        <GridItem xl={12} lg={12} md={12} sm={8} xs={4}>
          <HeaderContainer>
            <div>
              <Heading variant="heading-h3-medium" className="title">
                Acessos de usuários
              </Heading>
              <Text variant="body-2-regular">
                Gerencie o nível de acesso que os usuários possuem em sua organização.
              </Text>
            </div>

            <div>
              <Button
                variant="outline"
                iconStart={<Icon path={mdiPlus} />}
                onClick={handleCreateAccessButtonClick}
                disabled={!canCreateNewAccess}
              >
                Novo acesso
              </Button>
            </div>
          </HeaderContainer>
        </GridItem>

        <GridItem xl={12} lg={12} md={12} sm={8} xs={4}>
          <Callout
            text="Entenda os níveis de acessos e suas permissões"
            href="https://centraldeajuda.olaisaac.io/respons%C3%A1veis-financeiros/como-gerenciar-os-acessos-dos-usuarios"
            linkTarget="_blank"
            onLinkClick={handleCalloutLinkClick}
          />
        </GridItem>

        <GridItem xl={12} lg={12} md={12} sm={8} xs={4}>
          <UsersTable
            loggedUserId={userId}
            loggedUserGroupName={loggedUserGroupName}
            userRoles={userRolesBasedOnFeatureFlag}
            ordering={ordering}
            onChangeOrdering={(sortBy, sortOrder) => {
              updateOrdering({ sortBy, sortOrder })
            }}
            isLoading={isFetching}
            users={data?.data.users ?? []}
            onEdit={user => handleOpenActionDialog('edit', user)}
            onRemove={user => handleOpenActionDialog('remove', user)}
          />
        </GridItem>
      </Grid>

      <RemoveUserAccessDialog
        currentSchool={school}
        user={selectedUser}
        isOpen={isRemoveAccessDialogOpen}
        onClose={() => handleCloseDialog('remove')}
      />

      <AccessEditDialog
        loggedUserGroupName={loggedUserGroupName}
        userRoles={userRolesBasedOnFeatureFlag}
        currentSchool={school}
        user={selectedUser}
        actionUserId={userId}
        isOpen={isEditAccessDialogOpen}
        onClose={() => handleCloseDialog('edit')}
      />

      <AccessCreateDialog
        currentSchool={school}
        userRoles={userRolesBasedOnFeatureFlag}
        isOpen={isCreateAccessDialogOpen}
        onClose={() => setIsCreateAccessDialogOpen(false)}
      />
    </Container>
  )
}
