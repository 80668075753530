import {
  INSTALLMENT_CONTRACT_STATUSES,
  INSTALLMENT_STATUSES,
  INSTALLMENT_TYPES,
} from '../../GuardianDetailsInstallmentsTab/constants'
import { FiltersOptionsQuery } from '../../services/types'
import {
  CONTRACT_STATUS_OPTIONS,
  DEBT_STATUS_OPTIONS,
} from '../../GuardianDetailsContractsTab/components/GuardianDetailsContractsFilter/constants'
import { AllFiltersOptionsType } from '../../GuardianDetailsContractsTab/types'

/**
 * Transform filters from API { label = value } to { label, value }.
 * The API returns the filters having label equals value, so we need to transform it putting the label.
 * Product and Student are exceptions.
 *
 * @param filtersOptions Options of filters by the API.
 *
 * @returns Filters options in format { label, value }.
 */
export const getAllFilters = (filtersOptions: FiltersOptionsQuery): AllFiltersOptionsType => {
  const debtStatus = filtersOptions?.debt_statuses?.map(option => {
    const debtStatusOption = DEBT_STATUS_OPTIONS.find(
      debtStatusOption => debtStatusOption.value === option.value
    )

    return {
      label: debtStatusOption?.label || option.value,
      value: option.value,
    }
  })

  const contractStatus = filtersOptions?.contract_statuses?.map(option => {
    const contractStatusOption = CONTRACT_STATUS_OPTIONS.find(
      contractStatusOption => contractStatusOption.value === option.value
    )

    return {
      label: contractStatusOption?.label || option.value,
      value: option.value,
    }
  })

  const installmentContractStatus = filtersOptions?.contract_statuses?.map(option => {
    const contractStatusOption = INSTALLMENT_CONTRACT_STATUSES.find(
      contractStatusOption => contractStatusOption.value === option.value
    )

    return {
      label: contractStatusOption?.label || option.value,
      value: option.value,
    }
  })

  const year = filtersOptions?.reference_years?.map(option => ({
    label: option.value,
    value: option.value,
  }))

  const installmentStatus = filtersOptions?.installment_statuses?.map(option => {
    const installmentStatusOption = INSTALLMENT_STATUSES.find(
      installmentStatusOption => installmentStatusOption.value === option.value
    )

    return {
      label: installmentStatusOption?.label || option.value,
      value: option.value,
    }
  })

  const installmentType = filtersOptions?.installment_types?.map(option => {
    const installmentTypeOption = INSTALLMENT_TYPES.find(
      installmentTypeOption => installmentTypeOption.value === option.value
    )

    return {
      label: installmentTypeOption?.label || option.value,
      value: option.value,
    }
  })

  const products = filtersOptions?.products

  const student = filtersOptions?.students

  const negotiationId = filtersOptions?.negotiation_id

  const contractId = filtersOptions?.contract_id

  return {
    debtStatus,
    contractStatus,
    year,
    products,
    student,
    installmentContractStatus,
    installmentStatus,
    installmentType,
    negotiationId,
    contractId,
  }
}
