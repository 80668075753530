import { SchoolReportingPeriod } from '@/shared/models/SchoolChargeOperation'
import { School } from '@/shared/models/School'

export const getPayoutSubtitle = (isPayoutOpen: boolean) => {
  return isPayoutOpen ? 'Valor acumulado' : 'Valor total'
}

export const getSchoolReportingPeriod = (school: School | undefined) => {
  return school?.school_charge_config?.reporting_period ?? SchoolReportingPeriod.weekly
}
