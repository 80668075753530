import styled from 'styled-components'

import { spacing, colors, borderRadius } from '@gravity/tokens'
import { Card } from '@gravity/card'

export const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  background-color: ${colors['colors-background-neutral-2']};
  border-radius: ${borderRadius[6]};
  padding: ${spacing[14]};
  border-style: none;
  gap: ${spacing[10]};
`
export const TextGroup = styled.div`
  display: flex;
  flex-direction: column;
`
