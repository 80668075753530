import { formatCentsToReal } from '@/shared/utils'
import * as S from './styles'
import { HelpButton } from '../HelpButton'
import { StepFooterProps } from './types'
import { Button } from '@gravity/button'
import { Text } from '@gravity/text'

const StepFooter = ({
  showHelpButton = false,
  stepName,
  totalAmount,
  goBack,
  isLoading,
  onFinish,
  disableBack,
  disableConfirm,
  helpButtonEntry,
}: StepFooterProps) => (
  <S.Wrapper>
    {showHelpButton && (
      <HelpButton eventLocationName={stepName} helpButtonEntry={helpButtonEntry} />
    )}
    {totalAmount && totalAmount > 0 && (
      <S.FooterContainer>
        <S.FooterItem>
          <S.FooterContent>
            <Text variant="subtitle-regular" style={{ textAlign: 'end' }}>
              Valor a pagar:
            </Text>
            <Text variant="subtitle-regular" style={{ textAlign: 'end' }}>
              {formatCentsToReal(totalAmount ?? 0)}
            </Text>
          </S.FooterContent>
        </S.FooterItem>
      </S.FooterContainer>
    )}

    <S.FooterBtnWrapper>
      <div className="flex flex-row gap-4">
        <Button variant="ghost" disabled={disableBack} onClick={goBack}>
          Voltar
        </Button>
        <Button variant="solid" disabled={disableConfirm} loading={isLoading} onClick={onFinish}>
          Continuar
        </Button>
      </div>
    </S.FooterBtnWrapper>
  </S.Wrapper>
)

export default StepFooter
