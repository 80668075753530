import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { SchoolEnrollmentCycle } from '../../services/types'

import { useGetSchoolEnrollmentCycles } from '@/shared/hooks/queries/useGetSchoolEnrollmentCycles'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

type SchoolEnrollmentCycleProviderProps = {
  children: ReactNode
}

type SchoolEnrollmentContextType = {
  hasErrorOnFetchCycles: boolean
  isFetchingCycles: boolean
  retryFetchCycles: () => Promise<void>
  schoolEnrollmentCycles: SchoolEnrollmentCycle[]
  selectedEnrollmentCycle?: SchoolEnrollmentCycle
  updateSelectedEnrollmentCycle: (selectedEnrollmentCycle: SchoolEnrollmentCycle) => void
}

export const SchoolEnrollmentCycleContext = createContext({} as SchoolEnrollmentContextType)

export const SchoolEnrollmentCycleProvider = ({ children }: SchoolEnrollmentCycleProviderProps) => {
  const [selectedEnrollmentCycle, setSelectedEnrollmentCycle] = useState<SchoolEnrollmentCycle>()

  const { school } = useSelectedSchool()
  const {
    data: response,
    isFetching,
    isError,
    refetch,
  } = useGetSchoolEnrollmentCycles(school?.id ?? '', { enabled: !!school })
  const schoolEnrollmentCycles = response?.data ?? []

  const updateSelectedEnrollmentCycle = (selectedEnrollmentCycle: SchoolEnrollmentCycle) =>
    setSelectedEnrollmentCycle(selectedEnrollmentCycle)

  useEffect(() => {
    if (schoolEnrollmentCycles.length > 0) {
      setSelectedEnrollmentCycle(schoolEnrollmentCycles[0])
    }
  }, [schoolEnrollmentCycles])

  const contextValue: SchoolEnrollmentContextType = useMemo(
    () => ({
      hasErrorOnFetchCycles: isError,
      isFetchingCycles: isFetching,
      selectedEnrollmentCycle,
      retryFetchCycles: async () => {
        await refetch()
      },
      schoolEnrollmentCycles,
      updateSelectedEnrollmentCycle,
    }),
    [schoolEnrollmentCycles, selectedEnrollmentCycle, isFetching, isError]
  )

  return (
    <SchoolEnrollmentCycleContext.Provider value={contextValue}>
      {children}
    </SchoolEnrollmentCycleContext.Provider>
  )
}

export const useSchoolEnrollmentCycleContext = () => {
  const context = useContext(SchoolEnrollmentCycleContext)
  if (context === undefined) {
    throw new Error(
      'useSchoolEnrollmentCycleContext must be used within a SchoolEnrollmentCycleProvider'
    )
  }

  return context
}
