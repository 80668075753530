import { colors, spacing, borderRadius, boxShadow, fontSize } from '@gravity/tokens'
import styled from 'styled-components'
import { Icon as MDIIcon } from '@mdi/react'
import { Text } from '@gravity/text'

export const IsaacLogoWrapper = styled.div<{
  width?: number | string
}>`
  width: ${({ width }) => width};
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;

  path {
    fill: #fff;
  }
`

export const IsaacLogoCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0 5px;

  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${colors['colors-background-accent-1']};
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: ${spacing[6]};
`

export const SenderTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${spacing[2]};
`

export const EmailWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: ${spacing[8]};
  box-shadow: ${boxShadow[5]};
  border-radius: ${borderRadius[2]};
`

export const EmailBody = styled.div`
  flex: 1;
  padding: ${spacing[8]} ${spacing[9]};
  > button {
    margin-top: ${spacing[2]};
    width: 100%;
  }
`

export const EmailSenderWrapper = styled.div`
  margin: ${spacing[4]} ${spacing[10]} 0;
`

export const EmailHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${spacing[4]};
  background-color: ${colors['colors-background-accent-1']};
  padding: ${spacing[6]} ${spacing[10]};
  border-top-left-radius: ${borderRadius[2]};
  border-top-right-radius: ${borderRadius[2]};
  h3 {
    color: ${colors['colors-text-main-5']};
  }
`

export const TitleImgWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[10]};
`

export const TextArea = styled.textarea<{ hasError: boolean }>`
  border: 1px solid ${colors['colors-border-neutral-3']};
  border-radius: ${borderRadius[2]};
  width: 100%;
  overflow-y: scroll;
  height: 88px;
  padding: ${spacing[2]};
  resize: none;
  font: inherit;
  font-size: ${fontSize.base};

  &:focus {
    outline: 1px solid ${colors['colors-interaction-primary-3']};
  }
  ::placeholder {
    font: inherit;
    font-size: ${fontSize.base};
    color: ${colors['colors-text-main-4']};
  }
  ::-webkit-scrollbar-track {
    background: ${colors['colors-background-neutral-2']};
    border-radius: ${borderRadius[2]};
  }
  ::-webkit-scrollbar-thumb {
    background: ${colors['colors-background-neutral-4']};
    height: 47px;
    width: 10px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar {
    border-radius: inherit;
    width: 12px;
  }
  ::-webkit-scrollbar-button {
    display: none;
  }

  ${({ hasError }) =>
    hasError &&
    `
    border: 1px solid #ce2c31; 
  `}
`
export const InputErrorText = styled(Text)`
  color: #ce2c31; // TODO: update when the DS color becomes available
`

export const PaymentConditionBoxWrapper = styled.div`
  display: flex;
  gap: ${spacing[6]};
  margin: ${spacing[4]} 0;
`
export const PaymentConditionBox = styled.div`
  gap: ${spacing[2]};
  display: flex;
  flex-direction: column;
  background-color: ${colors['colors-border-neutral-2']};
  border-radius: ${borderRadius[2]};
  padding: ${spacing[6]};
`

export const EmailFooter = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${spacing[8]};
  padding: ${spacing[8]} ${spacing[9]};
  border-bottom-left-radius: ${borderRadius[2]};
  border-bottom-right-radius: ${borderRadius[2]};
  background-color: ${colors['colors-background-accent-3']};
  span {
    color: ${colors['colors-text-main-1']};
  }
`

export const FooterIconsWrapper = styled.div`
  display: flex;
  gap: ${spacing[4]};
  margin: ${spacing[4]} 0;
`
export const Icon = styled(MDIIcon)`
  color: ${colors['colors-interaction-primary-1']};
  width: 24px;
`

export const EmailSeparator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors['colors-border-neutral-2']};
  margin: ${spacing[8]} 0;
`

export const Footer = styled.div`
  margin-top: auto;
  padding-top: ${spacing[8]};
  display: flex;
  justify-content: end;
`
