import { ReactNode } from 'react'

import { formatCentsToReal } from 'src/shared/utils'
import { NotificationCheckoutDialog } from 'src/escolas/components/modal/NotificationCheckoutDialog'

import { Dialog } from '@gravity/dialog'
import { Text } from '@gravity/text'

import { StyledBoxLoading, StyledDiv } from './styles'
import { CircularLoading } from '@/shared/components/CircularLoading'

interface StatusDialogProps {
  children?: ReactNode
  guardianName: string
  isOpen: boolean
  isOverdue?: boolean
  isTextVisible?: boolean
  posName: string
  shortIds?: string[]
  title?: string
  totalAmount: number
}

const StatusDialog = ({
  children,
  guardianName,
  isOpen,
  isOverdue = true,
  isTextVisible,
  posName,
  totalAmount,
  shortIds,
  title = 'Comunicando com a maquininha',
}: StatusDialogProps) => {
  const Details = () => {
    return (
      <>
        <p>
          <Text variant="body-1-medium">Nome do responsável: </Text>
          <Text variant="body-1-regular">{guardianName}</Text>
        </p>
        <p>
          <Text variant="body-1-medium">Valor a pagar: </Text>
          <Text variant="body-1-regular">{formatCentsToReal(totalAmount)}</Text>
        </p>
        <p>
          <Text variant="body-1-medium">Maquininha: </Text>
          <Text variant="body-1-regular">{posName}</Text>
        </p>
      </>
    )
  }

  return (
    <Dialog
      open={isOpen}
      title={title}
      backdrop
      size={1}
      content={
        <>
          <StyledDiv>
            <StyledBoxLoading>
              <CircularLoading iconSize={70} />
            </StyledBoxLoading>
            {isTextVisible && <Details />}
          </StyledDiv>
          {children}
          {isTextVisible && (
            <NotificationCheckoutDialog shortIds={shortIds} isOverdue={isOverdue} />
          )}
        </>
      }
    />
  )
}

export default StatusDialog
