import { useNavigation } from '@/escolas/hooks'
import { useQuery } from '@/shared/hooks/useQuery'
import { useGetGuardians } from '@monorepo/enrollment/hooks/queries/enrollment'
import { CircularProgress, FormHelperText } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import RadioGroup from '@material-ui/core/RadioGroup'
import {
  FormControlLabel,
  Notification,
  Radio,
  TextField,
  Typography as TypographyDS,
} from '@olaisaac/design-system'
import { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { validateCpf } from 'src/shared/utils'
import styled from 'styled-components'
import { useIsReenrollment } from '../../hooks/useIsReenrollment'
import { GuardianFormNames, GuardianFormProps, GuardianFormType } from '../../types'
import { newEmptyGuardian } from '../../utils'
import { GuardianTextFields } from './GuardianTextFields'
import { GuardianFormTitle } from './style'
import { useGuardianQuery } from '@/escolas/pages/responsaveis/GuardianDetails/hooks/useGuardianQuery'
import { GUARDIAN_ID_QUERY_PARAM } from '../../constants'
import { Callout } from '@gravity/callout'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { usePersonScoreTaxId } from '../../hooks/usePersonScoreTaxId'

const NEW_GUARDIAN_OPTION = 'new'
const NO_OPTION_SELECTED = ''

const GuardianOptionsContainer = styled.div`
  margin-top: 8px;
`

const LoadingContainer = styled.div`
  margin: 16px;
  display: flex;
  justify-content: center;
`

const SearchContainer = styled.div`
  margin-top: 24px;
`

const taxIDHelperText = (
  isError: boolean,
  errorMessage: string,
  isLoading: boolean,
  isFetching: boolean,
  taxIDFound: boolean
): string => {
  const conditions: [boolean, string][] = [
    [isError, errorMessage || 'Insira um CPF válido'],
    [isLoading && !isFetching, ''],
    [isFetching, 'Buscando responsável ...'],
    [taxIDFound, 'CPF já cadastrado no isaac'],
    [!taxIDFound, 'Não há cadastros com este CPF'],
  ]

  for (const [condition, helperText] of conditions) {
    if (condition) return helperText
  }

  return ''
}

const GuardianForm = ({
  form,
  setIsFeedbackDialogOpen,
  isEditing,
  setIsEditing,
}: GuardianFormProps) => {
  const { schoolId = '' } = useNavigation()
  const { query } = useQuery()
  const { control, setValue, trigger, reset, getValues, clearErrors } = form
  const [selectedGuardianId, setSelectedGuardianId] = useState(NO_OPTION_SELECTED)
  const [sameGuardianSelected, setSameGuardianSelected] = useState(false)
  const { isReenrollment, student } = useIsReenrollment()
  const [taxIDFound, setTaxIDFound] = useState(false)
  const [lastSearchedGuardian, setLastSearchedGuardian] = useState<GuardianFormType>(
    newEmptyGuardian()
  )
  const { personTaxId } = usePersonScoreTaxId()

  const formValues = getValues()
  const { data: guardiansByStudentID = [], isError, isFetching } = useGetGuardians(
    'student-guardians',
    { schoolId, studentId: student?.id ?? '' },
    { enabled: isReenrollment, placeholderData: [] }
  )

  const {
    data: guardiansByTaxID,
    refetch,
    isSuccess: isSuccessByTaxID,
    isError: isErrorByTaxID,
    isLoading: isLoadingByTaxID,
    isFetching: isFetchingByTaxID,
  } = useGetGuardians(
    'taxid-search',
    { schoolId, taxID: formValues.guardian.tax_id },
    { enabled: false }
  )

  const isContractFormImprovementsEnabled = useUnleashFlag(
    UnleashFlags.EFI_168_CONTRACT_FORM_IMPROVEMENTS
  )

  const guardianId = query.get(GUARDIAN_ID_QUERY_PARAM) ?? ''

  const { guardian, isFetchGuardianError, isFetchGuardianFetched } = useGuardianQuery({
    guardianId,
    schoolId,
  })

  useEffect(() => {
    if (isFetchGuardianFetched) {
      setValue('guardian.tax_id', guardian?.data?.tax_id ?? '')
    } else if (personTaxId) {
      setValue('guardian.tax_id', personTaxId)
    }
  }, [isFetchGuardianFetched, personTaxId])

  useEffect(() => {
    if (!isSuccessByTaxID) {
      return
    }

    const found = guardiansByTaxID.length > 0
    const guardian = found
      ? guardiansByTaxID[0]
      : { ...newEmptyGuardian(), tax_id: formValues.guardian.tax_id }

    setValue('guardian', guardian)
    setLastSearchedGuardian(guardian)
    setTaxIDFound(found)
  }, [isSuccessByTaxID, guardiansByTaxID])

  useEffect(() => {
    setIsFeedbackDialogOpen(isError || isErrorByTaxID || isFetchGuardianError)
  }, [isError, isErrorByTaxID, isFetchGuardianError])

  useEffect(() => {
    setIsEditing(false)
  }, [selectedGuardianId, isLoadingByTaxID])

  return (
    <>
      <GuardianFormTitle>Responsável financeiro</GuardianFormTitle>
      <TypographyDS variation="bodyLarge" color="secondary" style={{ marginBottom: 24 }}>
        Estes são os dados do responsável financeiro do aluno(a).
      </TypographyDS>
      {isContractFormImprovementsEnabled ? (
        <Callout
          text="Não pode ser utilizado CPF de menor de idade ou que seja inválido na Receita Federal."
          linkLabel="Saiba mais"
          href="https://centraldeajuda.olaisaac.io/respons%C3%A1veis-financeiros/o-que-fazer-se-o-cpf-informado-for-considerado-invalido"
          linkTarget="_blank"
        />
      ) : (
        <Box mb={3}>
          <Notification
            description="É essencial que essas informações estejam corretas para garantirmos o contato com o responsável"
            variation="information"
          />
        </Box>
      )}

      {isReenrollment && isFetching && (
        <LoadingContainer>
          <CircularProgress />
        </LoadingContainer>
      )}

      {isReenrollment && guardiansByStudentID.length > 0 && (
        <div>
          <TypographyDS variation="subtitleDesktopLarge">
            Selecione um responsável já associado ao aluno ou adicione um novo
          </TypographyDS>

          <Controller
            name="guardian"
            rules={{
              validate: () =>
                guardiansByStudentID.length === 0 || selectedGuardianId !== NO_OPTION_SELECTED,
            }}
            control={control}
            render={({ fieldState: { error } }) => (
              <GuardianOptionsContainer>
                <FormControl component="fieldset" error={Boolean(error?.type)}>
                  <RadioGroup
                    aria-label="Utilizar o responsável já cadastrado"
                    name="shouldUseReenrollmentGuardian"
                    value={selectedGuardianId}
                    onChange={event => {
                      const guardianID = event.target.value
                      const foundGuardian = guardiansByStudentID.find(e => e.id === guardianID)
                      const guardian = foundGuardian ?? lastSearchedGuardian

                      reset({ ...formValues, guardian }, { keepErrors: true })
                      clearErrors('guardian')
                      setSelectedGuardianId(guardianID)
                      setSameGuardianSelected(Boolean(foundGuardian))
                    }}
                  >
                    {guardiansByStudentID.map(guardian => (
                      <FormControlLabel
                        key={guardian.id}
                        label={guardian.name}
                        value={guardian.id}
                        control={<Radio />}
                        style={{ marginBottom: 8 }}
                      />
                    ))}
                    <FormControlLabel
                      label="Outro responsável"
                      value={NEW_GUARDIAN_OPTION}
                      control={<Radio />}
                      style={{ marginBottom: 8 }}
                    />
                  </RadioGroup>
                  {Boolean(error?.type) && (
                    <FormHelperText>Por favor, selecione uma opção.</FormHelperText>
                  )}
                </FormControl>
              </GuardianOptionsContainer>
            )}
          />
        </div>
      )}

      {(!isReenrollment || selectedGuardianId === NEW_GUARDIAN_OPTION) && (
        <SearchContainer>
          <TypographyDS variation="bodyLarge">Digite o CPF do responsável financeiro:</TypographyDS>
          <Grid container spacing={2} style={{ marginTop: 16, marginBottom: 48 }}>
            <Grid item xs={4}>
              <Controller
                rules={{ required: true, validate: validateCpf }}
                name={GuardianFormNames.TAX_ID}
                control={control}
                render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
                  <FormControl fullWidth variant="outlined">
                    <NumberFormat
                      {...rest}
                      id={GuardianFormNames.TAX_ID}
                      type="tel"
                      value={value}
                      format="###.###.###-##"
                      label="CPF"
                      onValueChange={async currentValue => {
                        clearErrors(GuardianFormNames.TAX_ID)
                        if (!currentValue) {
                          return reset(
                            {
                              ...formValues,
                              guardian: { ...formValues.guardian, tax_id: '' },
                            },
                            { keepErrors: true }
                          )
                        }
                        onChange(currentValue.value)

                        if (currentValue.value.length === 11) {
                          const valid = await trigger(GuardianFormNames.TAX_ID)
                          if (valid) refetch()
                        }
                      }}
                      customInput={TextField}
                      variant="outlined"
                      error={Boolean(error?.type)}
                      helperText={taxIDHelperText(
                        Boolean(error?.type),
                        error?.message ?? '',
                        isLoadingByTaxID,
                        isFetchingByTaxID,
                        taxIDFound
                      )}
                    />
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
        </SearchContainer>
      )}

      {(sameGuardianSelected || !isLoadingByTaxID) && (
        <GuardianTextFields
          form={form}
          sameGuardianSelected={sameGuardianSelected}
          taxIDFound={taxIDFound}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
        />
      )}
    </>
  )
}

export default GuardianForm
