import { Button } from '@gravity/button'
import { usePostSubmitBatchMutation } from '../../hooks/usePostSubmitBatchMutation'
import { useParams } from 'react-router-dom'
import { useToast } from '@gravity/toast'
import { RefetchOptions, RefetchQueryFilters, QueryObserverResult } from '@tanstack/react-query'
import { OnboardingBatch } from '../../services/types/onboardingBatch'
import { OverrideBatchTrigger } from '../components/OverrideBatch/OverrideBatchTrigger'
import { pageNames, useEvents } from '../../hooks/eventContext'
import { Dialog } from '@gravity/dialog'
import { useState } from 'react'
import { Text } from '@gravity/text'
import styled from 'styled-components'
interface DashboardFooterProps {
  refetchGetOnboardingBatch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<OnboardingBatch, unknown>>
}

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const DashboardFooter = ({ refetchGetOnboardingBatch }: DashboardFooterProps) => {
  const { batchID } = useParams<{ batchID: string }>()
  const { toast } = useToast()
  const event = useEvents()
  const [open, setOpen] = useState(false)

  const {
    mutate: mutateStartMigration,
    isLoading: isStartMigrationLoading,
  } = usePostSubmitBatchMutation()

  const handleSubmit = async () => {
    event?.confirmSubmitBatchClicked()

    mutateStartMigration(
      { batchID },
      {
        onSuccess: async () => {
          setOpen(false)
          await refetchGetOnboardingBatch()
        },
        onError: error => {
          toast({
            title: 'Erro ao tentar migrar os dados',
            type: 'error',
          })
          console.error(error)
        },
      }
    )
  }

  const handleOpenChange = (open: boolean) => {
    if (open) {
      event?.submitBatchButtonClicked()
    }

    if (!open) {
      event?.cancelSubmitBatchClicked()
    }

    setOpen(open)
  }

  return (
    <>
      <OverrideBatchTrigger pageName={pageNames.BATCH_PAGE} />
      <Dialog
        backdrop
        size={2}
        open={open}
        onOpenChange={handleOpenChange}
        trigger={<Button>Migrar contratos</Button>}
        title="Ao iniciar migração, só vai ser possível fazer correções diretamente na Plataforma isaac"
        description={
          <DescriptionContainer>
            <Text>
              Parabéns, você está a um clique da etapa final! Agora é o momento de migrar todos os
              seus contratos para a Plataforma Isaac.
            </Text>
            <Text>
              Durante a migração, você poderá acompanhar o progresso, mas não será possível fazer
              correções nesse momento. Assim que a migração for concluída e os contratos aparecerem
              na Plataforma isaac, você poderá ajustar os dados diretamente por lá.
            </Text>
          </DescriptionContainer>
        }
        actionButton={
          <Button loading={isStartMigrationLoading} onClick={handleSubmit}>
            Iniciar migração
          </Button>
        }
        cancelButton={<Button variant="ghost">Cancelar</Button>}
      />
    </>
  )
}
