import qs from 'qs'
import { Box } from '@material-ui/core'
import { Drawer, ActionDrawerHeader, Divider, ButtonDocker, Button } from '@olaisaac/design-system'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { ChangesChip } from '@monorepo/report/components/ChangesChip'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

import { formatMicroCentsToReal, prettifyFullName } from '@/shared/utils'
import { TRANSACTIONS_IN_ORDER } from '@monorepo/report/constants/transactionsInOrder'
import { REPORT_PAGE_NAMES } from '@monorepo/report/constants/REPORT_PAGE_NAMES'

import { FinancialDetails, TransactionEventType } from '@monorepo/report/models/report'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

import { Content, SummaryItem } from '../EnrollmentSummaryDrawer/styles'

type EnrollmentReportTableRowDetailDrawerProps = {
  contractId: uuid
  contractReferenceYear?: string
  financialDetails?: FinancialDetails
  guardianId?: string
  isOpen: boolean
  onClose: () => void
  productId?: string
  productName: string
  receiptDate: Date
  studentId?: string
  studentName: string
}

export const EnrollmentReportTableRowDetailDrawer = ({
  guardianId,
  contractReferenceYear,
  studentId,
  studentName,
  productId,
  productName,
  financialDetails,
  receiptDate,
  isOpen,
  onClose,
}: EnrollmentReportTableRowDetailDrawerProps) => {
  const { school } = useSelectedSchool()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const handleNavigateToContract = () => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.ENROLLMENT_REPORT,
        name: EventDispatcherEvents.ENROLLMENT_PAYOUT_VIEW_CONTRACT,
        action: 'click',
        customProperties: {
          $page_name: REPORT_PAGE_NAMES.ENROLLMENT_PAYOUT_REPORT,
        },
      })

    const route = `/${school?.slug}/responsaveis/${guardianId}/faturas`

    const filtersQueryParams = qs.stringify({
      student_ids: studentId,
      product_ids: productId,
      reference_years: contractReferenceYear,
    })

    window.open(`${route}?${filtersQueryParams}`, '_blank')
  }

  const formatDate = (date: Date) => {
    const formatter = new Intl.DateTimeFormat('pt-BR')

    return formatter.format(date)
  }

  const convertEventsKeysToArray = financialDetails?.events
    ? (Object.keys(financialDetails?.events) as Array<TransactionEventType>)
    : []

  const values = {
    ...financialDetails?.events,
    total_base_amount: financialDetails?.total_base_amount,
    total_fee: -1 * financialDetails?.total_fee,
  }

  const studentNameFormatted = prettifyFullName(studentName)

  return (
    <Drawer open={isOpen}>
      <ActionDrawerHeader
        title={studentNameFormatted}
        subtitle={productName}
        extraContent={
          financialDetails?.events && (
            <Box display="flex" flexWrap="wrap" gridGap="0.5rem">
              <ChangesChip tags={[...convertEventsKeysToArray]} limit={15} />
            </Box>
          )
        }
        onClose={onClose}
      />

      <Content>
        <SummaryItem variation="tertiary">
          <p className="label">Data de recebimento</p>

          <span>{formatDate(receiptDate)}</span>
        </SummaryItem>

        <Divider variation="secondary" />

        {Object.keys(TRANSACTIONS_IN_ORDER).map(key => {
          const valueKey = key as keyof typeof values
          if (values[valueKey]) {
            return (
              <SummaryItem key={valueKey} variation="tertiary">
                <p className="label">
                  {TRANSACTIONS_IN_ORDER[valueKey as keyof typeof TRANSACTIONS_IN_ORDER].label}
                </p>

                <span>{formatMicroCentsToReal(values[valueKey])}</span>
              </SummaryItem>
            )
          }

          return null
        })}

        <SummaryItem variation="secondary">
          <p className="label">Valor do repasse</p>

          <span>{formatMicroCentsToReal(financialDetails?.total)}</span>
        </SummaryItem>
      </Content>

      <ButtonDocker>
        <Button fullWidth onClick={handleNavigateToContract}>
          Ir para contrato
        </Button>
      </ButtonDocker>
    </Drawer>
  )
}
