import { useEffect } from 'react'
import { Drawer } from '@olaisaac/design-system'

import { useGuardianInstallmentDrawerQuery } from '@/modules/guardians/InstallmentsDrawerContainer/hooks/useGuardianInstallmentDrawerQuery'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import {
  DrawerVariationsProps,
  drawerVariations,
} from '@/modules/guardians/InstallmentsDrawerContainer/variations'
import { InstallmentDrawerInfo } from '@/modules/guardians/InstallmentsDrawerContainer/types'
import { useDrawerType } from '@/modules/guardians/InstallmentsDrawerContainer/hooks/useDrawerType'
import { useInstallmentDrawerContext } from '@/modules/guardians/contexts/InstallmentDrawerContext'

import {
  InstallmentDrawerHeaderContainer,
  InstallmentDrawerContentWrapper,
  InstallmentDrawerFooterContainer,
} from './styles'
import { Installment } from '@/modules/guardians/GuardianDetails/types'

export interface InstallmentDrawerProps {
  installment: InstallmentDrawerInfo
  onCloseDrawer: () => void
}

export interface InstallmentsDrawerContainerV2 {
  guardianId: string
  onClose: () => void
  selectedInstallment: Installment | undefined
}

export const InstallmentsDrawerContainerV2 = ({
  guardianId,
  selectedInstallment,
  onClose,
}: InstallmentsDrawerContainerV2) => {
  const { school } = useSelectedSchool()

  const { selectedDrawer, setInstallmentDrawerInfo } = useInstallmentDrawerContext()

  const { installment, isInstallmentLoading } = useGuardianInstallmentDrawerQuery({
    urn: selectedInstallment?.urn ?? '',
    schoolId: school?.id ?? '',
    guardianId,
    status: selectedInstallment?.status ?? '',
  })

  const installmentDrawerInfo = (installment ?? { urn: null }) as InstallmentDrawerInfo

  const drawerType = useDrawerType({
    isLoading: isInstallmentLoading,
    installmentType: installmentDrawerInfo?.type,
    selectedDrawer,
  })

  const { header, content, footer, fullContent }: DrawerVariationsProps = drawerVariations[
    drawerType
  ]({
    installment: installmentDrawerInfo,
    onCloseDrawer: onClose,
  })

  useEffect(() => {
    setInstallmentDrawerInfo((installment ?? {}) as InstallmentDrawerInfo)
  }, [installment?.urn])

  return (
    <Drawer open onClose={onClose}>
      {header && <InstallmentDrawerHeaderContainer>{header}</InstallmentDrawerHeaderContainer>}
      {content && <InstallmentDrawerContentWrapper>{content}</InstallmentDrawerContentWrapper>}
      {footer && <InstallmentDrawerFooterContainer>{footer}</InstallmentDrawerFooterContainer>}
      {fullContent}
    </Drawer>
  )
}
