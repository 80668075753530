import type { ReactNode } from 'react'
import { Box } from '@material-ui/core'
import { ArrowBack, Help } from '@material-ui/icons'
import { Skeleton } from '@material-ui/lab'
import { Button } from '@gravity/button'
import { rgba } from 'polished'
import { useHistory } from 'react-router-dom'
import { colors } from '@gravity/tokens'
import {
  BoldActualizationPeriod,
  ContainerActualizationPeriod,
  ContainerTuitionHeader,
  LabelActualizationPeriod,
} from './styles'
import { formatDate } from '@/shared/utils'
import { Link } from '@gravity/link'
import { IsaacPayToggleGroup } from '@monorepo/report/components/IsaacPayToggleGroup'
import { Heading } from '@gravity/heading'

const BUTTON_COLOR = colors['colors-text-main-1']
const BUTTON_BACKGROUND_COLOR = rgba(BUTTON_COLOR, 0.14)

type PayoutTopHeaderProps = {
  backButtonLink: string
  isOnboardingDrawerOpen: boolean
  onRequestOnboarding: () => void
}
/**
 * Component for payout reuse top header
 *
 * @param props
 * @param props.backButtonLink Path to be redirected on click at back button
 * @param props.isOnboardingDrawerOpen Indicates if onboarding drawer is open
 * @param props.onRequestOnboarding Callback function called on request drawer open
 *
 */
export const PayoutTopHeader = ({
  backButtonLink,
  isOnboardingDrawerOpen,
  onRequestOnboarding,
}: PayoutTopHeaderProps) => {
  const { replace } = useHistory()
  return (
    <Box pt="1.25rem">
      <Box display="flex" justifyContent="space-between">
        <Button
          variant="ghost"
          iconStart={<ArrowBack />}
          size={1}
          onClick={() => replace(backButtonLink)}
        >
          Voltar
        </Button>

        <Button
          variant="ghost"
          size={1}
          iconStart={<Help />}
          style={
            isOnboardingDrawerOpen
              ? {
                  backgroundColor: BUTTON_BACKGROUND_COLOR,
                  color: BUTTON_COLOR,
                }
              : {}
          }
          onClick={onRequestOnboarding}
        >
          Ajuda
        </Button>
      </Box>
    </Box>
  )
}

type PayoutSubHeaderProps = {
  currentReportingPeriodView?: string
  defaultReportingPeriod?: string
  isHourIncluded?: boolean
  isLoading: boolean
  linkButtonText: string
  onChangeReportingPeriod?: (reportingPeriod: string) => void
  onClickAtLinkButton: () => void
  payoutReportPeriod: {
    endDate?: Date
    startDate?: Date
  }
  picker: ReactNode
  shouldShowToggle?: boolean
  title: string
}

/**
 * Component for payout report page header
 *
 * @param props
 * @param props.picker Custom component to be displayed as a picker
 * @param props.title Header title
 * @param props.isLoading Indicates if loading state should be displayed for the picker
 * @param props.shouldShowToggle Boolean value that indicates the rendering of calendar toggle
 * @param props.onChangeReportingPeriod Callback function called on change payout
 */
export const PayoutSubHeader = ({
  title,
  isLoading,
  picker,
  shouldShowToggle,
  currentReportingPeriodView,
  defaultReportingPeriod,
  onChangeReportingPeriod,
  payoutReportPeriod,
  isHourIncluded,
  linkButtonText,
  onClickAtLinkButton,
}: PayoutSubHeaderProps) => {
  const startDateTemplate = isHourIncluded ? 'D [de] MMMM[,] HH[h]mm' : 'D [de] MMMM'
  const endDateTemplate = isHourIncluded ? 'D [de] MMMM[,] HH[h]mm' : 'D [de] MMMM [de] YYYY'

  const formattedPayoutReportStartPeriodDate =
    payoutReportPeriod?.startDate && formatDate(payoutReportPeriod.startDate, startDateTemplate)

  const formattedPayoutReportEndPeriodDate =
    payoutReportPeriod?.endDate && formatDate(payoutReportPeriod.endDate, endDateTemplate)

  return (
    <ContainerTuitionHeader>
      <Box display="flex" alignItems="flex-end" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Heading variant="heading-h2-medium">{title}</Heading>

          {isLoading ? (
            <Box ml="1rem" mb="6px">
              <Skeleton
                variant="rect"
                width="11rem"
                height="1rem"
                style={{ borderRadius: '0.5rem' }}
                data-testid="skeleton"
              />
            </Box>
          ) : (
            <Box ml="1rem" mb="5px">
              {picker}
            </Box>
          )}
        </Box>

        {shouldShowToggle ? (
          isLoading ? (
            <Box ml="1rem" mb="6px">
              <Skeleton
                variant="rect"
                width="11rem"
                height="1rem"
                style={{ borderRadius: '0.5rem' }}
                data-testid="skeleton"
              />
            </Box>
          ) : (
            <Box display="flex" justifyContent="center">
              <IsaacPayToggleGroup
                value={currentReportingPeriodView ?? ''}
                defaultReportingPeriod={defaultReportingPeriod ?? ''}
                onChangeReportingPeriod={onChangeReportingPeriod ?? (() => undefined)}
              />
            </Box>
          )
        ) : null}
      </Box>
      <ContainerActualizationPeriod>
        <LabelActualizationPeriod variation="bodyLarge">
          Período de atualizações:&nbsp;
          <BoldActualizationPeriod>
            {`${formattedPayoutReportStartPeriodDate?.toLowerCase()} até ${formattedPayoutReportEndPeriodDate?.toLowerCase()}`}
          </BoldActualizationPeriod>
        </LabelActualizationPeriod>

        <Box>
          <Link size={1} onClick={onClickAtLinkButton}>
            {linkButtonText}
          </Link>
        </Box>
      </ContainerActualizationPeriod>
    </ContainerTuitionHeader>
  )
}
