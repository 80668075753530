import { Typography } from '@olaisaac/design-system'
import { Link } from '@gravity/link'
import styled from 'styled-components'
import { FC } from 'react'
import { CheckoutCombinableReceivable } from 'src/escolas/contexts/checkoutContext'
import { ReceivableStatuses } from 'src/shared/interfaces'
import { getReceivableStatus } from '../helpers/getReceivableStatus'
import { formatInstallmentTextCount } from '../helpers/formatInfoText'

export interface InformationTitleProps {
  handleClick: () => any
  receivables: CheckoutCombinableReceivable[]
  students: string[]
  totalAmount: number
}

export const Wrapper = styled.div`
  display: flex;
`

export const WrapperColumn = styled(Wrapper)`
  flex-direction: column;
  align-items: center;
  margin-top: 64px;
`

export const StyledLink = styled(Link)`
  margin-left: 6px;
`

const formatTitle = (students: string[]) => {
  const firstNameStudents = students.map(student => student.split(' ')[0])
  if (students.length === 1) {
    return `Pagamento das parcelas de ${firstNameStudents[0]}`
  } else if (students.length === 2) {
    return `Pagamento das parcelas de ${firstNameStudents[0]} e ${firstNameStudents[1]}`
  }

  const lastStudent = firstNameStudents.pop()

  return `Pagamento das parcelas de ${firstNameStudents.join(', ')} e ${lastStudent}`
}

const InformationTitle: FC<InformationTitleProps> = ({
  students,
  receivables,
  handleClick,
  totalAmount,
}) => {
  const statusCount = receivables.reduce(
    (acc, item) => {
      getReceivableStatus(item) === ReceivableStatuses.OVERDUE ? acc.overdue++ : acc.open++
      return acc
    },
    { overdue: 0, open: 0 }
  )

  return (
    <WrapperColumn>
      <Typography withoutMargin color="primary" variation="headlineDesktopSmall">
        {formatTitle(students)}
      </Typography>
      <Wrapper>
        <Typography color="secondary" variation="bodySmall">
          {formatInstallmentTextCount(statusCount.overdue, statusCount.open, totalAmount)}
        </Typography>
        <StyledLink onClick={handleClick}>Ver detalhes</StyledLink>
      </Wrapper>
    </WrapperColumn>
  )
}

export default InformationTitle
