import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import { Checkbox } from '@gravity/checkbox'
import { Callout } from '@gravity/callout'

import * as Styled from './styles'
import type { ContractSelectionSepProps } from './types'
import { SelectedContractCardsList } from '../SelectedContractCardsList'
import { Skeleton } from '@gravity/skeleton'

export const ContractSelectionSep = ({
  eligibleContracts,
  onSelectContract,
  isSelected,
  isLoading,
}: ContractSelectionSepProps) => {
  const selectedContractIds = eligibleContracts.filter(c => isSelected(c.id)).map(c => c.id)

  return (
    <Styled.Wrapper>
      <Heading variant="heading-h4-medium">
        Selecione os contratos que deseja trocar a titularidade
      </Heading>

      <Styled.CheckBoxWrapper>
        <Text variant="subtitle-medium">Qual contrato você deseja alterar a titularidade?</Text>
        {isLoading && (
          <>
            <Skeleton fullWidth height={24} />
            <Skeleton fullWidth height={24} />
            <Skeleton fullWidth height={24} />
            <Skeleton fullWidth height={24} />
          </>
        )}

        {eligibleContracts.map(c => (
          <Checkbox
            checked={isSelected(c.id)}
            key={c.id}
            size={1}
            text={`${c.product.name} - Aluno: ${c.student.name}`}
            onCheckedChange={() => onSelectContract(c)}
          />
        ))}
      </Styled.CheckBoxWrapper>

      <Callout text="A troca de titularidade do contrato será feita sempre a partir da primeira parcela em aberto" />

      <SelectedContractCardsList selectedContractIds={selectedContractIds} />
    </Styled.Wrapper>
  )
}
