import { useState } from 'react'
import { Box } from '@material-ui/core'
import dayjs from 'dayjs'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { ErrorState } from '../../components/ErrorState'
import { EnrollmentPayoutReportHeader } from './components/EnrollmentPayoutReportHeader'
import { PayoutReportContentMain } from '../../components/PayoutReportSubHeader'
import { EnrollmentPayoutReportOnboardingDrawer } from './components/EnrollmentPayoutReportOnboardingDrawer'
import { EnrollmentPayoutReportFilterDrawer } from './components/EnrollmentPayoutReportFilterDrawer'
import { EnrollmentSummaryDrawer } from './components/EnrollmentSummaryDrawer'
import { ReportPageTableHeader } from '@monorepo/report/components/ReportPageTableHeader'
import { EnrollmentReportTable } from './components/EnrollmentReportTable'
import { EnrollmentReportTableRowDetailDrawer } from './components/EnrollmentReportTableRowDetailDrawer'
import { EnrollmentPayoutReportFooter } from './components/EnrollmentPayoutReportFooter'

import { useDrawerWidth } from '@/shared/hooks/useDrawerWidth'
import { useEnrollmentPayoutReportData } from './hooks/useEnrollmentPayoutReportData'

import { formatNumberMicroCentsToReal } from '@/shared/utils/numberFormatters'
import { buildEnrollmentPayoutReportFilterOptions } from './utils/buildEnrollmentPayoutReportFilterOptions'

import { PAYOUT_REPORT_STATUS } from '../../constants/PAYOUT_REPORT_STATUS'
import { REPORT_PAGE_NAMES } from '../../constants/REPORT_PAGE_NAMES'

import type { FinancialTransactionProps } from '@monorepo/report/models/report'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'

import { Container, Content } from '../TuitionPayoutReport/styles'

type PageDrawers =
  | 'enrollment_onboarding'
  | 'enrollment_summary'
  | 'enrollment_filter'
  | 'student_detail'

/**
 * Enrollment payout report page component
 */
export const EnrollmentPayoutReport = () => {
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { drawerWidth } = useDrawerWidth()

  const [selectedInstallment, setSelectedInstallment] = useState<FinancialTransactionProps>()
  const [isPayoutOnboardingDrawerOpen, setIsPayoutOnboardingDrawerOpen] = useState(false)
  const [isPayoutSummaryDrawerOpen, setIsPayoutSummaryDrawerOpen] = useState(false)
  const [isPayoutFilterDrawerOpen, setIsPayoutFilterDrawerOpen] = useState(false)
  const [isStudentDetailsDrawerOpen, setIsStudentDetailsDrawerOpen] = useState(false)

  const {
    enrollmentPayoutReportList,
    isFetchingEnrollmentPayoutReportList,
    enrollmentPayoutReportDetails,
    isFetchingEnrollmentPayoutReportDetails,
    hasErrorOnFetch,
    refetch,
  } = useEnrollmentPayoutReportData()

  const drawers: Record<PageDrawers, React.Dispatch<React.SetStateAction<boolean>>> = {
    enrollment_onboarding: setIsPayoutOnboardingDrawerOpen,
    enrollment_summary: setIsPayoutSummaryDrawerOpen,
    enrollment_filter: setIsPayoutFilterDrawerOpen,
    student_detail: setIsStudentDetailsDrawerOpen,
  }

  const closeDrawers = () => {
    Object.entries(drawers).forEach(([, setDrawerVisibility]) => setDrawerVisibility(false))
  }

  const handleOpenDrawer = (name: PageDrawers) => {
    closeDrawers()

    drawers[name](true)
  }

  const isPayoutOpen =
    enrollmentPayoutReportDetails?.data.payout.status === PAYOUT_REPORT_STATUS.OPEN

  const includedFilters = buildEnrollmentPayoutReportFilterOptions(
    enrollmentPayoutReportDetails?.data.filters_included
  )

  const isTableEmpty = (enrollmentPayoutReportDetails?.pagination?.total ?? 0) === 0

  const hasOpenedDrawer =
    isPayoutOnboardingDrawerOpen ||
    isPayoutSummaryDrawerOpen ||
    isPayoutFilterDrawerOpen ||
    isStudentDetailsDrawerOpen

  const calculatedPayoutValue = (() => {
    const payoutValue =
      enrollmentPayoutReportDetails?.data.agregations.financial_details?.total ?? 0
    const negativePayoutValue =
      enrollmentPayoutReportDetails?.data.agregations.financial_details.events?.BALANCE_TRANSFER ??
      0

    return formatNumberMicroCentsToReal(payoutValue - negativePayoutValue)
  })()

  if (hasErrorOnFetch) {
    return (
      <ErrorState
        title="Repasse de matrículas"
        description="Ocorreu um erro inesperado ao carregar as informações do seu repasse. Tente novamente mais tarde."
        onRetry={refetch}
      />
    )
  }

  return (
    <Container isCollapsed={hasOpenedDrawer} drawerWidth={drawerWidth}>
      <Content>
        <EnrollmentPayoutReportHeader
          enrollmentPayoutReportList={enrollmentPayoutReportList}
          isLoadingEnrollmentPayoutReportList={isFetchingEnrollmentPayoutReportList}
          isOnboardingDrawerOpen={isPayoutOnboardingDrawerOpen}
          onRequestOnboarding={() => handleOpenDrawer('enrollment_onboarding')}
          onChangeEnrollmentPayout={closeDrawers}
          handleOpenDrawerSummary={() => {
            handleOpenDrawer('enrollment_summary')
          }}
        />

        <Box mt="2rem">
          <PayoutReportContentMain
            payoutValue={calculatedPayoutValue}
            isPayoutOpen={isPayoutOpen}
            isLoading={isFetchingEnrollmentPayoutReportDetails}
          />
        </Box>

        <Box mt="4rem" mb="2rem">
          {/* TODO: Move component to report module */}
          {/* TODO: USE REPLACE STRATEGY FOR NAME FILTER */}
          <ReportPageTableHeader
            filterOptions={includedFilters}
            reportType="enrollment"
            isFilterReportDrawerOpen={isPayoutFilterDrawerOpen}
            disableActions={isFetchingEnrollmentPayoutReportDetails || isTableEmpty}
            onRequestFilterDrawerOpen={() => handleOpenDrawer('enrollment_filter')}
          />
        </Box>

        {/* TODO: Move component to report module */}
        <EnrollmentReportTable
          isNewVersion
          isLoading={isFetchingEnrollmentPayoutReportDetails}
          transactions={enrollmentPayoutReportDetails?.data.financial_transactions ?? []}
          onRowClick={params => {
            const findInstallment = enrollmentPayoutReportDetails?.data.financial_transactions.find(
              installment => installment.referrals.installment_id === params.row.id
            )

            if (findInstallment) {
              setSelectedInstallment(findInstallment)
              handleOpenDrawer('student_detail')
            }

            isInitialized &&
              eventDispatcherClient.sendEvent({
                scope: EventDispatcherEventScopes.ENROLLMENT_REPORT,
                name: EventDispatcherEvents.ENROLLMENT_PAYOUT_REPORT_DETAILS,
                action: 'click',
                customProperties: {
                  $page_name: REPORT_PAGE_NAMES.ENROLLMENT_PAYOUT_REPORT,
                },
              })
          }}
        />
      </Content>

      <EnrollmentPayoutReportFooter
        enrollmentPayoutReportId={enrollmentPayoutReportDetails?.data.payout_id ?? ''}
        enrollmentPayoutReportStartDate={dayjs(
          enrollmentPayoutReportDetails?.data.started_at ?? undefined
        ).toDate()}
        enrollmentPayoutReportEndDate={dayjs(
          enrollmentPayoutReportDetails?.data.ended_at ?? undefined
        ).toDate()}
        totalItems={enrollmentPayoutReportDetails?.pagination?.total ?? 0}
        isLoading={isFetchingEnrollmentPayoutReportDetails}
      />

      <EnrollmentPayoutReportOnboardingDrawer
        isOpen={isPayoutOnboardingDrawerOpen}
        onClose={() => setIsPayoutOnboardingDrawerOpen(false)}
      />

      <EnrollmentPayoutReportFilterDrawer
        filterOptions={includedFilters}
        isOpen={isPayoutFilterDrawerOpen}
        onClose={() => setIsPayoutFilterDrawerOpen(false)}
      />

      {/* TODO: Move component to report module */}
      <EnrollmentSummaryDrawer
        financialDetails={enrollmentPayoutReportDetails?.data?.agregations.financial_details}
        payoutData={{
          payoutId: enrollmentPayoutReportDetails?.data.payout_id ?? '',
          payoutVersion: enrollmentPayoutReportDetails?.data.payout.version ?? 2,
          payoutBankTransfers: enrollmentPayoutReportDetails?.data.bank_transfers ?? [],
          enrollment_end_date: dayjs(
            enrollmentPayoutReportDetails?.data.ended_at ?? undefined
          ).toDate(),
        }}
        isPayoutOpen={false}
        isOpen={isPayoutSummaryDrawerOpen}
        onClose={() => setIsPayoutSummaryDrawerOpen(false)}
      />

      {/* TODO: Move component to report module */}
      <EnrollmentReportTableRowDetailDrawer
        guardianId={selectedInstallment?.referrals.guardian_id}
        contractReferenceYear={selectedInstallment?.referrals.contract_reference_year}
        contractId={selectedInstallment?.referrals.contract_id ?? ''}
        studentId={selectedInstallment?.referrals.student_id}
        studentName={selectedInstallment?.referrals.student_name ?? ''}
        productId={selectedInstallment?.referrals.product_id}
        productName={selectedInstallment?.referrals.product_name ?? ''}
        financialDetails={selectedInstallment?.financial_detail}
        receiptDate={dayjs(selectedInstallment?.referrals.last_receivable_paid_date).toDate()}
        isOpen={isStudentDetailsDrawerOpen}
        onClose={() => setIsStudentDetailsDrawerOpen(false)}
      />
    </Container>
  )
}
