import { useEffect } from 'react'
import { AxiosError } from 'axios'
import NumberFormat from 'react-number-format'
import { SubmitHandler, useForm } from 'react-hook-form'
import dayjs from 'dayjs'
import { zodResolver } from '@hookform/resolvers/zod'

import { Button } from '@gravity/button'
import { Callout } from '@gravity/callout'
import { Checkbox } from '@gravity/checkbox'
import { Dialog } from '@gravity/dialog'
import { TextField } from '@gravity/text-field'
import { useToast } from '@gravity/toast'

import { EditStudentForm, EditStudentSchema } from '@monorepo/students/models/editStudentSchema'

import { queryClient } from '@/shared/contexts/ReactQuery'
import { validateDate } from '@/shared/utils'
import { useMutateOnUpdateStudent } from '@monorepo/students/hooks/mutations/students'

import { CheckNoTaxID, Content, FieldsWrapper } from './styles'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventPageName } from '@monorepo/students/models/EventPageName.enum'
import { EventIdentifierName } from '@monorepo/students/models/EventIdentifierName.enum'

type EditStudentDialogProps = {
  birthDateParam: datestring
  onClose: () => void
  open: boolean
  studentId: string
  studentName: string
  taxID: string
}

export const EditStudentDialog = ({
  birthDateParam,
  studentName,
  taxID,
  open,
  studentId,
  onClose,
}: EditStudentDialogProps) => {
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const birthDateRaw = validateDate(birthDateParam)
  const { toast } = useToast()

  const defaultValues = {
    name: studentName ?? '',
    birthDate: birthDateRaw,
    taxID: taxID ?? '',
    noTaxID: false,
  }

  const {
    reset,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    watch,
  } = useForm<EditStudentForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(EditStudentSchema),
    defaultValues,
  })

  const { mutateAsync, isLoading } = useMutateOnUpdateStudent({
    onSuccess() {
      queryClient.invalidateQueries(['students', studentId, 'profile'])
      queryClient.invalidateQueries(['student-enrollment-cycle'])
      queryClient.invalidateQueries(['contracts', 'students'])

      toast({ type: 'success', title: 'Edição concluída com sucesso' })
      setValue('noTaxID', false)
      onClose()
    },

    onError(err: AxiosError) {
      if (err.response?.data?.errors[0]?.tag === 'DUPLICATED_KEY') {
        toast({
          type: 'error',
          title: 'Erro ao editar dados cadastrais',
          description: 'Existe outro aluno com os mesmos dados. Pesquise na página de Matrículas.',
        })
      } else {
        toast({ type: 'error', title: 'Erro ao editar dados cadastrais' })
      }
      reset(defaultValues)
      onClose()
    },
  })

  const noTaxID = watch('noTaxID')
  const taxIdValue = watch('taxID')

  const onSubmit: SubmitHandler<EditStudentForm> = async ({ name, birthDate, taxID }) => {
    if (isLoading) return

    await mutateAsync({
      id: studentId,
      student: {
        name,
        birth_date: dayjs(birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        tax_id: noTaxID ? '' : taxID,
      },
    })
  }

  const { errors } = formState

  const handleClose = () => {
    if (!isLoading) {
      if (isInitialized) {
        eventDispatcherClient.sendEvent({
          scope: EventDispatcherEventScopes.STUDENT_PAGE,
          name: EventDispatcherEvents.BUTTON_CLICKED,
          pageName: EventPageName.STUDENT_PAGE,
          identifierName: EventIdentifierName.GO_BACK_REGISTRATION_EDIT,
        })
      }

      onClose()
    }
  }
  const handleCheckNoTaxId = (checked: boolean) => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.STUDENT_PAGE,
        name: EventDispatcherEvents.CHECKBOX_SELECTED,
        pageName: EventPageName.STUDENT_PAGE,
        identifierName: EventIdentifierName.STUDENT_WITHOUT_CPF,
        customProperties: {
          $CPF: checked,
        },
      })
    }

    setValue('noTaxID', checked)
  }

  useEffect(() => {
    setValue('name', studentName)
    setValue('birthDate', birthDateRaw)
    setValue('taxID', taxID)
  }, [studentName, birthDateParam, taxID])

  const handleClickCalloutLink = () => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.STUDENT_PAGE,
        name: EventDispatcherEvents.LINK_CLICKED,
        pageName: EventPageName.STUDENT_PAGE,
        identifierName: EventIdentifierName.HELP_STUDENT_EDIT,
      })
    }
  }

  const handleSubmitForm = () => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.STUDENT_PAGE,
        name: EventDispatcherEvents.BUTTON_CLICKED,
        pageName: EventPageName.STUDENT_PAGE,
        identifierName: EventIdentifierName.TO_SAVE_REGISTRATION_EDIT,
      })
    }

    handleSubmit(onSubmit)()
  }

  const handleSendOpenDialogEvent = () => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.STUDENT_PAGE,
        name: EventDispatcherEvents.MODAL_VIEWED,
        pageName: EventPageName.STUDENT_PAGE,
        identifierName: EventIdentifierName.MODAL_STUDENT_EDIT,
      })
    }
  }

  return (
    <Dialog
      size={3}
      title="Editar cadastro"
      backdrop
      open={open}
      onOpenChange={open => {
        if (open) handleSendOpenDialogEvent()

        if (!open && !isLoading) {
          reset(defaultValues)
          handleClose()
        }
      }}
      cancelButton={
        <Button variant="ghost" disabled={isLoading}>
          Cancelar
        </Button>
      }
      actionButton={
        <Button
          variant="solid"
          type="submit"
          onClick={handleSubmitForm}
          disabled={
            'birthDate' in errors ||
            'name' in errors ||
            (!noTaxID && 'taxID' in errors) ||
            isLoading
          }
        >
          Salvar
        </Button>
      }
      content={
        <Content>
          <TextField
            size={3}
            fullWidth
            label="Nome"
            error={!!errors?.name?.message}
            errorMessage={errors?.name?.message}
            {...register('name')}
          />
          <FieldsWrapper>
            <NumberFormat
              style={{ flexGrow: 1, overflow: 'visible' }}
              id="student.birthDate"
              type="text"
              format="##/##/####"
              placeholder="DD/MM/AAAA"
              label="Data de nascimento"
              value={getValues('birthDate')}
              onValueChange={async currentValue => {
                setValue('birthDate', currentValue.formattedValue)
              }}
              customInput={TextField}
              size={3}
              variant="outlined"
              error={!!errors?.birthDate?.message}
              errorMessage={errors?.birthDate?.message}
              {...register('birthDate')}
            />
            <NumberFormat
              style={{ flexGrow: 1 }}
              disabled={noTaxID}
              id="student.tax_id"
              type="text"
              format="###.###.###-##"
              label="CPF"
              value={taxIdValue}
              onValueChange={async currentValue => {
                setValue('taxID', currentValue.value)
              }}
              customInput={TextField}
              size={3}
              variant="outlined"
              error={!noTaxID && !!errors?.taxID?.message}
              errorMessage={!noTaxID && errors?.taxID?.message}
              {...register('taxID')}
            />
            <CheckNoTaxID>
              <Checkbox
                disabled={taxID !== ''}
                size={2}
                text="Aluno não possui CPF"
                onCheckedChange={(checked: boolean) => {
                  handleCheckNoTaxId(checked)
                }}
                {...register('noTaxID')}
              />
            </CheckNoTaxID>
          </FieldsWrapper>
          <Callout
            linkLabel="Saiba mais"
            href="https://centraldeajuda.olaisaac.io/respons%C3%A1veis-financeiros/tudo-sobre-edicao-de-dados-cadastrais-de-alunos-"
            text="Ao informar o CPF do aluno, você garante maior segurança aos contratos da escola."
            onLinkClick={handleClickCalloutLink}
          />
        </Content>
      }
    />
  )
}
