import { useState } from 'react'
import BoxFooter from '../../BoxFooter/BoxFooter'
import { validateEditReason } from '@/shared/utils'
import type { EditReasonFieldProps } from '../types'
import * as S from '../styles'

const EditReasonField = ({ onSave, onCancel, value = '' }: EditReasonFieldProps) => {
  const [selectedValue, setSelectedValue] = useState(value)
  const [isDirty, setIsDirty] = useState(false)

  const handleValue = (value: any) => {
    setSelectedValue(value)
  }

  const isValid = validateEditReason(selectedValue)
  const showError = !isValid && isDirty
  return (
    <S.StyledBox>
      <S.StyledContent>
        <S.LargeStyledForm variant="outlined">
          <S.StyledTextArea
            value={selectedValue}
            id="edit_reason-input"
            name="edit_reason"
            aria-label="Motivo da edição"
            placeholder="Motivo da edição"
            size={3}
            fullWidth
            error={showError}
            onChange={e => {
              handleValue(e.target.value)
            }}
            onBlur={() => setIsDirty(true)}
            errorMessage={
              showError ? 'Deve ter entre 5 e 100 caracteres (somente letras e números)' : ''
            }
          />
        </S.LargeStyledForm>
      </S.StyledContent>
      <BoxFooter isValid={isValid} onCancel={onCancel} onSave={() => onSave(selectedValue)} />
    </S.StyledBox>
  )
}

export default EditReasonField
