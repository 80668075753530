import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGuardianContractsQuery } from './hooks/useGuardianContractsQuery'
import { InstallmentsProvider } from '@/shared/contexts/installmentsContext'
import { GuardianDetailsContractsTable } from './components/GuardianDetailsContractsTable'
import { ContractsDrawerContainer } from '../ContractsDrawerContainer/ContractsDrawerContainer'
import { useQuery } from '@/shared/hooks/useQuery'
import { GuardianDetailsContractsFilter } from './components/GuardianDetailsContractsFilter/GuardianDetailsContractsFilter'
import { useGuardianContractsQueryParams } from './hooks/useGuardianContractsQueryParams'
import { useGuardianFiltersOptionsQuery } from '../GuardianDetails/hooks/useGuardianFiltersOptionsQuery'
import { useQueryClient } from '@tanstack/react-query'
import Icon from '@mdi/react'
import { mdiHelpCircle } from '@mdi/js'
import { GlossaryButton } from './styles'
import { TypesOfContractGlossaryDrawer } from './components/TypesOfContractGlossaryDrawer'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { ContractDetailsDrawerState } from '@/escolas/components/contract/ContractDetailsDrawer/constants'
import { useGuardianContractsListQuery } from '../GuardianDetails/hooks/useGuardianContractsListQuery'
import { FiltersOptionsQuery } from '@/modules/guardians/services/types'

export const GuardianDetailsContractsTab = () => {
  const { school } = useSelectedSchool()
  const { guardianId } = useParams<{ guardianId: string }>()
  const { query, setOnQueryParam } = useQuery()
  const queryClient = useQueryClient()
  const { filters, updateFilters } = useGuardianContractsQueryParams()
  const [currentContractId, setCurrentContractId] = useState<uuid>()
  const [showContractDetailsDrawer, setShowContractDetailsDrawer] = useState(false)
  const [showTypesOfContractDrawer, setShowTypesOfContractDrawer] = useState(false)
  const [drawerState, setDrawerState] = useState<ContractDetailsDrawerState>(
    ContractDetailsDrawerState.DEFAULT
  )

  const handleOpenContractDetails = (contractId: uuid) => {
    setDrawerState(ContractDetailsDrawerState.DEFAULT)
    setCurrentContractId(contractId)
    setShowContractDetailsDrawer(true)
  }

  const handleOpenCancelContractDrawer = (contractId: uuid) => {
    handleOpenContractDetails(contractId)
    setDrawerState(ContractDetailsDrawerState.CANCEL_CONTRACT)
  }

  const handleChangeContractOwnership = (contractId: uuid) => {
    handleOpenContractDetails(contractId)
    setDrawerState(ContractDetailsDrawerState.CHANGE_CONTRACT_OWNERSHIP)
  }

  const handleGeneratePaymentStatement = (contractId: uuid) => {
    handleOpenContractDetails(contractId)
    setDrawerState(ContractDetailsDrawerState.PAYMENT_STATEMENT)
  }

  const schoolId = school?.id ?? ''

  const {
    contracts,
    refetchGuardianContracts,
    isFetchGuardianContractsLoading,
    isRefetchingGuardianContracts,
  } = useGuardianContractsQuery({
    filter: filters,
    guardianId,
    schoolId,
  })

  const {
    filterOptions,
    isFiltersOptionsFetched,
    refetchFiltersOptions,
  } = useGuardianFiltersOptionsQuery({
    guardianId,
    schoolId,
  })

  const {
    guardianContractsList,
    isFetchGuardianContractsListLoading,
  } = useGuardianContractsListQuery({
    guardianId,
    schoolId,
  })

  const shouldRefetch = Boolean(query.get('refetch'))

  useEffect(() => {
    if (shouldRefetch) {
      setOnQueryParam('', 'refetch', 'replace')
      refetchFiltersOptions()
      refetchGuardianContracts()
    }
  }, [shouldRefetch])

  const isLoadingContracts =
    isFetchGuardianContractsLoading ||
    isRefetchingGuardianContracts ||
    isFetchGuardianContractsListLoading

  const contractInfoButtonLabel = 'O que é situação do contrato?'

  return (
    <>
      <GuardianDetailsContractsFilter
        filters={filters}
        updateFilters={updateFilters}
        filtersOptions={filterOptions?.data as FiltersOptionsQuery}
        isFiltersOptionsFetched={isFiltersOptionsFetched}
        typeOfContractGlossaryButton={
          <GlossaryButton
            variant="ghost"
            title={contractInfoButtonLabel}
            onClick={() => setShowTypesOfContractDrawer(true)}
          >
            <Icon path={mdiHelpCircle} size="1.25rem" style={{ marginBottom: 2, marginTop: 2 }} />
            {contractInfoButtonLabel}
          </GlossaryButton>
        }
      />
      <GuardianDetailsContractsTable
        contracts={contracts?.data ?? []}
        contractsData={guardianContractsList ?? []}
        isLoading={isLoadingContracts}
        openContractDetails={contractId => handleOpenContractDetails(contractId)}
        openCancelContractDrawer={contractId => handleOpenCancelContractDrawer(contractId)}
        onChangeContractOwnership={contractId => handleChangeContractOwnership(contractId)}
        onGeneratePaymentStatement={contractId => handleGeneratePaymentStatement(contractId)}
      />
      {showContractDetailsDrawer && currentContractId && (
        <InstallmentsProvider>
          <ContractsDrawerContainer
            state={drawerState}
            contractId={currentContractId}
            refetchGuardianContracts={() => {
              queryClient.invalidateQueries(['guardian-legacy-contracts'])
              setOnQueryParam('true', 'refetch', 'replace')

              refetchGuardianContracts()
            }}
            onClose={() => setShowContractDetailsDrawer(false)}
          />
        </InstallmentsProvider>
      )}
      <TypesOfContractGlossaryDrawer
        open={showTypesOfContractDrawer}
        onClose={() => setShowTypesOfContractDrawer(false)}
      />
    </>
  )
}
