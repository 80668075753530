import { useEffect, useMemo, useState } from 'react'
import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import { spacing } from '@gravity/tokens'
import { useForm } from 'react-hook-form'
import { Callout } from '@gravity/callout'
import { RemovableChip } from '@gravity/chip'

import { ProductItem, ProductStudentsRequest } from '@monorepo/enrollment/services/campaigns/types'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useFetchProductsByReferenceYear } from '@monorepo/enrollment/hooks/queries/useFetchProductsByReferenceYear'
import { StepFooter } from '@monorepo/enrollment/components/Stepper/components/StepFooter'
import { useCampaignContext } from '@monorepo/enrollment/contexts/CampaignContext'
import { useFetchStudentsByProduct } from '@monorepo/enrollment/hooks/queries/useFetchStudentsByProduct'
import { usePagination } from '@/shared/hooks/usePagination'
import { StepPageProps } from '@monorepo/enrollment/components/Stepper/types'
import { sortProducts } from '@monorepo/enrollment/utils/sortProducts'
import { useEnrollmentMixpanelEvents } from '@monorepo/enrollment/hooks/useEnrollmentMixpanelEvents'

import { SelectAutocomplete } from './components/SelectAutocomplete'
import { ToggleButton } from './components/ToggleButton'
import { StudentsTable } from './components/StudentsTable'
import { RowActionTypes } from './components/StudentsTable/types'
import * as Styled from './styles'
import { FormProps } from './types'

export const StudentsStep = ({ onFinish, enrollmentCycle }: StepPageProps) => {
  const { school } = useSelectedSchool()
  const {
    conditions,
    chosenProducts,
    updateChosenProducts,
    nonParticipatingStudentsIds,
    updateNonParticipatingStudentsIds,
    updateTotalParticipatingStudents,
    totalParticipatingStudents,
  } = useCampaignContext()

  const submitBtnDisabled = totalParticipatingStudents <= 0
  const previousReferenceYear = enrollmentCycle.reference_year - 1
  const { data: response } = useFetchProductsByReferenceYear(
    {
      referenceYears: [previousReferenceYear],
      schoolId: school?.id ?? '',
    },
    { enabled: !!school }
  )

  const sortedProducts = sortProducts(!response?.data ? [] : response.data)

  const [selectedProduct, setSelectedProduct] = useState<ProductItem | null>(null)

  const { sendPageViewEvent, sendButtonClickEvent } = useEnrollmentMixpanelEvents()

  const currentProductList = useMemo(() => {
    if (!response?.data) return []

    const selectedProductsSet = new Set(chosenProducts)
    return sortedProducts.filter(product => !selectedProductsSet.has(product))
  }, [response, chosenProducts])

  const handleSelectProduct = (product: ProductItem | null) => {
    setSelectedProduct(product)
  }

  const handleAddProduct = () => {
    if (selectedProduct && !chosenProducts.includes(selectedProduct)) {
      updateChosenProducts([...chosenProducts, selectedProduct])
      setSelectedProduct(null)
    }
  }

  const handleRemoveProduct = (productId: string) => {
    const product = chosenProducts.find(p => p.id === productId)
    if (!product) return

    updateChosenProducts(chosenProducts.filter(p => p.id !== productId))
    setSelectedProduct(null)
  }

  const stepDescription = `Agora, escolha os alunos para sua campanha. Você pode adicioná-los a partir dos produtos nos quais estão matriculados em ${previousReferenceYear}.`
  const alertMessage = `Alunos não rematriculáveis podem demonstrar interesse na campanha e visualizar as condições de matrícula, porém precisam regularizar suas dívidas para terem sua parcela de pré-matrícula liberada. Além disso, alunos que já estejam participando de outra campanha para o mesmo produto em ${enrollmentCycle.reference_year} não serão incluídos na campanha.`

  const [showParticipatingStudents, setShowParticipatingStudents] = useState(true)

  const { pagination, updatePaginationValue, updatePagination } = usePagination(1, 10)
  const resetPagination = () => {
    updatePagination({ page: 1, itemsPerPage: 10 })
  }

  const handleUpdateTotalParticipatingStudents = (
    totalParticipating: number,
    totalNonParticipating: number
  ) => {
    if (showParticipatingStudents) {
      const total = totalParticipating - totalNonParticipating
      updateTotalParticipatingStudents(totalParticipating > 0 ? total : 0)
    }
  }

  const fetchStudentsParams: ProductStudentsRequest = {
    inProductIds: chosenProducts.map(product => product.id),
    isAbleCampaign: showParticipatingStudents,
    notProductId: conditions?.product?.id ?? '',
    referenceYear: enrollmentCycle.reference_year,
    pagination: {
      page: pagination.page,
      per_page: pagination.itemsPerPage,
    },
  }
  const { data, isSuccess, isLoading, refetch } = useFetchStudentsByProduct(fetchStudentsParams)
  const students = data?.data?.students ?? []
  const totalStudents = data?.pagination?.total ?? 0

  const tableRows = useMemo(() => {
    return students.map(student => ({
      columns: [
        student.student_name,
        student.main_guardian?.name,
        student.product_name,
        student.student_status,
      ],
      key: student.student_id,
    }))
  }, [students])

  useEffect(() => {
    handleUpdateTotalParticipatingStudents(totalStudents, nonParticipatingStudentsIds.length)
  }, [totalStudents, nonParticipatingStudentsIds])

  const handleShowParticipatingStudentsChange = (value: string) => {
    setShowParticipatingStudents(value === 'included')
    resetPagination()
  }

  useEffect(() => {
    if (chosenProducts.length > 0) {
      refetch()
    }
  }, [showParticipatingStudents, chosenProducts])

  useEffect(() => {
    sendPageViewEvent('ALUNOS_CAMPANHA')
  }, [])

  const handleSubmit = () => {
    sendButtonClickEvent('continuar_alunos')
    onFinish()
  }

  const handleActionRow = (studentId: string, action: string) => {
    if (action === RowActionTypes.REMOVE) {
      updateNonParticipatingStudentsIds([...nonParticipatingStudentsIds, studentId])
      sendButtonClickEvent('excluir_alunos')
    } else if (action === RowActionTypes.UNDO) {
      sendButtonClickEvent('desfazer_exclusao')
      updateNonParticipatingStudentsIds(nonParticipatingStudentsIds.filter(id => id !== studentId))
    }
  }

  const form = useForm<FormProps>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    defaultValues: {
      option: null,
    },
  })

  return (
    <Styled.Form onSubmit={form.handleSubmit(handleSubmit)}>
      <Heading variant="heading-h3-medium">Alunos</Heading>
      <div style={{ marginTop: spacing[4], marginBottom: spacing[8] }}>
        <Text variant="body-1-regular">{stepDescription}</Text>
        <div style={{ marginTop: spacing[4] }} />
        <Callout text={alertMessage} />
        <div style={{ marginTop: spacing[8] }}>
          <Text variant="subtitle-medium">Quais produtos de origem?</Text>
          <SelectAutocomplete
            form={form}
            referenceYear={previousReferenceYear}
            options={currentProductList}
            onOptionChange={handleSelectProduct}
            onAddProductAction={handleAddProduct}
          />
        </div>
        <Styled.ChipsWrapper>
          {chosenProducts?.map((product, index) => {
            return (
              <Styled.ChipContainer key={index}>
                <RemovableChip
                  text={product.name}
                  value={product.id}
                  onRemove={handleRemoveProduct}
                />
              </Styled.ChipContainer>
            )
          })}
        </Styled.ChipsWrapper>
        {chosenProducts.length > 0 && (
          <div>
            <div style={{ marginTop: spacing[8] }}>
              <ToggleButton onChange={value => handleShowParticipatingStudentsChange(value)} />
            </div>
            <div style={{ marginTop: spacing[8] }}>
              <StudentsTable
                rows={isSuccess ? tableRows : []}
                isLoading={isLoading}
                disabledRows={nonParticipatingStudentsIds}
                disableActions={!showParticipatingStudents}
                onActionRow={(key, action) => handleActionRow(key, action)}
                pagination={pagination}
                totalStudents={isSuccess ? totalStudents : 0}
                updatePaginationValue={updatePaginationValue}
              />
            </div>
          </div>
        )}
      </div>
      <StepFooter
        disabled={submitBtnDisabled}
        tooltipMessage={
          submitBtnDisabled ? 'Adicione um produto com alunos incluídos para continuar.' : ''
        }
      />
    </Styled.Form>
  )
}
