import { Heading } from '@gravity/heading'
import { Container, StyledCard } from './styled'
import { ContainerCardsProps } from './types'

export const ContainerCards = ({ cards, title }: ContainerCardsProps) => {
  return (
    <StyledCard>
      <Heading variant="heading-h2-medium">{title}</Heading>
      <Container>
        {cards.map(card => {
          return card
        })}
      </Container>
    </StyledCard>
  )
}
