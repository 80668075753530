import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Button } from '@gravity/button'
import Box from '@material-ui/core/Box'
import { DialogProps, Dialog } from '@gravity/dialog'
import Typography from '@material-ui/core/Typography'
import booksAndApple from '@/shared/assets/booksAndApple.png'
import meditating from '@/shared/assets/meditating.png'
import { useApiClient } from '@/shared/hooks'

const DEFAULT_MESSAGE = 'Por favor, tente novamente ou entre em contato com a equipe responsável.'

const StyledImage = styled.img`
  text-align: center;
  width: 50%;
  padding: ${({ theme }) => theme.spacing(4)}px 0px;
`

const StyledPaddingBox = styled(Box)`
  padding-top: 20px;
`

const TextAndImageContent = ({
  imageSrc,
  message,
  secondaryMessage,
}: {
  imageSrc: string
  message: string
  secondaryMessage?: React.ReactNode
}) => (
  <>
    <Box display="flex" justifyContent="center" textAlign="center">
      <Typography variant="body2">{message}</Typography>
    </Box>
    {secondaryMessage ? (
      <StyledPaddingBox display="flex" justifyContent="center" textAlign="center">
        {secondaryMessage}
      </StyledPaddingBox>
    ) : null}
    <Box display="flex" justifyContent="center">
      <StyledImage src={imageSrc} alt="" />
    </Box>
  </>
)

export const FailureFeedbackContent = ({ message = DEFAULT_MESSAGE }: { message?: string }) => {
  const { correlationId } = useApiClient()

  const secondaryMessage = correlationId ? (
    <Typography variant="body2">
      Se o erro persistir, informe este código ao suporte:{' '}
      <strong>#{correlationId.split('-')[0]}</strong>
    </Typography>
  ) : null
  return (
    <TextAndImageContent
      message={message}
      imageSrc={booksAndApple}
      secondaryMessage={secondaryMessage}
    />
  )
}

export type ConfirmationDialogProps = {
  backButtonLabel?: string
  buttonLabel?: string
  children?: ReactNode
  isConfirmDisabled?: () => boolean
  isVisible: boolean
  onClose: () => void
  shortIds?: string[]
  size?: DialogProps['size']
  submitHandler: () => void
  title?: string
}

const ConfirmationDialog = ({
  buttonLabel = 'Ok, entendi!',
  backButtonLabel,
  children,
  isVisible,
  onClose,
  submitHandler,
  title = '',
  isConfirmDisabled,
  size,
}: ConfirmationDialogProps) => {
  const handleDialogOpenChange = (open: boolean) => {
    if (!open && isVisible) onClose()
  }

  return (
    <Dialog
      open={isVisible}
      backdrop
      size={size}
      title={title}
      onOpenChange={handleDialogOpenChange}
      content={<div style={{ marginBottom: '2rem' }}>{children}</div>}
      cancelButton={
        backButtonLabel && (
          <Button variant="ghost" onClick={onClose}>
            {backButtonLabel}
          </Button>
        )
      }
      actionButton={
        <Button onClick={submitHandler} disabled={isConfirmDisabled?.()}>
          {buttonLabel}
        </Button>
      }
    />
  )
}

export default ConfirmationDialog

export const failureFeedbackTitle = 'Aconteceu um erro inesperado'

export const FailureFeedbackDialog = ({
  title = failureFeedbackTitle,
  ...rest
}: ConfirmationDialogProps) => (
  <ConfirmationDialog title={title} {...rest}>
    {rest.children ?? <FailureFeedbackContent />}
  </ConfirmationDialog>
)

export const SuccessFeedbackContent = ({
  message = 'Ação realizada com sucesso.',
}: {
  message?: string
}) => <TextAndImageContent message={message} imageSrc={meditating} />

export const successFeedbackTitle = 'Sucesso!'

export const SuccessFeedbackDialog = ({
  title = successFeedbackTitle,
  ...rest
}: ConfirmationDialogProps) => (
  <ConfirmationDialog title={title} {...rest}>
    {rest.children ?? <SuccessFeedbackContent />}
  </ConfirmationDialog>
)
