import styled from 'styled-components'

import theme from '@/shared/theme'

import { MAX_STEP_WIDTH } from '../../constants'

const { colors, spacing } = theme.primitiveTokens

export const Container = styled.div`
  max-width: ${MAX_STEP_WIDTH}px;
  margin: 0 auto;
`

export const Wrapper = styled.div`
  position: fixed;
  left: 96px;
  bottom: 0px;
  right: 0px;
  padding: ${spacing[4]} ${spacing[8]};
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: ${colors.gray[10]};
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
`

export const ContentWrapper = styled.div`
  position: absolute;
  right: ${spacing[7]};
`
