import { useEffect, useMemo, useState } from 'react'
import { Box } from '@material-ui/core'
import {
  Drawer,
  ActionDrawerHeader,
  ButtonDocker,
  Button,
  Search,
  Typography,
} from '@olaisaac/design-system'
import { debounce } from 'throttle-debounce'
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form'

import { CollapsibleFilterGroup } from '@/shared/components/CollapsibleFilterGroup'

import { useContractsFilter } from 'src/escolas/hooks'

import { ContractStatus } from 'src/shared/interfaces'
import { GuardiansContractsFilterType } from 'src/escolas/hooks/useContractsFilter/types'
import { OptionType } from '@/shared/components/CollapsibleFilterGroup/types'

import { normalizeText } from 'src/shared/utils'

export type GuardianContractsFilterDrawerProps = {
  isOpen: boolean
  onClose: () => void
}

const FILTER_OPTIONS: OptionType[] = [
  { label: 'Ativo', value: ContractStatus.OPEN },
  { label: 'Inativo', value: ContractStatus.CANCELED },
]

/**
 * Drawer component for contracts guardians view filters
 *
 * @param props
 * @param props.isOpen Indicates if drawer should be opened
 * @param props.onClose Callback called on request drawer close
 */
export const GuardianContractsFilterDrawer = ({
  isOpen,
  onClose,
}: GuardianContractsFilterDrawerProps) => {
  const { guardiansFilter, updateFilter, clearFilter } = useContractsFilter()

  const form = useForm<GuardiansContractsFilterType>({
    defaultValues: {
      contractStatus: [],
    },
  })

  const { handleSubmit, setValue, reset, watch, getValues } = form

  const [searchOptionsField, setSearchOptionsField] = useState('')
  const [isFilterApplied, setIsFilterApplied] = useState(true)

  useEffect(() => setValue('contractStatus', guardiansFilter.contractStatus), [isOpen])

  useEffect(() => {
    const subscription = watch(() => setIsFilterApplied(false))

    return () => subscription.unsubscribe()
  }, [watch])

  const handleApplyFilters: SubmitHandler<GuardiansContractsFilterType> = ({ contractStatus }) => {
    setIsFilterApplied(true)
    updateFilter('guardians', { contractStatus })
    onClose()
  }

  const handleClearFilter = () => {
    reset()
    setIsFilterApplied(true)
    clearFilter('guardians')
  }

  const [options, optionsQuantity] = useMemo(() => {
    const options = FILTER_OPTIONS.filter(({ label }) => {
      const normalizedLabel = normalizeText(label)
      const normalizedText = normalizeText(searchOptionsField)

      return normalizedLabel.includes(normalizedText)
    })

    return [options, options.length]
  }, [searchOptionsField])

  return (
    <FormProvider {...form}>
      <Drawer open={isOpen}>
        <ActionDrawerHeader title="Filtrar" onClose={onClose} />

        <Search
          placeholder="Buscar filtros"
          onChange={debounce(500, (value: string) => setSearchOptionsField(value))}
          onClear={() => setSearchOptionsField('')}
        />

        {optionsQuantity === 0 ? (
          <Box
            display="flex"
            flex="1"
            justifyContent="center"
            alignItems="center"
            padding="1.5rem 1.5rem"
          >
            <Typography variation="bodySmall" color="secondary" withoutMargin>
              Nenhum resultado encontrado
            </Typography>
          </Box>
        ) : (
          <Box
            display="flex"
            flex="1"
            flexDirection="column"
            padding="1.5rem 1.5rem"
            overflow="auto"
          >
            <Box mb="3rem">
              <CollapsibleFilterGroup
                label="Status do contrato"
                filterName="contractStatus"
                options={options}
                totalOptions={optionsQuantity}
                searchText={searchOptionsField}
                onClearFilter={() => setValue('contractStatus', [])}
              />
            </Box>
          </Box>
        )}

        <ButtonDocker>
          <Button fullWidth disabled={isFilterApplied} onClick={handleSubmit(handleApplyFilters)}>
            Filtrar
          </Button>

          <Button
            fullWidth
            variation="ghost"
            disabled={getValues('contractStatus').length === 0}
            onClick={handleClearFilter}
          >
            Limpar tudo
          </Button>
        </ButtonDocker>
      </Drawer>
    </FormProvider>
  )
}
