import { useState, useMemo } from 'react'
import { debounce } from 'throttle-debounce'
import { Controller, useController } from 'react-hook-form'
import type { UseFormReturn } from 'react-hook-form'
import type { ProductItem } from '@monorepo/enrollment/services/campaigns/types'
import Icon from '@mdi/react'
import { mdiPlus } from '@mdi/js'
import * as Styled from './styles'
import type { Option } from '@gravity/combobox'
import { FormProps } from '../../types'

export type SelectAutocompleteProps = {
  form: UseFormReturn<FormProps>
  onAddProductAction: () => void
  onOptionChange: (option: ProductItem | null) => void
  options: ProductItem[]
  referenceYear: number
}

const normalizeString = (str: string): string => {
  return str
    .toLowerCase()
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
}

export const SelectAutocomplete = ({
  form,
  referenceYear,
  options: initialOptions,
  onOptionChange,
  onAddProductAction,
}: SelectAutocompleteProps) => {
  const [isSearchingOptions, setIsSearchingOptions] = useState<boolean>(false)
  const [searchTerm, setSearchTerm] = useState('')
  const { field } = useController({
    name: 'option',
    control: form.control,
    defaultValue: null,
  })

  const noOptionsText = 'Nenhum produto com esse nome foi encontrado'

  const handleOptionChange = (selectedOption: Option | null) => {
    if (!selectedOption) {
      resetField()
      return
    }

    const option: Option = {
      label: selectedOption.label,
      value: selectedOption.value,
    }

    form.setValue('option', option)

    const productItem = initialOptions.find(product => product.id === selectedOption.value)
    onOptionChange(productItem || null)
  }

  const debouncedSearch = debounce(500, (newSearchTerm: string) => {
    setSearchTerm(newSearchTerm)
    setIsSearchingOptions(false)
  })

  const filteredOptions = useMemo(() => {
    return searchTerm
      ? initialOptions.filter(option =>
          normalizeString(option.name).includes(normalizeString(searchTerm))
        )
      : initialOptions
  }, [initialOptions, searchTerm])

  const handleSearch = (newSearchTerm: string) => {
    setIsSearchingOptions(true)
    debouncedSearch(newSearchTerm)
  }

  const formValues = form.getValues()

  const resetField = () => {
    form.reset({ ...formValues, option: null }, { keepErrors: true })
  }

  const handleOptionAction = () => {
    resetField()
    onAddProductAction()
  }
  return (
    <Controller
      control={form.control}
      name="option"
      render={() => (
        <Styled.SelectProductRow>
          <Styled.Combobox
            size={3}
            value={(field.value as Option) || null}
            options={filteredOptions.map(option => ({
              label: option.name,
              value: option.id,
            }))}
            onChangeValue={handleOptionChange}
            onSearch={handleSearch}
            placeholder={`Selecione ou procure um produto ${referenceYear}`}
            emptyPlaceholder={noOptionsText}
            isLoadingOptions={isSearchingOptions}
          />
          <Styled.Button
            type="button"
            onClick={handleOptionAction}
            variant="outline"
            iconStart={<Icon path={mdiPlus} />}
          >
            Adicionar
          </Styled.Button>
        </Styled.SelectProductRow>
      )}
    />
  )
}
