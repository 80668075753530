import styled, { css, DefaultTheme } from 'styled-components'

import { StepItemProps } from './types'

export const Wrapper = styled.button`
  display: flex;
  flex-direction: column;
  width: 100%;

  outline: inherit;
  border: none;
  background: none;

  &[aria-disabled='false'] {
    cursor: pointer;
    :hover {
      opacity: 0.7;
    }
  }
`

const getStepAccentColor = (
  theme: DefaultTheme,
  status: StepItemProps['status'],
  isActiveStep: boolean
) => {
  const colors = theme.gravity.colors
  if (status === 'error') return colors['colors-text-semantic-colors-error-1']
  if (isActiveStep || status === 'completed') return colors['colors-interaction-primary-1']
  return colors['colors-background-neutral-3']
}

export const ProgressBarWrapper = styled.div`
  height: 4px;
  width: 100%;
  background-color: ${({ theme }) => theme.gravity.colors['colors-background-neutral-3']};
  border-radius: 2px;
`

interface ProgressBarProps {
  isActiveStep: boolean
  showHalfProgressBar: boolean
  status: StepItemProps['status']
}
export const ProgressBar = styled.div<ProgressBarProps>`
  height: 100%;
  width: 100%;
  background-color: ${({ theme, status, isActiveStep }) =>
    getStepAccentColor(theme, status, isActiveStep)};
  border-radius: inherit;
  ${props =>
    props.showHalfProgressBar &&
    css`
      width: 50%;
    `}
`

interface TitleWrapperProps {
  isActiveStep: boolean
  status: StepItemProps['status']
}
export const TitleWrapper = styled.div<TitleWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: ${({ theme }) => theme.gravity.spacing[2]};
  gap: ${({ theme }) => theme.gravity.spacing[2]};
  border-radius: 2px;
  span {
    color: ${({ theme, status, isActiveStep }) => getStepAccentColor(theme, status, isActiveStep)};
  }
`
