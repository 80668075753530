import { RadioGroup } from '@gravity/radio'
import styled from 'styled-components'

export const Card = styled.div`
  display: flex;
  padding: 24px 32px 40px 34px;
  flex-direction: column;
`

export const RadioGroupStyled = styled(RadioGroup)`
  border-radius: 8px;
  gap: 0;
  margin-bottom: 12px;
  border: 1px solid ${({ theme }) => theme.primitiveTokens.colors.gray[10]};
  &:last-child ${Card} {
    border-bottom: 0;
  }
`
export const Container = styled.div`
  display: flex;
  margin-bottom: 136px;
  flex-direction: column;
  margin-top: 28px;
`
export const Title = styled.div`
  margin-top: 28px;
  margin-bottom: 12px;
`
export const FullWidthDiv = styled.div`
  width: 100%;
`
