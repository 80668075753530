export enum EventIdentifierName {
  ADD_NEW_CONTRACT = 'adicionar_contrato_aluno',
  CARD_GUARDIAN = 'card_de_responsavel_do_aluno',
  CARD_GUARDIAN_STUDENT = 'card_de_responsavel_do_aluno',
  CLICK_NAVIGATION = 'clique_menu_flutuante',
  CONTRACTS_FILTER = 'filtro_contratos',
  CONTRACTS_TABLE_ACTIONS = 'acoes_linha_contratos',
  CONTRACTS_TABLE_ROW = 'linha_de_contratos',
  EDIT_STUDENT_REGISTRATION = 'editar_cadastro_aluno',
  GO_BACK_REGISTRATION_EDIT = 'voltar_edicao_de_cadastro',
  HELP_STUDENT_EDIT = 'ajuda_edicao_de_cadastro',
  MODAL_STUDENT_EDIT = 'modal_edicao_de_cadastro',
  MODAL_STUDENT_GUARDIANS = 'modal_responsaveis_do_aluno',
  RETRY_SECTION = 'recarregar_secao',
  RETRY_STUDENT_STATUS = 'recarregar_status_aluno',
  STUDENT_DATA_MASK = 'mascara_dados_aluno',
  STUDENT_WITHOUT_CPF = 'aluno_sem_cpf',
  TO_PAY_BUTTON = 'pagar_aluno',
  TO_SAVE_CONTRACTS_FILTER = 'salvar_filtro_contratos',
  TO_SAVE_REGISTRATION_EDIT = 'voltar_edicao_de_cadastro',
  VIEW_STUDENT_PAGE = 'ver_pagina_de_aluno',
  VIEW_STUDENT_PENDING = 'ver_pendencias_aluno',
}
