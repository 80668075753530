import { useSchoolEnrollmentCycles } from '@/shared/hooks/useSchoolEnrollmentCycles'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks/useUnleashFlag'

// TODO: Evaluate migrating this hook to the `shared` package during the `guardians` module migration.
const useAddContractEnabled = (): boolean => {
  const isIntegratedSchool = useUnleashFlag(UnleashFlags.IS_INTEGRATED_SCHOOL)
  const isFeatureFlagFilterEnrollmentCycleEnabled = useUnleashFlag(
    UnleashFlags.MAT_843_ENABLE_ENROLLMENT_CYCLE_FILTER
  )

  if (isFeatureFlagFilterEnrollmentCycleEnabled) {
    const { hasAnyEnabledCycle } = useSchoolEnrollmentCycles()
    return !isIntegratedSchool && hasAnyEnabledCycle
  }

  return !isIntegratedSchool
}

export default useAddContractEnabled
