import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { IrregularStudentStatus, irregularStatusTranslationDict } from '../types'
import { IrregularStudentItem } from '@monorepo/enrollment/services/types'

export const useIrregularStudentsEvents = () => {
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const sendPageViewEvent = () => {
    if (!isInitialized) return

    eventDispatcherClient.sendEvent({
      scope: EventDispatcherEventScopes.IRREGULARITY_ENROLLMENTS_PAGE,
      name: EventDispatcherEvents.PAGE_VIEWED,
      action: 'page_view',
      entity: 'pendencias',
    })
  }

  const sendClickAccordionEvent = (irregularStudentItem: IrregularStudentItem) => {
    if (!isInitialized) return

    eventDispatcherClient.sendEvent({
      scope: EventDispatcherEventScopes.IRREGULARITY_ENROLLMENTS_PAGE,
      name: EventDispatcherEvents.DROPDOWN_CLICKED,
      action: 'click',
      entity: 'pendencias',
      customProperties: {
        $dropdown_name: 'Pendências de um aluno',
        $student_enrollment_request_aggregation_id: irregularStudentItem.id,
      },
    })
  }

  const sendClickSelectReferenceYearEvent = () => {
    if (!isInitialized) return

    eventDispatcherClient.sendEvent({
      scope: EventDispatcherEventScopes.IRREGULARITY_ENROLLMENTS_PAGE,
      name: EventDispatcherEvents.BUTTON_CLICKED,
      action: 'click',
      entity: 'pendencias',
      customProperties: {
        $button_name: 'Período letivo',
      },
    })
  }

  const sendClickSearchBarEvent = () => {
    if (!isInitialized) return

    eventDispatcherClient.sendEvent({
      scope: EventDispatcherEventScopes.IRREGULARITY_ENROLLMENTS_PAGE,
      name: EventDispatcherEvents.BUTTON_CLICKED,
      action: 'click',
      entity: 'pendencias',
      customProperties: {
        $button_name: 'Busca de alunos',
      },
    })
  }

  const sendClickStatusFilterBadgeEvent = (status: IrregularStudentStatus) => {
    if (!isInitialized) return

    eventDispatcherClient.sendEvent({
      scope: EventDispatcherEventScopes.IRREGULARITY_ENROLLMENTS_PAGE,
      name: EventDispatcherEvents.CHIP_CLICKED,
      action: 'click',
      entity: 'pendencias',
      customProperties: {
        $chip_clicked: irregularStatusTranslationDict[status],
      },
    })
  }

  const sendClickSelectProductEvent = () => {
    if (!isInitialized) return

    eventDispatcherClient.sendEvent({
      scope: EventDispatcherEventScopes.IRREGULARITY_ENROLLMENTS_PAGE,
      name: EventDispatcherEvents.SELECT_TRIGGER_CLICKED,
      action: 'click',
      entity: 'pendencias',
      customProperties: {
        $select_trigger_name: 'Produto',
      },
    })
  }

  const sendClickCopyStudentNameEvent = () => {
    if (!isInitialized) return

    eventDispatcherClient.sendEvent({
      scope: EventDispatcherEventScopes.IRREGULARITY_ENROLLMENTS_PAGE,
      name: EventDispatcherEvents.BUTTON_CLICKED,
      action: 'click',
      entity: 'pendencias',
      customProperties: {
        $button_name: 'Copiar nome de aluno',
      },
    })
  }

  const sendClickCopyExternalIDEvent = () => {
    if (!isInitialized) return

    eventDispatcherClient.sendEvent({
      scope: EventDispatcherEventScopes.IRREGULARITY_ENROLLMENTS_PAGE,
      name: EventDispatcherEvents.BUTTON_CLICKED,
      action: 'click',
      entity: 'pendencias',
      customProperties: {
        $button_name: 'Copiar external ID',
      },
    })
  }

  const sendClickRedirectLinkEvent = () => {
    if (!isInitialized) return

    eventDispatcherClient.sendEvent({
      scope: EventDispatcherEventScopes.IRREGULARITY_ENROLLMENTS_PAGE,
      name: EventDispatcherEvents.BUTTON_CLICKED,
      action: 'click',
      entity: 'pendencias',
      customProperties: {
        $button_name: 'Clicar em link de redirecionamento',
      },
    })
  }

  return {
    sendPageViewEvent,
    sendClickAccordionEvent,
    sendClickSelectReferenceYearEvent,
    sendClickSearchBarEvent,
    sendClickStatusFilterBadgeEvent,
    sendClickSelectProductEvent,
    sendClickCopyStudentNameEvent,
    sendClickCopyExternalIDEvent,
    sendClickRedirectLinkEvent,
  }
}
