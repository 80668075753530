import { Chip } from '@olaisaac/design-system'
import { useNavigation } from 'src/escolas/hooks'
import ListItem from '@material-ui/core/ListItem'
import styled from 'styled-components'
import { useSchoolEnrollmentCycles } from '@/shared/hooks/useSchoolEnrollmentCycles'
import useRouterReferenceYear from '@/escolas/hooks/useRouterReferenceYear'
import * as Styled from './styles'
import { Box, MenuItem } from '@material-ui/core'
import { useSelectYear } from '@monorepo/enrollment/pages/SchoolEnrollmentCycle/hooks/useSelectYear'

export const StyledListItem = styled(props => <ListItem {...props} />)`
  min-height: 48px;
  font-size: 1rem;
  font-family: 'Roboto';
`
export function ContractYearPopover() {
  const { navigateTo } = useNavigation()
  const referenceYear = useRouterReferenceYear()
  const { enrollmentCycleYears } = useSchoolEnrollmentCycles()
  const { handleChangeSelectedYear } = useSelectYear()

  const contractPageYears = enrollmentCycleYears

  const currentYear = new Date().getFullYear()

  const handleSelectYear = (year: number) => {
    handleChangeSelectedYear(year)
    return navigateTo({ path: `/contratos/${year}` })
  }

  return (
    <>
      <Styled.Container>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Styled.SelectYearTitle>Ano letivo:</Styled.SelectYearTitle>
          <Styled.SelectYear
            value={referenceYear}
            onChange={evt => handleSelectYear(Number(evt.target.value))}
          >
            {contractPageYears
              .map(year => (
                <MenuItem key={year} value={year}>
                  {year}
                  <Box flex={1} />
                  {year > currentYear && (
                    <Box display="inline-block" ml={1}>
                      <Chip label="Novo" variation="blue" />
                    </Box>
                  )}
                </MenuItem>
              ))
              .reverse()}
          </Styled.SelectYear>
        </Box>
      </Styled.Container>
    </>
  )
}

export default ContractYearPopover
