import { spacing } from '@gravity/tokens'
import styled from 'styled-components'

export const ResponsiveContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[14]};
  align-items: center;
  margin-left: auto;
  margin-right: auto;
`
