import { Button } from '@gravity/button'
import { Callout } from '@gravity/callout'
import { Checkbox } from '@gravity/checkbox'
import { Dialog } from '@gravity/dialog'
import { TextField } from '@gravity/text-field'

import styled from 'styled-components'
import dayjs from 'dayjs'
import NumberFormat from 'react-number-format'
import { validateDate } from '../utils'
import { SubmitHandler, useForm } from 'react-hook-form'
import { EditStudentForm, EditStudentSchema } from './editStudentSchema'
import { zodResolver } from '@hookform/resolvers/zod'

import { useToast } from '@gravity/toast'
import { useApiClient } from '@/shared/hooks/useApiClient'
import { studentsService } from '../services'

import { useQueryClient } from '@tanstack/react-query'
import { GetStudentEnrollmentCycleResponse } from '@monorepo/enrollment/services/types'
import { FetchContractsByStudentResponseDTO } from '@/shared/services/contracts/types'

type EditStudentDialogProps = {
  birthDateParam: datestring
  onClose: () => void
  open: boolean
  studentID: string
  studentName: string
  taxID: string
}

const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;

  span {
    overflow: hidden;
    white-space: nowrap;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 45px;
  width: 100%;
`

const CheckNoTaxID = styled.div`
  margin-top: 40px;
`

export const EditStudent = ({
  birthDateParam,
  studentName,
  taxID,
  open,
  studentID,
  onClose,
}: EditStudentDialogProps) => {
  const birthDateRaw = validateDate(birthDateParam)
  const { toast } = useToast()
  const { apiClient } = useApiClient()
  const queryClient = useQueryClient()

  const service = studentsService(apiClient.getClients().basePrivateApi)

  const defaultValues = {
    name: studentName ?? '',
    birthDate: birthDateRaw,
    taxID: taxID ?? '',
    noTaxID: false,
  }

  const {
    reset,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    watch,
  } = useForm<EditStudentForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(EditStudentSchema),
    defaultValues,
  })

  const noTaxID = watch('noTaxID')
  const taxIdValue = watch('taxID')

  const onSubmit: SubmitHandler<EditStudentForm> = async ({ name, birthDate, taxID }) => {
    await service
      .updateStudentV2(studentID, {
        name: name,
        birth_date: dayjs(birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        tax_id: noTaxID ? '' : taxID,
      })
      .then(response => {
        toast({ type: 'success', title: 'Edição concluída com sucesso' })
        setValue('noTaxID', false)
        queryClient.setQueriesData<GetStudentEnrollmentCycleResponse>(
          ['student-enrollment-cycle'],
          old => {
            if (!old) return old

            return {
              ...old,
              data: {
                ...old.data,
                student_name: response.name,
              },
            }
          }
        )
        queryClient.setQueriesData<FetchContractsByStudentResponseDTO>(
          ['contracts', 'students'],
          old => {
            if (!old) return old

            return {
              ...old,
              data: old.data.map(contract => ({
                ...contract,
                student: contract.student
                  ? {
                      ...contract.student,
                      birth_date: response.birth_date,
                      tax_id: response.tax_id,
                    }
                  : undefined,
              })),
            }
          }
        )
        onClose()
      })
      .catch(err => {
        if (err.response.data.errors[0].tag === 'DUPLICATED_KEY') {
          toast({
            type: 'error',
            title: 'Erro ao editar dados cadastrais',
            description:
              'Existe outro aluno com os mesmos dados. Pesquise na página de Matrículas.',
          })
        } else {
          toast({ type: 'error', title: 'Erro ao editar dados cadastrais' })
        }
        reset(defaultValues)
        onClose()
        console.log(err)
      })
  }
  const { errors } = formState

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog
      size={3}
      title="Editar cadastro"
      backdrop
      open={open}
      onOpenChange={value => {
        if (!value) {
          reset(defaultValues)
          handleClose()
        }
      }}
      cancelButton={<Button variant="ghost">Cancelar</Button>}
      actionButton={
        <Button
          variant="solid"
          type="submit"
          onClick={handleSubmit(onSubmit)}
          disabled={'birthDate' in errors || 'name' in errors || (!noTaxID && 'taxID' in errors)}
        >
          Salvar
        </Button>
      }
      content={
        <Content>
          <TextField
            size={3}
            fullWidth
            name="name"
            label="Nome"
            error={!!errors?.name?.message}
            errorMessage={errors?.name?.message}
            {...register('name')}
          />
          <FieldsWrapper>
            <NumberFormat
              style={{ flexGrow: 1, overflow: 'visible' }}
              id="student.birthDate"
              type="text"
              format="##/##/####"
              placeholder="DD/MM/AAAA"
              label="Data de nascimento"
              name="birthDate"
              value={getValues('birthDate')}
              onValueChange={async currentValue => {
                setValue('birthDate', currentValue.formattedValue)
              }}
              customInput={TextField}
              size={3}
              variant="outlined"
              error={!!errors?.birthDate?.message}
              errorMessage={errors?.birthDate?.message}
              {...register('birthDate')}
            />
            <NumberFormat
              style={{ flexGrow: 1 }}
              disabled={noTaxID}
              id="student.tax_id"
              type="text"
              format="###.###.###-##"
              label="CPF"
              name="taxID"
              value={taxIdValue}
              onValueChange={async currentValue => {
                setValue('taxID', currentValue.value)
              }}
              customInput={TextField}
              size={3}
              variant="outlined"
              error={!noTaxID && !!errors?.taxID?.message}
              errorMessage={!noTaxID && errors?.taxID?.message}
              {...register('taxID')}
            />
            <CheckNoTaxID>
              <Checkbox
                disabled={taxID !== ''}
                size={2}
                text="Aluno não possui CPF"
                onCheckedChange={(checked: boolean) => setValue('noTaxID', checked)}
                {...register('noTaxID')}
              />
            </CheckNoTaxID>
          </FieldsWrapper>
          <Callout
            linkLabel="Saiba mais"
            href="https://centraldeajuda.olaisaac.io/respons%C3%A1veis-financeiros/tudo-sobre-edicao-de-dados-cadastrais-de-alunos-"
            text="Ao informar o CPF do aluno, você garante maior segurança aos contratos da escola."
          />
        </Content>
      }
    />
  )
}
