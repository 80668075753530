import { Box as MuiBox } from '@material-ui/core'
import styled from 'styled-components'
import { Typography } from '@olaisaac/design-system'

export const Box = styled(MuiBox)`
  padding: 0 ${({ theme }) => theme.spacing(1)}px;
`

export const BoxCloseButton = styled(Box)`
  padding: ${({ theme }) => theme.spacing(4)}px ${({ theme }) => theme.spacing(4)}px;
`

export const BoxLoading = styled(Box)`
  margin-bottom: ${({ theme }) => `${theme.spacing(5)}px`};
`

interface withTopButton {
  readonly withButton: boolean
}

export const Image = styled.img<withTopButton>`
  text-align: center;
  padding: ${({ theme, withButton }) => {
    return `${theme.spacing(withButton ? 0 : 12)}px 0px 0px`
  }};
`

export const SubtitleText = styled(Typography)`
  ${({ theme }) => theme.humanizedTokens.components.typography.variation.subtitleDesktopMedium}
  font-size: 18px;
  text-align: center;
  margin: unset;
  padding: unset;
`

export const IconDiv = styled.div`
  display: flex;
  justify-content: center;
`

export const HeadingDiv = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
`

export const SubTitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-x: 7;
  margin-bottom: 2;
`

export const MessagesDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 0 ${({ theme }) => theme.spacing(7)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`

export const ConfirmationDiv = styled.div`
  display: flex;
  margin-top: 0;
  justify-content: start;
  flex-direction: column;
`
