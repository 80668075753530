import { LayoutTemplate } from '@monorepo/onboarding/LayoutTemplate'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useParams, useHistory } from 'react-router-dom'
import { useGetOnboardingBatch } from '../../hooks/useFetchOnboardingBatches'
import { LoadingPage } from '../LoadingPage'
import { DashboardContent } from './DashboardContent'
import { DashboardFooter } from './DashboardFooter'
import { DoneContent } from './DoneContent'
import { BatchDoneFooter } from './BatchDoneFooter'
import { OnboardingBatchStatus } from '../../services/types/onboardingBatch'
import { BatchProgress } from './BatchProgress'
import { EventProvider } from '@monorepo/onboarding/hooks/eventContext'

type PageContent = {
  content: JSX.Element
  description: string
  footer?: JSX.Element
  title: string
}

const defaultTitle = 'Visão final de migração'
const defaultDescription =
  'Verifique os valores, os alunos e os contratos a serem migrados para a Plataforma isaac.'

const PageComponent = () => {
  const { schoolSlug } = useSelectedSchool()
  const { batchID } = useParams<{ batchID: string }>()
  const history = useHistory()

  const {
    isLoading: isGetOnboardingLoading,
    data,
    refetch: refetchGetOnboardingBatch,
  } = useGetOnboardingBatch(
    { batchID },
    {
      onSuccess(data) {
        if (data.status === 'CANCELED') return history.push(`/${schoolSlug}/migrar-dados/`)

        if (!data.can_submit) return history.push(`/${schoolSlug}/migrar-dados/${batchID}/validar`)
      },
      onError(error) {
        console.error(error)
        history.push(`/${schoolSlug}/migrar-dados/`)
      },
    }
  )

  if (isGetOnboardingLoading) {
    return <LoadingPage />
  }

  if (data === undefined) {
    return null
  }

  const contentMap: Partial<Record<OnboardingBatchStatus, PageContent>> = {
    PENDING: {
      title: defaultTitle,
      description: defaultDescription,
      content: <DashboardContent />,
      footer: <DashboardFooter refetchGetOnboardingBatch={refetchGetOnboardingBatch} />,
    },
    PROCESSING: {
      title: defaultTitle,
      description: defaultDescription,
      content: <BatchProgress style={{ marginTop: 74 }} />,
    },
    DONE: {
      title: 'Resultado da migração de contratos',
      description:
        'Aqui você confere o resultado da migração de contratos. Se houver contratos com erros de migração, contate seu analista de implantação.',
      content: <DoneContent progress={data.progress} />,
      footer: <BatchDoneFooter progress={data.progress} />,
    },
  }

  const content = contentMap[data.status]

  if (!content) {
    return null
  }

  return (
    <LayoutTemplate title={content.title} description={content.description} footer={content.footer}>
      {content.content}
    </LayoutTemplate>
  )
}

export const BatchPage = () => {
  return (
    <EventProvider>
      <PageComponent />
    </EventProvider>
  )
}
