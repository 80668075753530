import { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useSchoolEnrollmentCycleContext } from '@monorepo/enrollment/contexts/SchoolEnrollmentContext'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

export function useSelectedYear(urlYearParam: string) {
  const { replace } = useHistory()
  const { schoolSlug } = useSelectedSchool()

  const {
    selectedEnrollmentCycle,
    schoolEnrollmentCycles,
    hasErrorOnFetchCycles,
    updateSelectedEnrollmentCycle,
    isFetchingCycles,
  } = useSchoolEnrollmentCycleContext()

  const updateUrlYear = useCallback((newYear: string) => {
    replace(`/${schoolSlug}/campanhas/${newYear}`)
  }, [])

  const selectedCycleYear = selectedEnrollmentCycle?.reference_year ?? 0

  const years = schoolEnrollmentCycles
    .filter(cycle => cycle.reference_year > 2024)
    .map(cycle => cycle.reference_year.toString())

  useEffect(() => {
    if (!selectedEnrollmentCycle) return

    const cycleFromURLParam =
      urlYearParam &&
      schoolEnrollmentCycles.find(
        enrollmentCycle => enrollmentCycle.reference_year === parseInt(urlYearParam, 10)
      )

    if (cycleFromURLParam) {
      updateSelectedEnrollmentCycle(cycleFromURLParam)
    } else {
      updateUrlYear(selectedEnrollmentCycle.reference_year.toString())
    }
  }, [urlYearParam, selectedEnrollmentCycle, updateUrlYear])

  return {
    hasErrorOnFetchCycles,
    selectedCycleYear,
    selectedEnrollmentCycle,
    years,
    updateCycleYear: updateUrlYear,
    isFetchingCycles,
  }
}
