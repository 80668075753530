import { useEffect, useMemo } from 'react'

import { Callout } from '@gravity/callout'

import { useGuardiansQuery } from './hooks/useGuardiansQuery'
import { useQuery } from '@/shared/hooks/useQuery'
import { useGuardiansQueryParams } from '../hooks/useGuardiansQueryParams'
import { UserGuidingGuidesIds, useUserGuidingTrigger } from '@/shared/hooks/useUserGuidingTrigger'
import { useLayout } from '@/shared/hooks/useLayout'
import { SortOrder } from '@/shared/hooks/useOrdering/types'

import { ListGuardiansTable, ListGuardiansFilter } from './components'
import { Search } from './components/Search'

import { getFilterSearchParam, formatSearchTerm } from './utils'
import { CalloutContainer, SearchContainer } from './styles'
import { GuardiansQueryParamsNameEnum } from '../constants'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useGetPaymentDisputes } from '@/shared/hooks/queries/usePaymentDisputeQueries'
import { PaymentDisputeSummaryState } from '@/shared/models/enums/PaymentDisputeSummaryState.enum'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventPageName } from '@/shared/models/enums/EventPageName.enum'
import { EventIdentifierName } from '@/shared/models/enums/EventIdentifierName.enum'
import { GuardianBasicInfo } from './types'

const ListGuardians = () => {
  const { school } = useSelectedSchool()
  const { setOnQueryParam } = useQuery()
  const { canUseAutoTrigger } = useUserGuidingTrigger()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  useLayout({ enableSideMenu: true, enableHeader: true, headerTitle: 'Responsáveis' })

  const {
    page,
    itemsPerPage,
    sortBy,
    sortOrder,
    searchTerm,
    filters,
    updateFilters,
    updateOrderingValue,
    setSearchTerm,
  } = useGuardiansQueryParams({})

  const formattedSearchTerm = formatSearchTerm(searchTerm ?? '')

  const { guardians, isFetchGuardiansLoading } = useGuardiansQuery({
    schoolId: school?.id ?? '',
    pagination: { page, per_page: itemsPerPage, sort_by: sortBy, sort_order: sortOrder },
    filter: { [getFilterSearchParam(searchTerm ?? '')]: formattedSearchTerm, ...filters },
  })

  const { paymentDisputesResponse } = useGetPaymentDisputes({
    schoolId: school?.id ?? '',
    status: [PaymentDisputeSummaryState.NOT_STARTED, PaymentDisputeSummaryState.STARTED],
  })

  const quantityOfPaymentDisputes = useMemo(() => {
    const uniqueGuardians = paymentDisputesResponse?.data?.reduce((acc, record) => {
      acc.add(record.guardian.id)
      return acc
    }, new Set())

    return uniqueGuardians ? uniqueGuardians.size : 0
  }, [paymentDisputesResponse?.data])

  const paymentDisputeCalloutText = () => {
    if (quantityOfPaymentDisputes === 1) {
      return 'Você tem 1 responsável que alega cobrança indevida em alguma fatura. Analise as contestações e faça correções, caso necessário.'
    }

    return `Você tem ${quantityOfPaymentDisputes} responsáveis que alegam cobrança indevida em alguma fatura. Analise as contestações e faça correções, caso necessário.`
  }

  const handleCalloutPaymentDisputeClick = () => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.PAYMENT_DISPUTE,
        name: EventDispatcherEvents.LINK_CLICKED,
        pageName: EventPageName.SEARCH_GUARDIANS,
        identifierName: EventIdentifierName.SEE_PAYMENT_DISPUTES_PAGE,
        customProperties: {
          $link_name: 'Ver cobranças indevidas',
          $payment_disputes_quantity: quantityOfPaymentDisputes,
        },
      })
    }
  }

  const showCallout = quantityOfPaymentDisputes > 0

  useEffect(() => {
    if (quantityOfPaymentDisputes > 0 && isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.PAYMENT_DISPUTE,
        name: EventDispatcherEvents.ALERT_VIEWED,
        pageName: EventPageName.SEARCH_GUARDIANS,
        identifierName: EventIdentifierName.SHOWED_PAYMENT_DISPUTES_CALLOUT,
        customProperties: {
          $payment_disputes_quantity: quantityOfPaymentDisputes,
        },
      })
    }
  }, [quantityOfPaymentDisputes, isInitialized])

  useEffect(() => {
    if (canUseAutoTrigger) {
      setOnQueryParam(
        UserGuidingGuidesIds.LIST_GUARDIANS_PAGE,
        GuardiansQueryParamsNameEnum.guide,
        'replace'
      )
    }
  }, [])

  return (
    <>
      {showCallout && (
        <CalloutContainer>
          <Callout
            text={paymentDisputeCalloutText()}
            linkLabel="Ver cobranças indevidas"
            href={`/${school?.slug}/cobrancas-indevidas`}
            onLinkClick={handleCalloutPaymentDisputeClick}
          />
        </CalloutContainer>
      )}

      <SearchContainer>
        <Search setSearchTerm={setSearchTerm} searchTerm={searchTerm} />
        <ListGuardiansFilter filters={filters} updateFilters={updateFilters} />
      </SearchContainer>
      <ListGuardiansTable
        guardians={guardians?.data ?? ([] as GuardianBasicInfo[])}
        isLoading={isFetchGuardiansLoading}
        total={guardians?.pagination?.total}
        sortOrder={sortOrder}
        setSortOrder={(sortOrder: SortOrder) => updateOrderingValue('sortOrder', sortOrder)}
      />
    </>
  )
}

export { ListGuardians }
