import { Route, useRouteMatch, Redirect } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'

import ContractRoutes from '@/escolas/router/[schoolSlug]/contratos/router'
import { LoadingBox } from '@/shared/components/LoadingBox'

import { useUnleashFlagsStatus } from '@/shared/hooks'
import { useNavigation } from '@/escolas/hooks'

import { ChangeGuardianRoutes } from './contratos/alterar-titularidade'
import { GuardianRoutes as GuardiansModuleRoutes } from '@/modules/guardians/routes'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

export default () => {
  const { path } = useRouteMatch()
  const { schoolSlug, school } = useSelectedSchool()
  const { schoolId } = useNavigation()
  const { flagsReady } = useUnleashFlagsStatus()

  const isReady = flagsReady && school && schoolId

  return isReady ? (
    <>
      <Route path={`${path}/contratos/:referenceYear`}>
        <ContractRoutes />
      </Route>

      <ChangeGuardianRoutes />

      <GuardiansModuleRoutes />

      <Route exact path={path}>
        <Redirect to={`/${schoolSlug}`} />
      </Route>
    </>
  ) : (
    <LoadingBox>
      <CircularProgress />
    </LoadingBox>
  )
}
