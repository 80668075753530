import { AxiosInstance } from 'axios'
import QueryString from 'qs'

import { PreviewChangeOwnershipRequest, PreviewChangeOwnershipResponse } from './types'

export const changeOwnershipService = (fetcher: AxiosInstance) => {
  return {
    previewChangeOwnership: async ({
      contract_ids,
    }: PreviewChangeOwnershipRequest): Promise<PreviewChangeOwnershipResponse> => {
      const { data: response } = await fetcher.get<{ data: PreviewChangeOwnershipResponse }>(
        '/contract/preview-change-ownership',
        {
          params: {
            contract_ids,
          },
          paramsSerializer: params => QueryString.stringify(params, { arrayFormat: 'comma' }),
        }
      )

      return response.data
    },
  }
}
