import { Box, Collapse } from '@material-ui/core'

import { PropsWithChildren } from 'react'
import * as S from './styles'
import type { InputBoxProps } from './types'
import { Card } from '@gravity/card'
import { colors } from '@gravity/tokens'

const InputBox = ({
  isOpen,
  onClick,
  name,
  isDisabled,
  children,
  title,
  subTitle,
  buttonLabel,
}: PropsWithChildren<InputBoxProps>) => {
  return (
    <Card role="treeitem" aria-expanded={isOpen} aria-label={title}>
      <S.WrapperContent>
        <S.WrapperTitle>
          <S.Title variant="subtitle-medium">{title}</S.Title>
          <S.SubTitle variant="body-2-regular">{subTitle}</S.SubTitle>
        </S.WrapperTitle>
        <Box>
          <S.StyledListItem
            style={{ backgroundColor: 'transparent' }}
            button
            disabled={isDisabled}
            data-testid={`open-button-${name}`}
            onClick={() => onClick(name)}
          >
            {!isOpen && (
              <S.ButtonLabel
                style={{
                  color: isDisabled ? colors['colors-text-main-3'] : colors['colors-text-main-1'],
                }}
                variant="body-1-regular"
              >
                {buttonLabel}
              </S.ButtonLabel>
            )}
            {isOpen ? <S.ExpandLess /> : <S.ExpandMore />}
          </S.StyledListItem>
        </Box>
      </S.WrapperContent>
      <Collapse in={isOpen} timeout={400} unmountOnExit>
        <S.CollapseContent>{children}</S.CollapseContent>
      </Collapse>
    </Card>
  )
}

export default InputBox
