import { Button } from '@gravity/button'
import { Badge } from '@gravity/badge'
import { Filter } from '@gravity/icons'
import { FilterButtonProps } from './types'

export const FilterButton = ({ onClick, selectedFiltersCount, ...props }: FilterButtonProps) => {
  return (
    <Button onClick={onClick} variant="ghost" {...props}>
      <Filter size="sm" style={{ marginRight: '12px', maxHeight: '20px', maxWidth: '20px' }} />
      Filtrar
      {selectedFiltersCount > 0 && (
        <div style={{ display: 'inline-flex', marginLeft: '0.5rem' }}>
          <Badge size={1} variant="soft" color="accent">
            {`${selectedFiltersCount}`}
          </Badge>
        </div>
      )}
    </Button>
  )
}
