import { Fragment } from 'react'
import { Table } from '@gravity/table'

import { usePagination } from 'src/shared/hooks/usePagination'

import { formatCPF } from '@/shared/utils'
import theme from '@/shared/theme'

import { TableLoading } from './components/TableLoading'
import { TableEmptyState } from './components/TableEmptyState'
import { useListGuardiansTable } from './hooks/useListGuardiansTable'
import { mapDataToRows } from './utils/mapDataToRows'
import { ListGuardiansTableProps, Rows } from './types'
import { TableContainer } from './styles'

const ListGuardiansTable = ({
  guardians,
  isLoading,
  total,
  sortOrder,
  setSortOrder,
}: ListGuardiansTableProps) => {
  const { pagination, updatePaginationValue } = usePagination(1, 25)

  const {
    columns,
    formatStudentsData,
    formatDebtStatusData,
    redirectToGuardianPage,
  } = useListGuardiansTable({
    sortOrder,
    setSortOrder,
  })

  const rows: Rows = isLoading ? [] : mapDataToRows(guardians ?? [])

  return (
    <>
      <TableContainer theme={theme} style={{ marginBottom: '30px' }}>
        <Table.Root data-testid="guardiansListTable">
          <Table.Head>
            {columns.map(column => (
              <Table.HeaderCell
                key={column.name}
                {...column}
                data-testid={'guardiansListColumn-' + column.name}
              />
            ))}
          </Table.Head>
          <Table.Body>
            {isLoading && <TableLoading columns={columns} />}
            {!isLoading && rows.length === 0 && <TableEmptyState columns={columns} />}
            {!isLoading &&
              rows.length > 0 &&
              rows.map((row: Record<string, any>) => (
                <Table.Row key={row.id} onClick={() => redirectToGuardianPage(row.id)}>
                  {columns.map(column => (
                    <Fragment key={column.name + row[column.name]}>
                      {column.name === 'debt_status' && (
                        <Table.BadgeCell
                          badgeVariant="soft"
                          {...formatDebtStatusData(row[column.name])}
                        />
                      )}
                      {column.name === 'students' && (
                        <Table.TextCell {...formatStudentsData(row[column.name])} />
                      )}
                      {column.name !== 'debt_status' && column.name !== 'students' && (
                        <Table.TextCell>
                          {column.name === 'tax_id'
                            ? formatCPF(row[column.name])
                            : row[column.name]}
                        </Table.TextCell>
                      )}
                    </Fragment>
                  ))}
                </Table.Row>
              ))}
          </Table.Body>
          <Table.Pagination
            data-test-id="guardiansListTablePagination"
            page={pagination.page}
            itemsPerPage={pagination.itemsPerPage}
            total={total ?? 0}
            onChangePage={newPage => {
              updatePaginationValue('page', newPage)
            }}
            onChangeItemsPerPage={newItemsPerPage => {
              updatePaginationValue('itemsPerPage', newItemsPerPage)
            }}
          />
        </Table.Root>
      </TableContainer>
    </>
  )
}

export default ListGuardiansTable
