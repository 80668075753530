import styled from 'styled-components'
import { Box, TextField as MUITextField } from '@material-ui/core'
import { Button as GravityButton } from '@gravity/button'
import { Combobox as GravityCombobox } from '@gravity/combobox'
import { spacing } from '@gravity/tokens'

export const TextField = styled(MUITextField)`
  .MuiInputBase-root {
    height: 40px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .MuiFormHelperText-root {
    position: absolute;
    bottom: -24px;
  }
`

export const Button = styled(GravityButton)`
  height: 40px;
  margin-left: ${spacing[6]};
`

export const TextFieldWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  margin-top: ${spacing[2]};
`

export const Combobox = styled(GravityCombobox)`
  width: 100%;
`
export const SelectProductRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`
