import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined'
import { Button } from '@gravity/button'

import type { HelperButtontProps } from './types'

const HelperButton = ({ link, onClick }: HelperButtontProps) => {
  function handleOnClick() {
    if (onClick) onClick()
    window.open(encodeURI(link), '_blank')
  }

  return (
    <Button variant="ghost" onClick={handleOnClick}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <HelpOutlineOutlinedIcon />
        Ajuda
      </div>
    </Button>
  )
}

export default HelperButton
