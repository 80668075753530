import { formatCentsToReal } from '@/shared/utils'
import { Card } from '@gravity/card'
import { Grid, GridItem } from '@gravity/grid'
import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import { mdiAccountSchoolOutline, mdiFolderOutline, mdiTagOutline, mdiCurrencyUsd } from '@mdi/js'
import Icon from '@mdi/react'
import { useEvents } from '@monorepo/onboarding/hooks/eventContext'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useBatchSummaryQuery } from '../../hooks/useBatchSummaryQuery'
import { GenericError } from '../components/GenericError'
import { LoadingPage } from '../LoadingPage'
import { Callout } from '@gravity/callout'

const StyledGrid = styled(Grid)`
  margin: 0;
`

const StyledCard = styled(Card)``

export const DashboardContent = () => {
  const { batchID } = useParams<{ batchID: string }>()
  const events = useEvents()

  const { isLoading, data, isError } = useBatchSummaryQuery(
    { batchID },
    {
      onError(error) {
        console.error(error)
      },
    }
  )

  useEffect(() => {
    events?.previewPageViewed()
  }, [])

  if (isError) return <GenericError />

  if (isLoading || data === undefined) {
    return <LoadingPage />
  }

  return (
    <StyledGrid>
      <GridItem>
        <Callout text="Os dados a seguir foram identificados a partir da planilha enviada. Corrija os dados inválidos na próxima etapa para garantir o cálculo da receita correta." />
      </GridItem>

      <GridItem lg={6}>
        <StyledCard variant="ghost">
          <Icon path={mdiCurrencyUsd} size={1} />
          <Heading style={{ color: '#2F7C57' }} variant="heading-h1-medium">
            {formatCentsToReal(data.total_amount)}
          </Heading>
          <Text variant="body-1-regular">
            Valor total de receita identificado corretamente na planilha
          </Text>
        </StyledCard>
      </GridItem>
      <GridItem lg={6}>
        <StyledCard variant="ghost">
          <Icon path={mdiTagOutline} size={1} />
          <Heading style={{ color: '#CE2C31' }} variant="heading-h1-medium">
            {formatCentsToReal(data.total_discount_amount)}
          </Heading>
          <Text variant="body-1-regular">
            Valor total de desconto identificado corretamente na planilha
          </Text>
        </StyledCard>
      </GridItem>
      <GridItem lg={6}>
        <StyledCard variant="ghost">
          <Icon path={mdiAccountSchoolOutline} size={1} />
          <Heading variant="heading-h1-medium">
            {data.student_count}{' '}
            <Text variant="subtitle-medium">
              {`(${data.student_full_scholarship_count} bolsistas integrais)`}
            </Text>
          </Heading>

          <Text variant="body-1-regular">Alunos identificados corretamente na planilha</Text>
        </StyledCard>
      </GridItem>
      <GridItem lg={6}>
        <StyledCard variant="ghost">
          <Icon path={mdiFolderOutline} size={1} />
          <Heading variant="heading-h1-medium">{data.contract_count}</Heading>
          <Text variant="body-1-regular">Contratos identificados corretamente na planilha</Text>
        </StyledCard>
      </GridItem>
    </StyledGrid>
  )
}
