import { mdiAlertCircleOutline } from '@mdi/js'
import Icon from '@mdi/react'
import { Check } from '@gravity/icons'

import type { StatusIconProps } from '../../types'

const ICON_COMPONENT_MAPPER: Record<StatusIconProps['status'], JSX.Element | null> = {
  completed: <Check size="xs" />,
  error: <Icon path={mdiAlertCircleOutline} size="1rem" />,
  incomplete: null,
}

export const StatusIcon = ({ status }: StatusIconProps) => ICON_COMPONENT_MAPPER[status]
