import type { FetchPayoutDetailsResponseDTO } from '@/shared/services/report/types'
import type { FilterOptions } from '@monorepo/report/pages/EnrollmentPayoutReport/components/EnrollmentPayoutReportFilterDrawer/types'
import type { PayoutTransactionEvents } from '@monorepo/report/models/PayoutTransactionEvents'

/**
 * Utility function to parse filter options for enrollment payout report page
 *
 * @param data Filter options
 *
 * @returns Parsed filter options
 */
export const buildEnrollmentPayoutReportFilterOptions = (
  data?: FetchPayoutDetailsResponseDTO<'enrollment'>['data']['filters_included']
): FilterOptions => {
  if (data) {
    return {
      events: data.events as Array<{ label: string; value: PayoutTransactionEvents }>,
      products: data.products,
      amountSigns: data.amount_signs as Array<{ label: string; value: 'POSITIVE' | 'NEGATIVE' }>,
      referenceYears: data.reference_years,
    }
  }

  return {
    events: [],
    products: [],
    amountSigns: [],
    referenceYears: [],
  }
}
