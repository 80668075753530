import { debounce } from 'throttle-debounce'
import { Grid, Search as DSSearch } from '@olaisaac/design-system'
import { SearchProps } from './types'

const Search = ({ searchTerm, setSearchTerm }: SearchProps) => {
  return (
    <Grid item sm={9} style={{ maxWidth: '480px' }} data-testid="guardiansListSearch">
      <DSSearch
        placeholder="Pesquisar por nome do responsável ou CPF"
        onChange={debounce(800, value => {
          setSearchTerm(value)
        })}
        initialValue={searchTerm || ''}
        onClear={() => setSearchTerm('')}
      />
    </Grid>
  )
}

export default Search
