import { useHistory, useParams } from 'react-router-dom'
import { SelectPrimitives } from '@gravity/select'
import { Button } from '@gravity/button'
import Icon from '@mdi/react'
import { mdiPlus } from '@mdi/js'
import { useEffect, useMemo, useState } from 'react'

import { ErrorDialog } from '@/shared/components/ErrorDialog'
import { useSetPageTitle } from '@/shared/hooks/useSetPageTitle'
import { PageWrapper } from '@/shared/components/PageWrapper'
import { useEnrollmentCampaignRedirect } from '@/shared/hooks/useEnrollmentCampaignRedirect'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { usePagination } from '@/shared/hooks/usePagination'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useOrdering } from '@/shared/hooks/useOrdering'
import { Callout } from '@gravity/callout'
import { useEnrollmentMixpanelEvents } from '@monorepo/enrollment/hooks/useEnrollmentMixpanelEvents'

import { useSelectedYear } from './hooks/useSelectedYear'
import { useCampaignsList } from './hooks/useCampaignsList'
import { CampaignsTable } from './components/CampaignsTable'
import { CampaignsFilter } from './components/CampaignsFilter'
import type { SortableColumns } from './components/CampaignsTable/types'
import * as Styled from './styles'
import { Heading } from '@gravity/heading'

type URLParams = {
  year: string
}

export const CampaignsList = () => {
  useEnrollmentCampaignRedirect()

  useSetPageTitle('Campanhas de rematrícula - isaac')

  const isCampaignAddTuitionEnabled = useUnleashFlag(
    UnleashFlags.MAT_474_ENABLE_CAMPAIGN_ADD_INSTALLMENTS
  )

  const { sendButtonClickEvent } = useEnrollmentMixpanelEvents()

  const { year: urlYearParam } = useParams<URLParams>()
  const {
    hasErrorOnFetchCycles,
    selectedEnrollmentCycle,
    selectedCycleYear,
    years,
    updateCycleYear,
    isFetchingCycles,
  } = useSelectedYear(urlYearParam)

  const history = useHistory()
  const { schoolSlug } = useSelectedSchool()

  const currentYear = new Date().getFullYear()
  const isAddNewCampaignBtnDisabled =
    selectedEnrollmentCycle?.enabled === false &&
    selectedEnrollmentCycle.reference_year < currentYear

  const { pagination, updatePaginationValue, updatePagination } = usePagination(1, 10)
  const { ordering, updateOrdering } = useOrdering<SortableColumns>({
    sortBy: 'created_at',
    sortOrder: 'DESC',
  })

  const defaultNoDataMessage = 'Nenhuma campanha foi criada até o momento.'

  const [campaignsStatusFilter, setCampaignsStatusFilter] = useState<string[]>([])
  const [noDataMessage, setNoDataMessage] = useState<string>(defaultNoDataMessage)

  const { campaigns, tablePagination, isSuccess, isLoading, refetch } = useCampaignsList({
    schoolId: selectedEnrollmentCycle?.school_id ?? '',
    schoolEnrollmentCycleId: selectedEnrollmentCycle?.school_enrollment_cycle_id ?? '',
    campaignsStatusFilter: campaignsStatusFilter,
    pagination: {
      page: pagination.page,
      per_page: pagination.itemsPerPage,
      sort_by: ordering.sortBy,
      sort_order: ordering.sortOrder,
    },
  })

  const tableRows = useMemo(() => {
    return campaigns.map(campaign => ({
      columns: [
        campaign.name,
        campaign.product_name,
        campaign.students_count.toString(),
        campaign.engaged_students_count.toString(),
        campaign.end_date,
        campaign.status,
        campaign.closed_type,
        String(campaign.total_of_campaign_emails_read ?? 0),
        String(campaign.paid_enrollments_count ?? 0),
      ],
      hasPaymentPlan: campaign.has_payment_plan,
      key: campaign.id,
    }))
  }, [campaigns])

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    refetch()
  }, [selectedCycleYear, campaignsStatusFilter, ordering])

  const handleFilterChange = (value: string) => {
    switch (value) {
      case 'OPEN':
        setCampaignsStatusFilter(['OPEN'])
        setNoDataMessage(defaultNoDataMessage)
        break
      case 'ENDED':
        setCampaignsStatusFilter(['CLOSED', 'CANCELED'])
        setNoDataMessage('Nenhuma campanha foi encerrada até o momento.')
        break
      default:
        setCampaignsStatusFilter([])
        setNoDataMessage(defaultNoDataMessage)
    }
    updatePagination({ page: 1, itemsPerPage: 10 })
  }

  const handleOnClickRow = (key: string) => {
    history.push(`/${schoolSlug}/campanhas/${urlYearParam}/${key}`)
  }

  return (
    <PageWrapper>
      <Styled.PageContainer>
        <ErrorDialog isError={hasErrorOnFetchCycles || (!isFetchingCycles && years.length === 0)} />

        <Styled.ActionsContainer>
          <Styled.HeadingWrapper>
            <Heading variant="heading-h2-medium">Campanhas de rematrícula:</Heading>

            <Styled.SelectYearWrapper>
              <SelectPrimitives.Root
                onValueChange={updateCycleYear}
                value={String(selectedCycleYear)}
                aria-label="Campanhas de matrícula"
              >
                <Styled.SelectLabelWrapper>
                  <SelectPrimitives.Trigger aria-label="Campanhas de matrícula" />
                </Styled.SelectLabelWrapper>
                <SelectPrimitives.Content>
                  {years.map(year => (
                    <SelectPrimitives.Item key={year} value={String(year)}>
                      {year}
                    </SelectPrimitives.Item>
                  ))}
                </SelectPrimitives.Content>
              </SelectPrimitives.Root>
            </Styled.SelectYearWrapper>
          </Styled.HeadingWrapper>

          <Styled.ActionButtonsWrapper>
            <Button
              iconStart={<Icon path={mdiPlus} />}
              onClick={() => {
                sendButtonClickEvent('nova_campanha')
                history.push({ pathname: `/${schoolSlug}/campanha/novo` })
              }}
              disabled={isAddNewCampaignBtnDisabled}
            >
              Nova campanha
            </Button>
          </Styled.ActionButtonsWrapper>
        </Styled.ActionsContainer>

        <Styled.FilterAndSecondary>
          <CampaignsFilter onChange={handleFilterChange} />
          <Styled.SecondaryButtons>
            <Button
              style={{ textWrap: 'nowrap' }}
              variant="ghost"
              onClick={() => {
                window.userGuiding.previewGuide(129983)
              }}
            >
              Como funcionam as campanhas?
            </Button>
            <Button
              style={{ textWrap: 'nowrap' }}
              variant="ghost"
              onClick={() => {
                sendButtonClickEvent('ver_relatorio')
                history.push({ pathname: `/${schoolSlug}/relatorio/matriculas` })
              }}
              disabled={isAddNewCampaignBtnDisabled}
            >
              Ver relatório completo
            </Button>
          </Styled.SecondaryButtons>
        </Styled.FilterAndSecondary>
        {!isCampaignAddTuitionEnabled && (
          <Callout
            text="Atenção: A adição de mensalidades para alunos em campanha está em desenvolvimento. Em
          breve, será possível adicioná-las em lote ou individualmente através do detalhamento da
          campanha."
          />
        )}
        <CampaignsTable
          rows={isSuccess ? tableRows : []}
          isLoading={isLoading}
          ordering={ordering}
          onChangeOrdering={(sortBy, sortOrder) => {
            updatePaginationValue('page', 1)
            updateOrdering({ sortBy, sortOrder })
          }}
          noDataMessage={noDataMessage}
          onClickRow={handleOnClickRow}
          pagination={pagination}
          totalPagination={tablePagination.total}
          updatePaginationValue={updatePaginationValue}
        />
      </Styled.PageContainer>
    </PageWrapper>
  )
}
