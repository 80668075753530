import { Grid, GridItem } from '@gravity/grid'
import styled from 'styled-components'

export const Wrapper = styled(Grid)`
  margin-left: 0;
  margin-right: 0;
`
export const Item = styled(GridItem)`
  margin-top: ${({ theme }) => theme.gravity.spacing['12']};
  > div {
    height: 100%;
  }
`
