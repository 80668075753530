import { Accordion } from '@gravity/accordion'
import { borderRadius, colors, spacing } from '@gravity/tokens'

import { PaymentDisputeSummary } from '@/shared/models/PaymentDisputeSummary'
import { PaymentDisputeItem } from '@/shared/models/PaymentDisputeItem'

import { paymentDisputeAccordionContentVariations } from './variations'

type PaymentDisputeAccordionContentProps = {
  loading?: boolean
  paymentDispute: PaymentDisputeSummary
  paymentDisputeItems: PaymentDisputeItem[]
}

export const PaymentDisputeAccordionContent = ({
  loading = false,
  paymentDispute,
  paymentDisputeItems,
}: PaymentDisputeAccordionContentProps) => {
  const component = paymentDisputeAccordionContentVariations[paymentDispute.type]({
    loading,
    paymentDispute,
    paymentDisputeItems,
  })

  return (
    <Accordion.Content
      style={{
        padding: `${spacing['4']} ${spacing['4']} ${spacing['8']} ${spacing['4']}`,
        backgroundColor: colors['colors-background-neutral-1'],
        border: `1px solid ${colors['colors-border-neutral-2']}`,
        borderTop: 0,
        borderBottomLeftRadius: borderRadius['2'],
        borderBottomRightRadius: borderRadius['2'],
      }}
    >
      {component}
    </Accordion.Content>
  )
}
