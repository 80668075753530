import { AllFiltersOptionsType } from '../../GuardianDetailsContractsTab/types'
import { FiltersInstallmentsFormType } from '../components/GuardianDetailsInstallmentsFilter/types'
import { InstallmentsFiltersType } from '../types'

/**
 * @description Transform a group of filters by the API to be used in ActiveFilters component.
 *
 * @param filters Filters by the API.
 * @param allFiltersOptions Options of filters by the API.
 *
 * @returns Filters in the format { label, value }.
 */
export const transformFilters = (
  filters: Partial<InstallmentsFiltersType>,
  allFiltersOptions: AllFiltersOptionsType
): FiltersInstallmentsFormType => {
  const transformedFilters: FiltersInstallmentsFormType = {
    installment_statuses: [],
    installment_types: [],
    reference_years: [],
    student_ids: [],
    product_ids: [],
    contract_id: undefined,
    contract_status: undefined,
    negotiation_id: undefined,
  }

  if (filters.installment_statuses) {
    transformedFilters.installment_statuses = filters.installment_statuses.map(value => {
      const option = allFiltersOptions?.installmentStatus?.find(option => option.value === value)
      return option || { label: '', value }
    })
  }

  if (filters.installment_types) {
    transformedFilters.installment_types = filters.installment_types.map(value => {
      const option = allFiltersOptions?.installmentType?.find(option => option.value === value)
      return option || { label: '', value }
    })
  }

  if (filters.reference_years) {
    transformedFilters.reference_years = filters.reference_years.map(value => {
      const option = allFiltersOptions?.year?.find(option => option.value === value)
      return option || { label: '', value }
    })
  }

  if (filters.student_ids) {
    transformedFilters.student_ids = filters.student_ids.map(value => {
      const option = allFiltersOptions?.student?.find(option => option.value === value)
      return option || { label: '', value }
    })
  }

  if (filters.product_ids) {
    transformedFilters.product_ids = filters.product_ids.map(value => {
      const option = allFiltersOptions?.products?.find(option => option.value === value)
      return option || { label: '', value }
    })
  }

  if (filters.contract_status) {
    transformedFilters.contract_status = allFiltersOptions.installmentContractStatus.find(
      option => option.value === filters.contract_status
    ) ?? { label: '', value: '' }
  }

  if (filters.negotiation_id) {
    const label = `Negociação #${filters.negotiation_id.substring(0, 6).toUpperCase()}`
    transformedFilters.negotiation_id = {
      label,
      value: filters.negotiation_id,
    }
  }

  if (filters.contract_id) {
    const label = `Contrato #${filters.contract_id.substring(0, 6).toUpperCase()}`
    transformedFilters.contract_id = {
      label,
      value: filters.contract_id,
    }
  }

  return transformedFilters
}
