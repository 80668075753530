import { Text } from '@gravity/text'

import type { StepItemProps } from './types'
import { StatusIcon } from '../StatusIcon/StatusIcon'
import * as Styled from './styles'

export const StepItem = ({
  isActiveStep = false,
  disabled = false,
  title,
  status = 'incomplete',
  onClickStep,
}: StepItemProps) => {
  const showHalfProgressBar = status === 'error' || (isActiveStep && status === 'incomplete')

  return (
    <Styled.Wrapper onClick={onClickStep} aria-disabled={disabled} disabled={disabled}>
      <Styled.ProgressBarWrapper>
        <Styled.ProgressBar
          isActiveStep={isActiveStep}
          showHalfProgressBar={showHalfProgressBar}
          status={status}
        />
      </Styled.ProgressBarWrapper>

      <Styled.TitleWrapper status={status} isActiveStep={isActiveStep}>
        <StatusIcon status={status} />
        <Text variant={isActiveStep ? 'body-2-medium' : 'body-2-regular'}>{title}</Text>
      </Styled.TitleWrapper>
    </Styled.Wrapper>
  )
}
