import { Help } from '@material-ui/icons'
import { Tooltip } from '@material-ui/core'
import { Divider } from '@olaisaac/design-system'
import { Text } from '@gravity/text'

import { convertToUTCDate, formatMicroCentsToReal } from '@/shared/utils'
import { ISAAC_PAY_DRAWER_ITEMS } from './constants/isaacPayDrawerItems'

import { SummaryItem as SummaryInfoItem, Content } from '../../styles'
import { FetchAggregatedProvidersReportResponseDTO } from '@monorepo/report/services/report/types'
import { BankTransfer } from '@monorepo/report/models/report'

type ExternalPaymentProps = {
  bankTransfers: BankTransfer[]
  externalPaymentSummary: FetchAggregatedProvidersReportResponseDTO['data']
  getValueColor: (value: number) => string
}

/**
 * Drawer for payout content. This content is for external payout providers
 * @param props
 * @param props.bankTransfers List of bank transfers
 * @param props.externalPaymentSummary Payout summary with totals and fees
 * @param props.getValueColor Function to get label color
 */
export const IsaacPayExternalPaymentDrawerContent = ({
  externalPaymentSummary,
  getValueColor,
  bankTransfers,
}: ExternalPaymentProps) => {
  return (
    <Content>
      <Text>Pagamentos recebidos pela escola</Text>
      <SummaryInfoItem variation="primary" color={getValueColor(1)}>
        <p className="label">{ISAAC_PAY_DRAWER_ITEMS.TOTAL_EXTERNAL.label}</p>
        <span>{formatMicroCentsToReal(externalPaymentSummary?.external_total_base_amount)}</span>
      </SummaryInfoItem>
      <Divider variation="secondary" style={{ margin: '2rem 0' }} />
      <Text>Pagamentos recebidos pelo isaac</Text>
      <SummaryInfoItem variation="primary" color={getValueColor(1)}>
        <p className="label">{ISAAC_PAY_DRAWER_ITEMS.TOTAL_PAYOUT.label}</p>
        <span>{formatMicroCentsToReal(externalPaymentSummary?.payout_total_base_amount)}</span>
      </SummaryInfoItem>
      <SummaryInfoItem variation="primary" color={getValueColor(-1)}>
        <p className="label">
          {ISAAC_PAY_DRAWER_ITEMS.PAYOUT_FEE.label}
          <Tooltip title={ISAAC_PAY_DRAWER_ITEMS.PAYOUT_FEE.description}>
            <Help />
          </Tooltip>
        </p>
        <span>{formatMicroCentsToReal(-1 * externalPaymentSummary?.payout_total_fee)}</span>
      </SummaryInfoItem>
      <Divider variation="primary" style={{ margin: '2rem 0' }} />
      <SummaryInfoItem variation="secondary">
        <p className="label">{ISAAC_PAY_DRAWER_ITEMS.TOTAL.label}</p>
        <span>{formatMicroCentsToReal(externalPaymentSummary?.total)}</span>
      </SummaryInfoItem>
      <Divider variation="secondary" style={{ margin: '2rem 0' }} />
      <SummaryInfoItem variation="primary" color={getValueColor(-1)}>
        <p className="label">{ISAAC_PAY_DRAWER_ITEMS.EXTERNAL_FEE.label}</p>
        <span>{formatMicroCentsToReal(externalPaymentSummary?.external_total_fee)}</span>
      </SummaryInfoItem>

      {bankTransfers.length > 0 && <Divider variation="secondary" /> &&
        bankTransfers.map((item, index) => {
          return (
            <SummaryInfoItem key={index} variation="tertiary">
              <p className="label">Transferência programada</p>

              <span>{convertToUTCDate(item.date).format('DD/MM/YYYY')}</span>
            </SummaryInfoItem>
          )
        })}
    </Content>
  )
}
