import Icon from '@mdi/react'
import { mdiMagnify } from '@mdi/js'
import styled from 'styled-components'
import { Text } from '@gravity/text'
import { Table } from '@gravity/table'

const StyledCell = styled(Table.CustomCell)`
  gap: 12px;
  align-items: center;
`

type TableEmptyRowProps = {
  colspan?: number
}

export const TableEmptyRow = ({ colspan }: TableEmptyRowProps) => {
  return (
    <Table.Row>
      <StyledCell colSpan={colspan} height="96px">
        <Icon path={mdiMagnify} size={1} />
        <Text variant="body-2-regular">Nenhum resultado encontrado</Text>
      </StyledCell>
    </Table.Row>
  )
}
