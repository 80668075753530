import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Text } from '@gravity/text'
import { Typography as MuiTypography } from '@material-ui/core'
import { useForm } from 'react-hook-form'

import { ContractDetailsDrawer } from '@/escolas/components/contract/ContractDetailsDrawer/ContractDetailsDrawer'
import { ContractDetailsDrawerState } from '@/escolas/components/contract/ContractDetailsDrawer/constants'
import { useGetContract } from '@/escolas/hooks/useGetContract'
import { useContract, useNavigation } from '@/escolas/hooks'
import { NewContractFormDefaults } from '@/escolas/components/contract/create/types'
import { UnleashFlags, useJWT, useSnackbar, useUnleashFlag } from '@/shared/hooks'
import { useApi } from '@/utils/hooks/useApi'
import {
  Contract,
  ContractCancellationReason,
  Installment,
  PathParams,
  PreContractStatuses,
  ReceivableStatuses,
} from '@/shared/interfaces'
import ConfirmationDialog from '@/shared/components/ConfirmationDialog'
import { useGetInvoicesByIds } from '@/escolas/hooks/useGetInvoicesByIds'
import { InvoicesNotGeneratedDialog } from '@/escolas/components/contract/InvoicesNotGeneratedDialog/InvoicesNotGeneratedDialog'
import { PagedDrawerProvider } from '@/escolas/contexts/pagedDrawerContext'
import CancellationDrawer from '@/escolas/components/contract/CancellationDrawer'
import { processInstallments } from '@/escolas/components/contract/ContractDetails'
import useInstallments from '@/escolas/hooks/useInstallments'
import { ContractCancellationForm } from '@/escolas/components/contract/CancellationDrawer/CancellationDrawerDefaultContent'
import * as Styled from './styles'
import ConfirmationDialogCtrl from '@/modules/guardians/ContractsDrawerContainer/components/ConfirmationDialogCtrl'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

import { getInvoicesIds } from '../utils/getInvoicesIds'

type ContractsDrawerContainerProps = {
  contractId: uuid
  onClose: () => void
  refetchGuardianContracts: () => void
  state?: ContractDetailsDrawerState
}

enum DialogContentsKeys {
  CANCELATION_PRE_CONTRACT_CONFIRM = 'CANCELATION_PRE_CONTRACT_CONFIRM',
  CANCELATION_PRE_CONTRACT_CONFIRM_RECEIVABLE_PAID = 'CANCELATION_PRE_CONTRACT_CONFIRM_RECEIVABLE_PAID',
}

const checkReceivablesPaidInContract = (contract: Contract) =>
  contract?.installments?.some(i => i?.receivables?.some(r => r.status === ReceivableStatuses.PAID))

export const ContractsDrawerContainer = ({
  contractId,
  refetchGuardianContracts,
  onClose,
  state = ContractDetailsDrawerState.DEFAULT,
}: ContractsDrawerContainerProps) => {
  const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] = useState<boolean>(
    state === ContractDetailsDrawerState.PAYMENT_STATEMENT
  )
  const [cancelQueryLoaded, setCancelQueryLoaded] = useState<boolean>(false)
  const [installmentsToCancelContract, setInstallmentsToCancelContract] = useState<
    Array<Installment>
  >([])
  const [drawerState, setDrawerState] = useState<ContractDetailsDrawerState>(state)
  const [showInvoicesNotGeneratedDialog, setShowInvoicesNotGeneratedDialog] = useState<boolean>(
    false
  )
  const [showCancellationErrorMessage, setShowCancellationErrorMessage] = useState<boolean>(false)
  const [showConfirmCancellationMessage, setShowConfirmCancellationMessage] = useState<boolean>(
    false
  )
  const [showCancellationDrawer, setShowCancellationDrawer] = useState<boolean>(
    state === ContractDetailsDrawerState.CANCEL_CONTRACT
  )
  const [isOnTheFirstPage, setIsOnTheFirstPage] = useState<boolean>(true)
  const [contentDialog, setContentDialog] = useState<DialogContentsKeys | null>(null)
  const [
    showChangeContractOwnershipDialog,
    setShowChangeContractOwnershipDialog,
  ] = useState<boolean>(state === ContractDetailsDrawerState.CHANGE_CONTRACT_OWNERSHIP)
  const { getNavigationUrl, schoolId } = useNavigation()
  const { school } = useSelectedSchool()
  const { schoolSlug } = useParams<PathParams>()
  const { isAdmin, getUserRolesBySchool } = useJWT()
  const { api } = useApi()
  const history = useHistory<NewContractFormDefaults>()
  let invoicesWithError: any[] | undefined = []

  const { setContract } = useContract()

  const { contract, isFetched, refetch } = useGetContract(contractId, schoolId) as {
    contract: Contract
    isFetched: boolean
    refetch: () => void
  }

  const [invoiceIds, setInvoiceIds] = useState<uuid[]>([])
  if (schoolId) {
    const invoicesById = useGetInvoicesByIds(invoiceIds, schoolId, false)
    invoicesWithError = invoicesById.invoicesWithError
  }
  const { processedInstallments, setProcessedInstallments } = useInstallments()

  const {
    setMessage: setSnackbarMessage,
    setIsOpen: setSnackbarIsOpen,
    setVariation: setSnackbarVariation,
  } = useSnackbar()

  const closeAllDrawers = () => {
    setDrawerState(ContractDetailsDrawerState.DEFAULT)
    setShowCancellationDrawer(false)
  }

  // --- Rematrícula -->  Usado em: ContractActionsCheckout_old.tsx |
  const reEnrollStudent = () => {
    const path = getNavigationUrl({
      path: `contratos`,
    })
    const params = new URLSearchParams()

    params.append('guardianId', contract.guardian_id)
    params.append('studentId', contract.student_id)

    history.push({
      pathname: `${path}/novo`,
      search: params.toString(),
    })
  }
  // ---

  // --- Registrar Assinatura --> Usado em: ManualSignDrawer.tsx
  const refetchContract = async () => {
    await refetch()
  }
  // ---

  // --- Gerar Demonstrativo de Pgto --> Usado em: ContractTable_old.tsx
  const showStatement = () => {
    window.open(`/${schoolSlug}/contrato/${contractId}/demonstrativo-pagos`)
    setIsConfirmationDialogVisible(false)
    onClose()
  }
  // ---

  // --- Troca de Titularidade --> Usado em: ContractActionsCheckout_old.tsx
  const changeContractOwnership = () => {
    setShowChangeContractOwnershipDialog(true)
  }

  const redirectToChangeContractOwnership = () => {
    setShowChangeContractOwnershipDialog(false)
    history.push(getNavigationUrl({ path: `/contratos/${contract?.id}/alterar-titularidade` }))
  }
  // ---

  const handleContractDrawerClose = () => {
    onClose()
  }

  // --- Cancelamento de Contratos --> Usado em CancellationDrawer.tsx

  const cancelContractForm = useForm<ContractCancellationForm>({
    mode: 'all',
    defaultValues: {
      installment_id: '',
      cancellation_description: '',
    },
  })

  const { handleSubmit, reset } = cancelContractForm

  const isRevokedCancelContract = useUnleashFlag(UnleashFlags.B2BCOR_193_BLOCK_CONTRACT_REVOKE)

  const isCancelContractEnabled = !isAdmin && !isRevokedCancelContract

  const isCancelPreContractFeatureFlagEnabled = !isAdmin

  const isPreContract = contract?.pre_contract_status === PreContractStatuses.CREATED_PCS

  const hasInstallmentsToCancelContract = installmentsToCancelContract?.length > 0

  const canCancelContract =
    !isPreContract && isCancelContractEnabled && hasInstallmentsToCancelContract
  const canCancelPreContract = isPreContract && isCancelPreContractFeatureFlagEnabled

  const considerUserRole = useUnleashFlag(UnleashFlags.PE_233_ENABLE_BASIC_OPERATION_ACCESS_LEVEL)
  const hasCancelContractRole = getUserRolesBySchool(school?.id ?? '').has('cancelar_contrato')

  const handleCancellationDrawerOpen = () => {
    closeAllDrawers()
    isAdmin ||
    canCancelContract ||
    canCancelPreContract ||
    (considerUserRole && hasCancelContractRole)
      ? setShowCancellationDrawer(true)
      : setShowCancellationErrorMessage(true)
  }

  const handleCancellationDrawerClose = () => {
    setShowCancellationDrawer(false)
    if (state === ContractDetailsDrawerState.CANCEL_CONTRACT) onClose()
  }

  const onCancellationSuccess = () => {
    refetchGuardianContracts()
    refetchContract()
    setShowConfirmCancellationMessage(false)
    handleCancellationDrawerClose()
  }

  const cancelContractSubmitHandler = (form: ContractCancellationForm) => {
    if (!contract || !schoolId) return

    setContentDialog(null)
    setShowConfirmCancellationMessage(false)
    const { cancellation_reason, installment_id, cancellation_description } = form

    const snackbarErrorMessage = isPreContract
      ? 'Ocorreu um erro ao cancelar o contrato'
      : 'Antes de cancelar, é necessário renegociar as parcelas vencidas deste contrato.'

    return api.contracts
      .revoke(
        contract?.id,
        {
          cancellation_reason:
            isCancelContractEnabled && !isPreContract
              ? ContractCancellationReason.OTHER
              : cancellation_reason,
          ...(!isPreContract && { installment_id }),
          cancellation_description: cancellation_description ?? '',
        },
        schoolId,
        contract
      )
      .then(() => {
        setSnackbarVariation('success')
        setSnackbarMessage('Contrato cancelado com sucesso.')
        setSnackbarIsOpen(true)
        onCancellationSuccess()
        setIsOnTheFirstPage(true)
        reset()
      })
      .catch(() => {
        setSnackbarVariation('error')
        setSnackbarMessage(snackbarErrorMessage)
        setSnackbarIsOpen(true, {
          title: 'Esse contrato não pode ser cancelado',
        })
        handleCancellationDrawerClose()
        setShowConfirmCancellationMessage(false)
        setIsOnTheFirstPage(true)
        reset()
      })
  }

  const dialogContents = {
    [DialogContentsKeys.CANCELATION_PRE_CONTRACT_CONFIRM_RECEIVABLE_PAID]: {
      title: 'Atenção',
      content: (
        <>
          <Styled.ModalTypography>
            Este contrato possui uma parcela paga. Caso queira seguir com o cancelamento, o estorno
            do pagamento precisará ser feito diretamente da escola para o responsável.
          </Styled.ModalTypography>
          <Styled.ModalTypography>
            O cancelamento de contrato é irreversível. Caso necessário, um novo contrato precisará
            ser criado.
          </Styled.ModalTypography>
        </>
      ),
      backButtonLabel: 'Voltar',
      buttonLabel: 'Cancelar contrato',
      submitHandler: handleSubmit(cancelContractSubmitHandler),
    },
    [DialogContentsKeys.CANCELATION_PRE_CONTRACT_CONFIRM]: {
      title: 'Atenção',
      content: (
        <Styled.ModalTypography>
          O cancelamento de contrato é irreversível. Caso necessário, um novo contrato precisará ser
          criado.
        </Styled.ModalTypography>
      ),
      backButtonLabel: 'Voltar',
      buttonLabel: 'Cancelar contrato',
      submitHandler: handleSubmit(cancelContractSubmitHandler),
    },
  }

  const getCancellationContractDialogMessage = () => {
    const hasPaidReceivables = checkReceivablesPaidInContract(contract)
    if (hasPaidReceivables) {
      setContentDialog(DialogContentsKeys.CANCELATION_PRE_CONTRACT_CONFIRM_RECEIVABLE_PAID)
    } else {
      setContentDialog(DialogContentsKeys.CANCELATION_PRE_CONTRACT_CONFIRM)
    }
  }
  // ---

  const hasInvoicesWithError =
    Boolean(invoicesWithError?.length && invoicesWithError?.length > 0) ?? false

  const getCancelContractInstallments = () => {
    if (!contract) return
    api.contracts.cancelContractInfo(contract.id).then(data => {
      setCancelQueryLoaded(true)
      setInstallmentsToCancelContract(data)
    })
  }
  // ---

  useEffect(() => {
    if (contract) {
      setContract(contract)
      setInvoiceIds(getInvoicesIds(contract))

      if (contract?.installments?.length === 0) return
      const processedInstallments = processInstallments(contract)
      if (processedInstallments) {
        setProcessedInstallments(processedInstallments)
        getCancelContractInstallments()
      }
    }
  }, [contract])

  /* Modal Troca de Titularidade */
  if (
    drawerState === ContractDetailsDrawerState.CHANGE_CONTRACT_OWNERSHIP ||
    showChangeContractOwnershipDialog
  )
    return (
      <ConfirmationDialog
        isVisible
        size={2}
        onClose={() => {
          setShowChangeContractOwnershipDialog(false)
          onClose()
        }}
        submitHandler={redirectToChangeContractOwnership}
        backButtonLabel="Voltar"
        buttonLabel="Continuar"
        title="O contrato anterior será cancelado a partir das parcelas a vencer:"
      >
        <Styled.ContractOwnershipModalContainer>
          <Text variant="body-1-regular">
            Antes de você continuar, saiba que ao realizar uma alteração de titularidade{' '}
            <b>o contrato atual será cancelado a partir das parcelas a vencer</b> e ficará inativo
            (mas não se preocupe, você ainda poderá visualizá-lo).
          </Text>
          <Text variant="body-1-regular">
            Um <b>novo contrato será criado</b> com os dados do novo responsável financeiro;
          </Text>
          <Text variant="body-1-regular">
            <b>
              Parcelas com vencimento para o dia de hoje, ficarão vinculadas ao contrato anterior.
              Novos boletos não serão gerados.
            </b>
          </Text>
          <Text variant="body-1-regular">Você deseja continuar?</Text>
        </Styled.ContractOwnershipModalContainer>
      </ConfirmationDialog>
    )

  /* Modal Gerar Demonstrativo */
  if (drawerState === ContractDetailsDrawerState.PAYMENT_STATEMENT || isConfirmationDialogVisible)
    return (
      <ConfirmationDialog
        isVisible
        onClose={() => setIsConfirmationDialogVisible(false)}
        submitHandler={showStatement}
        buttonLabel="Ok, gerar demonstrativo"
        title="Aviso"
      >
        <Text variant="body-2-regular">
          Este demonstrativo só apresenta valores pagos que são de competência do isaac, ou seja,
          valores anteriores a parceria não constam no demonstrativo.
        </Text>
      </ConfirmationDialog>
    )

  return (
    <PagedDrawerProvider>
      {/* Default Drawer */}
      <ContractDetailsDrawer
        // Dados e refetch
        contract={contract}
        getContract={refetchContract}
        // Controle do Drawer
        isOpen={isFetched}
        onClose={handleContractDrawerClose}
        drawerState={drawerState}
        setDrawerState={setDrawerState}
        // Rematrícula
        isReenrollable={contract?.isReenrollable}
        addContract={() => reEnrollStudent()}
        // Cancelamento de Contrato
        cancelContractQueryLoading={cancelQueryLoaded}
        openCancellationDrawer={handleCancellationDrawerOpen}
        // Cashier
        hasInvoicesWithError={hasInvoicesWithError}
        setShowInvoicesNotGeneratedDialog={setShowInvoicesNotGeneratedDialog}
        // Troca de titularidade
        changeContractOwnership={changeContractOwnership}
        // Gerar Demonstrativo
        setIsConfirmationDialogVisible={setIsConfirmationDialogVisible}
        // Categoria da escola
        isCheckoutSchool
      />

      {/* Drawer Cancelamento de Contrato */}
      <CancellationDrawer
        isOpen={showCancellationDrawer}
        onClose={handleCancellationDrawerClose}
        onConfirm={getCancellationContractDialogMessage}
        processedInstallments={processedInstallments}
        isOnTheFirstPage={isOnTheFirstPage}
        setIsOnTheFirstPage={setIsOnTheFirstPage}
        form={cancelContractForm}
        setShowConfirmCancellationMessage={setShowConfirmCancellationMessage}
        availableInstallments={installmentsToCancelContract}
      />

      {/* Modal Cashier */}
      <InvoicesNotGeneratedDialog
        isOpen={showInvoicesNotGeneratedDialog}
        setIsOpen={setShowInvoicesNotGeneratedDialog}
      />

      {/* Modais Cancelamento de Contrato */}

      {isCancelContractEnabled && (
        <ConfirmationDialog
          isVisible={showCancellationErrorMessage}
          onClose={() => setShowCancellationErrorMessage(false)}
          submitHandler={() => setShowCancellationErrorMessage(false)}
          title="Esse contrato não pode ser cancelado"
        >
          <MuiTypography>
            Contratos com parcelas vencidas ou renegociadas não podem ser cancelados. Entre em
            contato com o atendimento.
          </MuiTypography>
        </ConfirmationDialog>
      )}

      <ConfirmationDialog
        isVisible={showConfirmCancellationMessage}
        onClose={() => setShowConfirmCancellationMessage(false)}
        submitHandler={handleSubmit(cancelContractSubmitHandler)}
        backButtonLabel="Voltar"
        buttonLabel="Cancelar contrato"
        title="Atenção"
      >
        <MuiTypography>
          O cancelamento de contrato é irreversível e altera os próximos repasses.
        </MuiTypography>
      </ConfirmationDialog>

      {Boolean(contentDialog) && (
        <ConfirmationDialogCtrl
          dialogContents={dialogContents}
          isVisible={Boolean(contentDialog)}
          onClose={() => setContentDialog(null)}
          selectedContentKey={contentDialog ?? undefined}
        />
      )}
    </PagedDrawerProvider>
  )
}
