import { Button } from '@gravity/button'
import { DatepickerPrimitives as Datepicker } from '@gravity/datepicker'
import styled from 'styled-components'

export const Form = styled.form`
  padding-top: ${props => props.theme.gravity.spacing[6]};
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const DatePickerWrapper = styled.div`
  .MuiTextField-root {
    width: 100%;
    .MuiInputBase-root {
      height: 40px;

      .MuiSvgIcon-root {
        fill: ${props => props.theme.gravity.colors['colors-background-accent-1']};
      }
    }
  }
`

export const TextFieldWrapper = styled.div`
  input {
    width: 100%;
  }
`

export const SelectWrapper = styled.div`
  button {
    width: 100%;
  }
`

export const DatepickerTrigger = styled(Datepicker.Trigger)`
  width: 100%;
`

export const OfferInfoWrapper = styled.div`
  border-top: 1px solid ${props => props.theme.gravity.colors['colors-border-neutral-2']};
  margin: ${props => props.theme.gravity.spacing[8]} 0;
  padding-top: ${props => props.theme.gravity.spacing[8]};
  width: auto;
`

export const OfferButton = styled(Button)`
  width: 100%;
`

export const Footer = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: end;
`

export const AddOfferBtnWrapper = styled.div`
  flex: 0 0 50%;
`
